import enLang from './entries/en_US';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import itLang from './entries/it_IT';
import ruLang from './entries/ru_RU';
import uzLang from './entries/uz_UZ';

const AppLocale: any = {
  'en-US': enLang,
  'es-ES': esLang,
  'fr-FR': frLang,
  'ru-RU': ruLang,
  'uz-UZ': uzLang,
  'it-IT': itLang
};

export default AppLocale;
