import {ReportTranslationsType} from './ReportTranslationsType';

export const enReportTranslations: ReportTranslationsType = {
  'report.modal.title': 'Campaign Report Generation',
  'report.modal.error.title': 'Error',
  'report.modal.error.text': 'An error occurred during the report generation',
  'report.modal.selector.text':
    'Select the language in which the report should be generated',
  'report.modal.error.button': 'Close',
  'report.modal.button': 'Download',
};
