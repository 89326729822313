import {takeEvery, all, put, select, call} from "@redux-saga/core/effects";
import rsf from "shared/services/ReduxSagaFirebase";
import {AppState} from "shared/store";
import * as FirebaseFirestore from '@firebase/firestore-types';
import {subscriptionActions, LoadInvoicesInfoAction, LOAD_INVOICES_INFO, LOAD_SUBSCRIPTIONS_LISTING, LoadSubscriptionsListingAction} from "../actions/SubscriptionsActions";
import Subscription from "../models/Subscription";
import api, {pixagriApiUrl} from "shared/services/ApiConfig";
import {AxiosResponse} from "axios";
import PixagriSubscription from "../models/PixagriSubscription";
import Stripe from "stripe";
import {SelectedSubscriptionState} from "../models/SubscriptionsState";
import {fetchError, fetchStart, fetchSuccess} from "shared/actions";
import log from "shared/services/LogService";
import {appIntl} from "shared/utils/IntlGlobalProvider";
import {actions as fieldsAction} from '../../fields/actions/FieldsActions';
import {delay} from "redux-saga/effects";

function getSubscriptionsRequest(): Promise<AxiosResponse<PixagriSubscription[]>> {
    const subsriptionsUrl = `${pixagriApiUrl}/subscriptions`;
    return api.get(subsriptionsUrl);
}

function* loadSubscriptions(action: LoadSubscriptionsListingAction) {

    try {
        const {user} = yield select((state: AppState) => state.auth)
        const uid = user.uid

        yield put(fetchStart('loadSubscriptions'))

        if (action.payload.delay) {
            yield delay(action.payload.delay)
        }
        
        // Fetch from pixagri
        let pixagriSubscriptions: {[key: number]: PixagriSubscription} = {};
        const pixagriSubscriptionsResp: AxiosResponse<PixagriSubscription[]> = yield call(getSubscriptionsRequest);
        if (pixagriSubscriptionsResp && pixagriSubscriptionsResp.data) {
            const pixagriSubscriptionsList = pixagriSubscriptionsResp.data;
            pixagriSubscriptionsList.forEach((pixagriSubscription) => {
                if (pixagriSubscription.sub_id) {
                    pixagriSubscriptions[pixagriSubscription.sub_id] = pixagriSubscription;
                }
            })
        }

        // Fetch from firebase
        const subscriptionsSnapShot: FirebaseFirestore.QuerySnapshot = yield call(rsf.firestore.getCollection, `customers/${uid}/subscriptions`)
        let subscriptions: {[key: string]: Subscription} = {};
        subscriptionsSnapShot.forEach((data) => {
            const subscriptionData = data.data() as Subscription;
            subscriptionData.id = data.id;
            subscriptions[data.id] = subscriptionData;
        })

        yield put(subscriptionActions.loadSubscriptionsSuccess(subscriptions, pixagriSubscriptions));
        yield put(fetchSuccess('loadSubscriptions'));

        yield put(fieldsAction.loadFieldsSummary(null, true, null, user));

    } catch (error) {
        log.error(error);
        yield put(
            fetchError(
              'loadSubscriptions',
              error.message,
            ),
          );
    }
}

function* loadInvoicesInfoSaga(action: LoadInvoicesInfoAction) {
    const subscriptionId = action.payload;
    try {
        yield put(fetchStart("loadInvoicesInfoSaga"));
        const {user} = yield select((state: AppState) => state.auth)
        const uid = user.uid

        const invoicesSnapshot: FirebaseFirestore.QuerySnapshot = yield call(rsf.firestore.getCollection, `customers/${uid}/subscriptions/${subscriptionId}/invoices`)
        const selectedSubscription: SelectedSubscriptionState = {
            subscriptionId: subscriptionId,
            invoices: {} as {[key: string]: Stripe.Invoice}
        }
        invoicesSnapshot.forEach(snap => {
            const invoiceId = snap.id as string
            const invoiceData = snap.data() as Stripe.Invoice
            selectedSubscription.invoices = {
                ...selectedSubscription.invoices,
                [invoiceId]: invoiceData
            }
        })

        yield put(subscriptionActions.loadInvoicesInfoSuccess(selectedSubscription))
        yield put(fetchSuccess("loadInvoicesInfoSaga"));
    } catch (error) {
        log.error(`Error fetching invoices for subscription ${subscriptionId}`, error);
        yield put(fetchError("loadInvoicesInfoSaga", appIntl().formatMessage({id: "subscriptions.load_invoices_error"})));
    }
}

export function* subscriptionsSaga() {
    yield all([
        takeEvery(LOAD_SUBSCRIPTIONS_LISTING, loadSubscriptions),
        takeEvery(LOAD_INVOICES_INFO, loadInvoicesInfoSaga)
    ])
}