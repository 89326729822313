import React from 'react';
import {Box, Typography} from '@mui/material';
import InfoIcon from 'shared/components/Icons/InfoIcon';
import IntlMessages from 'shared/components/IntlMessages';

interface NoDataComponentProps {
  messageId?: string;
}

const NoDataComponent: React.FC<NoDataComponentProps> = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        width: '100%',
        height: '100%',
      }}>
      <InfoIcon color="primary" fontSize="large" />
      <Typography variant="body1" color="textSecondary" textAlign={"center"}>
        <IntlMessages id={props.messageId ? props.messageId : 'common.noDataLoaded'} />
      </Typography>
    </Box>
  );
};

export default NoDataComponent;
