import React from 'react';
import NitrogenCoverHelp from './NitrogenCoverHelp';

interface NitrogenHelpProps {
  /**
   * to manage which part of the information dialog is shown
   */
  selectedInfo: string;

  onActiveStepChange: (i: number) => void;

  step: number;
}

/**
 * Component to display the totality or different part of the information dialog
 * Contains a button to navigate between the different part of the dialog
 */
const NitrogenHelp: React.FC<NitrogenHelpProps> = ({
  selectedInfo,
}) => {
  return <NitrogenCoverHelp />
};

export default NitrogenHelp;
