import {Adjust, PushPin} from '@mui/icons-material';
import {
  Box,
  Card,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import bboxPolygon from '@turf/bbox-polygon';
import {Feature, FeatureCollection} from 'geojson';
import log from 'loglevel';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import 'mapbox-gl/dist/mapbox-gl.css';
import CarbonLegend from 'modules/carbon/components/CarbonLegend';
import NitrogenMapLegend from 'modules/nitrogen/components/NitrogenMapLegend';
import {FieldNitrogenService} from 'modules/nitrogen/models/FieldNitrogenService';
import HydroStressLegend from 'modules/waterBalance/components/HydroStressLegend';
import NdviLegend from 'modules/waterBalance/components/NdviLegend';
import WaterBalanceModel from 'modules/waterBalance/models/WaterBalanceModel';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import ReactMapGL, {Layer, MapEvent, Marker, Source} from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import useDimensions from 'react-use-dimensions';
import {Dispatch} from 'redux';
import useMapIcons from 'shared/hooks/useMapIcons';
import {ServiceId} from 'shared/models/RouterParam';
import {AppState} from 'shared/store';
import {mapToken} from '../../../shared/configs/AppConst';
import NdviSelector, {
  NdviSelectorMode,
} from '../../waterBalance/components/NdviSelector';
import fieldsActions from '../actions/FieldsActions';
import {
  ZOOM_SWITCH,
  generateFieldMapBboxStyle,
  generateFieldMapPolygonStyle,
  generateFieldMapSymbolStyle,
} from '../configs/FieldMapStyles';
import ndviMapStyle from '../configs/NdviMapStyle';
import Field from '../models/Field';
import {
  FieldCarbonService,
  FieldIrrigationService,
  FieldVegetationService,
} from '../models/FieldTesting';
import MapPosition from '../models/MapPosition';
import {replaceFieldId} from '../services/FieldsService';
import useStyles from './FieldsMap.style';
import FieldsMapTooltip from './FieldsMapTooltip';
// @ts-ignore
import {NitrogenChartUnit} from 'modules/nitrogen/models/NitrogenChartUnit';
import {Biomass} from 'modules/nitrogen/models/NitrogenCoverSat';
import {getColorGradient} from 'modules/nitrogen/services/NitrogenService';
import GetStatisticPixelValueEditor from 'modules/statistics/components/GetStatisticPixelValueEditor';
import VegetationUnitSelected from 'modules/vegetation/models/VegetationUnitSelected';
import NoDataComponent from 'shared/components/NoDataComponent';
import airbusLogo from '../../../assets/images/logo_airbus.png';
import FieldMapTools from '../models/FieldMapTools';

const geoViewport = require('@mapbox/geo-viewport');

// See transpilation issue:
// Here: https://github.com/visgl/react-map-gl/issues/1266
// Here: https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
const MapboxWorker =
  /* eslint-disable-next-line import/no-webpack-loader-syntax */
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// @ts-ignore
mapboxgl.workerClass = MapboxWorker;

/**
 * Zoom level offset use to add margin around the bbox
 */
export const ZOOM_LEVEL_MARGIN_OFFSET = 1;

/**
 *
 * @param zoomBbox
 */
const zoomToBbox = function (
  dispatch: Dispatch,
  zoomBbox: number[],
  width: number,
  height: number,
) {
  // Get the viewport from the all the features or the filtered features
  log.debug(`Size: ${width} x ${height}`);
  const viewport = geoViewport.viewport(zoomBbox, [width, height]);
  log.debug(`Target view port: ${JSON.stringify(viewport)}`);
  if (viewport && viewport.zoom) {
    dispatch(
      fieldsActions.setMapPosition({
        latitude: viewport.center[1],
        longitude: viewport.center[0],
        zoom: viewport.zoom - ZOOM_LEVEL_MARGIN_OFFSET,
      }),
    );
    dispatch(fieldsActions.setZoomToSelected(false));
  }
};

interface MapNdvi {
  coordinates: number[][];
  url: string;
  id: string;
  style: any;
}

interface FieldsMapProps {
  /**
   * If false, the geocoder component to look for a place is not displayed
   */
  geocoder?: boolean;

  /**
   * handle custom use of date selector
   */
  ndviSelectorMode?: NdviSelectorMode;

  /**
   * use to base map on compared date
   */
  compare?: boolean;

  /**
   * Custom campaign id to display specific data on map
   */
  selectedCampaignAnalysis?: number;

  /**
   * custom props for nitrogen service, handle image and legend to display
   */
  nitrogenUnitSelected?: NitrogenChartUnit;

  /**
   * custom props for nitrogen service, handle image and legend to display
   */
  vegetationUnit?: VegetationUnitSelected;
}

/**
 * Components to display fields map with markers, polygons, ndvi raster depending on scale.
 * It includes map overlay component such as:
 * - Geocoder
 * - Legend
 * - Date selection
 * - Tooltip window
 * @param props
 */
const FieldsMap: React.FC<FieldsMapProps> = (props) => {
  const dispatch: Dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const [ref, {width, height}] = useDimensions();
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const [clickedPosition, setClickedPosition] = useState<number[] | null>(null);

  const [hoveredState, setHoveredState] = useState<{
    featureId: number | null;
    x: number;
    y: number;
  }>({
    featureId: null,
    x: 0,
    y: 0,
  });
  const [mapFeatures, setMapFeatures] = useState<{
    fieldsCentroidGeojson?: FeatureCollection;
    fieldsGeojson?: FeatureCollection;
    ndvis?: MapNdvi[];
    bboxFeature?: Feature;
  }>({});
  const [targetZoomBbox, setTargetZoomBbox] = useState<number[] | null>(null);

  // Use auth state to get the thresholds
  const {user} = useSelector<AppState, AppState['auth']>((state) => state.auth);

  // Use the fields state to retrieve the list of fields and display the polygons
  const {
    data,
    selectedFieldId,
    selectedCampaign,
    selectedService,
    zoomToSelected,
    mapPosition,
    bbox,
    filteredFields,
    filteredFieldsBbox,
  } = useSelector<AppState, AppState['fields']>(({fields}) => fields);
  // Use the water balance state to display the right ndvi image
  const {
    data: waterBalanceData,
    selectedNdviDate,
    selectedCompareNdviDate,
  } = useSelector<AppState, AppState['waterBalance']>(
    (state) => state.waterBalance,
  );

  const {selectedBiomassDate, coversat} = useSelector<
    AppState,
    AppState['nitrogen']
  >((state) => state.nitrogen);

  const mapRef = useRef(null);
  useMapIcons(mapRef);

  useEffect(() => {
    // Build the geojson with fields and MapNdvi
    const fieldsGeojson: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    const fieldsCentroidGeojson: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    let bboxFeature: Feature | undefined = undefined;
    let ndvis: MapNdvi[] = [];
    let targetZoomBbox: number[] | null = null;
    let selectedField: Field | null = null;
    // TODO : improve logic with custom campaign
    if (data && selectedCampaign) {
      const fieldsById = data[selectedCampaign];
      if (fieldsById) {
        if (selectedFieldId) {
          selectedField = fieldsById[selectedFieldId];
        }
        const fields = Object.values(fieldsById);
        fields.forEach((field) => {
          let selectedRotation: FieldIrrigationService | null = null;
          let selectedPeriod: FieldCarbonService | null = null;
          let selectedVegetation: FieldVegetationService | null = null;
          // @ts-ignore
          let selectedCoversat: FieldNitrogenService | null = null;

          if (field.irrigation && field.irrigation.length) {
            selectedRotation =
              field.irrigation.find(
                (rotation) => rotation.campaign_id === selectedCampaign,
              ) || null;
          }
          if (field.carbon && field.carbon.length) {
            const campaign = props.selectedCampaignAnalysis
              ? props.selectedCampaignAnalysis
              : selectedCampaign;
            selectedPeriod =
              field.carbon.find((period) => period.campaign_id === campaign) ||
              null;
          }
          if (field.vegetation && field.vegetation.length) {
            selectedVegetation =
              field.vegetation.find(
                (veg) => veg.campaign_id === selectedCampaign,
              ) || null;
          }
          if (field.nitrogen && field.nitrogen.length) {
            // @ts-ignore
            selectedCoversat =
              field.nitrogen.find(
                (cover) => cover.campaign_id === selectedCampaign,
              ) || null;
          }

          // Add the feature only if no filter or included in the filter
          if (
            field &&
            (!filteredFields || filteredFields.includes(field.field_id))
          ) {
            const hovered = field.field_id === hoveredState.featureId;
            const selected = field.field_id === selectedFieldId;

            if (field.centroid) {
              const centroidFeature: Feature = {
                type: 'Feature',
                id: field.field_id,
                geometry: {
                  type: 'Point',
                  coordinates: field.centroid,
                },
                properties: {
                  hovered: hovered,
                  selected: selected,
                  hydro_stress_level: selectedRotation
                    ? selectedRotation.hydro_stress_level
                    : null,
                  carbon_flow_level: selectedPeriod
                    ? selectedPeriod.carbon_flow_level
                    : null,
                  n_tot_color:
                    selectedCoversat && selectedCoversat.val_mean_n_tot
                      ? getColorGradient(selectedCoversat.val_mean_n_tot, theme)
                      : theme.palette.grey[400],
                },
              };
              fieldsCentroidGeojson.features.push(centroidFeature);
            }

            if (field.geojson_geometry) {
              const feature: Feature = {
                type: 'Feature',
                id: field.field_id,
                geometry: field.geojson_geometry,
                properties: {
                  hovered: hovered,
                  selected: selected,
                  hydro_stress_level: selectedRotation
                    ? selectedRotation.hydro_stress_level
                    : null,
                  carbon_flow_level: selectedPeriod
                    ? selectedPeriod.carbon_flow_level
                    : null,
                  n_tot_color:
                    selectedCoversat &&
                    selectedCoversat.val_mean_n_tot &&
                    !selected
                      ? getColorGradient(selectedCoversat.val_mean_n_tot, theme)
                      : theme.palette.grey[400],
                },
              };
              fieldsGeojson.features.push(feature);

              let ndviCoordinates: number[][] | null = null;
              let ndviPic: string | null = null;
              // If the field is selected or we have an overview map with NDVI display
              if (
                selectedService &&
                (selectedService === ServiceId.VEGETATION ||
                  (selectedService === ServiceId.IRRIGATION &&
                    selectedFieldId)) &&
                selectedVegetation
              ) {
                //TODO: handle vegetation indicator
                if (
                  props.vegetationUnit === VegetationUnitSelected.FCOVER &&
                  selectedVegetation.fcover
                ) {

                  ndviPic = selectedVegetation.fcover.pic;
                } else if (
                  props.vegetationUnit === VegetationUnitSelected.LAI &&
                  selectedVegetation.lai
                ) {
                  ndviPic = selectedVegetation.lai.assets.img_url;
                }
                if (selectedVegetation.ndvi_coordinates) {
                  ndviCoordinates = selectedVegetation.ndvi_coordinates;
                }
                if (selected) {
                  const date = props.compare
                    ? selectedCompareNdviDate
                    : selectedNdviDate;
                  if (date) {
                    let foundWaterBalanceModel = null;
                    //TODO: handle vegetation indicator
                    if (
                      props.vegetationUnit === VegetationUnitSelected.FCOVER
                    ) {
                      foundWaterBalanceModel =
                        waterBalanceData.fcover_verde.find(
                          (waterBalance: WaterBalanceModel) => {
                            return waterBalance.wb_date === date;
                          },
                        );
                    } else if (
                      props.vegetationUnit === VegetationUnitSelected.LAI &&
                      waterBalanceData.lai_verde
                    ) {
                      foundWaterBalanceModel = waterBalanceData.lai_verde.find(
                        (waterBalance: WaterBalanceModel) => {
                          return waterBalance.wb_date === date;
                        },
                      );
                    }
                    if (
                      foundWaterBalanceModel &&
                      foundWaterBalanceModel.img_url
                    ) {
                      ndviPic = foundWaterBalanceModel.img_url;
                      if (foundWaterBalanceModel.bbox) {
                        try {
                          const ndvi_bbox = JSON.parse(
                            foundWaterBalanceModel.bbox,
                          );
                          // Start from the top left corner and clockwise to have correct image display
                          ndviCoordinates = [
                            [ndvi_bbox[0], ndvi_bbox[3]],
                            [ndvi_bbox[2], ndvi_bbox[3]],
                            [ndvi_bbox[2], ndvi_bbox[1]],
                            [ndvi_bbox[0], ndvi_bbox[1]],
                          ];
                        } catch (error) {
                          log.error(
                            `Error while parsing fcover_verde_pic_bbox for field ${field.field_id} and water balance ${foundWaterBalanceModel.wb_date}`,
                          );
                        }
                      }
                    }
                  }
                }
              } else if (
                selectedService &&
                selectedService === ServiceId.NITROGEN &&
                coversat &&
                selectedCoversat &&
                props.nitrogenUnitSelected
              ) {
                const date =
                  selectedBiomassDate || selectedCoversat.date_biomass;
                if (selected && date) {
                  const biomassFound = coversat.biomass.find(
                    (bio) => bio.date_biomass === date,
                  );
                  if (biomassFound) {
                    ndviPic =
                      (biomassFound[
                        `path_${props.nitrogenUnitSelected}_png` as keyof Biomass
                      ] as string) || null;
                    try {
                      const ndvi_bbox = JSON.parse(biomassFound.bbox);
                      ndviCoordinates = [
                        [ndvi_bbox[0], ndvi_bbox[3]],
                        [ndvi_bbox[2], ndvi_bbox[3]],
                        [ndvi_bbox[2], ndvi_bbox[1]],
                        [ndvi_bbox[0], ndvi_bbox[1]],
                      ];
                    } catch (error) {
                      log.error(
                        `Error while parsing biomass_bbox for field ${field.field_id} and biomass ${biomassFound.date_biomass}`,
                      );
                    }
                  }
                }
              }
              if (ndviCoordinates && ndviPic) {
                let id = `overlay_${field.field_id}_${
                  selectedService === ServiceId.NITROGEN
                    ? props.nitrogenUnitSelected
                    : selectedService === ServiceId.VEGETATION ||
                      selectedService === ServiceId.IRRIGATION
                    ? props.vegetationUnit
                    : null
                }`;
                if (selected) {
                  id = `overlay_selected_${
                    selectedService === ServiceId.NITROGEN
                      ? props.nitrogenUnitSelected
                      : selectedService === ServiceId.VEGETATION ||
                        selectedService === ServiceId.IRRIGATION
                      ? props.vegetationUnit
                      : null
                  }`;
                }
                const ndviMapStyleClone = {...ndviMapStyle};
                ndviMapStyleClone.id = id;

                ndvis.push({
                  url: ndviPic,
                  coordinates: ndviCoordinates,
                  id: id,
                  style: ndviMapStyleClone,
                });
              }
            }
          }
        });
      }

      // When a field is selected, zoom on it
      if (fieldsGeojson.features.length > 0) {
        let zoomBbox: number[] = bbox;
        if (filteredFieldsBbox) {
          zoomBbox = filteredFieldsBbox;
        }
        if (zoomBbox && zoomBbox.length === 4) {
          bboxFeature = bboxPolygon(zoomBbox);
        }

        if (zoomToSelected) {
          log.debug(`Zoom to selected`);
          if (selectedField && selectedField.bbox) {
            targetZoomBbox = selectedField.bbox;
            log.debug(`Selected field bbox: ${selectedField.bbox}`);
          } else if (bboxFeature) {
            // Zoom using the viewport from the all the features or the filtered features
            targetZoomBbox = zoomBbox;
          }
          setTargetZoomBbox(targetZoomBbox);
        } else {
          setTargetZoomBbox(null);
        }
      }

      log.debug(`Refreshing map features (${fieldsGeojson.features.length})`);
      setMapFeatures({
        fieldsCentroidGeojson,
        fieldsGeojson,
        bboxFeature,
        ndvis,
      });
    }
  }, [
    data,
    selectedCampaign,
    zoomToSelected,
    selectedFieldId,
    filteredFields,
    hoveredState,
    selectedService,
    selectedNdviDate,
    selectedBiomassDate,
    coversat,
    props.nitrogenUnitSelected,
    selectedCompareNdviDate,
    waterBalanceData.fcover_verde,
    props.compare,
    props.selectedCampaignAnalysis,
    props.vegetationUnit,
    waterBalanceData.lai_verde,
  ]);

  useEffect(() => {
    if (targetZoomBbox && width && height) {
      log.debug(`Zoom to targetZoomBbox: ${targetZoomBbox}`);
      zoomToBbox(dispatch, targetZoomBbox, width, height);
    }
  }, [targetZoomBbox, width, height, dispatch]);

  /**
   *
   * @param viewport Dispatch redux event when viewport is changed
   */
  const onViewportChange = (viewport: any) => {
    const {width, height, ...mapPosition} = viewport;
    dispatch(fieldsActions.setMapPosition(mapPosition as MapPosition));
  };

  /**
   * Handle click on feature on map: dispatch an event to select another field
   * @param event
   */
  const onClick = (event: MapEvent) => {
    const {features} = event;

    if (selectedTool === FieldMapTools.RASTER_INFO) {
      setClickedPosition(event.lngLat);
    } else if (features && features.length > 0) {
      const firstClickedFeature = features[0];
      history.push(replaceFieldId(location.pathname, firstClickedFeature.id));
    }
  };

  /**
   *
   * @param event Handle mouse hover the feature on the map
   */
  const onHover = (event: MapEvent) => {
    const {features} = event;
    if (features && features.length > 0) {
      const firstClickedFeature = features[0];
      if (firstClickedFeature.id) {
        setHoveredState({
          featureId: firstClickedFeature.id,
          x: event.offsetCenter.x,
          y: event.offsetCenter.y,
        });
      }
    } else {
      if (hoveredState.featureId !== null) {
        setHoveredState({
          featureId: null,
          x: event.offsetCenter.x,
          y: event.offsetCenter.y,
        });
      }
    }
  };

  /**
   * Handle click on geocoder place
   * @param viewport The selected location viewport
   */
  const handleGeocoderViewportChange = (viewport: MapPosition) => {
    dispatch(fieldsActions.setMapPosition(viewport));
  };

  const mapLegend = () => {
    switch (selectedService) {
      case ServiceId.IRRIGATION:
        if (selectedFieldId) {
          return <NdviLegend className={classes.fieldsMapLegend} />;
        } else {
          return <HydroStressLegend className={classes.fieldsMapLegend} />;
        }
      case ServiceId.VEGETATION:
        return (
          <NdviLegend
            className={classes.fieldsMapLegend}
            unit={props.vegetationUnit}
          />
        );
      case ServiceId.CARBON:
        return <CarbonLegend className={classes.fieldsMapLegend} />;
      case ServiceId.NITROGEN:
        return (
          <NitrogenMapLegend
            className={classes.fieldsMapLegend}
            unit={props.nitrogenUnitSelected}
          />
        );
      default:
        return null;
    }
  };
  const fieldMapPolygonStyle = generateFieldMapPolygonStyle(
    theme,
    selectedService,
  );
  const fieldMapSymbolStyle = generateFieldMapSymbolStyle(
    theme,
    selectedService,
  );
  const fieldMapBboxStyle = generateFieldMapBboxStyle(theme);

  const selectedNitrogen = coversat?.biomass?.find(
    (biomass) => biomass.date_biomass === selectedBiomassDate,
  );

  const nitrogenImage =
    selectedNitrogen &&
    props.nitrogenUnitSelected &&
    selectedNitrogen[`path_${props.nitrogenUnitSelected}_tif`];

  let vegetationImage = null;
  if (props.vegetationUnit === VegetationUnitSelected.FCOVER) {
    vegetationImage = waterBalanceData?.fcover_verde?.find(
      (fcover) => fcover.wb_date === selectedNdviDate,
    )?.tif_url;
  }

  if (props.vegetationUnit === VegetationUnitSelected.LAI) {
    vegetationImage = waterBalanceData?.lai_verde?.find(
      (lai) => lai.wb_date === selectedNdviDate,
    )?.tif_url;
  }

  return (
    <Card
      data-test='fields-map'
      className={classes.card}
      style={{marginBlock: 0, position: 'relative'}}
      ref={ref}>
      <ReactMapGL
        mapboxApiAccessToken={mapToken}
        width='100%'
        height='100%'
        ref={mapRef}
        {...mapPosition}
        mapStyle='mapbox://styles/mapbox/satellite-streets-v11'
        onViewportChange={onViewportChange}
        onClick={onClick}
        onHover={onHover}>
        {clickedPosition && selectedTool === FieldMapTools.RASTER_INFO && (
          <Marker
            longitude={clickedPosition[0]}
            latitude={clickedPosition[1]}
            offsetTop={-22}
            offsetLeft={-12}>
            <PushPin color='primary' />
          </Marker>
        )}

        {mapFeatures.fieldsCentroidGeojson && (
          <Source type='geojson' data={mapFeatures.fieldsCentroidGeojson}>
            <Layer {...fieldMapSymbolStyle} />
          </Source>
        )}

        {mapFeatures.fieldsGeojson && (
          <Source type='geojson' data={mapFeatures.fieldsGeojson}>
            <Layer {...fieldMapPolygonStyle} />
          </Source>
        )}

        {mapFeatures.ndvis &&
          mapFeatures.ndvis.map((ndvi) => {
            return (
              <Source
                key={ndvi.id}
                type='image'
                url={ndvi.url}
                coordinates={ndvi.coordinates}>
                <Layer {...ndvi.style} />
              </Source>
            );
          })}

        {mapFeatures.bboxFeature ? (
          <Source type='geojson' data={mapFeatures.bboxFeature}>
            <Layer {...fieldMapBboxStyle} />
          </Source>
        ) : null}
        {
          // TODO: change country "language={}"
        }

        {props.geocoder ? (
          <Geocoder
            mapRef={mapRef}
            onViewportChange={handleGeocoderViewportChange}
            mapboxApiAccessToken={mapToken}
            position='top-left'
            placeholder={intl.formatMessage({id: 'fields.map.searchLabel'})}
          />
        ) : null}
        {mapLegend()}

        {
          // Airbus copyright
          selectedService &&
            selectedService !== ServiceId.NITROGEN &&
            selectedService !== ServiceId.CARBON &&
            mapPosition.zoom > ZOOM_SWITCH &&
            selectedFieldId && (
              <Box
                className={classes.fieldsMapCopyright}
                display='flex'
                flexDirection='column'
                alignItems='flex-end'>
                <img width='50%' src={airbusLogo} alt='Airbus' />
                <Typography variant='caption' style={{color: 'white'}}>
                  © Airbus DS
                </Typography>
              </Box>
            )
        }

        {data &&
          hoveredState &&
          hoveredState.featureId &&
          selectedCampaign &&
          data[selectedCampaign] &&
          data[selectedCampaign][hoveredState.featureId] && (
            <FieldsMapTooltip
              campaignId={
                props.selectedCampaignAnalysis
                  ? props.selectedCampaignAnalysis
                  : selectedCampaign
              }
              field={data[selectedCampaign][hoveredState.featureId]}
              position={hoveredState}
              user={user}
            />
          )}
      </ReactMapGL>

      {data &&
      selectedFieldId &&
      props.ndviSelectorMode !== NdviSelectorMode.HIDDEN ? (
        <Card className={classes.fieldsMapNdviSelector}>
          <NdviSelector mode={props.ndviSelectorMode} />
        </Card>
      ) : null}

      {selectedFieldId &&
        (selectedService === ServiceId.NITROGEN ||
          selectedService === ServiceId.VEGETATION) && (
          <Box
            position='absolute'
            top={theme.spacing(4)}
            left={theme.spacing(4)}
            display={'flex'}>
            <ToggleButtonGroup
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.spacing(
                  1,
                  selectedTool ? 0 : 1,
                  selectedTool ? 0 : 1,
                  1,
                ),
              }}
              color='primary'
              orientation='vertical'
              value={selectedTool}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                newTool: string | null,
              ) => setSelectedTool(newTool)}
              aria-label='text alignment'>
              <ToggleButton
                value={FieldMapTools.RASTER_INFO}
                onClick={(evt) => {
                  // Otherwise a click is sent automatically via mapbox
                  setTimeout(() => {
                    setClickedPosition(null);
                  }, 500);
                }}>
                <Adjust />
              </ToggleButton>
            </ToggleButtonGroup>
            {selectedTool ? (
              <Box>
                <Box
                  bgcolor={theme.palette.background.paper}
                  sx={{
                    borderRadius: theme.spacing(0, 1, 1, 0),
                  }}>
                  {selectedTool === FieldMapTools.RASTER_INFO &&
                    selectedService === ServiceId.NITROGEN && (
                      <GetStatisticPixelValueEditor
                        size={'small'}
                        image={nitrogenImage}
                        bbox={bbox}
                        collapsedTextFields={true}
                        position={clickedPosition}
                        box={{width: 300, pl: 4, zIndex: 10}}
                      />
                    )}
                  {selectedTool === FieldMapTools.RASTER_INFO &&
                    selectedService === ServiceId.VEGETATION &&
                    vegetationImage && (
                      <GetStatisticPixelValueEditor
                        size={'small'}
                        image={vegetationImage}
                        bbox={bbox}
                        collapsedTextFields={true}
                        position={clickedPosition}
                        box={{width: 300, pl: 4, zIndex: 10}}
                      />
                    )}
                  {selectedTool === FieldMapTools.RASTER_INFO &&
                    selectedService === ServiceId.VEGETATION &&
                    !vegetationImage && (
                      <Box width={200} p={2}>
                        <NoDataComponent
                          messageId={intl.formatMessage({
                            id: 'fields.map.no_data',
                          })}
                        />
                      </Box>
                    )}
                </Box>
              </Box>
            ) : null}
          </Box>
        )}
    </Card>
  );
};

export default FieldsMap;
