import {FormikProps} from 'formik';
import React from 'react';
import MyTextField from 'shared/components/MyTextField';
import {WeenatWeatherStationCredentials} from '../models/BaseWeatherStationSchema';
import {UserWeatherStationPayload} from '../models/UserWeatherStationPayload';

interface WeenatProviderProps {
  formikProps: FormikProps<
    UserWeatherStationPayload<WeenatWeatherStationCredentials>
  >;
  disable: boolean;
}

const WeenatCredentialsForm: React.FC<WeenatProviderProps> = ({
  formikProps,
  disable,
}) => {
  const credentials = formikProps.values
    .credentials as WeenatWeatherStationCredentials;
  return (
    <>
      <MyTextField
        variant='outlined'
        name='credentials.email'
        label='Email'
        type='email'
        value={credentials.email}
        disabled={disable}
      />
    </>
  );
};

export default WeenatCredentialsForm;
