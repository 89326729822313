import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from '@mui/lab/DatePicker';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import {FieldArray, FormikErrors, FormikProps} from 'formik';
import {DateTime} from 'luxon';
import {FieldFormEditMode} from 'modules/fields/components/AddFieldForm';
import FieldCreation from 'modules/fields/models/FieldCreation';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import MyTextField from 'shared/components/MyTextField';
import {AppState} from 'shared/store';
import {CoverCropExploitation} from '../models/CoverCropExploitation';
import {CoverCropComposition} from '../models/NitrogenCoverCropPost';
import {ResiduManagement} from '../models/ResidueManagement';
import {getSpecieTranslation} from '../services/NitrogenService';

interface NitrogenEditProps {
  formikProps: FormikProps<FieldCreation>;
  editMode: FieldFormEditMode;
}
const NitrogenEdit: React.FC<NitrogenEditProps> = (props) => {
  const {formikProps, editMode} = props;
  const {species} = useSelector<AppState, AppState['nitrogen']>(
    (state) => state.nitrogen,
  );


  const theme = useTheme();
  const intl = useIntl();


  if (species) {
    return (
      <Box sx={{overflowY: 'auto', overflowX: 'hidden'}} pt={3}>
        <Typography variant='h6'>
          <FormattedMessage id='nitrogen.setting.title' />
        </Typography>

        <Box p={1} py={2} width={1}>
          <DatePicker
            disabled={editMode === FieldFormEditMode.MODIFY_FIELD_PAGE}
            value={
              formikProps.values.cover?.sowing_date
                ? DateTime.fromISO(formikProps.values.cover?.sowing_date)
                : null
            }
            onChange={(date: DateTime | null) => {
              formikProps.setFieldValue(
                'cover.sowing_date',
                date?.toISODate(),
                true,
              );
            }}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{width: '100%'}}
                variant='outlined'
                name='cover.sowing_date'
                label={intl.formatMessage({
                  id: 'nitrogen.form.label.sowing_date',
                })}
                inputProps={{
                  ...params.inputProps,
                  placeholder: intl.formatMessage({
                    id: 'common.input.date.placeholder',
                  }),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
        <FieldArray
          name='cover.covercrop_composition'
          render={(arrayHelper) => (
            <Box
              py={2}
              display='flex'
              flexDirection={'column'}
              alignItems={'center'}>
              <Box width={1}>
                {formikProps.values.cover?.covercrop_composition?.map(
                  (compo: CoverCropComposition, index: number) => (
                    <Box
                      key={index}
                      display={'flex'}
                      width={1}
                      position={'relative'}>
                      <Box p={1} flex={1}>
                        <MyTextField
                          value={getSpecieTranslation(compo.cc_species_id)}
                          style={{
                            minWidth: '100%',
                          }}
                          select
                          variant='outlined'
                          name={`cover.covercrop_composition[${index}].cc_species_id`}
                          label={intl.formatMessage({
                            id: 'nitrogen.form.label.covercrop_composition.species_name.placeholder',
                          })}
                          SelectProps={{
                            displayEmpty: true,
                            defaultValue: 0,
                          }}
                          inputProps={{
                            placeholder: intl.formatMessage({
                              id: 'nitrogen.form.label.covercrop_composition.species_name.placeholder',
                            }),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}>
                          <MenuItem disabled value={0}>
                            {''}
                          </MenuItem>
                          {Object.values(species).map((spec, index) => (
                            <MenuItem key={index} value={spec.cc_species_id}>
                              {getSpecieTranslation(spec.cc_species_id)}
                            </MenuItem>
                          ))}
                        </MyTextField>
                      </Box>
                      <Box p={1} flex={0.5} maxWidth={'25%'}>
                        <MyTextField
                          variant='outlined'
                          label={intl.formatMessage({
                            id: 'nitrogen.form.label.covercrop_composition.proportion',
                          })}
                          value={compo.cc_species_proportion}
                          type='number'
                          name={`cover.covercrop_composition[${index}].cc_species_proportion`}
                        />
                      </Box>
                      {index !== 0 && (
                        <IconButton
                          onClick={() => {
                            arrayHelper.remove(index);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  ),
                )}
              </Box>
              <Button
                variant='text'
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  paddingY: theme.spacing(2),
                }}
                onClick={() => {
                  arrayHelper.push({
                    species_name: '',
                    cc_species_id: 0,
                    cc_species_proportion: 0,
                  });
                }}>
                <FormattedMessage id='nitrogen.form.button.add_covercrop.title' />
              </Button>
              {formikProps.errors.cover &&
              formikProps.errors.cover[
                'covercrop_composition' as keyof FormikErrors<FieldCreation>['cover']
              ] &&
              typeof formikProps.errors.cover[
                'covercrop_composition' as keyof FormikErrors<FieldCreation>['cover']
              ] === 'string' ? (
                <Typography variant='caption' color='error'>
                  {
                    formikProps.errors.cover[
                      'covercrop_composition' as keyof FormikErrors<FieldCreation>['cover']
                    ]
                  }
                </Typography>
              ) : (
                ''
              )}
            </Box>
          )}
        />
        <Box py={2} display='flex' width={1}>
          <Box p={1} flex={1}>
            <MyTextField
              style={{width: '100%'}}
              select
              variant='outlined'
              value={formikProps.values.cover?.residue_management}
              name='cover.residue_management'
              label={intl.formatMessage({
                id: 'nitrogen.form.label.residu_management',
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'nitrogen.form.label.residu_management',
                }),
              }}
              InputLabelProps={{
                shrink: true,
              }}>
              {Object.values(ResiduManagement).map(
                (value: ResiduManagement, index) => (
                  <MenuItem key={index} value={value}>
                    <FormattedMessage
                      id={`nitrogen.form.selctor.residu_management.${value}`}
                    />
                  </MenuItem>
                ),
              )}
            </MyTextField>
          </Box>
          <Box py={2} p={1} flex={1}>
            <MyTextField
              select
              style={{width: '100%'}}
              variant='outlined'
              value={formikProps.values.cover?.covercrop_exploitation}
              name='cover.covercrop_exploitation'
              label={intl.formatMessage({
                id: 'nitrogen.form.label.covercrop_exploitation',
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'nitrogen.form.label.covercrop_exploitation',
                }),
              }}
              InputLabelProps={{
                shrink: true,
              }}>
              {Object.values(CoverCropExploitation).map((value, index) => (
                <MenuItem key={index} value={value}>
                  <FormattedMessage
                    id={`nitrogen.form.selctor.covercrop_exploitation.${value}`}
                  />
                </MenuItem>
              ))}
            </MyTextField>
          </Box>
        </Box>
        <Box display='flex' width={1}>
          <Box p={1} flex={1}>
            <DatePicker
              value={
                formikProps.values.cover?.destruction_date
                  ? DateTime.fromISO(formikProps.values.cover?.destruction_date)
                  : null
              }
              onChange={(date: DateTime | null) => {
                formikProps.setFieldValue(
                  'cover.destruction_date',
                  date?.toISODate(),
                  true,
                );
              }}
              renderInput={(params) => (
                <MyTextField
                  {...params}
                  style={{width: '100%'}}
                  variant='outlined'
                  name='cover.destruction_date'
                  label={intl.formatMessage({
                    id: 'nitrogen.form.label.destruction_date',
                  })}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: intl.formatMessage({
                      id: 'common.input.date.placeholder',
                    }),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Box>
          <Box p={1} flex={1}>
            <MyTextField
              style={{width: '100%'}}
              label={intl.formatMessage({
                id: 'nitrogen.form.label.target_n_quantity',
              })}
              variant='outlined'
              value={formikProps.values.cover?.target_n_quantity}
              name='cover.target_n_quantity'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};

export default NitrogenEdit;
