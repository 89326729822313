import axios from 'axios';
import log from 'loglevel';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

log.info(`Environment: ${process.env.REACT_APP_ENVIRONMENT_NAME}`);
export const pixagriApiServer = process.env.REACT_APP_ENVIRONMENT_NAME === "prod" ? `${process.env.REACT_APP_PIXAGRI_SERVER}` : `${process.env.REACT_APP_PIXAGRI_DEV_SERVER}`;
export const pixagriApiUrl = process.env.REACT_APP_ENVIRONMENT_NAME === "prod" ? `${pixagriApiServer}${process.env.REACT_APP_PIXAGRI_API_PREFIX_PROD}` : `${pixagriApiServer}${process.env.REACT_APP_PIXAGRI_API_PREFIX_DEV}`;

log.info(`Pixagri Api Url: ${pixagriApiUrl}`);

export default axiosInstance;
