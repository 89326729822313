import {EnvNames} from '../models/EnvNames';
import PixagriApplication from '../models/PixagriApplications';

class ApiService {
  SUFFIX_API_BY_ENV: {[envName in EnvNames]?: string} = {
    [EnvNames.DEV]: '7tboxlktjq-ew.a.run.app',
    [EnvNames.PROD]: '6nvegtsita-ew.a.run.app',
  };

  getApiUrl(applicationName: PixagriApplication, env: EnvNames) {
    let applicationUrl: string | null = null;
    if (env === EnvNames.PROD) {
      // TODO: prod url following 'https://api.terranis.fr/{applicationName}' using load balancer
    }

    // Default application url
    if (!applicationUrl) {
      const suffix = this.SUFFIX_API_BY_ENV[env];
      if (!suffix) {
        throw new Error(`No suffix found for env ${env}`);
      }

      applicationUrl = `https://${applicationName}-${suffix}`;
    }

    return applicationUrl;
  }
}

const apiService = new ApiService();
export default apiService;
