import React from 'react';
import {RouteConfig} from 'react-router-config';
import {dashboardRoutePath} from 'modules/dashboards/configs/DashboardRoutePaths';

export const dashboardRouterConfigs: RouteConfig[] = [
  {
    auth: ['user'],
    routes: [
      {
        path: dashboardRoutePath,
        component: React.lazy(() => import('modules/dashboards/components/Dashboard')),
      },
    ],
  },
];
