import StatisticIndicatorLegendColorCase from '../models/StatisticIndicatorLegendColorCase';
import {SummaryVegetationServiceInfo} from '../models/SummaryVegetationServiceInfo';

export const SAVE_SUMMARY_VEGETATION_INFO = 'SAVE_SUMMARY_VEGETATION_INFO';
export interface SaveSummaryVegetationInfoAction {
  type: typeof SAVE_SUMMARY_VEGETATION_INFO;
  payload: SummaryVegetationServiceInfo;
}
export const LEGEND_COLOR_CASE_SUCCESS = 'LEGEND_COLOR_CASE_SUCCESS';
export interface LegendColorCaseSuccessAction {
  type: typeof LEGEND_COLOR_CASE_SUCCESS;
  payload: StatisticIndicatorLegendColorCase[];
}
export const LEGEND_COLOR_CASE = 'LEGEND_COLOR_CASE';
export interface LegendColorCaseAction {
  type: typeof LEGEND_COLOR_CASE;
  payload: {
    legendImage: string;
    min_value: number;
    max_value: number;
    legend_levels_count?: number;
  };
}
export const LEGEND_COLOR_CASE_LAI = 'LEGEND_COLOR_CASE_LAI';
export interface LegendColorCaseLaiAction {
  type: typeof LEGEND_COLOR_CASE_LAI;
  payload: StatisticIndicatorLegendColorCase[];
}

export type VegetationActionsTypes =
  | SaveSummaryVegetationInfoAction
  | LegendColorCaseSuccessAction
  | LegendColorCaseAction;

export const vegetationActions = {
  saveSummaryVegetationInfo: (
    infos: SummaryVegetationServiceInfo,
  ): SaveSummaryVegetationInfoAction => {
    return {
      type: SAVE_SUMMARY_VEGETATION_INFO,
      payload: infos,
    };
  },

  legendColorCaseSuccess: (
    data: StatisticIndicatorLegendColorCase[],
  ): LegendColorCaseSuccessAction => {
    return {
      type: LEGEND_COLOR_CASE_SUCCESS,
      payload: data,
    };
  },

  legendColorCase: (
    legendImage: string,
    min_value: number,
    max_value: number,
    legend_levels_count?: number,
  ): LegendColorCaseAction => {
    return {
      type: LEGEND_COLOR_CASE,
      payload: {
        legendImage,
        min_value,
        max_value,
        legend_levels_count,
      },
    };
  },

  legendColorCaseLai: (
    data: StatisticIndicatorLegendColorCase[],
  ): LegendColorCaseLaiAction => {
    return {
      type: LEGEND_COLOR_CASE_LAI,
      payload: data,
    };
  },
};

export default vegetationActions;
