import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppRootComponent from './AppRootComponent';
import AuthRoutes from 'shared/utils/AuthRoutes';
import LocaleProvider from 'shared/utils/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';

import configureStore, {history} from './shared/store';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from 'shared/utils/ErrorBoundary';
import LocalizationProviderComponent from 'shared/utils/LocalizationProviderComponent';

const store = configureStore();

/**
 * Application entry point
 */
const App = () => (
  <ContextProvider>
    <Provider store={store}>
      <CremaThemeProvider>
        <CremaStyleProvider>
            <LocaleProvider>
              <LocalizationProviderComponent>
                  <ConnectedRouter history={history}>
                    <ErrorBoundary>
                      <AuthRoutes>
                        <CssBaseline />
                        <AppRootComponent />
                      </AuthRoutes>
                    </ErrorBoundary>
                  </ConnectedRouter>
              </LocalizationProviderComponent>
            </LocaleProvider>
        </CremaStyleProvider>
      </CremaThemeProvider>
    </Provider>
  </ContextProvider>
);

export default App;
