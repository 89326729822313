/**
 *
 */
export const EMPTY_DATA_SOURCE_MESSAGE = 'table.emptyDataMessage';

/**
 *
 */
export const DELETE_TOOLTIP = 'table.deleteTooltip';

/**
 *
 */
export const DELETE_TEXT = 'table.editRow.deleteText';

/**
 *
 */
export const CANCEL_TOOLTIP = 'table.editRow.cancelTooltip';

/**
 *
 */
export const SAVE_TOOLTIP = 'table.editRow.saveTooltip';

export const PAGE_SIZE = 10;
