import {ReportTranslationsType} from './ReportTranslationsType';

export const itReportTranslations: ReportTranslationsType = {
  'report.modal.title': 'Generazione del Report di Campagna',
  'report.modal.error.title': 'Errore',
  'report.modal.error.text':
    'Si è verificato un errore durante la generazione del report',
  'report.modal.selector.text': 'Seleziona la lingua in cui generare il report',
  'report.modal.error.button': 'Chiudi',
  'report.modal.button': 'Scarica',
};
