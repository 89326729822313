import {makeStyles} from '@mui/styles';
import {CremaTheme} from 'shared/models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
    return {
        alerterContainer: {
            border: `solid 2px ${theme.palette.primary.main}`,
            borderRadius: '10px',
            backgroundColor: theme.palette.background.paper
        }
    };
});

export default useStyles;
