export const cropsTranslationsFr = {
  'crops.58.pheno_stage.stade_1': 'Levée',
  'crops.58.pheno_stage.stade_2': '1 feuille',
  'crops.58.pheno_stage.stade_3': '2 feuilles',
  'crops.58.pheno_stage.stade_4': '4 feuilles',
  'crops.58.pheno_stage.stade_5': '6 feuilles',
  'crops.58.pheno_stage.stade_6': '7 feuilles',
  'crops.58.pheno_stage.stade_7': '8 feuilles',
  'crops.58.pheno_stage.stade_8': '10 feuilles',
  'crops.58.pheno_stage.stade_f': 'Floraison',
  'crops.58.pheno_stage.stade_9': '32% MS',
  'crops.58.pheno_stage.stade_10': 'Maturité',
  'crops.59.pheno_stage.stade_1': 'Levée',
  'crops.59.pheno_stage.stade_2': '1 feuille',
  'crops.59.pheno_stage.stade_3': '2 feuilles',
  'crops.59.pheno_stage.stade_4': '4 feuilles',
  'crops.59.pheno_stage.stade_5': '6 feuilles',
  'crops.59.pheno_stage.stade_6': '7 feuilles',
  'crops.59.pheno_stage.stade_7': '8 feuilles',
  'crops.59.pheno_stage.stade_8': '10 feuilles',
  'crops.59.pheno_stage.stade_f': 'Floraison',
  'crops.59.pheno_stage.stade_9': '32% MS',
  'crops.59.pheno_stage.stade_10': 'Maturité',
  'crops.60.pheno_stage.stade_1': 'Levée',
  'crops.60.pheno_stage.stade_2': '1 feuille',
  'crops.60.pheno_stage.stade_3': '2 feuilles',
  'crops.60.pheno_stage.stade_4': '4 feuilles',
  'crops.60.pheno_stage.stade_5': '6 feuilles',
  'crops.60.pheno_stage.stade_6': '7 feuilles',
  'crops.60.pheno_stage.stade_7': '8 feuilles',
  'crops.60.pheno_stage.stade_8': '10 feuilles',
  'crops.60.pheno_stage.stade_f': 'Floraison',
  'crops.60.pheno_stage.stade_9': '32% MS',
  'crops.60.pheno_stage.stade_10': 'Maturité',
  'crops.102.pheno_stage.stade_1': 'Levée',
  'crops.102.pheno_stage.stade_2': '1 feuille',
  'crops.102.pheno_stage.stade_3': '2 feuilles',
  'crops.102.pheno_stage.stade_4': '4 feuilles',
  'crops.102.pheno_stage.stade_5': '6 feuilles',
  'crops.102.pheno_stage.stade_6': '7 feuilles',
  'crops.102.pheno_stage.stade_7': '8 feuilles',
  'crops.102.pheno_stage.stade_8': '10 feuilles',
  'crops.102.pheno_stage.stade_f': 'Floraison',
  'crops.102.pheno_stage.stade_9': '32% MS',
  'crops.102.pheno_stage.stade_10': 'Maturité',
};
