import {makeStyles} from '@mui/styles';
import {CremaTheme} from 'shared/models/AppContextPropsType';

// TODO: share styles in common
const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    headerStyle: {
      padding: '2em',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:first-child': {
        borderTopLeftRadius: '4px',
      },
      '&:last-child': {
        borderTopRightRadius: '4px',
      },
    },
    rowStyle: {
      padding: '2em',
      backgroundColor: theme.palette.background.default,
    },
    tableContainer: {
      padding: '2em'
    }
  };
});
export default useStyles;
