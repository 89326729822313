// action strings
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const REDIRECT_TO = 'REDIRECT_TO';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const HEADER_HEIGHT = 'HEADER_HEIGHT';

export interface HeaderHeightAction {
  type: typeof HEADER_HEIGHT;
  value: number;
}
export interface FetchStartAction {
  type: typeof FETCH_START;
  fetchId: string;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
  fetchId: string;
}

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  fetchId: string;
  error: string;
  redirectTo?: string;
}

export interface ShowMessageAction {
  type: typeof SHOW_MESSAGE;
  message: string;
  redirectTo?: string;
}

export interface RedirectToAction {
  type: typeof REDIRECT_TO;
  redirectTo: string;
}

export interface ToggleDrawerAction {
  type: typeof TOGGLE_APP_DRAWER;
}

export interface HideMessageAction {
  type: typeof HIDE_MESSAGE;
}

export type CommonActionTypes =
  | FetchStartAction
  | FetchSuccessAction
  | FetchErrorAction
  | RedirectToAction
  | ShowMessageAction
  | ToggleDrawerAction
  | HideMessageAction
  | HeaderHeightAction;
