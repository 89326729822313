import {Theme} from '@mui/material';
import {ZOOM_SWITCH} from 'modules/fields/configs/FieldMapStyles';
import {LayerProps} from 'react-map-gl'
import {SubscriptionsStatus} from '../models/SubscriptionStatus';

/**
 * Generate map polygon style using theme colors
 * For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
 * @param theme The app theme
 */
export const generateFieldMapSelectionPolygonStyle = function (theme: Theme): LayerProps {
    return {
        id: 'polygons',
        type: 'fill',
        paint: {
            'fill-color': [
                'case',
                ['==', ['get', 'selected'], true],
                theme.palette.primary.main,
                ['==', ['get', 'subscriptionStatus'], SubscriptionsStatus.ACTIVE],
                theme.palette.grey[400],
                theme.palette.error.main
            ],
            'fill-opacity': [
                'case',
                ['==', ['get', 'hovered'], true],
                1,
                0.5
            ],
            'fill-outline-color': theme.palette.primary.main,
        },
        minzoom: ZOOM_SWITCH
    };
}

/**
 * Generate map symbol style using theme colors
 * @param theme The app theme
 */
export const generateFieldMapSelectionSymbolStyle = function (theme: Theme): LayerProps {
    return {
        id: 'points',
        type: 'symbol',
        paint: {
            'icon-color': [
                'case',
                ['==', ['get', 'selected'], true],
                theme.palette.primary.main,
                ['==', ['get', 'subscriptionStatus'], SubscriptionsStatus.ACTIVE],
                theme.palette.grey[400],
                theme.palette.error.main
            ],
        },
        layout: {
            'icon-image': 'map-pin',
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
        },
        maxzoom: ZOOM_SWITCH
    }
};

