export const BORDER_RADIUS_DATA_BOX = '10px';

export const MIN_WIDTH_COVER_SOIL_BOX = '32%';
export const MIN_WIDTH_BARE_SOIL_BOX = '45%';

export const COVER_CARD_HEIGHT = '32%';
export const BARE_CARD_HEIGHT = '49%';

export const GAUGE_LARGE_SCREEN_WIDTH = '350px';
export const GAUGE_WIDTH = '225px';

export const gaugeColors = ['#00FF00', '#AAFF00', '#FFAA00', '#FF0000'];

export const ACTIVE_COLOR_TIMELINE_BARE_SOIL = "#FEBB21"

