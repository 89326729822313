import Field from 'modules/fields/models/Field';
import {FieldIrrigationService} from 'modules/fields/models/FieldTesting';
import log from 'shared/services/LogService';

export function getRotation(
  rotationId: number | string,
  fieldsData: {[key: string]: {[key: string]: Field}},
): FieldIrrigationService | null | undefined {
  if (fieldsData) {
    const fieldsListByCampaign = Object.values(fieldsData);
    let rotation: FieldIrrigationService | null = null;
    for (let i = 0; i < fieldsListByCampaign.length; i++) {
      const fieldList = Object.values(fieldsListByCampaign[i]);
      for (let j = 0; j < fieldList.length; j++) {
        if (fieldList[j].irrigation && fieldList[j].irrigation.length) {
          const selectedRotation = fieldList[j].irrigation.find(
            // eslint-disable-next-line eqeqeq
            (rot) => rot.rotation_id == rotationId,
          );
          if (selectedRotation) {
            rotation = selectedRotation;
          }
        }
      }
    }
    if (!rotation) {
      log.error(`Rotation with id ${rotationId} not found`);
      return null;
    }
    return rotation;
  }
}
