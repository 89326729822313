import React from 'react';
import {SvgIcon} from '@mui/material';
import {SvgIconProps} from '@mui/material';
import {ReactComponent as IrrigationIconTab} from '../../assets/irrigationIcon.svg';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean
}

const IrrigationIcon = (props: HeaderIconProps) => {
  return (
    <SvgIcon {...props} component={IrrigationIconTab} style={{fillOpacity: props.selected ? "1":"0.5"}} viewBox="0 0 13 16" />
  );
};

export default IrrigationIcon;
