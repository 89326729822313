import {ProductsState} from '../models/ProductsState';

export const LOAD_PRODUCT_LISTING = 'LOAD_PRODUCT_LISTING';
export interface LoadProductListingAction {
    type: typeof LOAD_PRODUCT_LISTING
}

export const LOAD_PRODUCT_LISTING_SUCCESS = 'LOAD_PRODUCT_LISTING_SUCCESS';
export interface LoadProductListingSuccessAction {
    type: typeof LOAD_PRODUCT_LISTING_SUCCESS
    payload: ProductsState['data']
}

export type ProductsActionTypes = LoadProductListingAction | LoadProductListingSuccessAction;
const actions = {
    loadProductListing: (): LoadProductListingAction => {
        return {
            type: LOAD_PRODUCT_LISTING
        }
    },
    loadProductListingSuccess: (products: ProductsState['data']): LoadProductListingSuccessAction => {
        return {
            type: LOAD_PRODUCT_LISTING_SUCCESS,
            payload: products
        }
    }
}
export default actions;