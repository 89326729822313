import {Box} from '@mui/material';
import Field from 'modules/fields/models/Field';
import {computeTotalAreaHa} from 'modules/fields/services/FieldsService';
import React from 'react';
import log from 'shared/services/LogService';
import {DAYS_IN_MONTH, DAYS_IN_WEEK, DAYS_IN_YEAR} from '../configs/constant';
import {Interval} from '../models/IntervalEnum';
import Price from '../models/Price';
import Product from '../models/Product';
import OfferCard, {OfferCardType} from './OfferCard';
import useStyles from './ProductStyle';

interface PriceSelectionStepProps {
    selectedFields: Field[];
    selectedPrice?: Price;
    setSelectedPrice: any;
    selectedProduct?: Product;
}
export default function PriceSelectionStep({selectedFields, selectedProduct, selectedPrice, setSelectedPrice}: PriceSelectionStepProps) {

    const classes = useStyles();

    const totalArea = computeTotalAreaHa(selectedFields)
    log.debug('totalArea', totalArea)

    const priceListSorted = (prices: Price[]): Price[] => {
        const pricesList = [...prices];
        pricesList.sort((a, b) => {

            return computePriceDuration(a) - computePriceDuration(b)
        })
        return pricesList
    }

    const computePriceDuration = (price: Price): number => {
        let nDaysInterval = 1
        switch (price.interval) {
            case Interval.MONTH:
                nDaysInterval = DAYS_IN_MONTH;
                break;
            case Interval.WEEK:
                nDaysInterval = DAYS_IN_WEEK;
                break;
            case Interval.YEAR:
                nDaysInterval = DAYS_IN_YEAR;
                break;
        }

        return nDaysInterval * (price.interval_count ? price.interval_count : 1);
    }

    const cardList = (product: Product) => {
        const pricesList = product.prices
        if (pricesList && pricesList.length) {
            return (
                <Box className={classes.productCardContainer}>
                    {priceListSorted(pricesList).map((price: Price, index: number) => {
                        return (
                            <Box key={index} p={2} display='flex' flexDirection='column' alignItems="center">
                                <OfferCard type={OfferCardType.PRICE} item={price}
                                    productNameSelected={product.name}
                                    selected={price.id === selectedPrice?.id}
                                    setSelected={setSelectedPrice}
                                    totalArea={totalArea} />
                            </Box>
                        )
                    })}
                </Box>
            )
        } else {
            log.error(`No prices for product ${product.name}`)
            return null
        }

    }

    if (selectedProduct) {
        return (
            <Box className={classes.productStep}>
                {cardList(selectedProduct)}
            </Box>
        )
    } else {
        return null
    }
}