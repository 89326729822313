import MaterialTable, {MTableBodyRow, MTableHeader} from '@material-table/core';
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {FieldNitrogenService} from '../models/FieldNitrogenService';
import useStyles from '../../rotations/components/Rotations.style';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  DELETE_TOOLTIP,
  EMPTY_DATA_SOURCE_MESSAGE,
} from 'shared/configs/TableConst';
import {getCampaignTranslation} from 'modules/campaigns/services/CampaignsTranslation';
import useHeaderTableStyle from 'shared/hooks/useHeaderTableStyle';
import useCellTableStyle from 'shared/hooks/useCellTableStyle';
import nitrogenActions from '../actions/NitrogenActions';
import BaseDialog from 'shared/components/BaseDialog';
interface NitrogenCoverListProps {
  onClose: () => void;
}
const NitrogenCoverList: React.FC<NitrogenCoverListProps> = (props) => {
  const {data, selectedCampaign, selectedFieldId} = useSelector<
    AppState,
    AppState['fields']
  >((state) => state.fields);
  const {data: campaignData} = useSelector<AppState, AppState['campaigns']>(
    (state) => state.campaigns,
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const headerStyle = useHeaderTableStyle();
  const cellStyle = useCellTableStyle();

  const [coverList, setCoverList] = useState<FieldNitrogenService[] | null>(
    null,
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [rowDataToDelete, setRowDataToDelete] =
    useState<FieldNitrogenService | null>(null);

  useEffect(() => {
    if (data && selectedCampaign && selectedFieldId) {
      const selectedField = data[selectedCampaign]
        ? data[selectedCampaign][selectedFieldId]
        : null;
      if (
        selectedField &&
        selectedField.nitrogen &&
        selectedField.nitrogen.length
      ) {
        setCoverList(selectedField.nitrogen);
      } else {
        setCoverList([]);
      }
    } else {
      setCoverList([]);
    }
  }, [data, selectedCampaign, selectedFieldId]);

  const onDeleteCoverClick = () => {
    if (rowDataToDelete) {
      dispatch(
        nitrogenActions.deleteNitrogenCovercrop(
          rowDataToDelete.field_id,
          rowDataToDelete.covercrop_id,
        ),
      );
      setRowDataToDelete(null);
    }
  };
  if (coverList && campaignData) {
    return (
      <Box width={1}>
        <MaterialTable
          data={coverList}
          columns={[
            {
              title: intl.formatMessage({
                id: 'nitrogen.cover_history.table.campaign_column.title',
              }),
              field: 'campaign_id',
              align: 'center',
              render: (rowData) => {
                const campaign = campaignData[rowData.campaign_id];
                if (campaign) {
                  return getCampaignTranslation(campaign);
                } else {
                  return rowData.campaign_id;
                }
              },
            },
            {
              title: intl.formatMessage({
                id: 'nitrogen.form.label.sowing_date',
              }),
              field: 'sowing_date',
              align: 'center',
              render: (rowData) => {
                return intl.formatDate(rowData.sowing_date);
              },
            },
            {
              title: intl.formatMessage({
                id: 'nitrogen.form.label.destruction_date',
              }),
              field: 'destruction_date',
              align: 'center',
              render: (rowData) => {
                return intl.formatDate(rowData.destruction_date);
              },
            },
            {
              title: intl.formatMessage({
                id: 'nitrogen.form.label.residu_management',
              }),
              field: 'residue_management',
              align: 'center',
              render: (rowData) => {
                return intl.formatMessage({
                  id: `nitrogen.form.selctor.residu_management.${rowData.residue_management}`,
                });
              },
            },
            {
              title: intl.formatMessage({
                id: 'nitrogen.form.label.covercrop_exploitation',
              }),
              field: 'covercrop_exploitation',
              align: 'center',
              render: (rowData) => {
                return intl.formatMessage({
                  id: `nitrogen.form.selctor.covercrop_exploitation.${rowData.covercrop_exploitation}`,
                });
              },
            },
            {
              title: intl.formatMessage({
                id: 'nitrogen.form.label.covercrop_composition',
              }),
              field: 'species',
              render: (rowData) => {
                return rowData.covercrop_composition
                  .map((spec) =>
                    intl.formatMessage({
                      id: `species.${spec.cc_species_id}.label`,
                    }),
                  )
                  .join(', ');
              },
              align: 'center',
            },
          ]}
          actions={[
            {
              icon: 'delete',
              tooltip: intl.formatMessage({id: 'nitrogen.table.delete'}),
              onClick: (event, rowData) => {
                const data = rowData as FieldNitrogenService;
                setRowDataToDelete(data);
                setOpenDeleteModal(true);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
            headerStyle: headerStyle,
            editCellStyle: cellStyle,
          }}
          components={{
            Header: (props) => <MTableHeader {...props} />,
            Row: (props) => (
              <MTableBodyRow
                {...props}
                classes={props.index % 2 ? {root: classes.rowStyle} : {}}
              />
            ),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <FormattedMessage id={EMPTY_DATA_SOURCE_MESSAGE} />
              ),
              deleteTooltip: <FormattedMessage id={DELETE_TOOLTIP} />,
            },
            header: {
              actions: '',
            },
            pagination: {
              labelRowsSelect: intl.formatMessage({
                id: 'nitrogen.pagination',
              }),
              firstTooltip: intl.formatMessage({
                id: 'table.pagination.firstTooltip',
              }),
              lastTooltip: intl.formatMessage({
                id: 'table.pagination.lastTooltip',
              }),
              previousTooltip: intl.formatMessage({
                id: 'table.pagination.previousTooltip',
              }),
              nextTooltip: intl.formatMessage({
                id: 'table.pagination.nextTooltip',
              }),
              labelDisplayedRows: intl.formatMessage({
                id: 'table.pagination.labelDisplayedRows',
              }),
            },
          }}
        />
        <BaseDialog
          open={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
            setRowDataToDelete(null);
          }}
          buttonMessageId='fields.delete.button.label'
          titleMessageId='nitrogen.coverList.dialog_delete.title'
          handleValidate={onDeleteCoverClick}>
          <FormattedMessage id='nitrogen.coverList.dialog_delete.message' />
        </BaseDialog>
      </Box>
    );
  }
  return null;
};

export default NitrogenCoverList;
