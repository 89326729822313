import {AuthUser} from 'modules/auth/models/AuthUser';
import {ServiceId} from 'shared/models/RouterParam';
import FieldCreation from '../models/FieldCreation';
import FieldsImport from '../models/FieldsImport';
import FieldsState from '../models/FieldsState';
import MapPosition from '../models/MapPosition';

export const ON_SELECT_SERVICE = 'ON_SELECT_SERVICE';
export interface OnSelectServiceAction {
  type: typeof ON_SELECT_SERVICE;
  payload: ServiceId;
}

export const LOAD_FIELDS_SUMMARY = 'LOAD_FIELDS_SUMMARY';
export interface LoadFieldsSummaryAction {
  type: typeof LOAD_FIELDS_SUMMARY;
  payload: {
    selectedFieldId: number | null;
    zoomToSelected: boolean;
    selectedCampaignId: number | null;
    user: AuthUser;
  };
}

export const LOAD_FIELDS_SUMMARY_SUCCESS = 'LOAD_FIELDS_SUMMARY_SUCCESS';
export interface LoadFieldsSummarySuccessAction {
  type: typeof LOAD_FIELDS_SUMMARY_SUCCESS;
  payload: FieldsState;
}

export const ON_SELECT_FIELD = 'ON_SELECT_FIELD';
export interface OnSelectFieldAction {
  type: typeof ON_SELECT_FIELD;
  payload: {
    selectedFieldId: number | null;
    zoomToSelected: boolean;
  };
}

export const SET_ZOOM_TO_SELECTED = 'SET_ZOOM_TO_SELECTED';
export interface SetZoomToSelectedAction {
  type: typeof SET_ZOOM_TO_SELECTED;
  payload: {
    zoomToSelected: boolean;
  };
}

export const SET_MAP_POSITION = 'SET_MAP_POSITION';
export interface SetMapPositionAction {
  type: typeof SET_MAP_POSITION;
  payload: MapPosition;
}

export const CLEAR_FIELDS_DATA = 'CLEAR_FIELDS_DATA';
export interface ClearFieldsDataAction {
  type: typeof CLEAR_FIELDS_DATA;
}

export const ON_SELECT_FILTERS = 'ON_SELECT_FILTERS';
export interface OnSelectFiltersAction {
  type: typeof ON_SELECT_FILTERS;
  payload: {
    selectedFilters: {[key: string]: string[]};
    zoomToSelected: boolean;
  };
}

export const ON_SELECT_FILTERS_SUCCESS = 'ON_SELECT_FILTERS_SUCCESS';
export interface OnSelectFiltersSuccessAction {
  type: typeof ON_SELECT_FILTERS_SUCCESS;
  payload: {
    selectedFilters: {[key: string]: string[]};
    filteredFields: number[];
    filteredFieldsBbox: number[];
    zoomToSelected: boolean;
  };
}

export const SET_MAP_DISPLAY_NDVI = 'SET_MAP_DISPLAY_NDVI';
export interface SetMapDisplayNdviAction {
  type: typeof SET_MAP_DISPLAY_NDVI;
  payload: boolean;
}

export const ADD_NEW_FIELD = 'ADD_NEW_FIELD';
export interface AddNewFieldAction {
  type: typeof ADD_NEW_FIELD;
  payload: {
    field: FieldCreation;
    service: ServiceId;
  };
}

export const IMPORT_FIELDS = 'IMPORT_FIELDS';
export interface ImportFieldsAction {
  type: typeof IMPORT_FIELDS;
  payload: FieldsImport;
}

export const MODIFY_FIELD = 'MODIFY_FIELD';
export interface ModifyFieldAction {
  type: typeof MODIFY_FIELD;
  payload: {
    field: FieldCreation;
    service: ServiceId;
  };
}

export const DELETE_FIELD = 'DELETE_FIELD';
export interface DeleteFieldAction {
  type: typeof DELETE_FIELD;
  payload: number;
}
export const ON_SELECT_CAMPAIGN = 'ON_SELECT_CAMPAIGN';
export interface OnSelectCampaignAction {
  type: typeof ON_SELECT_CAMPAIGN;
  payload: number;
}

export type FieldsActionTypes =
  | LoadFieldsSummaryAction
  | LoadFieldsSummarySuccessAction
  | OnSelectFieldAction
  | SetZoomToSelectedAction
  | SetMapPositionAction
  | ClearFieldsDataAction
  | OnSelectFiltersAction
  | OnSelectFiltersSuccessAction
  | SetMapDisplayNdviAction
  | AddNewFieldAction
  | ImportFieldsAction
  | ModifyFieldAction
  | DeleteFieldAction
  | OnSelectCampaignAction
  | OnSelectServiceAction;

//Actions Creator
export const actions = {
  onSelectService: (serviceId: ServiceId): OnSelectServiceAction => {
    return {
      type: ON_SELECT_SERVICE,
      payload: serviceId,
    };
  },

  loadFieldsSummary: (
    selectedFieldId: number | null,
    zoomToSelected: boolean,
    selectedCampaignId: number | null,
    user: AuthUser,
  ): LoadFieldsSummaryAction => {
    return {
      type: LOAD_FIELDS_SUMMARY,
      payload: {selectedFieldId, zoomToSelected, selectedCampaignId, user},
    };
  },

  loadFieldsSummarySuccess: (
    fieldsSummary: FieldsState,
  ): LoadFieldsSummarySuccessAction => {
    return {
      type: LOAD_FIELDS_SUMMARY_SUCCESS,
      payload: fieldsSummary,
    };
  },

  onSelectField: (
    selectedFieldId: number | null,
    zoomToSelected: boolean,
  ): OnSelectFieldAction => {
    return {
      type: ON_SELECT_FIELD,
      payload: {selectedFieldId, zoomToSelected},
    };
  },

  setZoomToSelected: (zoomToSelected: boolean): SetZoomToSelectedAction => {
    return {
      type: SET_ZOOM_TO_SELECTED,
      payload: {zoomToSelected},
    };
  },

  setMapPosition: (mapPosition: MapPosition): SetMapPositionAction => {
    return {
      type: SET_MAP_POSITION,
      payload: mapPosition,
    };
  },

  clearFieldsData: (): ClearFieldsDataAction => {
    return {
      type: CLEAR_FIELDS_DATA,
    };
  },

  onSelectFilters: (
    selectedFilters: {[key: string]: string[]},
    zoomToSelected: boolean,
  ): OnSelectFiltersAction => {
    return {
      type: ON_SELECT_FILTERS,
      payload: {
        selectedFilters,
        zoomToSelected,
      },
    };
  },

  onSelectFiltersSuccess: (
    selectedFilters: {[key: string]: string[]},
    filteredFields: number[],
    filteredFieldsBbox: number[],
    zoomToSelected: boolean,
  ): OnSelectFiltersSuccessAction => {
    return {
      type: ON_SELECT_FILTERS_SUCCESS,
      payload: {
        selectedFilters,
        filteredFields,
        filteredFieldsBbox,
        zoomToSelected,
      },
    };
  },

  setMapDisplayNdvi: (mapDisplayNdvi: boolean): SetMapDisplayNdviAction => {
    return {
      type: SET_MAP_DISPLAY_NDVI,
      payload: mapDisplayNdvi,
    };
  },

  addNewField: (
    newField: FieldCreation,
    service: ServiceId,
  ): AddNewFieldAction => {
    return {
      type: ADD_NEW_FIELD,
      payload: {field: newField, service: service},
    };
  },

  importFields: (importedFields: FieldsImport): ImportFieldsAction => {
    return {
      type: IMPORT_FIELDS,
      payload: importedFields,
    };
  },

  modifyField: (
    modifiedField: FieldCreation,
    service: ServiceId,
  ): ModifyFieldAction => {
    return {
      type: MODIFY_FIELD,
      payload: {
        field: modifiedField,
        service: service,
      },
    };
  },

  deleteField: (selectedField: number): DeleteFieldAction => {
    return {
      type: DELETE_FIELD,
      payload: selectedField,
    };
  },

  onSelectCampaign: (selectedCampaign: number): OnSelectCampaignAction => {
    return {
      type: ON_SELECT_CAMPAIGN,
      payload: selectedCampaign,
    };
  },
};

export default actions;
