import React from 'react';
import {SvgIcon, useMediaQuery} from '@mui/material';
import {SvgIconProps} from '@mui/material';
import {ReactComponent as WeatherIconTab} from '../../assets/weatherIcon.svg';
import {useTheme} from '@mui/system';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean
}

const WeatherIcon = (props: HeaderIconProps) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <SvgIcon {...props} component={WeatherIconTab} color={md ? "inherit":"primary"} style={{fillOpacity: props.selected || !md ? "1":"0.5", color: md ? theme.palette.primary.contrastText:theme.palette.grey[300] }} viewBox="0 0 24 16" />
  );
};

export default WeatherIcon;
