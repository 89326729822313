
export const FIELDS_CREATE = "fields.create";
export const FIELDS_READ = "fields.read";
export const FIELDS_UPDATE = "fields.update";
export const FIELDS_DELETE = "fields.delete";
export const FIELDS_IMPORT = "fields.import";
export const ROTATIONS_CREATE = "rotations.create";
export const SOILS_CREATE = "soils.create";
export const SOILS_READ = "soils.read";
export const SOILS_UPDATE = "soils.update";
export const SOILS_DELETE = "soils.delete";
export const CROPS_CREATE = "crops.create";
export const CROPS_READ = "crops.read";
export const CROPS_UPDATE = "crops.update";
export const CROPS_DELETE = "crops.delete";
export const USERS_READ = "users.read";
export const FIELD_IRRIGATION_CREATE = "field.irrigation.create";
export const FIELD_IRRIGATION_READ = "field.irrigation.read";
export const FIELD_IRRIGATION_UPDATE = "field.irrigation.update";
export const FIELD_IRRIGATION_DELETE = "field.irrigation.delete";
export const FIELD_NITROGEN_CREATE = "field.nitrogen.create";
export const FIELD_NITROGEN_READ = "field.nitrogen.read";
export const FIELD_NITROGEN_UPDATE = "field.nitrogen.update";
export const FIELD_NITROGEN_DELETE = "field.nitrogen.delete";
export const FIELD_VEGETATION_READ = "field.vegetation.read";
export const FIELD_CARBON_READ = "field.carbon.read";
export const FIELD_WEATHER_READ = "field.weather.read";
export const FIELD_REPORT_READ = "field.report.read";

export type UserPermission =
  | typeof FIELDS_CREATE
  | typeof FIELDS_READ
  | typeof FIELDS_UPDATE
  | typeof FIELDS_DELETE
  | typeof FIELDS_IMPORT
  | typeof ROTATIONS_CREATE
  | typeof SOILS_CREATE
  | typeof SOILS_READ
  | typeof SOILS_UPDATE
  | typeof SOILS_DELETE
  | typeof CROPS_CREATE
  | typeof CROPS_READ
  | typeof CROPS_UPDATE
  | typeof CROPS_DELETE
  | typeof USERS_READ
  | typeof FIELD_IRRIGATION_CREATE
  | typeof FIELD_IRRIGATION_READ
  | typeof FIELD_IRRIGATION_UPDATE
  | typeof FIELD_IRRIGATION_DELETE
  | typeof FIELD_NITROGEN_CREATE
  | typeof FIELD_NITROGEN_READ
  | typeof FIELD_NITROGEN_UPDATE
  | typeof FIELD_NITROGEN_DELETE
  | typeof FIELD_VEGETATION_READ
  | typeof FIELD_WEATHER_READ
  | typeof FIELD_CARBON_READ
  | typeof FIELD_REPORT_READ;