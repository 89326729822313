import {FormikProps} from 'formik';
import React from 'react';
import MyTextField from 'shared/components/MyTextField';
import {SencropWeatherStationCredentials} from '../models/BaseWeatherStationSchema';
import {UserWeatherStationPayload} from '../models/UserWeatherStationPayload';

interface SencropProviderProps {
  formikProps: FormikProps<
    UserWeatherStationPayload<SencropWeatherStationCredentials>
  >;
  disable: boolean;
}

const SencropCredentialsForm: React.FC<SencropProviderProps> = ({
  formikProps,
  disable,
}) => {
  const credentials = formikProps.values
    .credentials as SencropWeatherStationCredentials;

  return (
    <>
      <MyTextField
        name='credentials.id'
        label='ID'
        type='text'
        value={credentials.id}
        disabled={disable}
      />
      <MyTextField
        name='credentials.password'
        label='Password'
        type='password'
        value={credentials.password}
        disabled={disable}
      />
    </>
  );
};

export default SencropCredentialsForm;
