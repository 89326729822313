import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as CanceledSubscription} from '../../assets/canceled_subscription.svg';

const CanceledSubscriptionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} component={CanceledSubscription} viewBox="0 0 83 100" />
  );
};

export default CanceledSubscriptionIcon;
