import React, {useContext} from 'react';
import {Box, Button, IconButton, Typography, useTheme} from '@mui/material';
import {InfoDialogChoice} from 'shared/configs/AppEnums';
import IntlMessages from 'shared/components/IntlMessages';
import log from 'shared/services/LogService';
import AppContextPropsType from 'shared/models/AppContextPropsType';
import {AppContext} from '@crema';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import CarbonHelp from './carbon/components/CarbonHelp';
import NitrogenHelp from './nitrogen/components/NitrogenHelp';
import {ServiceId} from 'shared/models/RouterParam';
import VegetationHelp from './vegetation/components/VegetationHelp';
import OnBoarding from './common/components/OnBoarding';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WeatherHelp from './weather/components/WeatherHelp';

interface InformationsProps {
  /**
   * to manage which part of the information dialog is shown
   */
  selectedInfo: string;
  /**
   * to manage the closing of the information dialog
   */
  handleModal: () => void;
  /**
   * state of the information dialog open/close
   */
  open: boolean;

  service?: ServiceId
}

/**
 * Component to display the totality or different part of the information dialog
 * Contains a button to navigate between the different part of the dialog
 */
const Informations: React.FC<InformationsProps> = ({
  selectedInfo,
  handleModal,
  open,
  service
}) => {
  // @ts-ignore
  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const getMaxStep = (service?: ServiceId) => {
    switch (service) {
      case ServiceId.CARBON:
        return 2
      case ServiceId.NITROGEN:
        return 1
      case ServiceId.VEGETATION:
        return 3
      case ServiceId.IRRIGATION:
        return 1
      case ServiceId.WEATHER:
        return 3
      default:
        return 13
    }
  }
  const switchInformationStepper = (service: ServiceId | undefined) => {
    switch (service) {
      case ServiceId.CARBON:
        return (
          <CarbonHelp
            selectedInfo={selectedInfo}
            step={activeStep}
            onActiveStepChange={setActiveStep}
          />
        );
      case ServiceId.NITROGEN:
        return (
          <NitrogenHelp
            selectedInfo={selectedInfo}
            step={activeStep}
            onActiveStepChange={setActiveStep}
          />
        );
      case ServiceId.VEGETATION:
        return (
          <VegetationHelp
            selectedInfo={selectedInfo}
            step={activeStep}
            onActiveStepChange={setActiveStep}
          />
        );
      case ServiceId.WEATHER:
        return (
          <WeatherHelp
            selectedInfo={selectedInfo}
            step={activeStep}
            onActiveStepChange={setActiveStep}
          />
        );
      default:
        return (
          <OnBoarding
            selectedInfo={selectedInfo}
            step={activeStep}
            onActiveStepChange={setActiveStep}
          />
        );
    }
  };


  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setActiveStep(activeStep + 1);
  }

  const onBackIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setActiveStep(activeStep - 1);
  }

  const {locale} = useContext<AppContextPropsType>(AppContext);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const lang = locale.locale.split("-")[0];

  //https://pixagri.terranis.fr/documentation/user_manual_${role}_{lang}.pdf
  const documentationTemplateUrl = process.env.REACT_APP_PIXAGRI_DOCUMENTATION_PREFIX_URL;
  let documentationUrl: string | undefined = undefined;
  if (documentationTemplateUrl && user && user.role && lang) {
    log.debug(`Role: ${user.role}, Lang: ${lang}, template ${documentationTemplateUrl}`);
    documentationUrl = `${documentationTemplateUrl}_${user.role}_${lang}.pdf`;
    log.debug(`Documentation url: ${documentationUrl}`);
  }

  return (
    <Box width={1} height={1} display='flex' flexDirection='column' id="informationBox" position="relative" >

      <Box
        id="pageShown"
        height={1}
        flex={1}
        
        display='flex'
        flexDirection='column'
        alignItems='center'>
        {switchInformationStepper(service)}
        <Box display="flex" alignItems={"center"} >
        {
            selectedInfo === InfoDialogChoice.NONE && activeStep !== 0 ?
            (<IconButton 
              onClick={onBackIconClick}
              sx={{
              position: "absolute",
              left: 0
            }}>
              <ArrowBackIcon />
            </IconButton>):""
          }

          {documentationUrl &&
            <Box m={1}>
              <Button
                component="button"
                style={{width: 280}}
                data-test='common-documentation-button'
                variant="outlined"
                color='primary'
                onClick={() => {window.open(documentationUrl, "_blank")}}>
                <IntlMessages id='common.documentation.button_title' />
              </Button>
            </Box>}
          {selectedInfo === InfoDialogChoice.NONE ? (
            activeStep !== getMaxStep(service) ? (
              <Button
                sx={{margin: theme.spacing(1)}}
                style={{width: 280}}
                data-test='info-next-button'
                variant='contained'
                color='primary'
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleClick(event)
                }>
                <IntlMessages id='informations.next' />
              </Button>
            ) : (
              <Button
                sx={{margin: theme.spacing(1)}}
                style={{width: 280}}
                data-test='info-close-button'
                variant='contained'
                color='primary'
                onClick={handleModal}>
                <IntlMessages id='informations.close' />
              </Button>
            )
          ) : (
            ''
          )}
        </Box>
      </Box>
      {/* Display version number set in gitlab ci from tags */}
      <Box alignSelf={'flex-end'}>
        <Typography variant='caption'>
          {process.env.REACT_APP_VERSION
            ? process.env.REACT_APP_VERSION
            : 'Version dev'}
        </Typography>
      </Box>
    </Box>
  );
};

export default Informations;
