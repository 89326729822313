import React, {ReactElement} from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import IntlMessages from '../IntlMessages';
import clsx from 'clsx';
import Hidden from '@mui/material/Hidden';
import {useHistory} from "react-router-dom";
import useStyles from './HeaderButton.style';
import {ServiceId} from 'shared/models/RouterParam';

/**
 * Generic header buttons props
 */
interface HeaderButtonProps {
  /**
   * The path to which the app is redirected
   * Used to define if the button is in the selected state too
   */
  path: string;

  /**
   * The title id for internationalization
   */
  titleId: string;

  /**
   * The SVG icon
   */
  icon: ReactElement;

  /**
   * True if the title must always be displayed even for small screen 
   * (usefull to use the same component in a popup for mobile screens)
   */
  alwaysShowTitle?: boolean;

  /**
   * True if url corresponding to button url
   */
  selected?: boolean;

  /**
   * dispacth new selectedService in state if provided
   */
  serviceId?: ServiceId
}


/**
 * Generic header buttons used by fields, crops and soils
 * @param props props (see above)
 */
const HeaderButton: React.FC<HeaderButtonProps> = (props) => {
  const {selected} = props

  let history = useHistory();

  const onClickHeaderButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    history.push(props.path);
  };

  const classes = useStyles();

  const title = () => {
    return (<Box className={clsx(classes.headerBtnTitle, 'headerBtnTitle')} component='span'>
      <IntlMessages id={props.titleId} />
    </Box>)
  };

  return (
    <>
      <IconButton
        className={clsx(classes.headerBtn, {
          selected,
        }, 'headerBtn')}
        color='inherit'
        onClick={onClickHeaderButton}>
        {props.icon}
        {props.alwaysShowTitle ? title() :
          (<Hidden lgDown>
            {title()}
          </Hidden>
          )}

      </IconButton>
    </>
  );
};

export default HeaderButton;
