import {Theme} from '@mui/material';
import Field from 'modules/fields/models/Field';
import {FieldIrrigationService} from 'modules/fields/models/FieldTesting';
import log from 'shared/services/LogService';
import {gaugeColors} from '../configs/RotationConfig';
import {HydroStressLevel} from '../models/HydroStressLevel';
import {NdviHomogeneityLevel} from '../models/NdviHomogeneityLevel';

export const DEFAULT_SWC2_THRESHOLD_HIGH = 1;
export const DEFAULT_SWC2_THRESHOLD_MEDIUM = 0.8;
export const DEFAULT_SWC2_THRESHOLD_LOW = 0.3;

export const DEFAULT_NDVI_STD_THRESHOLD_HETEROGENEOUS = 0.05;
export const DEFAULT_NDVI_STD_THRESHOLD_HIGHLY_HETEROGENEOUS = 0.1;

/**
 * Get the risk level given the swc2 value
 * @param swc2 the swc2 value
 * @param user the user info with the thresholds
 * @returns The risk level (enum)
 */
export function getHydroStressLevel(
  rawc: number | null,
  field_thresholds: {
    low: number;
    medium: number;
    high: number;
  },
): HydroStressLevel {
  if (rawc) {
    if (!isNaN(rawc)) {
      let threshold_high = DEFAULT_SWC2_THRESHOLD_HIGH;
      let threshold_medium = DEFAULT_SWC2_THRESHOLD_MEDIUM;
      let threshold_low = DEFAULT_SWC2_THRESHOLD_LOW;

      if (field_thresholds) {
        threshold_high = field_thresholds.high;
        threshold_medium = field_thresholds.medium;
        threshold_low = field_thresholds.low;
      }

      if (rawc >= threshold_high) {
        return HydroStressLevel.VERY_HIGH;
      } else if (rawc >= threshold_medium) {
        return HydroStressLevel.HIGH;
      } else if (rawc >= threshold_low) {
        return HydroStressLevel.MEDIUM;
      } else {
        return HydroStressLevel.LOW;
      }
    } else if (isNaN(rawc)) {
      log.error(`Invalid RAW value: ${rawc}`);
    } else {
      log.error(`Invalid value: raw :${rawc}}`);
    }
  }
  return HydroStressLevel.UNDEFINED;
}

/**
 * Get the id of the localized message for the risk level
 * @param rawc the rawc value
 * @param user the user info with the thresholds
 * @returns The id of the localized message for the risk level
 */
export function getHydroStressMessageId(
  rawc: number | null,
  field_thresholds: {low: number; medium: number; high: number},
): string {
  const riskLevel = getHydroStressLevel(rawc, field_thresholds);
  return getHydroStressLevelMessageId(riskLevel);
}

/**
 * Get the id of the localized message for the risk level
 * @param stressLevel the risk level
 * @returns The id of the localized message for the risk level
 */
export function getHydroStressLevelMessageId(
  stressLevel: HydroStressLevel,
): string {
  switch (stressLevel) {
    case HydroStressLevel.VERY_HIGH:
      return 'waterBalance.SWC2VeryHighRisk.label';
    case HydroStressLevel.HIGH:
      return 'waterBalance.SWC2HighRisk.label';
    case HydroStressLevel.MEDIUM:
      return 'waterBalance.SWC2MediumRisk.label';
    case HydroStressLevel.LOW:
      return 'waterBalance.SWC2LowRisk.label';
    default:
      return 'common.unknown';
  }
}

export function getHydroStressLevelColor(
  stressLevel: HydroStressLevel,
  theme: Theme,
): string {
  switch (stressLevel) {
    case HydroStressLevel.VERY_HIGH:
      return gaugeColors[3];
    case HydroStressLevel.HIGH:
      return gaugeColors[2];
    case HydroStressLevel.MEDIUM:
      return gaugeColors[1];
    case HydroStressLevel.LOW:
      return gaugeColors[0];
    default:
      return theme.palette.grey[400];
  }
}

/**
 * Get the homogeneity level given the ndvi standard deviation value
 * @param ndviStd the ndvi standard deviation value
 * @returns The homogeneity level (enum)
 */
export function getNdviHomogeneityLevel(ndviStd: string): NdviHomogeneityLevel {
  if (ndviStd && ndviStd !== '') {
    const ndviStdNum = parseFloat(ndviStd);
    if (!isNaN(ndviStdNum)) {
      if (ndviStdNum <= DEFAULT_NDVI_STD_THRESHOLD_HETEROGENEOUS) {
        return NdviHomogeneityLevel.HOMOGENEOUS;
      } else if (
        ndviStdNum <= DEFAULT_NDVI_STD_THRESHOLD_HIGHLY_HETEROGENEOUS
      ) {
        return NdviHomogeneityLevel.HETEROGENEOUS;
      } else {
        return NdviHomogeneityLevel.HIGHLY_HETEROGENEOUS;
      }
    }
  }
  return NdviHomogeneityLevel.UNDEFINED;
}

/**
 * Get the id of the localized message for the ndvi homogeneity level
 * @param ndviStd the ndvi standard deviation value
 * @returns The id of the localized message for the ndvi homogeneity level
 */
export function getNdviHomogeneityMessageId(ndviStd: string): string {
  const homogeneityLevel = getNdviHomogeneityLevel(ndviStd);
  switch (homogeneityLevel) {
    case NdviHomogeneityLevel.HIGHLY_HETEROGENEOUS:
      return 'waterBalance.NdviHighlyHeterogeneous.label';
    case NdviHomogeneityLevel.HETEROGENEOUS:
      return 'waterBalance.NdviHeterogeneous.label';
    case NdviHomogeneityLevel.HOMOGENEOUS:
      return 'waterBalance.NdviHomogeneous.label';
    default:
      return 'common.notAvailable';
  }
}

export const findIrrigationByCampaign = (data: Field, campaign_id: number) => {
  let irrigation: FieldIrrigationService | null = null;
  const campaignIrrigations = data.irrigation.filter(
    (e) => +e.campaign_id === +campaign_id,
  );
  if (campaignIrrigations.length) {
    irrigation = campaignIrrigations[campaignIrrigations.length - 1];
  }
  if (!irrigation) {
    log.debug('irrigation not found');
  }
  return irrigation;
};
