import React, {useContext, useEffect} from 'react';
import languageData, {LanguageProps} from '../configs/LanguageSwitcherConfig';
import Menu from '@mui/material/Menu';
import AppContext from '../../@crema/utility/AppContext';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import {makeStyles} from '@mui/styles';
import {Fonts} from '../configs/AppEnums';
import AppContextPropsType, {
  CremaTheme,
} from '../models/AppContextPropsType';
import {useDispatch, useSelector} from 'react-redux';
import actions from 'modules/auth/actions/AuthActions';
import {AppState} from 'shared/store';
import log from 'loglevel';

interface LanguageSwitcherProps {
  iconOnly?: boolean;
  localeOnly?: boolean;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  iconOnly = false,
  localeOnly = false
}) => {

  const dispatch = useDispatch();
  const {customer} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const {changeLocale, rtlLocale, locale, setRTL} = useContext<
    AppContextPropsType
  >(AppContext);
  const [
    anchorElLng,
    setAnchorElLng,
  ] = React.useState<HTMLButtonElement | null>(null);

  /**
   * Set current local to customer preferred local
   */
  useEffect(() => {
    if (changeLocale && customer && customer.preferred_locales && customer.preferred_locales.length > 0) {
      const preferredLocale = customer.preferred_locales[0]
      if (preferredLocale.toLowerCase() !== locale.locale.toLowerCase()) {
        const foundLanguage = languageData.find((language) => language.locale.toLowerCase() === preferredLocale.toLowerCase());
        if (foundLanguage) {
          if (rtlLocale.indexOf(foundLanguage.locale) !== -1) {
            setRTL!(true);
          } else {
            setRTL!(false);
          }

          changeLocale(foundLanguage)
        } else {
          log.error(`Local ${preferredLocale} not found in application supported locales`);
        }
      }
    }
  }, [customer, changeLocale, locale.locale, rtlLocale, setRTL])

  const onClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLng(event.currentTarget);
  };
  const changeLanguage = (language: LanguageProps) => {

    dispatch(actions.savePreferredLanguage(language.locale));
    setAnchorElLng(null);
  };

  const useStyles = makeStyles((theme: CremaTheme) => ({
    langBtn: {
      justifyContent: 'flex-start',
      width: '100%',
      height: 56,
      fontSize: 16,
      textTransform: 'uppercase',
      marginTop: 0,
      borderRadius: 0,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        color: theme.palette.primary.contrastText,
      },
      [theme.breakpoints.up('md')]: {
        fontFamily: Fonts.MEDIUM,
        justifyContent: 'center',
        width: 'auto',

        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
          color: theme.palette.primary.contrastText,
        },
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
        paddingLeft: '0.75rem',
        paddingRight: '1.5rem',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: '1.25rem',
        paddingRight: '2.5rem',
      },
      '&.langIconOnly': {
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        height: 56,
        borderLeft: '0 none',
        borderRadius: '50%',
        [theme.breakpoints.up('sm')]: {
          height: 70,
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: '12px',
          paddingRight: '12px',
          width: '100%',
        },
      },
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    alignMiddle: {
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    textUppercase: {
      textTransform: 'uppercase',
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      <IconButton
        className={clsx(
          classes.langBtn,
          {
            langIconOnly: iconOnly,
          },
          'langBtn',
        )}
        aria-label='account of current user'
        aria-controls='language-switcher'
        aria-haspopup='true'
        onClick={onClickMenu}
        color='inherit'>
        {!iconOnly ? (
          !localeOnly ? (
            <>
              <Box
                component='span'
                mr={{xs: 2, md: 3}}
                height={48}
                width={48}
                display='flex'
                alignItems='center'
                justifyContent='center'
                borderRadius='50%'
                className={classes.overflowHidden}>
                <i className={`flag flag-24 flag-${locale.icon}`} />
              </Box>
              <Box component='span' display='inline-block'>
                {locale.name}
              </Box>
            </>
          ) : (
              <Box component='span' display='inline-block'>
                {locale.locale.split('-')[0]}
              </Box>)
        ) : (
            <Box>
              <i className={`flag flag-24 flag-${locale.icon}`} />
            </Box>
          )}
      </IconButton>
      <Menu
        anchorEl={anchorElLng}
        id='language-switcher'
        keepMounted
        open={Boolean(anchorElLng)}
        onClose={() => setAnchorElLng(null)}>
        {languageData.map((language: LanguageProps, index: number) => (
          <MenuItem key={index} onClick={() => changeLanguage(language)}>
            <Box
              width={160}
              display='flex'
              flexDirection='row'
              alignItems='center'>
              <i className={`flag flag-24 flag-${language.icon}`} />
              <Box component='h4' ml={4} mb={0} fontSize={{xs: 14, xl: 16}}>
                {language.name}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
