import {useTheme} from '@mui/system';
import React, {useCallback, useEffect, useState} from 'react';

function useCellTableStyle() {
    const theme = useTheme()
    const [cellStyle, setCellStyle] = useState<React.CSSProperties>({})

    const cellStyleCallBack= useCallback(() => ({
        borderRight: `1px solid ${theme.palette.background.default}`,
      }), [theme])

      useEffect(() => {
          setCellStyle(cellStyleCallBack)
      }, [cellStyleCallBack])
      
      return cellStyle
      
}

export default useCellTableStyle;