import React, {useEffect, useState} from 'react';
import HeaderButton from 'shared/components/layout/HeaderButton';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {ServiceId} from 'shared/models/RouterParam';
import WeatherIcon from './icons/WeatherIcon';
import useServiceSelected from 'shared/hooks/useServiceSelected';
import navigationService from 'shared/services/NavigationService';
import {DEFAULT_WEATHER_VUE} from '../configs/WeatherRoutePaths';
import useCurrentCampaign from 'modules/campaigns/hook/useCurrentCampaign';

interface WeatherHeaderButtonProps {
  alwaysShowTitle?: boolean;
}
const WeatherHeaderButton: React.FC<WeatherHeaderButtonProps> = (props) => {
  const selected = useServiceSelected(ServiceId.WEATHER);
  const currentCampaign = useCurrentCampaign()

  const [path, setPath] = useState<string>('');
  const {selectedFieldId, selectedCampaign, fieldsList} = useSelector<
    AppState,
    AppState['fields']
  >(({fields}) => fields);

  useEffect(() => {
    let fieldId: number | null = null
    if (selectedFieldId) {
      fieldId = selectedFieldId
    } else if (fieldsList && fieldsList.length) {
      fieldId = fieldsList[0].field_id
    }
    let campaignId: number | null = null
    if (selectedCampaign) {
      campaignId = selectedCampaign
    } else {
      campaignId = currentCampaign
    }
    if (campaignId) {
      const firstPath = navigationService.getFirstServiceUrl(
        campaignId,
        fieldId,
        ServiceId.WEATHER,
        DEFAULT_WEATHER_VUE,
      );
      setPath(firstPath);
    }
  }, [selectedFieldId, selectedCampaign, currentCampaign]);

  return (
    <>
      <HeaderButton
        path={path}
        titleId='weather.headerButton.title'
        icon={<WeatherIcon selected={selected} />}
        alwaysShowTitle={props.alwaysShowTitle}
        selected={selected}
        serviceId={ServiceId.WEATHER}
      />
    </>
  );
};

export default WeatherHeaderButton;
