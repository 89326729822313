import {Box, Typography, useTheme} from '@mui/material';
import Field from 'modules/fields/models/Field';
import {isFieldSubscribe} from 'modules/fields/services/FieldsService';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ServiceId} from 'shared/models/RouterParam';
import {
  getCarbonFlowLevelColor,
  getCarbonFlowLevelMessage,
} from '../services/CarbonGaugeService';

interface CarbonMapTooltipProps {
  field: Field;
  campaignId: number;
}
const CarbonMapTooltip: React.FC<CarbonMapTooltipProps> = (props) => {
  const {field, campaignId} = props;
  const theme = useTheme();

  const period =
    field && field.carbon && field.carbon.length
      ? field.carbon.find((period) => period.campaign_id === campaignId)
      : null;
  if (period && isFieldSubscribe(ServiceId.CARBON, campaignId, field)) {
    return (
      <>
        <Box py={1} display={'flex'} flexDirection={'column'}>
          <Typography>
            <FormattedMessage id='carbon.tootltip.days_bare_soil' />
          </Typography>
          <Typography>{period.total_nbr_days_bare_soil}</Typography>
        </Box>
        <Box py={1} display={'flex'} flexDirection={'column'}>
          <Typography>
            <FormattedMessage id='carbon.tootltip.days_cover_soil' />
          </Typography>
          <Typography>{period.total_nbr_days_veg}</Typography>
        </Box>
        <Box py={1} display={'flex'} flexDirection={'column'}>
          <Typography>
            <FormattedMessage id='carbon.tootltip.carbon_flow' />
          </Typography>

          <Typography>
            <FormattedMessage
              id='carbon.tootltip.carbon_flow.value'
              values={{value: period.carbon_net_flux.toFixed(2)}}
            />
          </Typography>
        </Box>
        {period.carbon_flow_level && (
          <Box py={1} display={'flex'} flexDirection={'column'}>
            <Typography>
              <FormattedMessage id='carbon.tootltip.carbon_level' />
            </Typography>
            <Typography
              sx={{
                color: getCarbonFlowLevelColor(period.carbon_flow_level, theme),
              }}>
              <FormattedMessage
                id={getCarbonFlowLevelMessage(period.carbon_flow_level)}
              />
            </Typography>
          </Box>
        )}
      </>
    );
  }
  return null;
};

export default CarbonMapTooltip;
