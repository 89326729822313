export enum BillingAddressCollectionMode {
    AUTO = 'auto',
    REQUIRED = 'required'
}

export const supportedCheckoutSessionLocales = [
    "auto", "bg", "cs", "da", "de", "el", "en", "en-GB", "es", "es-419", "et", "fi", "fr", "fr-CA", "hu", "id", "it", "ja", "lt", "lv", "ms", "mt", "nb", "nl", "pl", "pt", "pt-BR", "ro", "ru", "sk", "sl", "sv", "th", "tr", "zh", "zh-HK", "zh-TW"
]


export default interface CheckoutSession {

    metadata: {
        [name: string]: string;
    };

    success_url: string;

    cancel_url: string;

    payment_method_types: string[];

    line_items: CheckoutLineItem[];
    //Enables user redeemable promotion codes
    allow_promotion_codes?: boolean;
    //Specify whether Checkout should collect the customer’s billing address.
    billing_address_collection: BillingAddressCollectionMode;
    //The IETF language tag of the locale Checkout is displayed in
    locale?: string;

    discounts?: {
        //The ID of a promotion code to apply to this Session.
        promotion_code?: string
    }
}

export interface CheckoutLineItem {
    //The description for the line item => Name_campaign_time_{...fields},
    description?: string;
    //The ID of the Price or Plan object
    price: string;
    //The quantity of the line item being purchased
    quantity?: number;
    //The tax rates which apply to this line item.
    tax_rates?: string[];
}

