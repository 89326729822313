import {
  GET_COVERSAT_DATA_SUCCESS,
  GET_COVER_CROP_SUCCESS,
  GET_NITROGEN_THRESHOLDS_SUCCESSS,
  GET_SPECIES_SUCCESS,
  NitrogenActionsTypes,
  RESET_NITROGEN_DATA,
  SAVE_SUMMARY_NITROGEN_INFO,
  SET_SELECTED_BIOMASS_DATE,
} from '../actions/NitrogenActions';
import {NitrogenState} from '../models/NitrogenState';
import {Reducer} from 'redux';

const initialState: NitrogenState = {
  summaryNitrogenInfo: null,
  species: null,
  coversat: null,
  covercrop: null,
  legend_thresholds: null,
  selectedBiomassDate: null,
};

const carbonReducers: Reducer<NitrogenState, NitrogenActionsTypes> = (
  state: NitrogenState = initialState,
  action: NitrogenActionsTypes,
): NitrogenState => {
  switch (action.type) {
    case RESET_NITROGEN_DATA: 
      return {
        ...state,
        coversat: null,
        selectedBiomassDate: null,
        covercrop: null
      }
    case GET_NITROGEN_THRESHOLDS_SUCCESSS: 
      return {
        ...state,
        legend_thresholds: action.payload
      }
    case SET_SELECTED_BIOMASS_DATE: 
      return {
        ...state,
        selectedBiomassDate: action.payload
      }
    case GET_SPECIES_SUCCESS:
      return {
        ...state,
        species: action.payload,
      };
    case SAVE_SUMMARY_NITROGEN_INFO:
      return {
        ...state,
        summaryNitrogenInfo: action.payload,
      };
    case GET_COVERSAT_DATA_SUCCESS:
      return {
        ...state,
        coversat: action.payload,
      };
    case GET_COVER_CROP_SUCCESS: 
      return {
        ...state,
        covercrop: action.payload
      }
    default:
      return state;
  }
};

export default carbonReducers;
