export const GET_REPORT = 'GET_REPORT';
export interface GetReportActions {
  type: typeof GET_REPORT;
  payload: {
    lang: string;
    send_email?: boolean;
    email?: string;
  };
}
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export interface GetReportSuccessActions {
  type: typeof GET_REPORT_SUCCESS;
  payload: {
    report: any;
  };
}
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export interface GetReportErrorActions {
  type: typeof GET_REPORT_ERROR;
  payload: {
    message: string;
  };
}

export const INIT_REPORT_STATE = 'INIT_REPORT_STATE';
export interface InitReportStateAction {
  type: typeof INIT_REPORT_STATE;
}
export type ReportActions =
  | GetReportActions
  | GetReportSuccessActions
  | GetReportErrorActions
  | InitReportStateAction;

export const reportActions = {
  initReportState: (): InitReportStateAction => {
    return {
      type: INIT_REPORT_STATE,
    };
  },
  getReport: (
    lang: string,
    send_email?: boolean,
    email?: string,
  ): GetReportActions => {
    return {
      type: GET_REPORT,
      payload: {
        lang: lang,
        send_email: send_email,
        email: email,
      },
    };
  },
  getReportSuccess: (report: any): GetReportSuccessActions => {
    return {
      type: GET_REPORT_SUCCESS,
      payload: {
        report: report,
      },
    };
  },
  getReportError: (message: string): GetReportErrorActions => {
    return {
      type: GET_REPORT_ERROR,
      payload: {
        message: message,
      },
    };
  },
};
