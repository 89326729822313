import {Reducer} from 'redux';
import {
  GET_CONNECTED_STATIONS_SUCCESS,
  GET_WEATHER_CLIMATE_DATA_SUCCESS,
  GET_WEATHER_CUMULATIVE_DATA_SUCCESS,
  GET_WEATHER_HISTOGRAM_DATA_SUCCESS,
  GET_WEATHER_PROVIDER_DATA_SUCCESS,
  SAVE_SUMMARY_WEATHER_INFO,
  WeatherActionTypes,
} from '../actions/WeatherActions';
import {WeatherStationData} from '../models/WeaterStationData';
import {WeatherState} from '../models/WeatherState';

const initialState: WeatherState = {
  summaryWeatherInfo: null,
  cumulative_data: null,
  climate_data: null,
  histogram_data: null,
  weather_providers: null,
  weather_stations_data: null,
};

const WeatherReducers: Reducer<WeatherState, WeatherActionTypes> = (
  state = initialState,
  action: WeatherActionTypes,
) => {
  switch (action.type) {
    case GET_WEATHER_HISTOGRAM_DATA_SUCCESS:
      return {
        ...state,
        histogram_data: action.payload,
      };
    case GET_WEATHER_CLIMATE_DATA_SUCCESS:
      return {
        ...state,
        climate_data: action.payload,
      };
    case GET_WEATHER_CUMULATIVE_DATA_SUCCESS:
      return {
        ...state,
        cumulative_data: action.payload,
      };
    case SAVE_SUMMARY_WEATHER_INFO:
      return {
        ...state,
        summaryWeatherInfo: action.payload,
      };
    case GET_WEATHER_PROVIDER_DATA_SUCCESS:
      return {
        ...state,
        weather_providers: action.payload,
      };
    case GET_CONNECTED_STATIONS_SUCCESS:
      return {
        ...state,
        weather_stations_data: action.payload as WeatherStationData,
      };
    default:
      return state;
  }
};

export default WeatherReducers;
