import {WeatherStationCredentials} from '../models/BaseWeatherStationSchema';
import {ClimateDataChart} from '../models/Climate';
import {DeleteProviderData} from '../models/DeleteProviderData';
import {HistogramDataChart} from '../models/Histrogram';
import {SummaryWeatherServiceInfo} from '../models/SummaryWeatherServiceInfo';
import {UserWeatherStationPayload} from '../models/UserWeatherStationPayload';
import {WeatherStation, WeatherStationData} from '../models/WeaterStationData';
import {CumulativeDataChart} from '../models/WeatherCumulative';
import {
  ConnectedStationType,
  WeatherStationResponse,
} from '../models/WeatherStationResponse';

export const GET_WEATHER_CUMULATIVE_DATA = 'GET_WEATHER_CUMULATIVE_DATA';
export interface GetWeatherCumulativeDataAction {
  type: typeof GET_WEATHER_CUMULATIVE_DATA;
  payload: number;
}
export const GET_WEATHER_CUMULATIVE_DATA_SUCCESS =
  'GET_WEATHER_CUMULATIVE_DATA_SUCCESS';
export interface GetWeatherCumulativeDataSuccessAction {
  type: typeof GET_WEATHER_CUMULATIVE_DATA_SUCCESS;
  payload: {[key: string]: CumulativeDataChart[]};
}

export const GET_WEATHER_HISTOGRAM_DATA = 'GET_WEATHER_HISTOGRAM_DATA';
export interface GetWeatherHistogramDataAction {
  type: typeof GET_WEATHER_HISTOGRAM_DATA;
  payload: number;
}
export const GET_WEATHER_HISTOGRAM_DATA_SUCCESS =
  'GET_WEATHER_HISTOGRAM_DATA_SUCCESS';
export interface GetWeatherHistogramDataSuccessAction {
  type: typeof GET_WEATHER_HISTOGRAM_DATA_SUCCESS;
  payload: {[key: string]: HistogramDataChart};
}

export const GET_WEATHER_CLIMATE_DATA = 'GET_WEATHER_CLIMATE_DATA';
export interface GetWeatherClimateDataAction {
  type: typeof GET_WEATHER_CLIMATE_DATA;
  payload: number;
}
export const GET_WEATHER_CLIMATE_DATA_SUCCESS =
  'GET_WEATHER_CLIMATE_DATA_SUCCESS';
export interface GetWeatherClimateDataSuccessAction {
  type: typeof GET_WEATHER_CLIMATE_DATA_SUCCESS;
  payload: ClimateDataChart[];
}

export const SAVE_SUMMARY_WEATHER_INFO = 'SAVE_SUMMARY_WEATHER_INFO';
export interface SaveSummaryWeatherInfoAction {
  type: typeof SAVE_SUMMARY_WEATHER_INFO;
  payload: SummaryWeatherServiceInfo;
}

export const GET_WEATHER_PROVIDER_DATA = 'GET_WEATHER_PROVIDER_DATA';
export interface GetWeatherProviderDataAction {
  type: typeof GET_WEATHER_PROVIDER_DATA;
}

export const GET_WEATHER_PROVIDER_DATA_SUCCESS =
  'GET_WEATHER_PROVIDER_DATA_SUCCESS';
export interface GetWeatherProviderDataSuccessAction {
  type: typeof GET_WEATHER_PROVIDER_DATA_SUCCESS;
  payload: {[key in ConnectedStationType]?: WeatherStationResponse};
}

export const MODIFY_USER_WEATHER_STATION = 'MODIFY_USER_WEATHER_STATION';
export interface ModifyUserWeatherStationAction {
  type: typeof MODIFY_USER_WEATHER_STATION;
  payload: UserWeatherStationPayload<WeatherStationCredentials>;
}

export const DELETE_USER_WEATHER_STATION = 'DELETE_USER_WEATHER_STATION';
export interface DeleteUserWeatherStationAction {
  type: typeof DELETE_USER_WEATHER_STATION;
  payload: DeleteProviderData;
}

export const GET_CONNECTED_STATIONS = 'GET_CONNECTED_STATIONS';
export interface GetConnectedStationsAction {
  type: typeof GET_CONNECTED_STATIONS;
}

export const GET_CONNECTED_STATIONS_SUCCESS = 'GET_CONNECTED_STATIONS_SUCCESS';
export interface GetConnectedStationsSuccessAction {
  type: typeof GET_CONNECTED_STATIONS_SUCCESS;
  payload: {
    [key in ConnectedStationType]?: WeatherStation /* modify type when we know type from backend */;
  };
}

export type WeatherActionTypes =
  | GetWeatherClimateDataAction
  | GetWeatherClimateDataSuccessAction
  | GetWeatherCumulativeDataAction
  | GetWeatherCumulativeDataSuccessAction
  | GetWeatherHistogramDataAction
  | GetWeatherHistogramDataSuccessAction
  | SaveSummaryWeatherInfoAction
  | GetWeatherProviderDataSuccessAction
  | GetWeatherProviderDataAction
  | GetConnectedStationsAction
  | GetConnectedStationsSuccessAction;

export const weatherActions = {
  saveSummaryWeatherInfo: (
    data: SummaryWeatherServiceInfo,
  ): SaveSummaryWeatherInfoAction => {
    return {
      type: SAVE_SUMMARY_WEATHER_INFO,
      payload: data,
    };
  },

  getCumulativeData: (fieldId: number): GetWeatherCumulativeDataAction => {
    return {
      type: GET_WEATHER_CUMULATIVE_DATA,
      payload: fieldId,
    };
  },
  getCumulativeDataSuccess: (data: {
    [key: string]: CumulativeDataChart[];
  }): GetWeatherCumulativeDataSuccessAction => {
    return {
      type: GET_WEATHER_CUMULATIVE_DATA_SUCCESS,
      payload: data,
    };
  },
  getHistogramData: (fieldId: number): GetWeatherHistogramDataAction => {
    return {
      type: GET_WEATHER_HISTOGRAM_DATA,
      payload: fieldId,
    };
  },
  getHistogramDataSuccess: (data: {
    [key: string]: HistogramDataChart;
  }): GetWeatherHistogramDataSuccessAction => {
    return {
      type: GET_WEATHER_HISTOGRAM_DATA_SUCCESS,
      payload: data,
    };
  },
  getClimateData: (fieldId: number): GetWeatherClimateDataAction => {
    return {
      type: GET_WEATHER_CLIMATE_DATA,
      payload: fieldId,
    };
  },
  getClimateDataSuccess: (
    data: ClimateDataChart[],
  ): GetWeatherClimateDataSuccessAction => {
    return {
      type: GET_WEATHER_CLIMATE_DATA_SUCCESS,
      payload: data,
    };
  },

  getWeatherProviderData: (): GetWeatherProviderDataAction => {
    return {
      type: GET_WEATHER_PROVIDER_DATA,
    };
  },

  getWeatherProviderDataSuccess: (data: {
    [key in ConnectedStationType]?: WeatherStationResponse;
  }): GetWeatherProviderDataSuccessAction => {
    return {
      type: GET_WEATHER_PROVIDER_DATA_SUCCESS,
      payload: data,
    };
  },

  modifyUserWeatherStation: (
    data: UserWeatherStationPayload<WeatherStationCredentials>,
  ): ModifyUserWeatherStationAction => {
    return {
      type: MODIFY_USER_WEATHER_STATION,
      payload: data,
    };
  },

  deleteUserWeatherStation: (
    data: DeleteProviderData,
  ): DeleteUserWeatherStationAction => {
    return {
      type: DELETE_USER_WEATHER_STATION,
      payload: data,
    };
  },

  getConnectedStation: (): GetConnectedStationsAction => {
    return {
      type: GET_CONNECTED_STATIONS,
    };
  },

  getConnectedStationSuccess: (data: WeatherStationData) => {
    return {
      type: GET_CONNECTED_STATIONS_SUCCESS,
      payload: data,
    };
  },
};

export default weatherActions;
