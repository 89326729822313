import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as Warning} from '../../../assets/images/icons/warningIcon.svg';

const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={Warning}
      viewBox='0 0 24 24'
    />
  );
};

export default WarningIcon;
