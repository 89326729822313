import {AxiosResponse} from 'axios';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions';
import api, {pixagriApiUrl} from 'shared/services/ApiConfig';
import log from 'shared/services/LogService';
import carbonActions, {
  LoadCarbonPeriodsAction,
  LOAD_CARBON_PERIODS,
  LOAD_CARBON_THRESHOLDS,
} from '../actions/CarbonActions';
import {CarbonFlowLevel} from '../models/CarbonFlowLevel';
import {
  CarbonPeriod,
} from '../models/CarbonPeriod';
import {CarbonThreshold} from '../models/CarbonThreshold';

function getCarbonPeriods(fieldId: number, periodId: number) {
  const carbonPeriodsUrl = `${pixagriApiUrl}/fields/${fieldId}/periods/${periodId}/csoils`;
  return api.get(carbonPeriodsUrl);
}

function getCarbonGaugeThreshold() {
  const carbonThresholdsUrl = `${pixagriApiUrl}/csoils/carbon_flux_thresholds`;
  return api.get(carbonThresholdsUrl)
}

function* loadCarbonPeriod(action: LoadCarbonPeriodsAction) {
  try {
    yield put(fetchStart('loadCarbonPeriod'));
    const {periodId, fieldId} = action.payload;

    const res: AxiosResponse = yield call(getCarbonPeriods, fieldId, periodId);
    let carbonResponse = res.data as CarbonPeriod[];
    let periods: {[key: string]: CarbonPeriod} = {};

    carbonResponse.forEach(period => {
      periods[period.period_id] = period
    })

    yield put(fetchSuccess('loadCarbonPeriod'));
    yield put(carbonActions.onSelectPeriod(periodId, periods[periodId].campaign_id));
    yield put(carbonActions.loadCarbonSuccess(periods));
  } catch (error) {
    log.error(error);
    yield put(fetchError(`loadCarbonPeriod`, 'error'));
  }
}

function* loadCarbonThresholds() {
  try {
    yield put(fetchStart('loadCarbonThresholds'))
    const res: AxiosResponse = yield call(getCarbonGaugeThreshold)
    const thresholds = res.data as CarbonThreshold[]


    if (!thresholds || !thresholds.length) {
      throw new Error(`No Carbon thresholds get from api`)
    }
    /**
     * parse threshold value to get number
     */
    thresholds.forEach(thres => {
      thres.thres_max = parseFloat(thres.thres_max as string)
      thres.thres_min = parseFloat(thres.thres_min as string)
    })
    /**
     * sorted by level asc
     */
    thresholds.sort((a, b) => {
      const thresMinA = a.thres_min as number
      const thresMinB = b.thres_min as number
      return (thresMinA-thresMinB)
    })
    /**
     * Fill level 
     */
    if (thresholds[0]) { 
      thresholds[0].level = CarbonFlowLevel.LOW
    } else {
      log.error(`Cannot define ${CarbonFlowLevel.LOW}`)
    }
    if (thresholds[1]) { 
      thresholds[1].level = CarbonFlowLevel.LOW_MEDIUM
    } else {
      log.error(`Cannot define ${CarbonFlowLevel.LOW_MEDIUM}`)
    }
    if (thresholds[2]) { 
      thresholds[2].level = CarbonFlowLevel.MEDIUM_HIGH
    } else {
      log.error(`Cannot define ${CarbonFlowLevel.MEDIUM_HIGH}`)
    }
    if (thresholds[3]) { 
      thresholds[3].level = CarbonFlowLevel.HIGH
    } else {
      log.error(`Cannot define ${CarbonFlowLevel.HIGH}`)
    }


    yield put(carbonActions.loadCarbonThresholdsSuccess(thresholds))
    yield put(fetchSuccess('loadCarbonThresholds'))
  } catch (error) {
    log.error(error)
    yield put(fetchError(`loadCarbonThresholds`, error.message))
  }

}

export function* carbonSaga() {
  yield all([
    takeEvery(LOAD_CARBON_PERIODS, loadCarbonPeriod),
    takeEvery(LOAD_CARBON_THRESHOLDS, loadCarbonThresholds)
  ]);
}
