
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {RoleConfig} from '../models/RoleConfig';
import {UserPermission} from '../models/UserPermission';

function useCheckPermissions(allowedPermissions: UserPermission | UserPermission[] | undefined): boolean {

    const [allowed, setAllowed] = useState<boolean>(false);

    const {rolesConfig, user} = useSelector<AppState, AppState['auth']>(
        (state) => state.auth,
    );


    useEffect(() => {

        let roleConfig: RoleConfig | null = null;
        if (user && user.role && rolesConfig) {
            roleConfig = rolesConfig[user.role]
        }

        let allowed: boolean = false;
        if (roleConfig && roleConfig.permissions) {
            if (allowedPermissions) {
                if (Array.isArray(allowedPermissions)) {
                    for (let iAllowedPermission = 0; iAllowedPermission < allowedPermissions.length; iAllowedPermission++) {
                        const allowedPermission = allowedPermissions[iAllowedPermission];
                        if (roleConfig.permissions.includes(allowedPermission)) {
                            allowed = true;
                            break;
                        }
                    }
                } else {
                    if (roleConfig.permissions.includes(allowedPermissions)) {
                        allowed = true;
                    }
                }
            } else {
                allowed = true;
            }
        }
        setAllowed(allowed);
    }, [rolesConfig, user, allowedPermissions]);

    return allowed;
}

export default useCheckPermissions;