import {Box, Typography, useTheme} from '@mui/material';
import React from "react";
import ArrowLeftMiddle from "./icons/ArrowLeftMiddle";
import dateComparisonImage from "../assets/dateComparisonImage.png";
import {FormattedMessage} from "react-intl";

const VegetationCompareDateHelp: React.FC = () => {
  const theme = useTheme();

  return (
    <Box flex={1} width={1} display="flex" flexDirection={"column"}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.vegetation.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography
          width='700px'
          variant='caption'
          color={theme.palette.grey[600]}
          paddingY={3}
          textAlign='center'>
          <FormattedMessage id="info.vegetation.compare_date.explanation" />
        </Typography>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={dateComparisonImage} alt='nitrogen' width={'700px'} />

          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-180}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={"bold"}>
                <FormattedMessage id='info.select_field' />
              </Typography>
              <ArrowLeftMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -50,
                  right: -90,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VegetationCompareDateHelp;
