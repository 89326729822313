import SysIrrigation from "../models/SysIrrigation";

export const getSysIrrigationTranslationKey = (sysIrrigation: SysIrrigation): string | null => {
    return getSysIrrigationTranslationKeyFromCode(sysIrrigation.sys_irrigation_code);
}

export const getSysIrrigationTranslationKeyFromCode = (sysIrrigationCode: string): string | null => {
    if (sysIrrigationCode) {
        return `sysIrrigation.${sysIrrigationCode.toLowerCase()}.label`;
    } else {
        return null;
    }
}