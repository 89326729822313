import {Reducer} from 'redux';
import {ReportState} from '../models/ReportState';
import {
  GET_REPORT_ERROR,
  INIT_REPORT_STATE,
  ReportActions,
} from './ReportActions';

const initialState: ReportState = {
  error_message: null,
};
const reportReducer: Reducer<ReportState, ReportActions> = (
  state = initialState,
  action: ReportActions,
) => {
  switch (action.type) {
    case GET_REPORT_ERROR:
      return {
        ...state,
        error_message: action.payload.message,
      };
    case INIT_REPORT_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reportReducer;
