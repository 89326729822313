import React, {useEffect, useState} from "react";
// import ReactMapGL from 'react-map-gl';
import log from 'shared/services/LogService';

function useMapIcons(mapRef: React.RefObject<any>): Boolean {

    const [mapIconLoaded, setMapIconLoaded] = useState<Boolean>(false);
    /**
       * Load map pin sdf to be able to use it
       * SDF is a raster format allowing scaling without pixelisation: https://docs.mapbox.com/help/troubleshooting/using-recolorable-images-in-mapbox-maps/#what-are-signed-distance-fields-sdf
       * It has been generated using https://github.com/elastic/maki
       */
    useEffect(() => {
        if (mapRef && mapRef.current) {
            log.debug(`Map Ref exists, getting map`)
            const map = mapRef.current.getMap();
            if (map) {
                log.debug(`Map exists, loading icon`)
                map.loadImage(
                    `${process.env.PUBLIC_URL}/map-pin.png`,
                    (error: any, image: any) => {
                        if (error) {
                            log.error(`Error loading map icon: ${error.message}`)
                            throw error;
                        }
                        log.debug(`Icon correctly loaded on map`)
                        if (!map.hasImage('map-pin')) {
                            log.debug(`Map does not have icon yet, adding it`);
                            map.addImage('map-pin', image, {sdf: true});
                            setMapIconLoaded(true);
                        } else {
                            log.debug(`Map already has icon`);
                        }
                    },
                );
            }
        }
    }, [mapRef]);

    return mapIconLoaded;
}

export default useMapIcons;