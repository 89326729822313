import {AddCover} from '../models/AddCover';
import {NitrogenCoverCropGet} from '../models/NitrogenCoverCropGet';
import {NitrogenCoverSat} from '../models/NitrogenCoverSat';
import {NitrogenSpecie} from '../models/NitrogenSpecies';
import {NitrogenThresholds} from '../models/NitrogenThresholds';
import {SummaryNitrogenServiceInfo} from '../models/SummaryNitrogenServiceInfo';

export const SAVE_SUMMARY_NITROGEN_INFO = 'SAVE_SUMMARY_NITROGEN_INFO';
export interface SaveSummaryNitrogenInfoAction {
  type: typeof SAVE_SUMMARY_NITROGEN_INFO;
  payload: SummaryNitrogenServiceInfo;
}

export const GET_SPECIES = 'GET_SPECIES';
export interface GetSpeciesAction {
  type: typeof GET_SPECIES;
}
export const GET_SPECIES_SUCCESS = 'GET_SPECIES_SUCCESS';
export interface GetSpeciesSuccessAction {
  type: typeof GET_SPECIES_SUCCESS;
  payload: {[key: string]: NitrogenSpecie};
}

export const GET_COVERSAT_DATA = 'GET_COVERSAT_DATA';
export interface GetCoversatDataAction {
  type: typeof GET_COVERSAT_DATA;
  payload: {
    cc_id: number;
    field_id: number;
  };
}
export const GET_COVERSAT_DATA_SUCCESS = 'GET_COVERSAT_DATA_SUCCESS';
export interface GetCoversatDataSuccessAction {
  type: typeof GET_COVERSAT_DATA_SUCCESS;
  payload: NitrogenCoverSat;
}

export const ADD_COVER = "ADD_COVER"
export interface AddCoverAction {
  type: typeof ADD_COVER
  payload: {
    field_id: number,
    cover: AddCover
  }
}

export const UPDATE_COVER = "UPDATE_COVER"
export interface UpdateCoverAction {
  type: typeof UPDATE_COVER
  payload: {
    field_id: number,
    cover: AddCover
  }
}

export const GET_COVER_CROP = "GET_COVER_CROP"
export interface GetCoverCropAction {
  type: typeof GET_COVER_CROP
  payload: {
    field_id: number,
    cc_id: number
  }
}
export const GET_COVER_CROP_SUCCESS = "GET_COVER_CROP_SUCCESS"
export interface GetCoverCropSuccessAction {
  type: typeof GET_COVER_CROP_SUCCESS
  payload: NitrogenCoverCropGet
}

export const SET_SELECTED_BIOMASS_DATE = "SET_SELECTED_BIOMASS_DATE"
export interface SetSelectedBiomassDateAction {
  type: typeof SET_SELECTED_BIOMASS_DATE
  payload: string
} 

export const GET_NITROGEN_THRESHOLDS = "GET_NITROGEN_THRESHOLDS"
export interface GetNitrogenThresholdsAction {
  type: typeof GET_NITROGEN_THRESHOLDS
}
export const GET_NITROGEN_THRESHOLDS_SUCCESSS = "GET_NITROGEN_THRESHOLDS_SUCCESSS"
export interface GetNitrogenThresholdsSuccessAction {
  type: typeof GET_NITROGEN_THRESHOLDS_SUCCESSS
  payload: {[key: string]: NitrogenThresholds}
}

export const DELETE_COVER_CROP = "DELETE_COVER_CROP"
export interface DeleteCovercropAction {
  type: typeof DELETE_COVER_CROP
  payload: {
    fieldId: number,
    ccId: number
  }
}
export const RESET_NITROGEN_DATA = "RESET_NITROGEN_DATA"
export interface ResetNitrogenDataAction {
  type: typeof RESET_NITROGEN_DATA
}

export type NitrogenActionsTypes =
  | SaveSummaryNitrogenInfoAction
  | GetSpeciesAction
  | GetSpeciesSuccessAction
  | GetCoversatDataAction
  | GetCoversatDataSuccessAction
  | AddCoverAction
  | GetCoverCropAction
  | GetCoverCropSuccessAction
  | UpdateCoverAction
  | SetSelectedBiomassDateAction
  | GetNitrogenThresholdsAction
  | GetNitrogenThresholdsSuccessAction
  | DeleteCovercropAction
  | ResetNitrogenDataAction;

export const nitrogenActions = {
  resetNitrogenData: (): ResetNitrogenDataAction => {
    return {
      type: RESET_NITROGEN_DATA
    }
  },
  deleteNitrogenCovercrop: (fieldId: number, ccId: number): DeleteCovercropAction => {
    return {
      type: DELETE_COVER_CROP,
      payload: {
        fieldId: fieldId,
        ccId: ccId
      }
    }
  },

  getNitrogenThresholdsSuccess: (data: {[key: string]: NitrogenThresholds}): GetNitrogenThresholdsSuccessAction => {
    return {
      type: GET_NITROGEN_THRESHOLDS_SUCCESSS,
      payload: data
    }
  },
  getNitrogenThresholds: (): GetNitrogenThresholdsAction => {
    return {
      type: GET_NITROGEN_THRESHOLDS
    }
  },
  setselectedBiomassDate: (date: string): SetSelectedBiomassDateAction => {
    return {
      type: SET_SELECTED_BIOMASS_DATE,
      payload: date
    }
  },
  updateCover: (fieldId: number, cover: AddCover): UpdateCoverAction => {
    return {
      type: UPDATE_COVER,
      payload: {
        field_id: fieldId,
        cover: cover
      }
    }
  },
  getCovercrop: (fieldId: number, ccId: number): GetCoverCropAction => {
    return {
      type: GET_COVER_CROP,
      payload: {
        field_id: fieldId,
        cc_id: ccId
      }
    }
  },
  getCovercropSuccess: (covercrop: NitrogenCoverCropGet) => {
    return {
      type: GET_COVER_CROP_SUCCESS,
      payload: covercrop      
    }
  },
  
  addCover: (fieldId: number, cover: AddCover): AddCoverAction => {
    return {
      type: ADD_COVER,
      payload: {
        field_id: fieldId,
        cover: cover
      }
    }
  },
  
  getCoverSatDataSuccess: (
    data: NitrogenCoverSat,
  ): GetCoversatDataSuccessAction => {
    return {
      type: GET_COVERSAT_DATA_SUCCESS,
      payload: data,
    };
  },
  getCoverSatData: (ccId: number, fieldId: number): GetCoversatDataAction => {
    return {
      type: GET_COVERSAT_DATA,
      payload: {
        cc_id: ccId,
        field_id: fieldId,
      },
    };
  },
  getSpecies: (): GetSpeciesAction => {
    return {
      type: GET_SPECIES,
    };
  },
  getSpeciesSuccess: (species: {
    [key: string]: NitrogenSpecie;
  }): GetSpeciesSuccessAction => {
    return {
      type: GET_SPECIES_SUCCESS,
      payload: species,
    };
  },
  saveSummaryNitrogenInfo: (
    infos: SummaryNitrogenServiceInfo,
  ): SaveSummaryNitrogenInfoAction => {
    return {
      type: SAVE_SUMMARY_NITROGEN_INFO,
      payload: infos,
    };
  },
};

export default nitrogenActions;
