import {Theme} from '@mui/material';
import {CarbonFlowLevel} from 'modules/carbon/models/CarbonFlowLevel';
import {getCarbonFlowLevelColor} from 'modules/carbon/services/CarbonGaugeService';
// @ts-ignore
import {HydroStressLevel} from 'modules/waterBalance/models/HydroStressLevel';
import {getHydroStressLevelColor} from 'modules/waterBalance/services/WaterBalanceService';
import {LayerProps} from 'react-map-gl';
import {ServiceId} from 'shared/models/RouterParam';

export const ZOOM_SWITCH = 13.75;

const stressLevelColorChoice = function (theme: Theme): mapboxgl.Expression {
  return [
    'case',
    ['==', ['get', 'hydro_stress_level'], HydroStressLevel.VERY_HIGH],
    getHydroStressLevelColor(HydroStressLevel.VERY_HIGH, theme),
    ['==', ['get', 'hydro_stress_level'], HydroStressLevel.HIGH],
    getHydroStressLevelColor(HydroStressLevel.HIGH, theme),
    ['==', ['get', 'hydro_stress_level'], HydroStressLevel.MEDIUM],
    getHydroStressLevelColor(HydroStressLevel.MEDIUM, theme),
    ['==', ['get', 'hydro_stress_level'], HydroStressLevel.LOW],
    getHydroStressLevelColor(HydroStressLevel.LOW, theme),
    getHydroStressLevelColor(HydroStressLevel.UNDEFINED, theme),
  ];
};
const carbonLevelColorChoice = function (theme: Theme): mapboxgl.Expression {
  return [
    'case',
    ['==', ['get', 'carbon_flow_level'], CarbonFlowLevel.LOW],
    getCarbonFlowLevelColor(CarbonFlowLevel.LOW, theme),
    ['==', ['get', 'carbon_flow_level'], CarbonFlowLevel.LOW_MEDIUM],
    getCarbonFlowLevelColor(CarbonFlowLevel.LOW_MEDIUM, theme),
    ['==', ['get', 'carbon_flow_level'], CarbonFlowLevel.MEDIUM_HIGH],
    getCarbonFlowLevelColor(CarbonFlowLevel.MEDIUM_HIGH, theme),
    ['==', ['get', 'carbon_flow_level'], CarbonFlowLevel.HIGH],
    getCarbonFlowLevelColor(CarbonFlowLevel.HIGH, theme),
    getCarbonFlowLevelColor(CarbonFlowLevel.UNDEFINED, theme),
  ];
};

const nitrogenLevelColor = function (theme: Theme): mapboxgl.Expression {
  return ['get', 'n_tot_color'];
};

const getColorPalette = function (theme: Theme, service: ServiceId | null) {
  switch (service) {
    case ServiceId.IRRIGATION:
      return stressLevelColorChoice(theme);
    case ServiceId.CARBON:
      return carbonLevelColorChoice(theme);
    case ServiceId.NITROGEN:
      return nitrogenLevelColor(theme);
    default:
      return theme.palette.grey[400];
  }
};

/**
 * Generate map polygon style using theme colors
 * For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
 * @param theme The app theme
 */
export const generateFieldMapPolygonStyle = function (
  theme: Theme,
  service: ServiceId | null,
): LayerProps {
  return {
    id: 'polygons',
    type: 'fill',
    paint: {
      'fill-color': getColorPalette(theme, service),
      'fill-opacity': [
        'case',
        ['==', ['get', 'hovered'], true],
        1,
        ['==', ['get', 'selected'], true],
        1,
        0.5,
      ],
      'fill-outline-color': theme.palette.primary.main,
    },
    minzoom: ZOOM_SWITCH,
  };
};

/**
 * Generate map bbox style using theme colors
 * @param theme The app theme
 */
export const generateFieldMapBboxStyle = function (theme: Theme): LayerProps {
  return {
    id: 'bbox',
    type: 'line',
    paint: {
      'line-color': theme.palette.background.default,
    },
  };
};

/**
 * Generate map polygon style using theme colors
 * For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
 * @param theme The app theme
 */
export const generateImportFieldsPolygonStyle = function (
  theme: Theme,
): LayerProps {
  return {
    id: 'polygons',
    type: 'fill',
    paint: {
      'fill-color': theme.palette.primary.main,
      'fill-opacity': 1,
      'fill-outline-color': theme.palette.primary.main,
    },
    minzoom: ZOOM_SWITCH,
  };
};

/**
 * Generate map symbol style using theme colors
 * @param theme The app theme
 */
export const generateFieldMapSymbolStyle = function (
  theme: Theme,
  service: ServiceId | null,
): LayerProps {
  return {
    id: 'points',
    type: 'symbol',
    paint: {
      'icon-color': getColorPalette(theme, service),
    },
    layout: {
      'icon-image': 'map-pin',
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
    },
    maxzoom: ZOOM_SWITCH,
  };
};

/**
 * Generate map symbol style using theme colors
 * @param theme The app theme
 */
export const generateImportFieldSymbolStyle = function (
  theme: Theme,
): LayerProps {
  return {
    id: 'points',
    type: 'symbol',
    paint: {
      'icon-color': theme.palette.primary.main,
    },
    layout: {
      'icon-image': 'map-pin',
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
    },
    maxzoom: ZOOM_SWITCH,
  };
};
