import {SvgIconProps} from '@mui/material';
import {SubscriptionsStatus} from 'modules/subscriptions/models/SubscriptionStatus';
import React from 'react';
import ActiveSubscriptionIcon from './ActiveSubscriptionIcon';
import CanceledSubscriptionIcon from './CanceledSubscriptionIcon';
import NoSubscriptionIcon from './NoSubscriptionIcon';
import PastDueSubscriptionIcon from './PastDueSubscriptionIcon';

interface SubscriptionStatusIconProps extends SvgIconProps {
  status: SubscriptionsStatus | null | undefined;
}

/**
 * Switcher for subscription icon depending on status
 * @param props The crop type
 */
const SubscriptionStatusIcon = (props: SubscriptionStatusIconProps) => {

  // TODO: add a tooltip

  if (props.status) {
    switch (props.status) {
      case SubscriptionsStatus.ACTIVE:
        return <ActiveSubscriptionIcon color='primary' {...props} />;
      case SubscriptionsStatus.TRIALING:
        return <ActiveSubscriptionIcon color='primary' {...props} />;
      case SubscriptionsStatus.CANCELED:
        return <CanceledSubscriptionIcon {...props} />;
      case SubscriptionsStatus.PAST_DUE:
        return <PastDueSubscriptionIcon {...props} />;
      case SubscriptionsStatus.UNPAID:
        return <PastDueSubscriptionIcon {...props} />;
    }
  }
  return (
    <NoSubscriptionIcon {...props} />
  );
};

export default SubscriptionStatusIcon;
