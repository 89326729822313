import log from 'loglevel';
import {all, call, put, takeLatest} from 'redux-saga/effects';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions';
import vegetationActions, {
  LEGEND_COLOR_CASE,
  LEGEND_COLOR_CASE_LAI,
  LegendColorCaseAction,
  LegendColorCaseLaiAction,
} from '../actions/VegetationActions';
import StatisticIndicatorLegendColorCase from '../models/StatisticIndicatorLegendColorCase';
import {buildIndicatorLegend} from '../services/LegendService';

function* legendColorCase(action: LegendColorCaseAction) {
  try {
    const {legendImage, min_value, max_value, legend_levels_count} =
      action.payload;
    yield put(fetchStart('legendColorCase'));
    const palette: StatisticIndicatorLegendColorCase[] = yield call(
      buildIndicatorLegend,
      legendImage,
      min_value,
      max_value,
      legend_levels_count,
    );
    yield put(vegetationActions.legendColorCaseSuccess(palette));
    yield put(fetchSuccess('legendColorCase'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('legendColorCase', error));
  }
}

function* legendColorCaseLai(action: LegendColorCaseLaiAction) {
  try {
    yield put(fetchStart('legendColorCase'));
    const data = action.payload;
    yield put(vegetationActions.legendColorCaseSuccess(data));
    yield put(fetchSuccess('legendColorCase'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('legendColorCase', error));
  }
}

export function* vegetationSagas() {
  yield all([takeLatest(LEGEND_COLOR_CASE, legendColorCase)]);
  yield all([takeLatest(LEGEND_COLOR_CASE_LAI, legendColorCaseLai)]);
}
