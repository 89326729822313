import {selectedFieldRootPath} from 'modules/fields/configs/FieldsRoutePaths';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

/**
 * Default vue to redirect if url path doesn't exist
 */
export const DEFAULT_VEGETATION_VUE: ServiceView =
  ServiceView.VEGETATION_DEVELOPPMENT;

const vegetationServiceRootPath = `${selectedFieldRootPath}/${ServiceId.VEGETATION}`;

/**
 * Full path to vegetation development vue
 */
export const selectedFieldVegetationVegDev = `${vegetationServiceRootPath}/${ServiceView.VEGETATION_DEVELOPPMENT}`;

/**
 * Full path to date comparison vue
 */
export const selectedFieldVegetationDateComparison = `${vegetationServiceRootPath}/${ServiceView.DATE_COMPARISON}`;

/**
 * Full path to field comparison vue
 */
export const selectedFieldVegetationFieldComparison = `${vegetationServiceRootPath}/${ServiceView.FIELD_COMPARISON}`;

/**
 * Full path to edit vue
 */
export const selectedFieldVegetationEdit = `${vegetationServiceRootPath}/${ServiceView.EDIT}`;
