import {ReportTranslationsType} from './ReportTranslationsType';

export const uzReportTranslations: ReportTranslationsType = {
  'report.modal.title': 'Kampaniya hisobotini yaratish',
  'report.modal.error.title': 'Xatolik',
  'report.modal.error.text': 'Hisobot yaratish jarayonida xatolik yuz berdi',
  'report.modal.selector.text':
    'Hisobot qaysi tilida yaratilishi kerakligini tanlang',
  'report.modal.error.button': 'Yopish',
  'report.modal.button': 'Yuklab olish',
};
