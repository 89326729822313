import React, {useEffect, useState} from 'react';
import {Loader} from '@crema';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import IntlMessages from 'shared/components/IntlMessages';
import StepBar from './StepBar';
import StepTitle from './StepTitle';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import FieldSelectionStep from 'modules/subscriptions/components/SubscriptionFieldSelectionStep';
import SummaryFieldSelectionStep from 'modules/subscriptions/components/SummaryFieldSelectionStep';
import PriceSelectionStep from 'modules/products/components/PriceSelectionStep';
import ProductSelectionStep from 'modules/products/components/ProductSelectionStep';
import Field from 'modules/fields/models/Field';
import Product from 'modules/products/models/Product';
import Campaign from 'modules/campaigns/models/Campaign';
import Price from 'modules/products/models/Price';
import CheckoutSession, {CheckoutLineItem, supportedCheckoutSessionLocales} from '../models/CheckoutSession';
import {baseCheckoutSession} from '../configs/chekoutSession';
import {useHistory} from 'react-router-dom';
import {QUERY_PAYMENT_CANCEL, QUERY_PAYMENT_SUCCESS, STRIPE_METADATA_} from '../configs/constant';
import {STRIPE_METADATA_REF} from 'modules/products/configs/constant';
import {computeTotalAreaHa, isFieldComplete, isFieldSubscribe} from '../../fields/services/FieldsService';
import {getServiceOverviewFullRoute} from 'modules/fields/configs/FieldsRoutePaths';
import log from 'shared/services/LogService';
import {handleStripeSubscription} from '../services/sendCheckoutSession';
import useStepperStyles from './StepperStyle';
import {CremaTheme} from 'shared/models/AppContextPropsType';
import BaseDialog from 'shared/components/BaseDialog';
import AddFieldForm, {
  FieldFormEditMode,
} from 'modules/fields/components/AddFieldForm';
import useStyles from '../../fields/components/Fields.style';
import {ServiceProduct} from 'modules/products/models/ProductsState';
import {getCampaignTranslation} from 'modules/campaigns/services/CampaignsTranslation';
import {useIntl} from 'react-intl';
import {ServiceId} from 'shared/models/RouterParam';
import SubscriptionSummaryStep from './SubscriptionSummaryStep';
import SubscriptionPaymentMethod from '../models/SubscriptionPaymentMethod';
import SubscriptionSteps from '../models/SubscriptionSteps';
import {isFreeTrial} from 'modules/products/services/productService';

interface Error {
  isError: boolean;
  message: string;
}

export default function SubscriptionCreation() {
  const {customer, token} = useSelector<AppState, AppState['auth']>(
    (state) => state.auth,
  );
  const {loading} = useSelector<AppState, AppState['common']>(
    ({common}) => common,
  );

  const {data: productsData} = useSelector<AppState, AppState['products']>(
    (state) => state.products,
  );

  const {
    selectedCampaign: selectedCampaignId,
    selectedService,
    selectedFieldId,
    data: fieldsData,
  } = useSelector<AppState, AppState['fields']>((state) => state.fields);

  const {data} = useSelector<AppState, AppState['campaigns']>(
    (state) => state.campaigns,
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const theme: CremaTheme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();

  const classes = useStepperStyles();
  const paperClasses = useStyles();
  const [products, setProducts] = useState<ServiceProduct | null>(null);
  const [stepContentList, setStepContentList] = useState<SubscriptionSteps[]>([]);
  const [selectedFields, setSelectedFields] = useState<Field[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [paymentMethod, setPaymentMethod] = useState<SubscriptionPaymentMethod>(customer?.allow_subscribe_without_checkout ? SubscriptionPaymentMethod.INVOICE : SubscriptionPaymentMethod.CHECKOUT)
  const [activeStep, setActiveStep] = useState<number>(0);
  //@ts-ignore
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [selectedPrice, setSelectedPrice] = useState<Price>();
  const [checkoutSession, setCheckoutSession] =
    useState<CheckoutSession>(baseCheckoutSession);
  const errorStateDefault: Error = {isError: false, message: ''};
  const [error, setError] = useState<Error>(errorStateDefault);
  const [openFieldEditModal, setOpenFieldEditModal] = useState<boolean>(false);
  const [fieldToEdit, setFieldToEdit] = useState<Field | null>(null);

  useEffect(() => {
    if (selectedService && productsData) {
      const products = productsData[selectedService];
      if (products) {
        setProducts(products);
      } else {
        log.error(`No product found for service ${selectedService}`);
      }
    }
  }, [selectedService, productsData]);
  /**
   * Set by default campaign to currentCampaign
   */
  useEffect(() => {
    if (data && selectedCampaignId) {
      const currentCampaign: Campaign = data[selectedCampaignId];
      if (currentCampaign) {
        setSelectedCampaign(currentCampaign);
      } else {
        log.error(
          `Cannot define currentCampaign with id: ${selectedCampaignId}`,
        );
      }
    }
  }, [selectedCampaignId, data]);

  /**
   * selected selectedField by default
   */
  useEffect(() => {
    if (
      selectedFieldId &&
      fieldsData &&
      selectedCampaignId &&
      selectedService
    ) {
      const fieldsInCampaign = fieldsData[selectedCampaignId];
      if (fieldsInCampaign && fieldsInCampaign[selectedFieldId]) {
        const selectedField = fieldsInCampaign[selectedFieldId];
        if (
          !isFieldSubscribe(
            selectedService,
            selectedCampaignId,
            selectedField,
          ) &&
          isFieldComplete(selectedService, selectedCampaignId, selectedField)
        ) {
          setSelectedFields([selectedField]);
        }
      }
    }
  }, [fieldsData, selectedCampaignId, selectedFieldId, selectedService]);

  useEffect(() => {
    if (products) {
      const productList = Object.keys(products);
      if (productList.length > 1) {
        setStepContentList([SubscriptionSteps.FIELD_SELECTION,
          SubscriptionSteps.SUMMARY_FIELD_SELECTION,
          SubscriptionSteps.PRODUCT_SELECTION,
          SubscriptionSteps.PRICE_SELECTION,
          SubscriptionSteps.SUBSCRIPTION_SUMMARY
        ]);
      } else if (productList.length === 1) {
        const product = Object.values(products);
        setSelectedProduct(product[0]);
        setStepContentList([
          SubscriptionSteps.FIELD_SELECTION,
          SubscriptionSteps.SUMMARY_FIELD_SELECTION,
          SubscriptionSteps.PRICE_SELECTION,
          SubscriptionSteps.SUBSCRIPTION_SUMMARY
        ]);
      }
    }
  }, [
    products,
    selectedFields,
    selectedProduct,
    selectedPrice,
    selectedCampaign,
  ]);

  const resetModalDefaultState = () => {
    setSelectedFields(null);
    setSelectedProduct(undefined);
    setSelectedPrice(undefined);
    handleCreatingSubscriptionProcess();
    setActiveStep(0);
    setError(errorStateDefault);
  };

  const renderCurrentStep = (currentStep: number, stepContentList: SubscriptionSteps[]) => {
    const currentStepContent = stepContentList[currentStep];
    switch(currentStepContent) {
      case SubscriptionSteps.FIELD_SELECTION:
        return (<FieldSelectionStep
          selectedFields={selectedFields ? selectedFields : []}
          onSelectedFieldsChange={setSelectedFields}
          onFieldToEditChange={setFieldToEdit}
          onOpenEditModal={setOpenFieldEditModal}
        />);
        case SubscriptionSteps.SUMMARY_FIELD_SELECTION:
          return (<SummaryFieldSelectionStep
            selectedFields={selectedFields ? selectedFields : []}
            setSelectedFields={setSelectedFields}
            editable
          />);
        case SubscriptionSteps.PRODUCT_SELECTION:
          return (<ProductSelectionStep
            selectedFields={selectedFields ? selectedFields : []}
            products={products!}
            selectedProduct={selectedProduct}
            setSelectedProduct={handleSelectedProduct}
          />);
        case SubscriptionSteps.PRICE_SELECTION:
          return (<PriceSelectionStep
            selectedFields={selectedFields ? selectedFields : []}
            selectedProduct={selectedProduct}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
          />);
        case SubscriptionSteps.SUBSCRIPTION_SUMMARY:
          return (<SubscriptionSummaryStep
            selectedFields={selectedFields ? selectedFields : []}
            selectedProduct={selectedProduct!}
            selectedPrice={selectedPrice!}
            paymentMethod={paymentMethod}
            onPaymentMethodChange={handlePaymentMethodChange}
          />);
    }
  }

  const handleCreatingSubscriptionProcess = (query?: string) => {
    // setCreatingSubscription(init ? defaultCreationSubsState : !creatingSubscription)
    history.push({pathname: history.location.pathname, search: query});
  };

  const handlePaymentMethodChange = (newPaymentMethod: SubscriptionPaymentMethod) => {
    setPaymentMethod(newPaymentMethod);
  };

  const completeCheckoutSession = () => {
    if (
      selectedCampaign &&
      selectedService &&
      selectedFields &&
      selectedFields.length &&
      selectedProduct &&
      selectedPrice &&
      checkoutSession
    ) {
      /**
       * Complete Metadata
       */

      const field_ids: number[] = [];
      selectedFields.forEach((field) => {
        field_ids.push(field.field_id);
      });

      const product_ref: string =
        selectedProduct[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`];
      const price_ref: string =
        selectedPrice[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`];
      const checkoutSessionMetadata = {
        campaign_id: selectedCampaign.campaign_id.toString(),
        field_ids: JSON.stringify(field_ids),
        product_ref: product_ref,
        price_ref: price_ref,
        product_type: isFreeTrial(selectedProduct) ? 'trial' : 'standard',
      };

      /**
       * Complete line-item
       */

      const price = selectedPrice.id;
      const quantity = Math.floor(computeTotalAreaHa(selectedFields));

      const lineItem: CheckoutLineItem = {
        price: price,
        quantity: quantity,
      };
      const checkoutSessionCompleted: CheckoutSession = {
        ...checkoutSession,
        success_url: `${window.location.origin}${getServiceOverviewFullRoute(
          selectedCampaign.campaign_id,
          selectedService,
        )}${QUERY_PAYMENT_SUCCESS}`,
        cancel_url: `${window.location.origin}${getServiceOverviewFullRoute(
          selectedCampaign.campaign_id,
          selectedService,
        )}${QUERY_PAYMENT_CANCEL}`,
        metadata: checkoutSessionMetadata,
        line_items: [lineItem],
      };
      if (
        customer &&
        customer.preferred_locales &&
        customer.preferred_locales.length > 0
      ) {
        let targetLocale: string | null = null;
        const preferredLocale = customer.preferred_locales[0];
        if (supportedCheckoutSessionLocales.includes(preferredLocale)) {
          targetLocale = preferredLocale;
        } else {
          const preferredLocaleParts = preferredLocale.split('-');
          if (
            supportedCheckoutSessionLocales.includes(preferredLocaleParts[0])
          ) {
            targetLocale = preferredLocaleParts[0];
          }
        }
        if (targetLocale) {
          checkoutSessionCompleted.locale = targetLocale;
        }
      }
      setCheckoutSession(checkoutSessionCompleted);
      return checkoutSessionCompleted;
    } else {
      log.debug(`      selectedCampaign: ${selectedCampaign} &&
      selectedFields : ${selectedFields} &&
      selectedFields.length : ${selectedFields?.length} &&
      selectedProduct: ${selectedProduct} &&
      selectedPrice : ${selectedPrice}&&
      checkoutSession : ${checkoutSession}
`);
    }
  };
  const controlAndSendCheckoutSession = (
    checkoutSession: CheckoutSession,
    paymentMethod: SubscriptionPaymentMethod,
    token: string,
  ) => {
    const missingValues: string[] = [];
    const lineItems = checkoutSession.line_items[0];
    const metadata = checkoutSession.metadata;
    Object.keys(lineItems).forEach((key) => {
      if (!lineItems[key as keyof CheckoutLineItem]) {
        missingValues.push(key);
      }
    });
    Object.keys(metadata).forEach((key) => {
      if (!metadata[key]) {
        missingValues.push(key);
      }
    });
    if (missingValues.length) {
      log.error(
        `Cannot send checkout session because of missing values for`,
        missingValues,
      );
    } else if (selectedService) {
      // Dispatch fetch start in order to display a loader
      handleStripeSubscription(
        checkoutSession,
        paymentMethod,
        dispatch,
        token,
        selectedService,
      );
    }
  };
  /**
   * Action to do when click on next button
   * @param activeStep
   */
  const onFinalAction = (activeStep: number) => {
    if (activeStep < stepContentList.length - 1) {
      if (!selectedPrice) {
        setActiveStep(activeStep + 1);
      } else {
        // Price already selected, go directly to summary
        setActiveStep(stepContentList.length - 1);
      }
    } else {
      const checkoutSessionCompleted = completeCheckoutSession();
      if (checkoutSessionCompleted && token) {
        controlAndSendCheckoutSession(checkoutSessionCompleted, paymentMethod, token);
      }
    }
  };
  const checkSelectedField = () => {
    if (selectedFields && selectedFields.length) {
      setError(errorStateDefault);
      return true;
    } else {
      setError({
        isError: true,
        message: 'subscriptions.stepper.error.message.missing_fields',
      });
      return false;
    }
  };
  const checkSelectedProduct = () => {
    if (selectedProduct) {
      setError(errorStateDefault);
      return true;
    } else {
      setError({
        isError: true,
        message: 'subscriptions.stepper.error.message.missing_product',
      });
      return false;
    }
  };
  const checkSelectedPrice = () => {
    if (selectedPrice) {
      setError(errorStateDefault);
      return true;
    } else {
      setError({
        isError: true,
        message: 'subscriptions.stepper.error.message.missing_price',
      });
      return false;
    }
  };
  const isValidate = (activeStep: number) => {
    const activeStepContent = stepContentList[activeStep];
    switch (activeStepContent) {
      case SubscriptionSteps.FIELD_SELECTION:
        return checkSelectedField();
      case SubscriptionSteps.SUMMARY_FIELD_SELECTION:
        return checkSelectedField();
      case SubscriptionSteps.PRODUCT_SELECTION:
        if (products && Object.keys(products).length > 1) {
          return checkSelectedProduct();
        } else {
          return () => {
            checkSelectedProduct();
            checkSelectedPrice();
          };
        }
      case SubscriptionSteps.PRICE_SELECTION:
        return checkSelectedPrice();
      case SubscriptionSteps.SUBSCRIPTION_SUMMARY:
        return true;
    }
  };

  const previousStep = (activeStep: number) => {
    setSelectedPrice(undefined);
    setActiveStep(activeStep - 1);
  };

  const validationStep = (activeStep: number) => {
    if (isValidate(activeStep)) {
      onFinalAction(activeStep);
    }
  };

  const handleSelectedProduct = (product: Product) => {
    setSelectedProduct(product);
    // If only one price, set the price at the same time
    if (product && product.prices?.length === 1) {
      setSelectedPrice(product.prices[0]);
    }
  };

  if (selectedService) {
    return (
      <>
        {fieldToEdit && (
          <BaseDialog
            classes={{
              paper: paperClasses.dialogPaper,
            }}
            fullWidth={true}
            titleMessageId='fields.update.field'
            open={openFieldEditModal}
            onClose={() => setOpenFieldEditModal(false)}>
            <AddFieldForm
              editMode={
                selectedService === ServiceId.NITROGEN
                  ? FieldFormEditMode.ADD_COVER
                  : FieldFormEditMode.ADD_ROTATION_MODAL
              }
              field={fieldToEdit}
              onSubmit={() => setOpenFieldEditModal(false)}
            />
          </BaseDialog>
        )}

        <Dialog
          fullWidth
          maxWidth={isDownMd ? 'lg' : 'md'}
          open={true}
          onClose={resetModalDefaultState}>
          {loading && <Loader />}
          <DialogTitle className={classes.stepperDialogTitle}>
            <Typography flex={1} variant='h5' classes={{h5: classes.title}}>
              <IntlMessages
                id={'subscriptions.stepper.title'}
                values={{
                  service: selectedService
                    ? intl.formatMessage({
                        id: `${selectedService}.headerButton.title`,
                      })
                    : null,
                }}
              />
            </Typography>
            {data && selectedCampaignId && (
              <Typography
                display={'block'}
                fontWeight={'bold'}
                variant='subtitle1'>
                {getCampaignTranslation(data[selectedCampaignId])}
              </Typography>
            )}
            <IconButton
              aria-label='close'
              onClick={resetModalDefaultState}
              className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.stepperDialogContent}>
            <StepTitle activeStep={activeStep} />

            {renderCurrentStep(activeStep, stepContentList)}
          
          </DialogContent>
          <DialogActions className={classes.stepperDialogAction}>
            {error && error.isError ? (
              <Typography className={classes.errorMessage}>
                <IntlMessages id={error.message} />
              </Typography>
            ) : (
              ''
            )}
            <Box className={classes.stepperButtonsContainer}>
              {activeStep > 0 ? (
                <Button
                  className={classes.stepperPrevButton}
                  variant='outlined'
                  color={'primary'}
                  onClick={() => previousStep(activeStep)}>
                  <IntlMessages id={'subscriptions.stepper.button.prev_step'} />
                </Button>
              ) : (
                ''
              )}
              <Button
                className={classes.stepperButtonValidation}
                color='primary'
                variant='contained'
                onClick={() => validationStep(activeStep)}>
                {activeStep === (stepContentList.length - 1) ? (
                  paymentMethod === SubscriptionPaymentMethod.CHECKOUT ?
                  <IntlMessages id='subscriptions.stepper.button.go_to_payment' />:
                  <IntlMessages id='subscriptions.stepper.button.subscribe' />
                ) : (
                  <IntlMessages id={'subscriptions.stepper.button.next_step'} />
                )}
              </Button>
            </Box>
            <StepBar
              activeStep={activeStep}
              stepperLength={stepContentList.length}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return null;
}