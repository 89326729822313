export interface LanguageProps {
  languageId: string;
  locale: string;
  name: string;
  icon: string;
}

export const languageData: LanguageProps[] = [
  {
    languageId: 'french',
    locale: 'fr-FR',
    name: 'Français',
    icon: 'fr',
  },
  {
    languageId: 'english',
    locale: 'en-US',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'spanish',
    locale: 'es-ES',
    name: 'Español',
    icon: 'es',
  },
  {
    languageId: 'russian',
    locale: 'ru-RU',
    name: 'Русский',
    icon: 'ru',
  },
  {
    languageId: 'uzbek',
    locale: 'uz-UZ',
    name: "O'zbek",
    icon: 'uz',
  },
  {
    languageId: 'italian',
    locale: 'it-IT',
    name: "Italiano",
    icon: 'it',
  },
];
export default languageData;
