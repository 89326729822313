import {AddCover} from '../models/AddCover';
import {CoverCropExploitation} from '../models/CoverCropExploitation';
import {ResiduManagement} from '../models/ResidueManagement';

/**
 * Min & Max value for n-tot map legend gradient
 */
export const MIN_NITROGEN_TOT = 0;
export const MAX_NITROGEN_TOT = 80;
export const GRADIENT_BREAKPOINT_NUMBER = 20;
export const GRADIENT_COLOR_REF = 'RdYlGn';

export const BASE_COVER_CREATION: AddCover = {
  sowing_date: '',
  destruction_date: '',
  residue_management: ResiduManagement.BURY,
  covercrop_exploitation: CoverCropExploitation.EXPORTED,
  target_n_quantity: 0,
  covercrop_composition: [
    {
      cc_species_id: 0,
      cc_species_proportion: 0,
    },
  ],
};
