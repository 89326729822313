import {makeStyles} from '@mui/styles';
import {CremaTheme} from 'shared/models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  card: {
    height: '100%',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  fieldsMapNdviSelector: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    padding: theme.spacing(1),
  },
  fieldsMapLegend: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(2),
    minWidth: 400,
    maxWidth: '80%',
    zIndex: 9,
  },
  fieldsMapCopyright: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    zIndex: 9,
  },
  geocoder: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tooltip: {
    position: 'absolute',
    margin: theme.spacing(1),
    padding: theme.spacing(3),
    zIndex: 9,
    maxWidth: 300,
    pointerEvents: 'none',
  },
}));

export default useStyles;
