import {CropsTranslationsType} from './CropsTranslationsType';

export const cropsTranslationsEn: CropsTranslationsType = {
  'crops.58.pheno_stage.stade_1': 'Emergence',
  'crops.58.pheno_stage.stade_2': '1 leaf',
  'crops.58.pheno_stage.stade_3': '2 leafs',
  'crops.58.pheno_stage.stade_4': '4 leafs',
  'crops.58.pheno_stage.stade_5': '6 leafs',
  'crops.58.pheno_stage.stade_6': '7 leafs',
  'crops.58.pheno_stage.stade_7': '8 leafs',
  'crops.58.pheno_stage.stade_8': '10 leafs',
  'crops.58.pheno_stage.stade_f': 'Flowering',
  'crops.58.pheno_stage.stade_9': '32% DM',
  'crops.58.pheno_stage.stade_10': 'Ripeness',
  'crops.59.pheno_stage.stade_1': 'Emergence',
  'crops.59.pheno_stage.stade_2': '1 leaf',
  'crops.59.pheno_stage.stade_3': '2 leafs',
  'crops.59.pheno_stage.stade_4': '4 leafs',
  'crops.59.pheno_stage.stade_5': '6 leafs',
  'crops.59.pheno_stage.stade_6': '7 leafs',
  'crops.59.pheno_stage.stade_7': '8 leafs',
  'crops.59.pheno_stage.stade_8': '10 leafs',
  'crops.59.pheno_stage.stade_f': 'Flowering',
  'crops.59.pheno_stage.stade_9': '32% DM',
  'crops.59.pheno_stage.stade_10': 'Ripeness',
  'crops.60.pheno_stage.stade_1': 'Emergence',
  'crops.60.pheno_stage.stade_2': '1 leaf',
  'crops.60.pheno_stage.stade_3': '2 leafs',
  'crops.60.pheno_stage.stade_4': '4 leafs',
  'crops.60.pheno_stage.stade_5': '6 leafs',
  'crops.60.pheno_stage.stade_6': '7 leafs',
  'crops.60.pheno_stage.stade_7': '8 leafs',
  'crops.60.pheno_stage.stade_8': '10 leafs',
  'crops.60.pheno_stage.stade_f': 'Flowering',
  'crops.60.pheno_stage.stade_9': '32% DM',
  'crops.60.pheno_stage.stade_10': 'Ripeness',
  'crops.102.pheno_stage.stade_1': 'Emergence',
  'crops.102.pheno_stage.stade_2': '1 leaf',
  'crops.102.pheno_stage.stade_3': '2 leafs',
  'crops.102.pheno_stage.stade_4': '4 leafs',
  'crops.102.pheno_stage.stade_5': '6 leafs',
  'crops.102.pheno_stage.stade_6': '7 leafs',
  'crops.102.pheno_stage.stade_7': '8 leafs',
  'crops.102.pheno_stage.stade_8': '10 leafs',
  'crops.102.pheno_stage.stade_f': 'Flowering',
  'crops.102.pheno_stage.stade_9': '32% DM',
  'crops.102.pheno_stage.stade_10': 'Ripeness',
};
