import {appIntl} from 'shared/utils/IntlGlobalProvider';
import VegetationUnitSelected from '../models/VegetationUnitSelected';

/**
 * format the YAxisRight to show string reference instead of data as number
 */
export function formatYAxisLeft(
  tickItem: number,
  unit?: VegetationUnitSelected,
) {
  if (unit && unit === VegetationUnitSelected.LAI) {
    if (tickItem === 0)
      return appIntl().formatMessage({
        id: 'vegetation.yAxisRight_LAI.bottom',
      }) as string;
    if (tickItem === 6)
      return appIntl().formatMessage({
        id: 'vegetation.yAxisRight_LAI.top',
      }) as string;
  } else {
    if (tickItem === 0)
      return appIntl().formatMessage({
        id: 'vegetation.yAxisRight.bottom',
      }) as string;
    if (tickItem === 1)
      return appIntl().formatMessage({
        id: 'vegetation.yAxisRight.top',
      }) as string;
  }
}

/**
 * function to render the date based on app language selection
 */
// TODO: Move to utils shared
export function formatDateLocale(tickItem: number) {
  return appIntl().formatDate(tickItem, {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });
}
