import {useCallback, useEffect, useState} from 'react';
import {ServiceId} from 'shared/models/RouterParam';
import {useHistory} from 'react-router-dom';
import * as H from 'history';

/**
 * Define if a service is selected based on current history pathname
 * @param service ServiceId
 * @returns
 */
function useServiceSelected(service: ServiceId): boolean {
  let history = useHistory();

  const getIsSelected = useCallback(
    (history: H.History<any>) => {
      return history.location.pathname.includes(service);
    },
    [service],
  );

  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    history.listen(() => setSelected(getIsSelected(history)));
  }, [history, getIsSelected]);

  return selected;
}

export default useServiceSelected;
