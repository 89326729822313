import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as Question} from '../../../assets/images/icons/questionIcon.svg';

const QuestionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={Question}
      viewBox='0 0 24 24'
    />
  );
};

export default QuestionIcon;
