export default class HttpError extends Error {
  value: any;

  constructor(
    public code: string,
    message: string,
    public statusCode: number,
    public nonTransient: boolean = false,
  ) {
    super();
    this.message = message;
    this.value = '';
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  withCustomMessage(message: string, value: any): HttpError {
    return new HttpError(
      this.code,
      message,
      this.statusCode,
      this.nonTransient,
    ).withValue(value);
  }

  withValue(value: any): HttpError {
    this.value = value;
    return this;
  }

  getValue(): any {
    return this.value;
  }
}

export interface ErrorWithCode {
  code?: string;
  message: string;
  /**
   * If true, the error is not transient and no retry should be done
   */
  non_transient?: boolean;
  value?: any;
}
