import {BASE_COVER_CREATION} from 'modules/nitrogen/configs/NitrogenConfigs';
import {BASE_ROTATION_CREATION} from 'modules/waterBalance/configs/RotationConfig';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import * as yup from 'yup';
import {AddField} from '../models/AddField';
import FieldCreation from '../models/FieldCreation';

export const FIELD_NAME_MAX_CHARACTERS = 30;
export const FIELD_COMMENTS_MAX_CHARACTERS = 50;

/**
 *  use to force height to have a large map if not many input have to be display
 *  */
export const MIN_HEIGHT_MODAL = '600px';

export const BASE_FIELD: AddField = {
  field_name: '',
  comments: '',
  geometry: '',
  group: 'groupe',
  soil_id: 0,
  station_id: null,
  max_irrigation_dose: null,
  irrigation_cycle_duration: null,
  field_thresholds: {
    low: 0.3,
    medium: 0.8,
    high: 1,
  },
};
export const BASE_FIELD_CREATION: FieldCreation = {
  ...BASE_FIELD,
  cover: {
    ...BASE_COVER_CREATION,
  },
  rotation: {
    ...BASE_ROTATION_CREATION,
  },
};

export const baseValidationSchema = () => {
  return {
    field_name: yup
      .string()
      .max(
        FIELD_NAME_MAX_CHARACTERS,
        appIntl().formatMessage(
          {id: 'fields.validation.fieldName_max_character_error'},
          {maxChar: FIELD_NAME_MAX_CHARACTERS},
        ),
      )
      .required(
        appIntl().formatMessage({id: 'fields.validation.fieldName'}) as string,
      ),
    comments: yup.string().max(
      FIELD_COMMENTS_MAX_CHARACTERS,

      appIntl().formatMessage(
        {id: 'fields.validation.comments_max_character_error'},
        {maxChar: FIELD_COMMENTS_MAX_CHARACTERS},
      ),
    ),
    /*.required(
                appIntl().formatMessage({id: 'fields.validation.comments'}) as string,
            )*/ soil_id: yup
      .number()
      .positive()
      .nullable(true)
      .required(
        appIntl().formatMessage({id: 'fields.validation.soilId'}) as string,
      ),
    max_irrigation_dose: yup
      .number()
      .nullable(true)
      .typeError(
        appIntl().formatMessage({
          id: 'fields.validation.max_irrigation_dose_type',
        }),
      ),
    irrigation_cycle_duration: yup
      .number()
      .nullable(true)
      .typeError(
        appIntl().formatMessage({
          id: 'fields.validation.irrigation_cycle_duration_type',
        }),
      ),
    field_thresholds: yup.object().shape({
      low: yup
        .number()
        .required(
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_low_required',
          }),
        )
        .typeError(
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_type',
          }),
        )
        .min(
          0,
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_min',
          }),
        )
        .max(
          1,
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_max',
          }),
        )
        .test(
          'is-less-than-medium',
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_low_less_than_medium',
          }),
          function (value) {
            if (value) {
              const {medium} = this.parent;
              return value < medium;
            }
            return true;
          },
        ),
      medium: yup
        .number()
        .required(
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_medium_required',
          }),
        )
        .typeError(
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_type',
          }),
        )
        .min(
          0,
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_min',
          }),
        )
        .max(
          1,
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_max',
          }),
        )
        .test(
          'is-less-than-high',
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_medium_less_than_high',
          }),
          function (value) {
            if (value) {
              const {high} = this.parent;
              return value < high;
            }
            return true;
          },
        )
        .test(
          'is-more-than-low',
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_medium_more_than_low',
          }),
          function (value) {
            if (value) {
              const {low} = this.parent;
              return value > low;
            }
            return true;
          },
        ),
      high: yup
        .number()
        .required(
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_high_required',
          }),
        )
        .typeError(
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_type',
          }),
        )
        .min(
          0,
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_min',
          }),
        )
        .max(
          1,
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_max',
          }),
        )
        .test(
          'is-more-than-medium',
          appIntl().formatMessage({
            id: 'fields.validation.field_thresholds_high_more_than_medium',
          }),
          function (value) {
            if (value) {
              const {medium} = this.parent;
              return value > medium;
            }
            return true;
          },
        ),
    }),
  };
};
