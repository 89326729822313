import {soilsTranslationsEs} from 'modules/soils/translations/SoilsTranslationsEs';
import saMessages from '../locales/es_ES.json';
import {esES} from '@mui/material/locale';
import {esReportTranslations} from 'modules/report/translations/EsReportTranslations';

const esLang = {
  messages: {
    ...saMessages,
    ...soilsTranslationsEs,
    ...esReportTranslations,
  },
  muiLocale: esES,
  locale: 'es-ES',
};
export default esLang;
