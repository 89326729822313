import {ProductsState} from "../models/ProductsState";
import {LOAD_PRODUCT_LISTING, LOAD_PRODUCT_LISTING_SUCCESS, ProductsActionTypes} from "../actions/ProductsActions";
import {Reducer} from "redux";

const initialState: ProductsState = {
    data: null,
}

const productsReducers: Reducer<ProductsState, ProductsActionTypes> = (state: ProductsState = initialState, action: ProductsActionTypes) => {
    switch (action.type) {
        case LOAD_PRODUCT_LISTING:
            return {
                ...state
            };
        case LOAD_PRODUCT_LISTING_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
}
export default productsReducers