import {ReportTranslationsType} from './ReportTranslationsType';

export const ruReportTranslations: ReportTranslationsType = {
  'report.modal.title': 'Генерация отчета по кампании',
  'report.modal.error.title': 'Ошибка',
  'report.modal.error.text': 'Произошла ошибка во время генерации отчета',
  'report.modal.selector.text': 'Выберите язык для генерации отчета',
  'report.modal.error.button': 'Закрыть',
  'report.modal.button': 'Скачать',
};
