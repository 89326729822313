import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {FormikProps} from 'formik';
import SoilModel from 'modules/soils/models/SoilModel';
import {getSoilTranslationKeyFromCode} from 'modules/soils/services/SoilsTranslation';
// import ReloadWaterBalanceButton from 'modules/waterBalance/components/ReloadWaterBalanceButton';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import {ConnectedStationType} from 'modules/weather/models/WeatherStationResponse';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import MyTextField from 'shared/components/MyTextField';
import {ServiceId} from 'shared/models/RouterParam';
import {AppState} from 'shared/store';
import FieldCreation from '../models/FieldCreation';
import {FieldFormEditMode} from './AddFieldForm';
import useStyles from './Fields.style';

interface FieldEditFormProps {
  editMode: FieldFormEditMode;
  selectedService: ServiceId;
  allowEdit: boolean;
  formikProps: FormikProps<FieldCreation>;
  import?: boolean;
}

interface WeatherStationSelectItem {
  is_header?: boolean;
  name: string;
  value: number | null;
}
/**
 * Form in charge to display field informations. This form is independant of selectedService.
 * Use to display data, edit and create
 * Has to be called from a Formik container
 * Called in AddFieldForm.tsx
 * @param props
 * @returns
 */
const FieldEditForm: React.FC<FieldEditFormProps> = (props) => {
  const {editMode, allowEdit, selectedService, formikProps} = props;

  const {messages} = useIntl();

  const classes = useStyles();
  const intl = useIntl();
  const soilsData = useSelector<AppState, AppState['soils']>(
    ({soils}) => soils,
  );
  const {weather_stations_data} = useSelector<AppState, AppState['weather']>(
    ({weather}) => weather,
  );
  const fields = useSelector<AppState, AppState['fields']>(
    ({fields}) => fields,
  );

  //   useEffect(() => {
  //     if (fields && fields.data && fields.selectedFieldId) {
  //       formikProps.setValues({
  //         field_name: fields.data[fields.selectedFieldId].field_name || '',
  //         soil_id: fields.data[fields.selectedFieldId].soil_id || null,
  //       });
  //     }
  //   }, [fields.selectedFieldId]);

  const datalist: WeatherStationSelectItem[] = [];
  if (weather_stations_data && Object.keys(weather_stations_data).length > 0) {
    Object.keys(weather_stations_data).forEach((type) => {
      const simWeatherStationProvider: WeatherStationSelectItem = {
        is_header: true,
        name: type,
        value: null,
      };
      datalist.push(simWeatherStationProvider);
      if (
        weather_stations_data[type as ConnectedStationType] &&
        weather_stations_data[type as ConnectedStationType]!.length
      ) {
        weather_stations_data[type as ConnectedStationType]!.forEach(
          (station) => {
            const element: WeatherStationSelectItem = {
              is_header: false,
              name: station.serial
                ? station.station_name + ' - ' + station.serial
                : station.station_name,
              value: station.station_id,
            };
            datalist.push(element);
          },
        );
      }
    });
  }

  const [soilInfo, setSoilInfo] = useState<SoilModel | null>(null);

  useEffect(() => {
    if (soilsData && formikProps.values.soil_id) {
      const soil = soilsData.data[formikProps.values.soil_id];
      if (soil) {
        setSoilInfo(soil);
      }
    }
  }, [soilsData, formikProps.values.soil_id]);
  if (soilsData) {
    return (
      <Box width={1}>
        <Box display='flex' alignItems='center'>
          <Typography variant='h6' style={{flexGrow: 1}}>
            <FormattedMessage id='fields.informations' />
          </Typography>
          {editMode === FieldFormEditMode.MODIFY_FIELD_PAGE &&
          selectedService === ServiceId.IRRIGATION
            ? ''
            : // <ReloadWaterBalanceButton forceCompute />
              ''}
        </Box>
        {props.import && (
          <Typography variant='body2' paddingTop={3} paddingBottom={4}>
            <IntlMessages id='fields.import.informations_explanation' />
          </Typography>
        )}
        {!props.import && (
          <Box display='flex'>
            <Box flex={1} p={1}>
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='field-name'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                    ? true
                    : false
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                label={<IntlMessages id='fields.form.name' />}
                name='field_name'
                value={formikProps.values.field_name}
              />
            </Box>
            <Box flex={1} p={1}>
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='field-comment'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                label={<IntlMessages id='fields.form.comments' />}
                name='comments'
                value={formikProps.values.comments}
              />
            </Box>
          </Box>
        )}
        <Box py={4} px={1}>
          <MyTextField
            fullWidth
            variant='outlined'
            data-test='field-soil'
            disabled={!allowEdit ? true : false}
            select
            SelectProps={{
              displayEmpty: true,
              defaultValue: 0,
            }}
            className={`${
              editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                ? classes.fieldFormTextParams
                : ''
            }`}
            name='soil_id'
            value={formikProps.values.soil_id}
            label={<IntlMessages id='fields.form.soils' />}>
            <MenuItem disabled value={0}>
              {''}
            </MenuItem>
            {[...Object.values(soilsData.data)].map((element) => {
              return (
                <MenuItem
                  key={element.soil_id}
                  value={element.soil_id}
                  data-test='form-soil-options'>
                  {element.soil_code
                    ? intl.formatMessage({
                        id: getSoilTranslationKeyFromCode(element.soil_code)!,
                      })
                    : element.soil_type}
                </MenuItem>
              );
            })}
          </MyTextField>
        </Box>
        <Box display='flex'>
          <Box flex={1} p={1}>
            <TextField
              fullWidth
              disabled
              value={soilInfo && soilInfo.depth ? soilInfo.depth : ''}
              label={intl.formatMessage({id: 'fields.form.soil.depth.label'})}
            />
          </Box>
          <Box flex={1} p={1}>
            <TextField
              fullWidth
              disabled
              value={
                soilInfo && soilInfo.taw_default ? soilInfo.taw_default : ''
              }
              label={intl.formatMessage({id: 'fields.form.soil.rew.label'})}
            />
          </Box>
        </Box>
        {(fields.selectedService === ServiceId.IRRIGATION ||
          fields.selectedService === ServiceId.VEGETATION) && (
          <Box>
            <Box display='flex'>
              <FormControl
                sx={{
                  my: 4,
                  px: 1,
                  width:
                    formikProps.values.station_id !== null ? '90%' : '100%',
                }}>
                <InputLabel
                  id='labelId'
                  shrink
                  sx={{backgroundColor: 'white', px: 1.5}}>
                  {messages['fields.form.weather_station']}
                </InputLabel>
                <Select
                  labelId='labelId'
                  disabled={!allowEdit ? true : false}
                  fullWidth
                  input={
                    <OutlinedInput
                      label={messages['fields.form.weather_station']}
                    />
                  }
                  value={formikProps.values.station_id}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      'station_id',
                      e.target.value ? +e.target.value : null,
                    );
                  }}>
                  <MenuItem aria-label='None' value={undefined} disabled>
                    {datalist.length > 0
                      ? ''
                      : messages['fields.form.no_weather_station']}
                  </MenuItem>
                  {datalist &&
                    datalist.length > 0 &&
                    datalist.map((element, index) => {
                      if (element.is_header) {
                        return (
                          <ListSubheader key={index}>
                            {element.name}
                          </ListSubheader>
                        );
                      }
                      return (
                        <MenuItem
                          key={element.value}
                          value={element.value as number}>
                          {element.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {formikProps.values.station_id !== null && (
                <DeleteSharpIcon
                  sx={{
                    alignSelf: 'center',
                    justifySelf: 'center',
                    cursor: 'pointer',
                    width: '10%',
                  }}
                  fontSize='medium'
                  color='error'
                  onClick={() => formikProps.setFieldValue('station_id', null)}
                />
              )}
            </Box>
            <Box flex={1} p={1}>
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='max_irrigation_dose'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                    ? true
                    : false
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                label={<IntlMessages id='fields.form.max_irrigation' />}
                InputLabelProps={{shrink: true}}
                name='max_irrigation_dose'
                value={formikProps.values.max_irrigation_dose}
              />
            </Box>
            <Box flex={1} p={1} pt={4}>
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='irrigation_cycle_duration'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                    ? true
                    : false
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                InputLabelProps={{shrink: true}}
                label={
                  <IntlMessages id='fields.form.irrigation_cycle_duration' />
                }
                name='irrigation_cycle_duration'
                value={formikProps.values.irrigation_cycle_duration}
              />
            </Box>
            <Box flex={1} p={1} pt={4} display={'flex'} gap={2}>
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='field_thresholds_low'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                    ? true
                    : false
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                InputLabelProps={{shrink: true}}
                label={<IntlMessages id='fields.form.field_thresholds_low' />}
                name='field_thresholds.low'
                value={formikProps.values.field_thresholds?.low}
                onChange={formikProps.handleChange}
              />
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='field_thresholds_medium'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                    ? true
                    : false
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                InputLabelProps={{shrink: true}}
                label={
                  <IntlMessages id='fields.form.field_thresholds_medium' />
                }
                name='field_thresholds.medium'
                value={formikProps.values.field_thresholds?.medium}
                onChange={formikProps.handleChange}
              />
              <MyTextField
                fullWidth
                variant='outlined'
                data-test='field_thresholds_high'
                disabled={
                  !allowEdit ||
                  (editMode === FieldFormEditMode.ADD_COVER ||
                  editMode === FieldFormEditMode.ADD_ROTATION_MODAL
                    ? true
                    : false)
                    ? true
                    : false
                }
                className={`${
                  editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                    ? classes.fieldFormTextParams
                    : ''
                }`}
                InputLabelProps={{shrink: true}}
                label={<IntlMessages id='fields.form.field_thresholds_high' />}
                name='field_thresholds.high'
                value={formikProps.values.field_thresholds?.high}
                onChange={formikProps.handleChange}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }
  return null;
};

export default FieldEditForm;
