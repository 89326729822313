export const GET_DASHBOARD_INFO = "GET_DASHBOARD_INFO"
export interface GetDashboardInfoAction {
    type: typeof GET_DASHBOARD_INFO
}

export type DashboardActionsType = GetDashboardInfoAction;

export const dashboardActions = {
    getDashboard: (): GetDashboardInfoAction => {
        return {
            type: GET_DASHBOARD_INFO
        }
    },
}

export default dashboardActions;