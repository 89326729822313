import {List, ListItemText, ListItem, Typography, Box} from '@mui/material';
import React from 'react';
import Price from '../models/Price';
import Product from '../models/Product';
import QuestionIcon from 'shared/components/Icons/QuestionIcon';
import useStyles from './ProductStyle';
import IntlMessages from 'shared/components/IntlMessages';
import {priceToDisplay} from '../services/currencySymbol';
import {FormattedMessage} from 'react-intl';


interface TablePriceProps {
    item: Price | Product;
    type: "price" | "product";
}

export default function TablePrice({item, type}: TablePriceProps) {

    const classes = useStyles();
    const isDisplay = (tiersList: any[], index: number): boolean => {
        if (tiersList[index + 1] && tiersList[index].unit_amount === tiersList[index + 1].unit_amount && tiersList[index + 1].up_to) {
            return false
        } else {
            return true
        }

    }

    const computePrices = (price: Price) => {
        let tiersList = price.tiers
        return tiersList.map((tier, index: number) => {
            const shouldBeDisplay: boolean = isDisplay(tiersList, index)
            if (shouldBeDisplay && tier.up_to !== 1) {
                return (
                    <ListItem key={index} className={classes.tooltipListItem}>
                        <ListItemText className={classes.tooltipListItemText}>
                            <Typography className={[classes.tooltipBoldTypo, classes.blackColor].join(' ')}>
                                {tier.up_to ? tier.up_to.toFixed(0) : (
                                    <IntlMessages id='subscriptions.stepper.price.max_quantity_area.unlimited' />
                                )}
                            </Typography>
                        </ListItemText>
                        <ListItemText className={classes.tooltipListItemText}>
                            <Typography className={[classes.tooltipBoldTypo, classes.primaryColor].join(' ')}>
                                {priceToDisplay(tier.flat_amount, price.currency)}
                            </Typography>
                        </ListItemText>
                        <ListItemText className={classes.tooltipListItemText}>
                            <Typography className={[classes.tooltipBoldTypo, classes.primaryColor].join(' ')}>
                                {priceToDisplay(tier.unit_amount, price.currency)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                )
            } else {
                return null
            }
        })
    }
    return (
        <Box className={classes.tooltipTable}>
            <Box className={classes.tooltipTitle}>
                <QuestionIcon color='primary' />
                <Typography className={classes.tooltipTitleTypo}>
                    <IntlMessages id="subscriptions.stepper.price.tooltip.title" />
                </Typography>
            </Box>
            <List dense className={classes.tooltipList}>
                {type === "product" ? (
                    ""
                ) : item.tiers.length ? (
                    <>
                        <ListItem className={classes.tooltipListItem}>
                            <ListItemText className={classes.tooltipListItemText}>
                                <Typography className={[classes.tooltipUnderlineTypo, classes.blackColor].join(' ')}>
                                    <FormattedMessage id="products.table_price.surface_title" />
                                </Typography>
                            </ListItemText>
                            <ListItemText className={classes.tooltipListItemText}>
                                <Typography className={[classes.tooltipUnderlineTypo, classes.blackColor].join(' ')}>
                                    <FormattedMessage id="products.table_price.flat_price" />
                                </Typography>
                            </ListItemText>
                            <ListItemText className={classes.tooltipListItemText}>
                                <Typography className={[classes.tooltipUnderlineTypo, classes.blackColor].join(' ')}>
                                    <FormattedMessage id="products.table_price.unit_price" />
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        {computePrices(item as Price)}
                    </>
                ) : ""}
            </List>
        </Box>
    )

}