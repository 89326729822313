import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';
import {ReactComponent as Carbon} from '../../assets/carbonIcon.svg';

interface HeaderIconProps extends SvgIconProps {
    selected: boolean
}

const CarbonIcon: React.FC<HeaderIconProps> = (props) => {
  
    return (
        <SvgIcon {...props} component={Carbon} 
        style={{fillOpacity: props.selected ? "1":"0.5"}}        
        viewBox="0 0 17 16" />
    )
}

export default CarbonIcon;