import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as NoSubscription} from '../../assets/no_subscription.svg';

const NoSubscriptionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} component={NoSubscription} viewBox="0 0 83 100" />
  );
};

export default NoSubscriptionIcon;
