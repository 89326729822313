import {STRIPE_METADATA_} from 'modules/subscriptions/configs/constant';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import log from 'shared/services/LogService';
import {PRODUCT_TRIAL_KEYWORD, STRIPE_METADATA_REF} from '../configs/constant';
import Product from '../models/Product';
import {ProductsState} from '../models/ProductsState';

export const getProduct = (ref: string, products: ProductsState) => {
  const {data} = products;
  if (data) {
    const productList: Product[] = [];
    Object.values(data).forEach((service) => {
      if (service) {
        Object.values(service).forEach((product) => {
          const isInList = productList.find((pdt) => pdt.id === product.id);
          if (!isInList) {
            productList.push(product);
          }
        });
      }
    });
    let product: Product | null =
      productList.find(
        (pdt) =>
          pdt[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`] &&
          pdt[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`] === ref,
      ) || null;

    if (product) {
      return product;
    } else {
      log.error(`Cannot retrieve product of ref ${ref}`);
    }
  } else {
    log.error(`Problem while getting products data`);
  }
};

export const getPrice = (product: Product, priceRef: string) => {
  const price = product.prices?.find(
    (price) => price[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`] === priceRef,
  );
  return price;
};

export const duration = (
  priceRef: string,
  productRef: string,
  products: ProductsState,
) => {
  const product = getProduct(productRef, products);
  if (product) {
    const price = getPrice(product, priceRef);
    if (price) {
      const interval_count = price.interval_count;
      const interval = price.interval;
      return (
        <>
          {`${interval_count} `}
          <FormattedMessage
            id={`subscriptions.stepper.price.interval.${interval}`}
          />
        </>
      );
    } else {
      log.error(`Cannot retrieve price with ref ${priceRef}`);
      return null;
    }
  } else {
    return null;
  }
};

export const isFreeTrial = (product: Product): boolean => {
  return product.ref?.includes(PRODUCT_TRIAL_KEYWORD);
}
