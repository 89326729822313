import {Reducer} from 'redux';
import {
  LEGEND_COLOR_CASE_SUCCESS,
  SAVE_SUMMARY_VEGETATION_INFO,
  VegetationActionsTypes,
} from '../actions/VegetationActions';
import {VegetationState} from '../models/VegetationState';

const initialState: VegetationState = {
  summaryVegetationInfo: null,
  legendColorCase: null,
};

const vegetationReducers: Reducer<VegetationState, VegetationActionsTypes> = (
  state: VegetationState = initialState,
  action: VegetationActionsTypes,
): VegetationState => {
  switch (action.type) {
    case SAVE_SUMMARY_VEGETATION_INFO:
      return {
        ...state,
        summaryVegetationInfo: action.payload,
      };
    case LEGEND_COLOR_CASE_SUCCESS:
      return {
        ...state,
        legendColorCase: action.payload,
      };
    default:
      return state;
  }
};

export default vegetationReducers;
