import {makeStyles} from '@mui/styles';
import {CremaTheme} from 'shared/models/AppContextPropsType';

/**
 * General style for cards
 * TODO: to be removed ?
 */
const useFieldElementStyles = makeStyles((theme: CremaTheme) => {
  return {

    elementCard: {
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    tabFieldSelection: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '10px',
      width: '25%',
    },
    tabFieldSelectionHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(6, 0, 6, 0),
      borderRadius: '10px 10px 0 0',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    tabFieldSelector: {
      padding: theme.spacing(1, 2, 1, 2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'strecht'
    },
    listItemSelectionBgNone: {
      padding: theme.spacing(0, 2, 0, 2),
      display: 'flex'
    },
    listItemSelectionBgGrey: {
      padding: theme.spacing(0, 2, 0, 2),
      display: 'flex',
      backgroundColor: theme.palette.grey[100]
    },
    checkBox: {
      paddingLeft: 0
    }
  };
});

export default useFieldElementStyles;
