import log from 'shared/services/LogService';
import {
  CommonActionTypes,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HEADER_HEIGHT,
  HIDE_MESSAGE,
  REDIRECT_TO,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
} from '../actions/CommonAction';
import {Common} from '../models/CommonState';

const INIT_STATE: Common = {
  error: '',
  fetchCount: 0,
  fetchList: [],
  loading: false,
  isAppDrawerOpen: true,
  headerHeight: 0,
  updatingContent: false,
  message: '',
};

export default (state = INIT_STATE, action: CommonActionTypes): Common => {
  switch (action.type) {
    case FETCH_START: {
      let fetchCount: number = state.fetchCount;
      let fetchList: string[] = state.fetchList;
      if (action.fetchId) {
        fetchCount = state.fetchCount + 1;
        fetchList = [...state.fetchList, action.fetchId];
      }
      return {
        ...state,
        error: '',
        message: '',
        fetchCount: fetchCount,
        fetchList: fetchList,
        loading: fetchCount > 0,
      };
    }
    case FETCH_SUCCESS: {
      let fetchCount: number = state.fetchCount;
      let fetchList: string[] = state.fetchList;
      if (action.fetchId) {
        fetchCount = state.fetchCount - 1;
        fetchList = [...state.fetchList];
        for (let i = 0; i < fetchList.length; i++) {
          if (fetchList[i] === action.fetchId) {
            fetchList.splice(i, 1);
          }
        }
        if (fetchCount !== fetchList.length) {
          log.error(
            `Fetch count ${fetchCount} and fetch list not synchro ${fetchList}`,
          );
        }
      }
      return {
        ...state,
        fetchCount: fetchCount,
        fetchList: fetchList,
        error: '',
        redirectTo: '',
        message: '',
        loading: fetchCount > 0,
        updatingContent: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.message,
        redirectTo: action.redirectTo,
        loading: false,
        updatingContent: false,
      };
    }
    case REDIRECT_TO: {
      return {
        ...state,
        redirectTo: action.redirectTo,
      };
    }
    case FETCH_ERROR: {
      let fetchCount: number = state.fetchCount;
      let fetchList: string[] = state.fetchList;
      if (action.fetchId) {
        fetchCount = state.fetchCount - 1;
        fetchList = [...state.fetchList];
        for (let i = 0; i < fetchList.length; i++) {
          if (fetchList[i] === action.fetchId) {
            fetchList.splice(i, 1);
          }
        }
        if (fetchCount !== fetchList.length) {
          log.error(
            `Fetch count ${fetchCount} and fetch list not synchro ${fetchList}`,
          );
        }
      }
      return {
        ...state,
        fetchCount: fetchCount,
        fetchList: fetchList,
        loading: fetchCount > 0,
        error: action.error,
        redirectTo: action.redirectTo,
        message: '',
        updatingContent: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        redirectTo: '',
        message: '',
        updatingContent: false,
      };
    }
    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }

    case HEADER_HEIGHT: {
      return {
        ...state,
        headerHeight: action.value,
      };
    }
    default:
      return state;
  }
};
