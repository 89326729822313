import React from 'react';
import {Loader} from '@crema/index';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from '../store';
import {
  Box,
  DialogContentText,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import BaseDialog from './BaseDialog';
import {fetchError, showMessage} from 'shared/actions/Common';
import WarningIcon from './Icons/WarningIcon';
import InfoIcon from './Icons/InfoIcon';
import {CremaTheme} from 'shared/models/AppContextPropsType';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme: CremaTheme) => ({
  dialogIcon: {
    fontSize: 70,
    color: theme.palette.primary.main,
  },
}));

interface InfoViewProps { }
const InfoView: React.FC<InfoViewProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const {error, loading, message, redirectTo} = useSelector<AppState, AppState['common']>(
    ({common}) => common,
  );

  // Only a redirect without message or error
  if (redirectTo && !error && !message) {
    history.push(redirectTo);
  }

  const onCloseMessageDialog = () => {
    // Redirect if needed
    if (redirectTo) {
      history.push(redirectTo);
    }

    // Clear message
    dispatch(showMessage(''));
  }

  const showMessageDialog = () => {
    return <BaseDialog
      open={true}
      titleMessageId='common.infoDialogTitle'
      buttonMessageId='common.dialogOk'
      onClose={onCloseMessageDialog}
    >
      <Box display="flex" flexDirection="column"
        justifyItems="center"
        alignItems="center">
        <InfoIcon className={classes.dialogIcon} />
        <DialogContentText>
          {message}
        </DialogContentText>
      </Box>
    </BaseDialog>;
  };

  const onCloseErrorDialog = () => {
    // Redirect if needed
    if (redirectTo) {
      history.push(redirectTo);
    }

    // Clear error
    dispatch(fetchError("", ''));
  }

  const showErrorDialog = () => {
    return <BaseDialog
      open={true}
      titleMessageId='common.errorDialogTitle'
      buttonMessageId='common.dialogOk'
      onClose={onCloseErrorDialog}
    >
      <Box display="flex" flexDirection="column"
        justifyItems="center"
        alignItems="center">
        <WarningIcon className={classes.dialogIcon} />
        <DialogContentText>
          {error}
        </DialogContentText>
      </Box>
    </BaseDialog>;
  };

  return (
    <>
      {loading && <Loader />}

      {message && showMessageDialog()}
      {error && showErrorDialog()}
    </>
  );
};

export default InfoView;
