import React from 'react';
import {SvgIcon} from '@mui/material';
import {SvgIconProps} from '@mui/material';
import {ReactComponent as VegetationIconTab} from '../../assets/vegetationIcon.svg';

interface HeaderIconProps extends SvgIconProps {
  selected: boolean
}

const VegetationIcon = (props: HeaderIconProps) => {
  return (
    <SvgIcon {...props} component={VegetationIconTab} style={{fillOpacity: props.selected ? "1":"0.5"}} viewBox="0 0 22 16" />
  );
};

export default VegetationIcon;
