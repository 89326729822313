import React from 'react';
import PixagriIcon from '../../assets/images/pixagriLogo.svg';

//interface AppLogoProps extends ElementProps {}

const AppLogo = (props: any) => {
  return <img src={PixagriIcon} alt='logo-pixagri' {...props} width='150px' />;
};

export default AppLogo;
