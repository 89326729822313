import {Box, Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import React, {useState} from 'react';
import IntlMessages from 'shared/components/IntlMessages';
import {CremaTheme} from 'shared/models/AppContextPropsType';
import ModifyWeatherStation from '../../../weather/components/ModifyWeatherStation';
import ModifyNotification from '../ModifyUserInfo/ModifyNotification';
import ModifyOrganismInfo from '../ModifyUserInfo/ModifyOrganismInfo';
import ModifyUserInfo from '../ModifyUserInfo/ModifyUserInfo';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    buttonNavigation: {
      width: '25%',
      padding: theme.spacing(3),
      fontWeight: 'bold',
    },
    selected: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  };
});
interface SettingsUserInfoProps {
  /**
   * Callback when the user submit the user info modifications
   */
  handleModalModifyUser: () => void;
}
enum SettingsPage {
  MODIFY_USER = 'modify_user',
  MODIFY_ORGANISM = 'modify_organism',
  MODIFY_NOTIFICATION = 'modify_notification',
  MODIFY_TRESHOLD = 'modify_treshold',
  MODIFY_WEATHER_STATION = 'modify_weather_station',
}
export default function SettingsUserInfo({
  handleModalModifyUser,
}: SettingsUserInfoProps) {
  const [settingsPageSelected, setSettingsPageSelected] =
    useState<SettingsPage>(SettingsPage.MODIFY_USER);
  const classes = useStyles();

  const switchChildren = (settingsPageSelected: SettingsPage) => {
    switch (settingsPageSelected) {
      case SettingsPage.MODIFY_ORGANISM:
        return <ModifyOrganismInfo />;
      case SettingsPage.MODIFY_NOTIFICATION:
        return <ModifyNotification />;
      case SettingsPage.MODIFY_WEATHER_STATION:
        return <ModifyWeatherStation />;

      default:
        return <ModifyUserInfo handleModalModifyUser={handleModalModifyUser} />;
    }
  };
  return (
    <>
      <Box display='flex' justifyContent='strecht' width={'100%'}>
        <Button
          className={clsx(
            classes.buttonNavigation,
            settingsPageSelected === SettingsPage.MODIFY_USER
              ? classes.selected
              : '',
          )}
          onClick={() => {
            setSettingsPageSelected(SettingsPage.MODIFY_USER);
          }}>
          <IntlMessages id='user.settings.personal_informations' />
        </Button>
        <Button
          className={clsx(
            classes.buttonNavigation,
            settingsPageSelected === SettingsPage.MODIFY_ORGANISM
              ? classes.selected
              : '',
          )}
          onClick={() => {
            setSettingsPageSelected(SettingsPage.MODIFY_ORGANISM);
          }}>
          <IntlMessages id='user.settings.organism_informations' />
        </Button>
        <Button
          className={clsx(
            classes.buttonNavigation,
            settingsPageSelected === SettingsPage.MODIFY_NOTIFICATION
              ? classes.selected
              : '',
          )}
          onClick={() => {
            setSettingsPageSelected(SettingsPage.MODIFY_NOTIFICATION);
          }}>
          <IntlMessages id='user.settings.notification' />
        </Button>
        <Button
          className={clsx(
            classes.buttonNavigation,
            settingsPageSelected === SettingsPage.MODIFY_WEATHER_STATION
              ? classes.selected
              : '',
          )}
          onClick={() => {
            setSettingsPageSelected(SettingsPage.MODIFY_WEATHER_STATION);
          }}>
          <IntlMessages id='user.settings.weather_station' />
        </Button>
      </Box>
      <Box>{switchChildren(settingsPageSelected)}</Box>
    </>
  );
}
