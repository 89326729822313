import {Typography, Box} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import editImage from "../assets/images/editImage.jpg"
import ArrowTopMiddle from './icons/edit/ArrowTopMiddle';
import ArrowRightTop from './icons/edit/ArrowRightTop';
import ArrowRightMiddle from './icons/edit/ArrowRightMiddle';
import ArrowRightBottom from './icons/edit/ArrowRightBottom';
import ArrowLeftMiddle from './icons/edit/ArrowLeftMiddle';




/**
 * Component for showing overview in the information dialog
 * Contains an image and indication for the user to understand the application
 */
const FieldEdit: React.FC = () => {

    return (
        <Box flex={1} width={1} display="flex" flexDirection={"column"}>
            <Typography
                variant='h4'
                fontWeight={'bold'}
                sx={{alignSelf: 'flex-start'}}>
                <FormattedMessage id='info.edit.title' />
            </Typography>
            <Box
                flex={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Box
                    alignSelf={'center'}
                    width={'700px'}
                    id='image-container'
                    sx={{position: 'relative'}}>
                    <img src={editImage} alt='nitrogen' width={'700px'} />
                    <Box
                        display='flex'
                        position={'absolute'}
                        alignItems='center'
                        top={-35}
                        right={0}>
                        <Box width={'200px'} position='relative'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                <FormattedMessage id='info.edit.field_info' />
                            </Typography>
                            <ArrowTopMiddle
                                sx={{
                                    fontSize: '120px',
                                    position: 'absolute',
                                    top: 0,
                                    left: -100,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        position={'absolute'}
                        alignItems='center'
                        top={90}
                        right={-180}>
                        <Box width={'170px'} position='relative'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                <FormattedMessage id='info.edit.soil_select' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.soil_select.note' />
                            </Typography>
                            <ArrowRightTop
                                sx={{
                                    fontSize: '100px',
                                    position: 'absolute',
                                    top: 0,
                                    left: -100,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        position={'absolute'}
                        alignItems='center'
                        top={170}
                        right={-180}>
                        <Box width={'170px'} position='relative'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                <FormattedMessage id='info.edit.tricks' />
                            </Typography>
                            <ArrowRightMiddle
                                sx={{
                                    fontSize: '100px',
                                    position: 'absolute',
                                    top: -40,
                                    left: -90,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        position={'absolute'}
                        alignItems='center'
                        top={270}
                        right={-250}>
                        <Box width={'250px'} position='relative'>
                            <Typography variant='caption' fontWeight={"bold"}>
                                <FormattedMessage id='info.edit.nitrogen_edit' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.nitrogen_edit.sowing_date' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.nitrogen_edit.species' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.nitrogen_edit.residu' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.nitrogen_edit.management' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.nitrogen_edit.destruction_date' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.nitrogen_edit.n_target_quantity' />
                            </Typography>

                            <ArrowRightBottom
                                sx={{
                                    fontSize: '100px',
                                    position: 'absolute',
                                    top: 50,
                                    left: -50,
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        position={'absolute'}
                        alignItems='center'
                        top={'50%'}
                        left={-175}>
                        <Box width={'150px'} position='relative'>
                            <Typography variant='caption' fontWeight={'bold'}>
                                <FormattedMessage id='info.edit.draw_field' />
                            </Typography>
                            <Typography variant='caption' component="div">
                                <FormattedMessage id='info.edit.draw_field.note' />
                            </Typography>
                            <ArrowLeftMiddle
                                sx={{
                                    fontSize: '150px',
                                    position: 'absolute',
                                    top: -60,
                                    right: -150,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
};

export default FieldEdit;
