import {Scrollbar} from '@crema';
import {Box, Checkbox, List, ListItem, ListItemText} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store';
import Field from '../../fields/models/Field';
import useFieldElementStyles from '../../fields/components/FieldElement.style';
import ActiveSubscriptionIcon from './icons/ActiveSubscriptionIcon';
import {
  hasFieldServiceInfoBeforeSubscribe,
  hasFieldSubscriptionsToService,
} from '../services/isFieldSubscribe';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {isFieldComplete, isFieldSubscribe} from 'modules/fields/services/FieldsService';
import FieldsFilter from 'modules/fields/components/FieldsFilter';
import {ServiceId} from 'shared/models/RouterParam';

interface FieldSelectionTableProps {
  selectedFields: Field[];
  onSelectedFieldsChange: (field: Field[]) => void;
  fieldsList: Field[];
  onFieldToEditChange: (field: Field) => void;
  onOpenEditModal: (open: boolean) => void;
}
export default function FieldSelectionTable({
  selectedFields,
  onSelectedFieldsChange,
  fieldsList,
  onFieldToEditChange,
  onOpenEditModal,
}: FieldSelectionTableProps) {
  const classes = useFieldElementStyles();
  const {selectedCampaign: selectedCampaignId, selectedService, crops} = useSelector<
    AppState,
    AppState['fields']
    >(({fields}) => fields);
      const [selectedCrop, setSelectedCrop] = useState<{
        [key: string]: string[];
      }>({
        crop_name: [],
        farmer: [],
      });

  const fieldListItem = (field: Field, index: number) => {
    const fieldSubscription =
      field.subscriptions[selectedService as keyof Field['subscriptions']];

    const onItemClick = (
      isSelected: boolean,
      hasServiceInfo: boolean,
      field: Field,
    ) => {
      const fieldsSelected = selectedFields;
      if (!hasServiceInfo) {
        onFieldToEditChange(field);
        onOpenEditModal(true);
      } else {
        if (isSelected) {
          onSelectedFieldsChange(
            fieldsSelected.filter((fld) => fld.field_id !== field.field_id),
          );
        } else {
          onSelectedFieldsChange(fieldsSelected.concat(field));
        }
      }
    };

    if (selectedService && selectedCampaignId) {
      return (
        <>
          <ListItem
            disabled={isFieldSubscribe(
              selectedService,
              selectedCampaignId,
              field,
            )}
            key={index}
            button
            onClick={() =>
              onItemClick(
                selectedFields.findIndex(
                  (fieldSelected) => field.field_id === fieldSelected.field_id,
                ) > -1,
                isFieldComplete(selectedService, selectedCampaignId, field),
                field,
              )
            }
            className={
              index % 2 !== 0
                ? classes.listItemSelectionBgNone
                : classes.listItemSelectionBgGrey
            }>
            {fieldSubscription &&
            isFieldSubscribe(selectedService, selectedCampaignId, field) ? (
              <Box mr={3}>
                <ActiveSubscriptionIcon color='primary' />
              </Box>
            ) : (
              <Checkbox
                className={classes.checkBox}
                checked={
                  selectedFields.findIndex(
                    (fieldSelected) =>
                      field.field_id === fieldSelected.field_id,
                  ) > -1
                }
              />
            )}

            <ListItemText primary={field.field_name} />
            {!isFieldComplete(selectedService, selectedCampaignId, field) && (
              <WarningAmberIcon color='error' />
            )}
          </ListItem>
        </>
      );
    }
  };

        const displayFilter = () => {
          if (
            selectedCampaignId &&
            crops &&
            selectedService &&
            (selectedService === ServiceId.VEGETATION ||
              selectedService === ServiceId.IRRIGATION)
          ) {
            return (
              <Box width={'100%'}>
                <FieldsFilter                 
                  selectedFilters={selectedCrop}
                  allFilters={crops}
                  propertyKey='crop_name'
                  onFilterChange={setSelectedCrop}
                  title='fields.crops'
                />
              </Box>
            );
          }
          return null;
        };


  const listFields = () => {
    if (fieldsList) {
      log.debug(`Displayed Fields List: ${fieldsList.length}`);

      let isAllFieldSelected =
        (selectedService &&
          selectedFields.length ===
            fieldsList.filter(
              (field) => !hasFieldSubscriptionsToService(field, selectedService),
            ).length) ||
        false;

      const onSelectAllClick = () => {
        if (selectedService && selectedCampaignId) {
          const allFieldsUnsuscribe = fieldsList.filter(
            (field) =>
              !hasFieldSubscriptionsToService(field, selectedService) &&
              hasFieldServiceInfoBeforeSubscribe(
                field,
                selectedService,
                selectedCampaignId,
              ),
          );
          if (!isAllFieldSelected) {
            onSelectedFieldsChange(allFieldsUnsuscribe);
          } else {
            onSelectedFieldsChange([]);
          }
        }
      };

      const applyFilter = () => {
        if(selectedService &&
            (selectedService === ServiceId.VEGETATION ||
            selectedService === ServiceId.IRRIGATION)) {
          const cropList = selectedCrop['crop_name']
          if (cropList && cropList.length) {
            return fieldsList.filter(field =>
              field.irrigation
              && field.irrigation.length
              && field.irrigation.find(rot => rot.campaign_id === selectedCampaignId && cropList.includes(rot.crop_name)))  
          } 
        }
        return fieldsList
      }
      return (
        <List>
          <ListItem
            button
            onClick={onSelectAllClick}
            className={classes.listItemSelectionBgNone}>
            <Checkbox
              className={classes.checkBox}
              checked={isAllFieldSelected}
            />
            <ListItemText>
              <IntlMessages
                id={'subscriptions.stepper.step_one.table.selectAll'}
              />
            </ListItemText>
          </ListItem>
          <Box>
            {applyFilter()?.map((field: Field, index: number) => {
              return fieldListItem(field, index);
            })}
          </Box>
        </List>
      );
    } else {
      log.debug(`No fields to display found`);
      return null;
    }
  };

  if (fieldsList) {
    return (
      <Box className={classes.tabFieldSelection}>
        <Box>
          <Box className={classes.tabFieldSelectionHeader}>
            <IntlMessages id={'subscriptions.stepper.step_one.table.title'} />
          </Box>
          {displayFilter()}
        </Box>
        <Box overflow='auto'>
          <Scrollbar>
            {/* show selected filters fields */}
            {listFields()}
          </Scrollbar>
        </Box>
      </Box>
    );
  }
  return null;
}