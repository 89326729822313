export enum NitrogenChartUnit {
  N_SOIL = 'n_sol',
  N_TOT = 'n_tot',
  P = 'p',
  K = 'k',
  S = 's',
  MG = 'mg',
  C = 'c',
  BIOMASS = 'biomass',
}
