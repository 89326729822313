import React, {useEffect, useState} from 'react';
import HeaderButton from 'shared/components/layout/HeaderButton';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {ServiceId} from 'shared/models/RouterParam';
import NitrogenIcon from './icons/NitrogenIcon';
import useServiceSelected from 'shared/hooks/useServiceSelected';
import navigationService from 'shared/services/NavigationService';
import {DEFAULT_NITROGEN_VUE} from '../configs/NitrogenRoutePaths';
import useCurrentCampaign from 'modules/campaigns/hook/useCurrentCampaign';

interface NitrogenHeaderButtonProps {
  alwaysShowTitle?: boolean;
}

const NitrogenHeaderButton: React.FC<NitrogenHeaderButtonProps> = (props) => {
  const selected = useServiceSelected(ServiceId.NITROGEN);
  const currentCampaign = useCurrentCampaign()
  const [path, setPath] = useState<string>('');
  const {selectedFieldId, selectedCampaign} = useSelector<
    AppState,
    AppState['fields']
  >(({fields}) => fields);

  useEffect(() => {
    let campaignId: number | null = null

    if (selectedCampaign) {
      campaignId = selectedCampaign
    } else {
      campaignId = currentCampaign
    }

      const firstPath = navigationService.getFirstServiceUrl(
        campaignId,
        selectedFieldId,
        ServiceId.NITROGEN,
        DEFAULT_NITROGEN_VUE,
      );
      setPath(firstPath);
  }, [selectedFieldId, selectedCampaign, currentCampaign]);

  return (
    <>
      <HeaderButton
        icon={<NitrogenIcon selected={selected} />}
        path={path}
        titleId='nitrogen.headerButton.title'
        alwaysShowTitle={props.alwaysShowTitle}
        selected={selected}
        serviceId={ServiceId.NITROGEN}
      />
    </>
  );
};

export default NitrogenHeaderButton;
