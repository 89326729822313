import {DateTime} from 'luxon';
import log from 'shared/services/LogService';
import Campaign from '../models/Campaign';

export const replaceCampaignId = function (
  url: string,
  campaignId: number,
): string {
  return url.replace(/(.*\/campaigns)\/?(\d*)?(\/.*)?/, `$1/${campaignId}$3`);
};

export const getCurrentCampaign = function (campaigns: Campaign[]) {
  let currentYear: string = new Date().getFullYear().toString();
  let currentCampaign: number = 0;
  campaigns?.forEach((element) => {
    if (element.campaign_end.includes(currentYear))
      currentCampaign = element.campaign_id;
  });
  return currentCampaign;
};

export const getCampaignStartYear = (
  id: string,
  campaignData: {[key: string]: Campaign},
) => {
  if (campaignData) {
    const selectedCampaign = campaignData[id];
    if (selectedCampaign) {
      const startDate = selectedCampaign.campaign_start;
      return DateTime.fromISO(startDate).year;
    } else {
      log.error(`Cannot get campaign start year with id ${id}`);
    }
  }
};
export const getCurrentCampaignBis = function (campaigns: Campaign[]) {
  const dateNow = DateTime.now();
  let currentCampaign: Campaign | null =
    campaigns.find(
      (campaign) =>
        DateTime.fromISO(campaign.campaign_start) < dateNow &&
        dateNow < DateTime.fromISO(campaign.campaign_end),
    ) || null;
  if (currentCampaign) {
    return currentCampaign.campaign_id;
  } else {
    log.error(`Cannot define curren campaign`);
    return null;
  }
};