import {SubscriptionQuery} from "../models/SubscriptionQuery";

export const STRIPE_METADATA_: string = 'stripe_metadata_';

const STRIPE_PUBLISHABLE_KEY_DEV = "pk_test_51IJamkFHBDTTEk81j3UYoBdCorEfyPYiI7unyxqntXlOCW1KZWfW0VHRO3pXRFmDGexDmXOc0FCIT8yhGKSOWdfl00r8kIGHYg";
const STRIPE_PUBLISHABLE_KEY_PROD = "pk_live_51IJamkFHBDTTEk81x7qaVe6c5A3xNitX16a5eRtQzuXZXyOqQkRRB1eTIwaQYhqcPRZddjQNyDv8tVJFloEmSFU300AT9tNABI";
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_ENVIRONMENT_NAME === "prod" ? STRIPE_PUBLISHABLE_KEY_PROD : STRIPE_PUBLISHABLE_KEY_DEV;

export const PAYMENT_SUCCESS = "success"
// Wait 3s before loading subscriptions
export const DELAY_LOADING_SUBSCRIPTIONS_AFTER_PAYMENT = 3000;
export const PAYMENT_CANCEL = "cancel"
export const ALREADY_EXISTING_TRIAL = 'already_existing_trial';
export const QUERY_PAYMENT_SUCCESS = `?${SubscriptionQuery.PAYMENT}=${PAYMENT_SUCCESS}`;
export const QUERY_PAYMENT_CANCEL = `?${SubscriptionQuery.PAYMENT}=${PAYMENT_CANCEL}`;
export const QUERY_PAYMENT_ALREADY_EXISTING_TRIAL = `?${SubscriptionQuery.PAYMENT}=${ALREADY_EXISTING_TRIAL}`;

export const SUBSCRIPTION_DISPLAY = "display"
export const SUBSCRIPTION_CREATE = "create"
export const QUERY_SUBSCRIPTION_DISPLAY = `?${SubscriptionQuery.SUBSCRIPTION}=${SUBSCRIPTION_DISPLAY}`
export const QUERY_SUBSCRIPTION_CREATE = `?${SubscriptionQuery.SUBSCRIPTION}=${SUBSCRIPTION_CREATE}`

export const FREE_TRIAL_KEYWORD= 'trial'


export const QUERY_INVOICE = `?${SubscriptionQuery.INVOICE}=`
export const QUERY_FIELD_LIST = `?${SubscriptionQuery.FIELD_LIST}=`

export function getInvoiceQueryString(invoicesId: string) {
    return `${QUERY_INVOICE}${invoicesId}`
}
export function getFieldListQueryString(fieldList: string) {
    return `${QUERY_FIELD_LIST}${fieldList}`
}

export function setLocalStorageSubscriptionModalKey(uid: string) {
    return `subscriptionAlerterModal_${uid}`
}