import {Box, Typography} from "@mui/material";
import React from "react";
import {FormattedMessage} from "react-intl";
import bareSoilImage from "../assets/bareSoilImage.png";
import ArrowLeftMiddle from "./icons/ArrowLeftMiddle";
import ArrowRightBottom from "./icons/ArrowRightBottom";
import ArrowRightMiddle from "./icons/ArrowRightMiddle";
import ArrowRightTop from "./icons/ArrowRightTop";
import ArrowTopLeft from "./icons/ArrowTopLeft";
import ArrowTopMiddle from "./icons/ArrowTopMiddle";

const BareSoilHelp: React.FC = () => {
  return (
    <Box flex={1} width={1} display="flex" flexDirection={"column"}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.carbon.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={bareSoilImage} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-60}
            left={-70}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.go_to_edit_page' />
              </Typography>
              <ArrowTopLeft
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -20,
                  left: 140,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-50}
            left={265}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.select_campaign' />
              </Typography>
              <ArrowTopMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -20,
                  right: 160,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-70}
            right={-232}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.go_to_cover_soil' />
              </Typography>
              <ArrowRightTop
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 35,
                  right: 150,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={90}
            right={-195}>
            <Box width={'180px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.bare_soil.data' />
              </Typography>
              <Typography variant='caption' component={'div'}>
                <FormattedMessage id='info.carbon.bare_soil.data.days_number' />
              </Typography>
              <Typography variant='caption' component={'div'}>
                <FormattedMessage id='info.carbon.bare_soil.data.period_number' />
              </Typography>
              <ArrowRightMiddle
                sx={{
                  fontSize: '90px',
                  position: 'absolute',
                  top: 80,
                  left: -30,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-40}
            right={-155}>
            <Box width={'180px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                {' '}
                <FormattedMessage id='info.carbon.bare_soil.timeline' />
              </Typography>
              <ArrowRightBottom
                sx={{
                  fontSize: '80px',
                  position: 'absolute',
                  top: -80,
                  right: 100,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-170}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id="info.select_field" />
              </Typography>
              <ArrowLeftMiddle
                sx={{
                  fontSize: '90px',
                  position: 'absolute',
                  top: -50,
                  right: -20,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BareSoilHelp;
