import {Box, Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {Form, Formik} from 'formik';
import {MultiPolygon, Polygon} from 'geojson';
import CheckAllowedButton from 'modules/auth/components/CheckAllowedButton';
import useCheckPermissions from 'modules/auth/hooks/useCheckPermissions';
import {FIELDS_UPDATE} from 'modules/auth/models/UserPermission';
import nitrogenActions from 'modules/nitrogen/actions/NitrogenActions';
import NitrogenCoverList from 'modules/nitrogen/components/NitrogenCoverList';
import RotationsList from 'modules/rotations/components/RotationsList';
import SubscriptionFieldInfo from 'modules/subscriptions/components/SubscriptionFieldInfo';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BaseDialog from 'shared/components/BaseDialog';
import {ServiceId} from 'shared/models/RouterParam';
import {AppState} from 'shared/store/index';
import wellknown from 'wellknown';
import IntlMessages from '../../../shared/components/IntlMessages';
import rotationsActions from '../../rotations/actions/RotationsActions';
import {actions} from '../actions/FieldsActions';
import {BASE_FIELD_CREATION, MIN_HEIGHT_MODAL} from '../configs/FieldEdit';
import Field from '../models/Field';
import FieldCreation from '../models/FieldCreation';
import {getServiceFieldForm} from '../services/FieldFormService';
import {
  getFieldCreationMap,
  getFieldEditValidationSchema,
} from '../services/FieldsService';
import AddFieldFormMap from './AddFieldFormMap';
import EditFieldContainer from './EditFieldContainer';
import FieldEditForm from './FieldEditForm';
import useStyles from './Fields.style';

/**
 * Which action as been done in the form
 */
export enum FieldFormSubmitAction {
  ADD_FIELD = 'ADD_FIELD',
  IMPORT_FIELDS = 'IMPORT_FIELDS',
  MODIFY_FIELD = 'MODIFY_FIELD',
  ADD_ROTATION = 'ADD_ROTATION',
  DELETE_FIELD = 'DELETE_FIELD',
  ADD_COVER = 'ADD_COVER',
}

// TODO: why two enums?
export enum FieldFormEditMode {
  ADD_FIELD_MODAL = 'ADD_FIELD_MODAL',
  IMPORT_FIELDS_MODAL = 'IMPORT_FIELDS_MODAL',
  MODIFY_FIELD_PAGE = 'MODIFY_FIELD_PAGE',
  ADD_ROTATION_MODAL = 'ADD_ROTATION_MODAL',
  ADD_COVER = 'ADD_COVER',
}

interface AddFieldFormProps {
  /**
   * Called when the form has been submitted (delete field, etc.)
   */
  onSubmit?: (action: FieldFormSubmitAction) => void;
  /**
   * determine which form must be displayed
   */
  editMode: FieldFormEditMode;

  /**
   * Field to Edit
   */
  field?: Field;
}

/**
 * set state for add & modify fields plus rotations forms
 * @param props
 */
/**
 * WARNING  Update, Add or Delete a field is temporary disable for all services
 * @param props
 * @returns
 */
const AddFieldForm: React.FC<AddFieldFormProps> = (props) => {
  const {editMode, onSubmit, field, ...boxProps} = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const allowEdit = useCheckPermissions(FIELDS_UPDATE);
  const {
    selectedFieldId,
    selectedCampaign,
    mapPosition,
    selectedService,
    fieldsList,
  } = useSelector<AppState, AppState['fields']>(({fields}) => fields);

  const {covercrop} = useSelector<AppState, AppState['nitrogen']>(
    (state) => state.nitrogen,
  );
  const {data} = useSelector<AppState, AppState['campaigns']>(
    (state) => state.campaigns,
  );

  /*
   * sets multiple state for dialogs and buttons
   */
  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  /*
   * sets multiple state for field and rotation informations
   */
  const [newFieldPolygon, setNewFieldPolygon] = useState<
    Polygon | MultiPolygon | undefined
  >(undefined);
  const [newFieldWkt, setNewFieldWkt] = useState<string | undefined>(undefined);
  const [newField, setNewField] = useState<FieldCreation>(BASE_FIELD_CREATION);

  /**
   * specific to nitrogen
   */

  useEffect(() => {
    if (selectedService) {
      if (field) {
        setNewFieldPolygon(field.geojson_geometry);
        // TODO: Review coversat data => ask to render all field needed in get fields/nitrogen
        const fieldToEdit = getFieldCreationMap(
          field,
          selectedCampaign,
        ) as FieldCreation;

        setNewField(fieldToEdit);
      } else if (
        (selectedService === ServiceId.IRRIGATION &&
          editMode === FieldFormEditMode.ADD_ROTATION_MODAL) ||
        (selectedService === ServiceId.NITROGEN &&
          editMode === FieldFormEditMode.ADD_COVER)
      ) {
        if (fieldsList && fieldsList.length && selectedFieldId) {
          const field = fieldsList.find(
            (field) => field.field_id === selectedFieldId,
          );
          if (field) {
            setNewFieldPolygon(field.geojson_geometry);
            // TODO: Review coversat data => ask to render all field needed in get fields/nitrogen
            setNewField(
              getFieldCreationMap(
                field,
                selectedCampaign,
                true,
              ) as FieldCreation,
            );
          }
        }
      }
    }
  }, [
    field,
    editMode,
    selectedCampaign,
    selectedService,
    covercrop,
    selectedFieldId,
    fieldsList,
  ]);

  /**
   * Handle polygon change on the map
   * @param newPolygon
   */
  const handlePolygonChange = (newPolygon: Polygon) => {
    if (newPolygon) {
      // Convert to wkt
      const wkt = wellknown.stringify(newPolygon as wellknown.GeoJSONPolygon);

      // Update states
      setNewFieldWkt(wkt);
      setNewFieldPolygon(newPolygon);
    }
  };

  const deleteField = () => {
    if (selectedFieldId) {
      dispatch(actions.deleteField(selectedFieldId));
      if (onSubmit) {
        setTimeout(() => {
          // TODO
          //setIsFieldDeleted(true);
          onSubmit(FieldFormSubmitAction.DELETE_FIELD);
        }, 500);
      }
    }
  };

  /**
   * Define if map is on readOnly mode
   * @param editMode
   * @returns
   */
  const isMapReadOnly = (editMode: FieldFormEditMode, service: ServiceId) => {
    if (editMode === FieldFormEditMode.MODIFY_FIELD_PAGE) {
      return !allowEdit /*|| hasFieldSuscribtionsToService(newField as Field, service)*/;
    } else {
      return editMode === FieldFormEditMode.ADD_ROTATION_MODAL;
    }
  };

  const historyTableToDisplay = (service: ServiceId) => {
    switch (service) {
      case ServiceId.IRRIGATION:
        return <RotationsList onClose={() => setHistoryModal(!historyModal)} />;
      case ServiceId.VEGETATION:
        return <RotationsList onClose={() => setHistoryModal(!historyModal)} />;
      case ServiceId.WEATHER:
        return <RotationsList onClose={() => setHistoryModal(!historyModal)} />;
      case ServiceId.NITROGEN:
        return (
          <NitrogenCoverList onClose={() => setHistoryModal(!historyModal)} />
        );
      default:
        return null;
    }
  };

  /**
   * Return different end buttons
   * @param editMode
   * @param formikProps
   * @returns
   */
  const getEditFieldValidationActions = (editMode: FieldFormEditMode) => {
    if (selectedService) {
      switch (editMode) {
        case FieldFormEditMode.ADD_FIELD_MODAL:
          return (
            <Button
              style={{
                marginTop: 'auto',
                width: '100%',
              }}
              type='submit'
              variant='contained'
              color='primary'
              data-test='field-validate-button'>
              <IntlMessages id='fields.button.validation' />
            </Button>
          );
        case FieldFormEditMode.ADD_ROTATION_MODAL:
          return (
            <Button
              variant='contained'
              color='primary'
              type='submit'
              data-test='rotation-validate-button'>
              <IntlMessages id='fields.button.validation' />
            </Button>
          );
        case FieldFormEditMode.ADD_COVER:
          return (
            <Button
              variant='contained'
              color='primary'
              type='submit'
              data-test='cover-validate-button'>
              <IntlMessages id='fields.button.validation' />
            </Button>
          );
        case FieldFormEditMode.MODIFY_FIELD_PAGE:
          return (
            <Box
              sx={{
                marginTop: 'auto',
              }}>
              <SubscriptionFieldInfo />
              <BaseDialog
                fullWidth
                PaperProps={{
                  sx: {
                    maxWidth: '90%',
                  },
                }}
                titleMessageId='rotations.list'
                open={historyModal}
                onClose={() => setHistoryModal(!historyModal)}>
                {historyTableToDisplay(selectedService)}
              </BaseDialog>
              <Box display='flex' justifyContent='space-around'>
                <Box flex={1} p={2}>
                  <CheckAllowedButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    fullWidth
                    buttonTitleId='fields.button.modify'
                    allowedPermissions='fields.update'></CheckAllowedButton>
                </Box>
                {historyTableToDisplay(selectedService) && (
                  <Box flex={1} p={2}>
                    <Button
                      variant='contained'
                      color='primary'
                      sx={{textTransform: 'none'}}
                      fullWidth
                      onClick={() => setHistoryModal(!historyModal)}>
                      <IntlMessages id='fields.button.history' />
                    </Button>
                  </Box>
                )}
                <Box flex={1} p={2}>
                  <CheckAllowedButton
                    variant='contained'
                    classes={{root: classes.deleteButton}}
                    fullWidth
                    buttonTitleId='fields.button.delete'
                    allowedPermissions='fields.delete'
                    onClick={() =>
                      setDeleteDialogOpen(!deleteDialogOpen)
                    }></CheckAllowedButton>
                </Box>
                <Dialog open={deleteDialogOpen}>
                  <DialogContent>
                    <IntlMessages id='fields.delete.validationMessage' />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        deleteField();
                        setDeleteDialogOpen(!deleteDialogOpen);
                      }}>
                      <IntlMessages id='fields.button.delete' />
                    </Button>
                    <Button
                      onClick={() => setDeleteDialogOpen(!deleteDialogOpen)}>
                      <IntlMessages id='fields.button.cancel' />
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          );
        default:
          return null;
      }
    }
  };

  if (selectedService && data) {
    return (
      <>
        <Box
          display='flex'
          {...boxProps}
          width={1}
          height={1}
          minHeight={MIN_HEIGHT_MODAL}>
          <Formik
            enableReinitialize={true}
            initialValues={newField}
            validationSchema={getFieldEditValidationSchema(selectedService)}
            validate={() => ({})}
            onSubmit={(data) => {
              if (
                typeof data.max_irrigation_dose === 'string' &&
                data.max_irrigation_dose.trim() === ''
              ) {
                data.max_irrigation_dose = null;
              } else if (data.max_irrigation_dose) {
                data.max_irrigation_dose = +data.max_irrigation_dose;
              }

              if (
                typeof data.irrigation_cycle_duration === 'string' &&
                data.irrigation_cycle_duration.trim() === ''
              ) {
                data.irrigation_cycle_duration = null;
              } else if (data.irrigation_cycle_duration) {
                data.irrigation_cycle_duration =
                  +data.irrigation_cycle_duration;
              }
              let action: FieldFormSubmitAction =
                FieldFormSubmitAction.MODIFY_FIELD;
              if (
                editMode === FieldFormEditMode.ADD_FIELD_MODAL &&
                newFieldWkt
              ) {
                action = FieldFormSubmitAction.ADD_FIELD;
                dispatch(
                  actions.addNewField(
                    {
                      ...data,
                      geometry: newFieldWkt as string,
                    },
                    selectedService,
                  ),
                );
              } else if (editMode === FieldFormEditMode.ADD_ROTATION_MODAL) {
                action = FieldFormSubmitAction.ADD_ROTATION;
                const {rotation, field_id} = data;
                if (rotation && field_id) {
                  dispatch(
                    rotationsActions.addNewRotation(
                      field_id as number,
                      rotation,
                    ),
                  );
                }
              } else if (editMode === FieldFormEditMode.MODIFY_FIELD_PAGE) {
                console.warn('modify ');
                action = FieldFormSubmitAction.MODIFY_FIELD;
                dispatch(
                  actions.modifyField(
                    {
                      ...data,
                      geometry: newFieldWkt as string,
                    },
                    selectedService,
                  ),
                );
              } else if (editMode === FieldFormEditMode.ADD_COVER) {
                action = FieldFormSubmitAction.ADD_COVER;
                const {cover, field_id} = data;
                if (cover && field_id) {
                  dispatch(nitrogenActions.addCover(field_id as number, cover));
                }
              }
              if (onSubmit) {
                onSubmit(action);
              }
            }}>
            {(props) => (
              <>
                {
                  // Component to edit polygon geometry, readonly only if add rotation
                }
                <EditFieldContainer type='map' editMode={editMode}>
                  <AddFieldFormMap
                    readonly={isMapReadOnly(editMode, selectedService)}
                    initialMapPosition={mapPosition}
                    polygon={newFieldPolygon}
                    onPolygonChange={handlePolygonChange}
                    editMode={editMode}
                    field={field}
                  />
                </EditFieldContainer>
                <Form className={classes.fieldInfosForm}>
                  <Box sx={{overflowY: 'auto', overflowX: 'hidden'}} flex={1}>
                    <EditFieldContainer type='form' editMode={editMode}>
                      <FieldEditForm
                        editMode={editMode}
                        formikProps={props}
                        selectedService={selectedService}
                        allowEdit={allowEdit}
                      />
                    </EditFieldContainer>
                    {getServiceFieldForm(selectedService, editMode, props) && (
                      <EditFieldContainer type='form' editMode={editMode}>
                        {getServiceFieldForm(selectedService, editMode, props)}
                      </EditFieldContainer>
                    )}
                  </Box>
                  {getEditFieldValidationActions(editMode)}
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </>
    );
  }
  return null;
};

export default AddFieldForm;
