import {Theme} from '@mui/material';
import {DayPlus, DynNitrogen} from '../models/NitrogenCoverSat';

import chroma from 'chroma-js';
import {
  GRADIENT_BREAKPOINT_NUMBER,
  GRADIENT_COLOR_REF,
  MAX_NITROGEN_TOT,
  MIN_NITROGEN_TOT,
} from '../configs/NitrogenConfigs';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
/**
 * DayPlus enum according given key from model Coversat biomass
 * @param key
 * @returns
 */
export function getDynNitrogenDayNumber(key: keyof DynNitrogen) {
  switch (key) {
    case 'dyn_n_30d':
      return DayPlus.D30;
    case 'dyn_n_60d':
      return DayPlus.D60;
    case 'dyn_n_90d':
      return DayPlus.D90;
    case 'dyn_n_120d':
      return DayPlus.D120;
    case 'dyn_n_150d':
      return DayPlus.D150;
    case 'dyn_n_180d':
      return DayPlus.D180;
    default:
      return DayPlus.D30;
  }
}

export function getColorGradient(value: number, theme: Theme) {
  const gradient = chroma
    .scale("RdYlGn").domain([MIN_NITROGEN_TOT, MAX_NITROGEN_TOT])
    ;
  return gradient(value).toString();
}

/**
 * return an Chroma Object representative & reusable for pick color and draw linear gradient
 * @param minValue 
 * @param maxValue 
 * @returns 
 */
export function chromaGradient(minValue: number, maxValue: number): chroma.Scale<chroma.Color> {
  return chroma.scale(GRADIENT_COLOR_REF).domain([minValue, maxValue])
}

/**
 * generate linear gradient string with brakpoint in % based on color array
 * @param scale 
 * @returns 
 */
export const getLinearGradient = (scale: string[]) => {

  const stopUnit = 100 / GRADIENT_BREAKPOINT_NUMBER

  const gradientArray = scale.map((color, index) => {
    let stop = stopUnit
    if (index !== 0) {
      stop = stop + stop*index
    }

    return ` ${color} ${stop}%`
  })

  return gradientArray.join(",")

}
/**
 * return CSS properties for linear gradient according to gradient give as argument
 * @param chroma 
 * @returns 
 */
export const backgroundGradient = (chroma: chroma.Scale<chroma.Color>) => {
  const gradient = chroma.colors(GRADIENT_BREAKPOINT_NUMBER)
  return `linear-gradient(to right, ${getLinearGradient(gradient)})`
}


export function getSpecieTranslation(id: number | string) {
  return appIntl().formatMessage({id: `species.${id}.label`});
}
