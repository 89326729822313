import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {MainRole} from '../models/MainRole';

function useCheckMainRole(mainRole: MainRole): boolean {

    const [allowed, setAllowed] = useState<boolean>(false);

    const {customer} = useSelector<AppState, AppState['auth']>(
        (state) => state.auth,
    );

    useEffect(() => {
        let allowed: boolean = false;
        if (customer && customer.role === mainRole) {
            allowed = true;
        }
        setAllowed(allowed);
    }, [customer, mainRole]);

    return allowed;
}

export default useCheckMainRole;