import Rotation from '../models/Rotation';
import Field from '../../fields/models/Field';
import {AddRotation} from 'modules/waterBalance/models/AddRotation';

export const ADD_NEW_ROTATION = 'ADD_NEW_ROTATION';
export interface AddNewRotationAction {
  type: typeof ADD_NEW_ROTATION,
  payload: {
    selectedFieldId: number,
    newRotation: AddRotation
  }
};

export const MODIFY_ROTATION = 'MODIFY_ROTATION';
export interface ModifyRotationAction {
  type: typeof MODIFY_ROTATION,
  payload: {
    field_id: number,
    rotation: AddRotation
  }
};

export const LOAD_ROTATIONS = 'LOAD_ROTATIONS';
export interface LoadRotationsAction {
  type: typeof LOAD_ROTATIONS;
  payload: {
    selectedFieldId: number | null;
  }
};

export const LOAD_ROTATIONS_SUCCESS = 'LOAD_ROTATIONS_SUCCESS';
export interface LoadRotationsSuccessAction {
  type: typeof LOAD_ROTATIONS_SUCCESS;
  payload: {[key: string]: Rotation}
};

export const DELETE_ROTATION = 'DELETE_ROTATION';
export interface DeleteRotationAction {
  type: typeof DELETE_ROTATION;
  payload: {
    selectedField: Field | null;
    selectedRotationId: number | null;
  }
}

export type RotationActionTypes =
  | AddNewRotationAction
  | ModifyRotationAction
  | LoadRotationsAction
  | LoadRotationsSuccessAction
  | DeleteRotationAction

export const actions = {
  addNewRotation: (selectedFieldId: number, newRotation: AddRotation): AddNewRotationAction => {
    return {
      type: ADD_NEW_ROTATION,
      payload: {selectedFieldId, newRotation}
    }
  },

  modifyRotation: (fieldId: number, rotation: AddRotation): ModifyRotationAction => {
    return {
      type: MODIFY_ROTATION,
      payload: {
        field_id: fieldId,
        rotation: rotation
      }
    }
  },

  loadRotation: (selectedFieldId: number | null): LoadRotationsAction => {
    return {
      type: LOAD_ROTATIONS,
      payload: {selectedFieldId}
    }
  },

  loadRotationsSuccess: (rotations: {[key: string]: Rotation}): LoadRotationsSuccessAction => {
    return {
      type: LOAD_ROTATIONS_SUCCESS,
      payload: rotations
    }
  },

  deleteRotation: (selectedField: Field | null, selectedRotationId: number | null): DeleteRotationAction => {
    return {
      type: DELETE_ROTATION,
      payload: {selectedField, selectedRotationId}
    }
  }

}

export default actions;