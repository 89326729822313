import {RoleConfig} from "../models/RoleConfig";

/**
 * Default roles config waiting for api endpoint
 */
export const defaultRolesConfig: {[key: string]: RoleConfig} = {
    "distributor_farmer": {
        "role_type": "user",
        "permissions": [
            "fields.create",
            "fields.read",
            "fields.update",
            "fields.delete",
            "fields.import",
            "rotations.create"
        ]
    },
    "viewer": {
        "role_type": "user",
        "permissions": [
            "fields.read",
            "crops.read",
            "soils.read"
        ]
    },
    "farmer": {
        "role_type": "user",
        "permissions": [
            "fields.create",
            "fields.read",
            "fields.update",
            "fields.delete",
            "fields.import",
            "rotations.create",
            "soils.create",
            "soils.read",
            "soils.update",
            "soils.delete",
            "crops.create",
            "crops.read",
            "crops.update",
            "crops.delete"
        ]
    },
    "distributor": {
        "role_type": "user",
        "permissions": [
            "fields.create",
            "fields.read",
            "fields.update",
            "fields.delete",
            "fields.import",
            "rotations.create",
            "soils.create",
            "soils.read",
            "soils.update",
            "soils.delete",
            "crops.create",
            "crops.read",
            "crops.update",
            "crops.delete"
        ]
    },
    "admin": {
        "role_type": "user",
        "permissions": [
            "fields.create",
            "fields.read",
            "fields.update",
            "fields.delete",
            "fields.import",
            "rotations.create",
            "soils.create",
            "soils.read",
            "soils.update",
            "soils.delete",
            "crops.create",
            "crops.read",
            "crops.update",
            "crops.delete",
            "users.read"
        ]
    },
    "field.irrigation": {
        "role_type": "subscription_field_feature",
        "permissions": [
            "field.irrigation.create",
            "field.irrigation.read",
            "field.irrigation.update",
            "field.irrigation.delete"
        ]
    },
    "field.nitrogen": {
        "role_type": "subscription_field_feature",
        "permissions": [
            "field.nitrogen.create",
            "field.nitrogen.read",
            "field.nitrogen.update",
            "field.nitrogen.delete"
        ]
    },
    "field.vegetation": {
        "role_type": "subscription_field_feature",
        "permissions": [
            "field.vegetation.read"
        ]
    },
    "field.carbon": {
        "role_type": "subscription_field_feature",
        "permissions": [
            "field.carbon.read"
        ]
    },
    "field.weather": {
        "role_type": "subscription_field_feature",
        "permissions": [
            "field.weather.read"
        ]
    },
    "field.report": {
        "role_type": "subscription_field_feature",
        "permissions": [
            "field.report.read"
        ]
    }
}
