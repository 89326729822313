import {StepLabel, Stepper, Step} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React from 'react';
import useStepperStyles from './StepperStyle';

type Props = {activeStep: number, stepperLength: number}

export default function StepBar({activeStep, stepperLength}: Props) {
    const classes = useStepperStyles()
    const stepIcon = (i: number) => {

        return (
            <FiberManualRecordIcon color={activeStep === i ? 'primary' : 'disabled'} className={classes.stepBarIcon} />
        )

    }


    const stepsGenerator = (stepperLength: number) => {

        const steps: any[] = []

        for (let i = 0; i < stepperLength; i++) {
            steps.push(
                <Step key={i} className={classes.step}>
                    <StepLabel StepIconComponent={() => stepIcon(i)} />
                </Step>
            )
        }
        return steps

    }


    return (
        <Stepper alternativeLabel activeStep={activeStep} className={classes.stepBar}>
            {stepsGenerator(stepperLength).map(step => step)}
        </Stepper>
    )
}