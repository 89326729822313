import DatePicker from '@mui/lab/DatePicker';
import {Box, MenuItem, Typography} from '@mui/material';
import {FormikProps} from 'formik';
import {DateTime} from 'luxon';
import {getCropTranslationKeyFromCode} from 'modules/crops/services/CropsTranslation';
import {FieldFormEditMode} from 'modules/fields/components/AddFieldForm';
//@ts-ignore
import FieldCreation from 'modules/fields/models/FieldCreation';
import {getSysIrrigationTranslationKey} from 'modules/sysIrrigation/services/SysIrrigationTranslation';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import MyTextField from 'shared/components/MyTextField';
import {AppState} from 'shared/store';
import useStyles from '../../fields/components/Fields.style';

interface IrrigationFieldEditFormProps {
  formikProps: FormikProps<FieldCreation>;
  editMode: FieldFormEditMode;
}
/**
 * Form in charge to display Irrigation informations of a selected field.
 * Use to display data, edit and create
 * Has to be called from a Formik container
 * Called in AddFieldForm.tsx
 * @param props
 * @returns
 */
const IrrigationFieldEditForm: React.FC<IrrigationFieldEditFormProps> = (
  props,
) => {
  const {formikProps, editMode} = props;
  const classes = useStyles();
  const intl = useIntl();

  const cropsData = useSelector<AppState, AppState['crops']>(
    ({crops}) => crops,
  );

  const sysIrrigationData = useSelector<AppState, AppState['sysIrrigation']>(
    ({sysIrrigation}) => sysIrrigation,
  );

  return (
    <Box pt={3}>
      <Typography variant='h6'>
        <FormattedMessage id='fields.form.rotation' />
      </Typography>
      <Box p={1} py={2}>
        <DatePicker
          value={
            formikProps.values.rotation?.sowing_date
              ? DateTime.fromISO(formikProps.values.rotation?.sowing_date)
              : null
          }
          onChange={(date: DateTime | null) => {
            formikProps.setFieldValue(
              'rotation.sowing_date',
              date?.toISODate(),
              true,
            );
          }}
          renderInput={(params) => (
            <MyTextField
              {...params}
              fullWidth
              variant='outlined'
              data-test='field-sowingDate'
              className={`${
                editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                  ? classes.fieldFormTextParams
                  : ''
              }`}
              label={<FormattedMessage id='fields.form.date' />}
              name='rotation.sowing_date'
              value={
                editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                  ? formikProps.values?.rotation?.sowing_date
                  : Date.now()
              }
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: intl.formatMessage({
                  id: 'common.input.date.placeholder',
                }),
              }}
            />
          )}
        />
      </Box>
      <Box p={1} py={2}>
        <DatePicker
          value={
            formikProps.values.rotation?.harvest_date
              ? DateTime.fromISO(formikProps.values.rotation?.harvest_date)
              : null
          }
          onChange={(date: DateTime | null) => {
            formikProps.setFieldValue(
              'rotation.harvest_date',
              date?.toISODate(),
              true,
            );
          }}
          renderInput={(params) => (
            <MyTextField
              {...params}
              fullWidth
              variant='outlined'
              data-test='field-harvest_date'
              className={`${
                editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                  ? classes.fieldFormTextParams
                  : ''
              }`}
              label={<FormattedMessage id='fields.form.harvest_date' />}
              name='rotation.harvest_date'
              value={
                editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
                  ? formikProps.values?.rotation?.harvest_date
                  : Date.now()
              }
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: intl.formatMessage({
                  id: 'common.input.date.placeholder',
                }),
              }}
            />
          )}
        />
      </Box>
      <Box p={1} py={2}>
        <MyTextField
          fullWidth
          variant='outlined'
          data-test='field-crop'
          select
          SelectProps={{
            displayEmpty: true,
            defaultValue: 0,
          }}
          className={` ${
            editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
              ? classes.fieldFormTextParams
              : ''
          }`}
          name='rotation.crop_id'
          value={formikProps.values.rotation?.crop_id}
          label={<FormattedMessage id='fields.form.crops' />}>
          <MenuItem disabled value={0}>
            {''}
          </MenuItem>
          {Object.values(cropsData.data).map((element) => {
            return (
              <MenuItem
                key={element.crop_id}
                value={element.crop_id}
                data-test='form-crop-options'>
                {element.crop_code
                  ? intl.formatMessage({
                      id: getCropTranslationKeyFromCode(element.crop_code)!,
                    })
                  : element.crop_name}
              </MenuItem>
            );
          })}
        </MyTextField>
      </Box>
      <Box p={1} py={2}>
        <MyTextField
          fullWidth
          variant='outlined'
          data-test='field-sysIrrigation'
          select
          className={`${
            editMode === FieldFormEditMode.MODIFY_FIELD_PAGE
              ? classes.fieldFormTextParams
              : ''
          }`}
          SelectProps={{
            displayEmpty: true,
            defaultValue: 0,
          }}
          name='rotation.sys_irrigation_id'
          value={formikProps.values.rotation?.sys_irrigation_id}
          label={<FormattedMessage id='fields.form.irrigation' />}>
          <MenuItem disabled value={0}>
            {''}
          </MenuItem>
          {Object.values(sysIrrigationData.data).map((element) => {
            return (
              <MenuItem
                key={element.sys_irrigation_id}
                value={element.sys_irrigation_id}
                data-test='form-irrigation-options'>
                {element.sys_irrigation_code
                  ? intl.formatMessage({
                      id: getSysIrrigationTranslationKey(element)!,
                    })
                  : element.irrigation_type}
              </MenuItem>
            );
          })}
        </MyTextField>
      </Box>
    </Box>
  );
};

export default IrrigationFieldEditForm;