import Subscription from "../models/Subscription";
import {SelectedSubscriptionState} from "../models/SubscriptionsState";
import PixagriSubscription from "../models/PixagriSubscription";

export const LOAD_SUBSCRIPTIONS_LISTING = 'LOAD_SUBSCRIPTIONS_LISTING';
export interface LoadSubscriptionsListingAction {
    type: typeof LOAD_SUBSCRIPTIONS_LISTING
    payload: {
        delay?: number;
    }
}

export const LOAD_SUBSCRIPTIONS_LISTING_SUCCESS = 'LOAD_SUBSCRIPTIONS_LISTING_SUCCESS';
export interface LoadSubscriptionsListingSuccessAction {
    type: typeof LOAD_SUBSCRIPTIONS_LISTING_SUCCESS
    payload: {
        data: {[key: string]: Subscription} | null,
        pixagriData: {[key: string]: PixagriSubscription} | null
    }
}

export const LOAD_INVOICES_INFO = 'LOAD_INVOICES_INFO';
export interface LoadInvoicesInfoAction {
    type: typeof LOAD_INVOICES_INFO,
    payload: string
}

export const LOAD_INVOICES_INFO_SUCCESS = 'LOAD_INVOICES_INFO_SUCCESS';
export interface LoadInvoicesSuccessAction {
    type: typeof LOAD_INVOICES_INFO_SUCCESS,
    payload: SelectedSubscriptionState
}

export type SubscriptionsActionTypes = LoadSubscriptionsListingAction | LoadSubscriptionsListingSuccessAction | LoadInvoicesInfoAction | LoadInvoicesSuccessAction;

export const subscriptionActions = {
    loadSubscriptions: (delay?: number): LoadSubscriptionsListingAction => {
        return {
            type: LOAD_SUBSCRIPTIONS_LISTING,
            payload: {
                delay: delay
            }
        }
    },
    loadSubscriptionsSuccess: (subscriptions: {[key: string]: Subscription}, pixagriSubscriptions: {[key: number]: PixagriSubscription}): LoadSubscriptionsListingSuccessAction => {
        return {
            type: LOAD_SUBSCRIPTIONS_LISTING_SUCCESS,
            payload: {
                data: subscriptions,
                pixagriData: pixagriSubscriptions,
            }
        }
    },
    loadInvoicesInfo: (id: string): LoadInvoicesInfoAction => {
        return {
            type: LOAD_INVOICES_INFO,
            payload: id
        }
    },
    loadInvoicesInfoSuccess: (invoices: SelectedSubscriptionState): LoadInvoicesSuccessAction => {
        return {
            type: LOAD_INVOICES_INFO_SUCCESS,
            payload: invoices
        }
    }

}