import {uzReportTranslations} from 'modules/report/translations/UzReportTranslations';
import uzMessages from '../locales/uz_UZ.json';
import {ruRU} from '@mui/material/locale';

const UzLang = {
  messages: {
    ...uzMessages,
    ...uzReportTranslations,
  },
  muiLocale: ruRU,
  locale: 'uz-UZ',
};
export default UzLang;
