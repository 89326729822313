import React from 'react';
import {makeStyles} from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {AppState} from 'shared/store';
import WaterBalanceModel from '../models/WaterBalanceModel';
import WaterBalanceState from '../models/WaterBalanceState';
import {actions} from '../actions/WaterBalanceActions';
import {Box, Theme} from '@mui/material';
import {ServiceId} from 'shared/models/RouterParam';
import {Biomass} from 'modules/nitrogen/models/NitrogenCoverSat';
import nitrogenActions from 'modules/nitrogen/actions/NitrogenActions';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export enum NdviSelectorMode {
  READ_ONLY = "read_only",
  COMPARE_DATE = "compare_date",
  HIDDEN = "hidden"
}

interface NdviSelectorProps {
  mode?: NdviSelectorMode;
}

/**
 * Select component in order to be able to select date of ndvi map
 * Use the redux state waterBalance.selectedNdviDate
 * @param props
 */
const NdviSelector: React.FC<NdviSelectorProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {data, selectedNdviDate, selectedCompareNdviDate}: WaterBalanceState = useSelector<
    AppState,
    AppState['waterBalance']
  >(({waterBalance}) => waterBalance);
  
  const {selectedBiomassDate, coversat} = useSelector<AppState, AppState['nitrogen']>((state) => state.nitrogen)
  const {selectedService} = useSelector<AppState, AppState['fields']>((state) => state.fields)

  let ndviData: WaterBalanceModel[] = [];
  if (data && data.fcover_verde) {
    ndviData = data.fcover_verde as WaterBalanceModel[];
  }
  let biomassData: Biomass[] = []
  if (coversat) {
    biomassData = coversat.biomass
  }

  const handleNdviDateChange = (event: SelectChangeEvent<string | null>) => {
    // Dispatch action
    if (props.mode === NdviSelectorMode.COMPARE_DATE) {
      dispatch(actions.setSelectedCompareNdviDate(event.target.value as string))
    } else {
      dispatch(actions.setSelectedNdviDate(event.target.value as string));
    }
  };

  const handleBiomassDateChange = (event: SelectChangeEvent<string | null>) => {
    dispatch(nitrogenActions.setselectedBiomassDate(event.target.value as string))
  }
  const defineActionsOnChange = (service: ServiceId, event: SelectChangeEvent<string | null>) => {  
      switch (service) {
        case ServiceId.IRRIGATION:
        case ServiceId.VEGETATION:
          return handleNdviDateChange(event)
        case ServiceId.NITROGEN: 
          return handleBiomassDateChange(event)
          default:
            return null
      }
  }
  const defineNdviDateToDisplay = (mode?: NdviSelectorMode) => {
    if (mode && mode === NdviSelectorMode.COMPARE_DATE) {
      return selectedCompareNdviDate
    } else {
      return selectedNdviDate
    }
  }
  const getDateToDisplay = (service: ServiceId, mode?: NdviSelectorMode) => {
    switch (service) {
      case ServiceId.IRRIGATION:
      case ServiceId.VEGETATION:
        return defineNdviDateToDisplay(mode)
      case ServiceId.NITROGEN:
        return selectedBiomassDate
      default:
        return null
    }
  }

  const getDateList = (service: ServiceId) => {
    switch (service) {
      case ServiceId.IRRIGATION:
      case ServiceId.VEGETATION:
        const ndviDateList = ndviData.map(waterBalanceModel => waterBalanceModel.wb_date)
        return ndviDateList
      case ServiceId.NITROGEN:
        const biomassDateList = biomassData.map(biomass => biomass.date_biomass)
        return biomassDateList
      default:
        return [];
    }
  }

  const label: string = intl.formatMessage({
    id: 'waterBalance.ndviSelector.label',
  });

  if (selectedService) {
    if (props.mode === NdviSelectorMode.READ_ONLY && getDateToDisplay(selectedService)) {
      return <Box p={3}>{getDateToDisplay(selectedService)}</Box>;
    }
    return (
      <div>
        <FormControl variant='standard' className={classes.formControl}>
          <InputLabel>{label}</InputLabel>
          <Select
            displayEmpty
            value={getDateToDisplay(selectedService, props.mode) ? getDateToDisplay(selectedService, props.mode) : ""}
            onChange={(e) => defineActionsOnChange(selectedService, e)}
            label={label}>
            {getDateList(selectedService) && getDateList(selectedService).length
              ? getDateList(selectedService).map((date, index) => {
                return (
                  <MenuItem value={date} key={index}>
                    {intl.formatDate(date)}
                  </MenuItem>
                );
              })
              : null}
          </Select>
        </FormControl>
      </div>
    );
  };
  return null
}

export default NdviSelector;
