import {Box, Link, List, ListItem, Typography} from '@mui/material';
import {currencySymbol} from 'modules/products/services/currencySymbol';
import moment from 'moment';
import React from 'react';
import IntlMessages from 'shared/components/IntlMessages';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Stripe from 'stripe';
import useStepperStyles from './StepperStyle';


interface SubscriptionsInvoicesTableProps {
    invoices: {[key: string]: Stripe.Invoice};
}

export default function SubscriptionsInvoicesTable({invoices}: SubscriptionsInvoicesTableProps) {

    const invoicesList: Stripe.Invoice[] = Object.values(invoices)
    const classes = useStepperStyles();
    const invoicesNumberList = (invoices: Stripe.Invoice[]) => {
        return (
            <List className={classes.list}>
                <ListItem>
                    <Typography className={classes.bold}>
                        <IntlMessages id='subscriptions.invoice.table.invoices_number' />
                    </Typography>
                </ListItem>
                {invoices.map((invoice, index) => (
                    <ListItem key={index} className={classes.fitContent}>
                        {invoice.number ? invoice.number : invoice.id}
                    </ListItem>
                ))}
            </List>
        )
    }
    const invoicesPeriodeStart = (invoices: Stripe.Invoice[]) => {

        return (
            <List className={classes.list}>
                <ListItem>
                    <Typography className={classes.bold}>
                        <IntlMessages id='subscriptions.invoice.table.periode_start' />
                    </Typography>
                </ListItem>
                {invoices.map((invoice, index) => {
                    const invoicePeriodStart = invoice.lines.data[0].period.start
                    const startDate = moment.unix(invoicePeriodStart).utc().format('DD/MM/YYYY')
                    return (
                        <ListItem key={index} className={classes.fitContent}>
                            {startDate}
                        </ListItem>
                    )
                })}
            </List>
        )
    }
    const invoicesPeriodeEnd = (invoices: Stripe.Invoice[]) => {

        return (
            <List className={classes.list}>
                <ListItem>
                    <Typography className={classes.bold}>
                        <IntlMessages id='subscriptions.invoice.table.periode_end' />
                    </Typography>
                </ListItem>
                {invoices.map((invoice, index) => {
                    const invoicePeriodEnd = invoice.lines.data[0].period.end
                    const endDate = moment.unix(invoicePeriodEnd).utc().format('DD/MM/YYYY')
                    return (
                        <ListItem key={index} className={classes.fitContent}>
                            {endDate}
                        </ListItem>
                    )
                })}
            </List>
        )
    }
    const invoicesAmount = (invoices: Stripe.Invoice[]) => {

        return (
            <List className={classes.list}>
                <ListItem>
                    <Typography className={classes.bold}>
                        <IntlMessages id='subscriptions.invoice.table.amount' />
                    </Typography>
                </ListItem>
                {invoices.map((invoice, index) => {
                    const amountDue = (invoice.amount_due / 100).toFixed(2)
                    return (
                        <ListItem key={index} className={classes.fitContent}>
                            {`${amountDue} ${currencySymbol(invoice.currency)}`}
                        </ListItem>
                    )
                })}
            </List>
        )
    }
    const invoicesDownload = (invoices: Stripe.Invoice[]) => {

        return (
            <List className={classes.list}>
                <ListItem>
                    <Typography className={classes.bold}>
                        <IntlMessages id='subscriptions.invoice.table.download' />
                    </Typography>
                </ListItem>
                {invoices.map((invoice, index) => (
                    <Link href={invoice.invoice_pdf ? invoice.invoice_pdf : ""} target="_blank" key={index}>
                        <SystemUpdateAltIcon color='primary' />
                    </Link>
                )
                )}
            </List>
        )
    }
    return (
        <Box display='flex' justifyContent='space-between'>
            {invoicesNumberList(invoicesList)}
            {invoicesPeriodeStart(invoicesList)}
            {invoicesPeriodeEnd(invoicesList)}
            {invoicesAmount(invoicesList)}
            {invoicesDownload(invoicesList)}
        </Box>
    )
}