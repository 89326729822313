import {cropsTranslationsEn} from 'modules/crops/translations/CropsTranslationsEn';
import enMessages from '../locales/en_US.json';
import {enUS} from '@mui/material/locale';
import {soilsTranslationsEn} from 'modules/soils/translations/SoilsTranslationsEn';
import {enReportTranslations} from 'modules/report/translations/EnReportTranslations';

const EnLang = {
  messages: {
    ...enMessages,
    ...cropsTranslationsEn,
    ...soilsTranslationsEn,
    ...enReportTranslations,
  },
  muiLocale: enUS,
  locale: 'en-US',
};
export default EnLang;
