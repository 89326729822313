import React, {useEffect, useState} from 'react';
import HeaderButton from 'shared/components/layout/HeaderButton';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {ServiceId} from 'shared/models/RouterParam';
import VegetationIcon from './icons/VegetationIcon';
import useServiceSelected from 'shared/hooks/useServiceSelected';
import navigationService from 'shared/services/NavigationService';
import {DEFAULT_VEGETATION_VUE} from '../configs/VegetationRoutePaths';
import useCurrentCampaign from 'modules/campaigns/hook/useCurrentCampaign';

interface VegetationHeaderButtonProps {
  alwaysShowTitle?: boolean;
}
const VegetationHeaderButton: React.FC<VegetationHeaderButtonProps> = (
  props,
) => {
  const selected = useServiceSelected(ServiceId.VEGETATION);
  const currentCampaign = useCurrentCampaign()

  const [path, setPath] = useState<string>('');
  const {selectedFieldId, selectedCampaign} = useSelector<
    AppState,
    AppState['fields']
  >(({fields}) => fields);

  useEffect(() => {
    let campaignId: number | null = null

    if (selectedCampaign) {
      campaignId = selectedCampaign
    } else {
      campaignId = currentCampaign
    }

      const firstPath = navigationService.getFirstServiceUrl(
        campaignId,
        selectedFieldId,
        ServiceId.VEGETATION,
        DEFAULT_VEGETATION_VUE,
      );
      setPath(firstPath);
  }, [selectedFieldId, selectedCampaign, currentCampaign]);

  return (
    <>
      <HeaderButton
        path={path}
        titleId='vegetation.headerButton.title'
        icon={<VegetationIcon selected={selected} />}
        alwaysShowTitle={props.alwaysShowTitle}
        selected={selected}
        serviceId={ServiceId.VEGETATION}
      />
    </>
  );
};

export default VegetationHeaderButton;
