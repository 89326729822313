import {DateTime} from 'luxon';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import * as yup from 'yup';

export const nitrogenValidationSchema = () => {
  return {
    sowing_date: yup
      .string()
      .required(
        appIntl().formatMessage({id: 'fields.validation.nitrogen.sowingDate'}),
      ),
    destruction_date: yup
      .string()
      .test(
        'isLater',
        appIntl().formatMessage({
          id: 'fields.validation.nitrogen.destructionDate.later',
        }),
        function (val) {
          if (val) {
            const startDate = this.parent.sowing_date;
            const sowingDate = DateTime.fromISO(startDate);
            const destructionDate = DateTime.fromISO(val);
            return sowingDate < destructionDate;
          }
          return false;
        },
      )
      .test(
        'max',
        appIntl().formatMessage({
          id: 'fields.validation.nitrogen.destructionDate.max',
        }),
        function (val) {
          if (val) {
            const startDate = this.parent.sowing_date;
            const sowingDate = DateTime.fromISO(startDate);
            const destructionDate = DateTime.fromISO(val);
            const maxDestructionDate = sowingDate.plus({days: 364});
            return destructionDate <= maxDestructionDate;
          }
          return false;
        },
      )

      .required(
        appIntl().formatMessage({
          id: 'fields.validation.nitrogen.destructionDate',
        }),
      ),
    residue_management: yup.string().required(
      appIntl().formatMessage({
        id: 'fields.validation.nitrogen.residuManagement',
      }),
    ),
    covercrop_exploitation: yup.string().required(
      appIntl().formatMessage({
        id: 'fields.validation.nitrogen.covercropExploitation',
      }),
    ),
    target_n_quantity: yup.number(),
    covercrop_composition: yup
      .array()
      .of(
        yup.object().shape({
          /*species_name: yup.string().required(
            appIntl().formatMessage({
              id: 'fields.validation.nitrogen.species.name',
            }),
          ),*/
          cc_species_id: yup
            .number()
            .moreThan(
              0,
              appIntl().formatMessage({
                id: 'fields.validation.nitrogen.species.name',
              }),
            )
            .required(
              appIntl().formatMessage({
                id: 'fields.validation.nitrogen.species.name',
              }),
            ),
          cc_species_proportion: yup
            .number()
            .moreThan(
              0,
              appIntl().formatMessage({
                id: 'fields.validation.nitrogen.species.proportion_sup_zero',
              }),
            )
            .max(
              1,
              appIntl().formatMessage({
                id: 'fields.validation.nitrogen.species.proportion_max_one',
              }),
            )
            .required(
              appIntl().formatMessage({
                id: 'fields.validation.nitrogen.species.proportion',
              }),
            ),
        }),
      )
      .test(
        'sum',
        appIntl().formatMessage({
          id: 'fields.validation.nitrogen.covercrop_composition.sum',
        }),
        function (array) {
          let sum = 0;
          if (array && array.length) {
            array.forEach((compo) => {
              if (compo?.cc_species_proportion) {
                sum += compo.cc_species_proportion;
              }
            });
          }
          if (Math.round(sum * 10000) !== 10000) {
            return false;
          } else {
            return true;
          }
        },
      )
      .required(
        appIntl().formatMessage({
          id: 'fields.validation.nitrogen.covercrop_composition',
        }),
      ),
  };
};
