import log from "shared/services/LogService";
import Stripe from "stripe";
import {DAYS_IN_MONTH, WEEK_IN_MONTH, YEAR_IN_MONTH} from "../configs/constant";
import {Interval} from "../models/IntervalEnum";
import Price from "../models/Price";
import Product from "../models/Product";

/**
 * return the lower price (for the total area / month) between the lowerPrices from each price from a product
 * @param prices 
 * @returns 
 */
export const computeLowerPrice = (product: Product, totalArea: number) => {

    const allLowerPrices: number[] = []
    if (product && product.prices) {
        product.prices.forEach(price => {
            const comparisonNeeded = price.interval !== Interval.MONTH || price.interval_count !== 1;
            let lowerPrice: number = computePrice(price, totalArea)

            if (comparisonNeeded) {
                lowerPrice = reducePriceToOneMonth(lowerPrice, price.interval_count, price.interval)
            }

            allLowerPrices.push(lowerPrice)
        })
        // compare all lowerPrice from each tiers and return the lower
        allLowerPrices.sort((a, b) => a - b)
        const lowerPrice = allLowerPrices.find(price => price !== 0)
        log.debug(`Lower price for product ${product.ref} (id: ${product.id}): ${lowerPrice ? lowerPrice / 100 : 0}`)
        return lowerPrice ? lowerPrice : 0
    } else {
        log.error(`Cannot define lower price because prices is null or undefined`)
        return 0
    }
}

/**
 * define a price by ha by subscriptions.stepper.price.interval.month for comparison with all prices
 * @param amount 
 * @param interval_count 
 * @param interval 
 * @returns 
 */
export const reducePriceToOneMonth = (amount: number, interval_count: number | null, interval?: Interval | null) => {
    let reduceAmount = amount;
    if (interval_count && interval_count > 1) {
        reduceAmount = reduceAmount / interval_count
    }
    if (interval && interval !== Interval.MONTH) {
        reduceAmount = switchInterval(reduceAmount, interval);
    }

    return reduceAmount
}

/**
 * Compute equivalent price by subscriptions.stepper.price.interval.month
 * @param amount 
 * @param interval 
 * @returns 
 */
const switchInterval = (amount: number, interval: Interval) => {
    switch (interval) {
        case Interval.DAY:
            return amount * DAYS_IN_MONTH;
        case Interval.WEEK:
            return amount * WEEK_IN_MONTH;
        case Interval.YEAR:
            return amount * YEAR_IN_MONTH;
        default:
            return amount
    }
}
/**
* Lower price from a tier
* @param price 
* @returns 
*/
export const computePrice = (price: Price, totalArea: number): number => {
    if (price.billing_scheme === "tiered") {
        const tieredPrice = computeTierPrice(price, totalArea);
        log.debug(`Compute tier price for ${price.ref} (id ${price.id}) and area ${totalArea}: ${tieredPrice / 100}`);
        return tieredPrice;
    } else {
        return price.unit_amount * totalArea;
    }
}


export const computeTierPrice = (price: Price, totalArea: number): number => {
    switch (price.tiers_mode) {
        case "volume":

            let applicableTier = price.tiers.find((tier) => tier.up_to && totalArea < tier.up_to);
            if (!applicableTier) {
                // Take the tier with up_to = null = infinite
                applicableTier = price.tiers.find((tier) => !tier.up_to);
            }
            if (applicableTier) {
                return computeTierVolumePrice(applicableTier, totalArea);
            } else {
                log.error(`Invalid price tiers for price ${price.id} (ref ${price.ref})`);
                return 0;
            }

        case "graduated":

            let computedPrice = 0;
            let previousUpTo: number | null = 0;
            // we suppose that the tiers are sorted
            for (let iTier = 0; iTier < price.tiers.length; iTier++) {
                if (previousUpTo == null || totalArea <= previousUpTo) {
                    break;
                }
                const tier = price.tiers[iTier];
                let unitQuantity = 0;
                if (tier.up_to && totalArea >= tier.up_to) {
                    unitQuantity = tier.up_to;
                } else {
                    unitQuantity = totalArea - (previousUpTo ? previousUpTo : 0)
                }
                //console.log(`Quantity ${unitQuantity} for tier ${tier.unit_amount}`)
                computedPrice += ((tier.flat_amount ? tier.flat_amount : 0) + (tier.unit_amount ? tier.unit_amount : 0)*unitQuantity)
                previousUpTo = tier.up_to;
            }
            return computedPrice;

        default:
            log.error(`Not supported price tiers mode ${price.tierMode} for price ${price.id} (ref ${price.ref})`);
            return 0;
    }
}

export const computeTierVolumePrice = (tier: Stripe.Price.Tier, totalArea: number): number => {
    let totalPrice = 0;
    if (tier.flat_amount) {
        totalPrice += tier.flat_amount;
    }
    if (tier.unit_amount) {
        totalPrice += tier.unit_amount * totalArea;
    }
    return totalPrice;
}

