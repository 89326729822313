import moment from "moment";
import {appIntl} from "shared/utils/IntlGlobalProvider";
import Campaign from "../models/Campaign";

export const getCampaignTranslation = (campaign: Campaign): string => {
    if (campaign && campaign.campaign_start && campaign.campaign_end) {
        const intl = appIntl();
        const startMoment = moment(campaign.campaign_start);
        const endMoment = moment(campaign.campaign_end);
        return intl.formatMessage({id: "campaigns.label_template"}, {start_year: startMoment.format("YYYY"), end_year: endMoment.format("YYYY")});
    }
    return ""
}