import {CarbonPeriod} from '../models/CarbonPeriod';
import {CarbonThreshold} from '../models/CarbonThreshold';
import {SummaryCarbonServiceInfo} from '../models/SummaryCarbonServiceInfo';

export const SAVE_SUMMARY_CARBON_INFO = 'SAVE_SUMMARY_CARBON_INFO';
export interface SaveSummaryCarbonInfoAction {
  type: typeof SAVE_SUMMARY_CARBON_INFO;
  payload: SummaryCarbonServiceInfo;
}

export const LOAD_CARBON_PERIODS = 'LOAD_CARBON_PERIODS';
export interface LoadCarbonPeriodsAction {
  type: typeof LOAD_CARBON_PERIODS;
  payload: {
    fieldId: number;
    periodId: number;
  };
}
export const LOAD_CARBON_PERIODS_SUCCESS = 'LOAD_CARBON_PERIODS_SUCCESS';
export interface LoadCarbonPeriodsSuccessAction {
  type: typeof LOAD_CARBON_PERIODS_SUCCESS;
  payload: {[key: string]: CarbonPeriod};
}
export const ON_SELECT_PERIOD = 'ON_SELECT_PERIOD';
export interface OnSelectPeriodAction {
  type: typeof ON_SELECT_PERIOD;
  payload: {
    periodId: number | null
    campaignId: number | null
  }
}

export const LOAD_CARBON_THRESHOLDS = "LOAD_CARBON_THRESHOLDS"
export interface LoadCarbonThresholdsAction {
  type: typeof LOAD_CARBON_THRESHOLDS
}
export const LOAD_CARBON_THRESHOLDS_SUCCESS = "LOAD_CARBON_THRESHOLDS_SUCCESS"
export interface LoadCarbonThresholdsSuccessAction {
  type: typeof LOAD_CARBON_THRESHOLDS_SUCCESS
  payload: CarbonThreshold[]
}

export const RESET_CARBON_DATA = 'RESET_CARBON_DATA';
export interface ResetCarbonDataAction {
  type: typeof RESET_CARBON_DATA
}

export type CarbonActionsTypes =
  | SaveSummaryCarbonInfoAction
  | LoadCarbonPeriodsAction
  | LoadCarbonPeriodsSuccessAction
  | OnSelectPeriodAction
  | LoadCarbonThresholdsAction
  | LoadCarbonThresholdsSuccessAction
  | ResetCarbonDataAction;

export const carbonActions = {
  resetCarbonData: (): ResetCarbonDataAction => {
    return {
      type: RESET_CARBON_DATA
    }
  },
  
  loadCarbonThresholds: (): LoadCarbonThresholdsAction => {
    return {
      type: LOAD_CARBON_THRESHOLDS
    }
  },
  loadCarbonThresholdsSuccess: (thresholds: CarbonThreshold[]): LoadCarbonThresholdsSuccessAction => {
    return {
      type: LOAD_CARBON_THRESHOLDS_SUCCESS,
      payload: thresholds
    }
  },
  onSelectPeriod: (periodId: number | null, campaignId: number | null): OnSelectPeriodAction => {
    return {
      type: ON_SELECT_PERIOD,
      payload: {
        periodId: periodId,
        campaignId: campaignId
      },
    };
  },
  saveSummaryCarbonInfo: (
    infos: SummaryCarbonServiceInfo,
  ): SaveSummaryCarbonInfoAction => {
    return {
      type: SAVE_SUMMARY_CARBON_INFO,
      payload: infos,
    };
  },

  loadCarbonPeriods: (
    fieldId: number,
    periodId: number,
  ): LoadCarbonPeriodsAction => {
    return {
      type: LOAD_CARBON_PERIODS,
      payload: {
        fieldId: fieldId,
        periodId: periodId,
      },
    };
  },

  loadCarbonSuccess: (periods: {
    [key: string]: CarbonPeriod;
  }): LoadCarbonPeriodsSuccessAction => {
    return {
      type: LOAD_CARBON_PERIODS_SUCCESS,
      payload: periods,
    };
  },
};

export default carbonActions;
