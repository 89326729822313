import {Box, Checkbox, FormControlLabel, FormGroup, List, ListItem, Typography} from '@mui/material';
import React from 'react';
import Field from '../../fields/models/Field';
import useStepperStyles from 'modules/subscriptions/components/StepperStyle';
import IntlMessages from 'shared/components/IntlMessages';
import {computeTotalAreaHa} from 'modules/fields/services/FieldsService';
import {AREA_UNIT} from 'modules/products/configs/constant';
import Price from 'modules/products/models/Price';
import Product from 'modules/products/models/Product';
import SubscriptionPaymentMethod from '../models/SubscriptionPaymentMethod';
import {useIntl} from 'react-intl';
import {AppState} from 'shared/store';
import {useSelector} from 'react-redux';
import {computePrice} from 'modules/products/services/computedLowerPrice';
import {priceToDisplay} from 'modules/products/services/currencySymbol';
import {priceName} from 'modules/products/components/OfferCard';
import {isFreeTrial} from 'modules/products/services/productService';

interface SubscriptionSummaryStepProps {
    selectedFields: Field[];
    selectedPrice: Price;
    selectedProduct: Product;
    paymentMethod: SubscriptionPaymentMethod;
    onPaymentMethodChange: (paymentMethod: SubscriptionPaymentMethod) => void;
};

export default function SubscriptionSummaryStep({selectedFields, selectedPrice, selectedProduct, paymentMethod, onPaymentMethodChange}: SubscriptionSummaryStepProps) {

    const classes = useStepperStyles()
    const intl = useIntl();
    const {customer} = useSelector<AppState, AppState['auth']>(
        (state) => state.auth,
      );
    
    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            onPaymentMethodChange(event.target.name as SubscriptionPaymentMethod);
        }
      };

    const switchDataToDisplay = (type: "totalFields" | "totalArea" | "priceName" | "totalPrice") => {
        switch (type) {

            case "totalFields":
                return (
                    <ListItem className={classes.listItem}>
                        <Box className={classes.listItemText}>
                            <Typography className={classes.fieldNumber}>
                                {selectedFields.length}
                                <IntlMessages id='subscriptions.stepper.step_two.field' />
                            </Typography>
                        </Box>
                    </ListItem>
                );

            case "totalArea":
                return (
                    <ListItem>
                        <Box className={classes.listItemText}>
                            <Typography className={classes.primaryColor}>
                                {`${computeTotalAreaHa(selectedFields).toFixed(0)} ${AREA_UNIT}`}
                            </Typography>
                        </Box>
                    </ListItem>
                )

            case "priceName":
                    return (
                        <ListItem className={classes.listItem}>
                            <Box className={classes.listItemText}>
                                <Typography className={classes.fieldNumber}>
                                    {priceName(selectedProduct?.name ?? "", selectedPrice, intl)}
                                </Typography>
                            </Box>
                        </ListItem>
                    );
            
            case "totalPrice":
                return (
                    <ListItem>
                        <Box className={classes.listItemText}>
                            <Typography className={classes.primaryColor}>
                                {`${priceToDisplay(computePrice(selectedPrice, computeTotalAreaHa(selectedFields)), selectedPrice.currency)}`}
                            </Typography>
                        </Box>
                    </ListItem>
                )
            default:
                return null
        }
    }

    const list = (title: string, dataToDisplay: "totalFields" | "totalArea" | "priceName" | "totalPrice") => {
        return (
            <List>
                <ListItem className={classes.listItem}>
                    <Box className={classes.listItemText}>
                        <Typography variant='subtitle1' className={classes.titleListItem}>
                            <IntlMessages id={title} />
                        </Typography>
                    </Box>
                </ListItem>
                {switchDataToDisplay(dataToDisplay)}
            </List>
        )
    }


    return (
        <>
            <Box className={classes.listContainer}>
                {selectedFields.length ?
                    (
                        <>
                            <Box className={classes.sideList}>
                                {list("subscriptions.stepper.step_two.sum_field.title", "totalFields")}
                            </Box>
                            <Box className={classes.sideList}>
                                {list("subscriptions.stepper.step_two.sum_area.title", "totalArea")}
                            </Box>
                            <Box className={classes.sideList}>
                                {list("subscriptions.stepper.step_five.summary_price_name.title", "priceName")}
                            </Box>
                            <Box className={classes.sideList}>
                                {list("subscriptions.stepper.step_five.sumary_total_price.title", "totalPrice")}
                            </Box>
                        </>
                    ) : (
                        <Typography variant='subtitle1' className={classes.fallBack}>
                            <IntlMessages id="subscriptions.stepper.step_two.fall_back.no_field_selected" />
                        </Typography>
                    )}
            </Box>
            {!isFreeTrial(selectedProduct) && <Box mt={6} mb={2}>
                <Typography variant='h6' className={classes.stepTitle}>
                    <IntlMessages id="subscriptions.stepper.step_five.summary_payment_mode_title" />
                </Typography>
                <FormGroup>
                    <FormControlLabel
                        control={
                        <Checkbox checked={paymentMethod === SubscriptionPaymentMethod.CHECKOUT} onChange={handlePaymentMethodChange} name={SubscriptionPaymentMethod.CHECKOUT} />
                        }
                        label={intl.formatMessage({id: "subscriptions.stepper.step_five.summary_payment_mode_checkout_label"})}
                    />
                    <FormControlLabel
                        control={
                        <Checkbox 
                        disabled={!customer?.allow_subscribe_without_checkout}
                        checked={paymentMethod === SubscriptionPaymentMethod.INVOICE} onChange={handlePaymentMethodChange} name={SubscriptionPaymentMethod.INVOICE} />
                        }
                        label={intl.formatMessage({id: customer?.allow_subscribe_without_checkout ? "subscriptions.stepper.step_five.summary_payment_mode_invoice_label" : "subscriptions.stepper.step_five.summary_payment_mode_invoice_disabled_label"})}
                    />
                </FormGroup>
            </Box>}
        </>
    )
}

