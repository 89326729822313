import React from 'react';
import {ServiceView} from 'shared/models/RouterParam';
import VegetationHistoryHelp from './VegetationHistoryHelp';
import VegetationCompareDateHelp from './VegetationCompareDateHelp';
import VegetationCompareFieldHelp from './VegetationCompareFieldHelp';

interface VegetationHelpProps {
  /**
   * to manage which part of the information dialog is shown
   */
  selectedInfo: string;

  onActiveStepChange: (i: number) => void;

  step: number;
}

/**
 * Component to display the totality or different part of the information dialog
 * Contains a button to navigate between the different part of the dialog
 */
const VegetationHelp: React.FC<VegetationHelpProps> = ({
  selectedInfo,
  onActiveStepChange,
  step,
}) => {
  React.useEffect(() => {
    switch (selectedInfo) {
      case ServiceView.VEGETATION_DEVELOPPMENT:
        onActiveStepChange(0);
        break;
      case ServiceView.DATE_COMPARISON:
        onActiveStepChange(1);
        break;
      case ServiceView.FIELD_COMPARISON:
        onActiveStepChange(2);
        break;
    }
  }, [selectedInfo, onActiveStepChange]);

    const pageShown = (step: number) => {
      switch (step) {
        case 0:
          return <VegetationHistoryHelp />;
        case 1:
          return <VegetationCompareDateHelp />;
        case 2:
          return <VegetationCompareFieldHelp />;
        default:
          return <VegetationHistoryHelp />;
      }
    };


  return pageShown(step);
};

export default VegetationHelp;
