import {call, put, takeEvery, all, delay} from 'redux-saga/effects';
import {
  LOAD_CROPS_LISTING,
  ADD_NEW_CROP,
  DELETE_CROP,
  MODIFY_CROP,
  AddNewCropAction,
  DeleteCropAction,
  ModifyCropAction,
} from '../actions/CropsActions';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions/Common';
import api, {pixagriApiUrl} from 'shared/services/ApiConfig';
import CropModel from '../models/CropModel';
import actions from '../actions/CropsActions';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import getCropFromNameMatch from '../configs/CropIconsConfig';
import {timeoutDuration} from 'shared/configs/AppConst';

function getCropsListingRequest() {
  const cropsUrl = `${pixagriApiUrl}/crops`;
  return api.get(cropsUrl);
}

function* loadCropsListing() {
  try {
    yield put(fetchStart('loadCropsListing'));
    const res = yield call(getCropsListingRequest);
    const cropsListing = res.data as CropModel[];
    const cropsMap: {[key: string]: CropModel} = {};
    cropsListing.forEach((value) => {
      if (value.crop_id !== undefined) {
        cropsMap[value.crop_id] = {
          ...value,
          crop_icon: getCropFromNameMatch(value.crop_name),
        };
      }
    });

    yield put(actions.loadCropsListingSuccessAction(cropsMap));
    yield put(fetchSuccess('loadCropsListing'));
  } catch (error) {
    yield put(fetchError('loadCropsListing', error.message));
  }
}

function addNewCropRequest(newCrop: CropModel) {
  const cropUrl = `${pixagriApiUrl}/crops`;
  return api.post(cropUrl, newCrop);
}

function* addNewCrop(action: AddNewCropAction) {
  try {
    yield put(fetchStart('addNewCrop'));
    const newCrop: CropModel = action.payload;
    const {farmerIdsList, ...newCropToSend} = newCrop;
    if (!farmerIdsList || !farmerIdsList.length) {
      yield call(addNewCropRequest, newCropToSend);
    } else {
      for (let id of farmerIdsList) {
        const cropForFarmer: CropModel = {
          ...newCropToSend,
          farmer_id: id,
        };
        yield call(addNewCropRequest, cropForFarmer);
      }
    }

    yield put(fetchSuccess('addNewCrop'));

    yield call(reloadCropsListingAfterDelay);
  } catch (error) {
    yield put(fetchError('addNewCrop', error.message));
  }
}

function deleteCropRequest(selectedCrop: number) {
  const cropUrl = `${pixagriApiUrl}/crops/${selectedCrop}`;
  return api.delete(cropUrl);
}

function* deleteCrop(action: DeleteCropAction) {
  try {
    yield put(fetchStart('deleteCrop'));
    yield call(deleteCropRequest, action.payload);
    yield put(fetchSuccess('deleteCrop'));

    yield call(reloadCropsListingAfterDelay);
  } catch (error) {
    let errorMessage = error.message;
    if (error.response && error.response.status === 409) {
      errorMessage = appIntl().formatMessage({id: 'crops.delete.error409'});
    }
    yield put(fetchError('deleteCrop', errorMessage));
  }
}

function modifyCropRequest(modifiedCrop: CropModel) {
  const selectedCrop: number | undefined = modifiedCrop.crop_id;
  const cropUrl = `${pixagriApiUrl}/crops/${selectedCrop}`;
  return api.put(cropUrl, modifiedCrop);
}

function* modifyCrop(action: ModifyCropAction) {
  try {
    yield put(fetchStart('modifyCrop'));
    yield call(modifyCropRequest, action.payload);
    yield put(fetchSuccess('modifyCrop'));

    yield call(reloadCropsListingAfterDelay);
  } catch (error) {
    yield put(fetchError('modifyCrop', error.message));
  }
}

function* reloadCropsListingAfterDelay(delayMultiplier: number = 1) {
  // Wait for some times
  yield delay(timeoutDuration * delayMultiplier);

  //reload crops
  yield put(actions.loadCropsListing());
}

export function* cropsSagas() {
  yield all([
    takeEvery(LOAD_CROPS_LISTING, loadCropsListing),
    takeEvery(ADD_NEW_CROP, addNewCrop),
    takeEvery(DELETE_CROP, deleteCrop),
    takeEvery(MODIFY_CROP, modifyCrop),
  ]);
}
