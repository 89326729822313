import {
  getSelectedFieldByServiceFullRoute,
  getServiceOverviewFullRoute,
} from 'modules/fields/configs/FieldsRoutePaths';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

class NavigationService {
  /**
   * Get pathname to redirect when a new service is selected
   * @param campaignId
   * @param serviceId
   * @param viewId default Vue
   * @param fieldId
   * @returns
   */
  getFirstServiceUrl(
    campaignId: number,
    fieldId: number | null,
    serviceId: ServiceId,
    viewId?: ServiceView,
  ): string {
    if (fieldId && viewId) {
      return getSelectedFieldByServiceFullRoute(
        campaignId.toString(),
        fieldId.toString(),
        serviceId,
        viewId,
      );
    } else {
      return getServiceOverviewFullRoute(campaignId.toString(), serviceId, ServiceView.MAP);
    }
  }
}

const navigationService = new NavigationService();

export default navigationService;
