import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {UserPermission} from '../models/UserPermission';
import CustomHelpTooltip from 'shared/components/CustomHelpTooltip';
import IntlMessages from 'shared/components/IntlMessages';
import useCheckPermissions from '../hooks/useCheckPermissions';
import {useIntl} from 'react-intl';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 220,
    border: '1px solid #dadde9',
  },
}))(Tooltip);


interface CheckAllowedButtonProps extends ButtonProps {
  allowedPermissions?: UserPermission | UserPermission[];
  hideNotAllowed?: boolean
  buttonTitleId: string;
  notAllowedTitleId?: string;
  notAllowedInfoId?: string;
  notAllowedActionId?: string;
}

/**
 * Tooltip card used to display legend info or explain disabled feature
 * @param props 
 * @returns 
 */
const CheckAllowedButton: React.FC<CheckAllowedButtonProps> = (props) => {

  const {allowedPermissions, buttonTitleId, notAllowedTitleId, notAllowedInfoId, notAllowedActionId, hideNotAllowed, ...buttonProps} = props;
  const intl = useIntl();

  const allowed = useCheckPermissions(allowedPermissions);
  const renderButton = (disabled: boolean) => {
    return (<Button
      sx={{textTransform: "none"}}
      disabled={disabled}
      {...buttonProps}>
      <IntlMessages id={buttonTitleId} />
    </Button>);
  }

  if (!allowed) {
    if (!hideNotAllowed) {
      return (<HtmlTooltip title={
        <React.Fragment>
          <CustomHelpTooltip noCard
            title={intl.formatMessage({id: notAllowedTitleId ? notAllowedTitleId : "common.not_allowed_title"})}
            info={intl.formatMessage({id: notAllowedInfoId ? notAllowedInfoId : "common.not_allowed_info"})}
            action={intl.formatMessage({id: notAllowedActionId ? notAllowedActionId : "common.not_allowed_action"})} />
        </React.Fragment>
      }
      >
        <Box>
          {renderButton(true)}
        </Box>
      </HtmlTooltip >)
    } else {
      return null;
    }
  } else {
    return renderButton(false)
  }
};

export default CheckAllowedButton;