import * as React from 'react';
import {Typography} from "@mui/material";
import {useHistory} from "react-router";
import IntlMessages from "shared/components/IntlMessages";
import {QUERY_SUBSCRIPTION_CREATE} from "../configs/constant";

interface SubscriptionLinkProps {
}
/**
 * Link to open the subscribe dialog
 * @returns 
 */
export default function SubscriptionLink(props: SubscriptionLinkProps) {

    const history = useHistory();

    const goToSubscriptionStepeper = () => {
        history.push({pathname: history.location.pathname, search: QUERY_SUBSCRIPTION_CREATE})
    }
    return (
        <Typography onClick={goToSubscriptionStepeper} color="primary" style={{fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer'}}>
            <IntlMessages id="subscriptions.subscribe" />
        </Typography>
    )

}