import SoilCode from "../models/SoilCode";
import SoilModel from "../models/SoilModel";

export const getSoilTranslationKey = (soil: SoilModel): string | null => {
    return getSoilTranslationKeyFromCode(soil.soil_code)
}

export const getSoilTranslationKeyFromCode = (soilCode?: SoilCode): string | null => {
    if (soilCode) {
        return `soils.${soilCode.toLowerCase()}.label`;
    } else {
        return null;
    }
}