import log from 'loglevel';
import {
  indexPath,
  selectedCampaignParam,
  selectedCampaignRootPath,
} from 'modules/campaigns/configs/CampaignsRoutePaths';
import {generatePath} from 'react-router';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

/**
 * Campaign fields path
 */
export const selectedCampaignFieldsPath = `${selectedCampaignRootPath}/fields`;

/**
 * Get the campaign field overview path or inital path if the campaign is not defined
 * @param campaignId
 */
export const getCampaignFieldsPath = function (
  campaignId: number | null,
): string {
  if (campaignId) {
    return selectedCampaignFieldsPath.replace(
      `:${selectedCampaignParam}`,
      campaignId.toString(),
    );
  }
  return indexPath;
};

/**
 * Field parameter
 */
export const selectedFieldParam = 'fieldId';

/**
 * Service parametr
 */
export const selectedServiceParam = 'serviceId';

/**
 * Tab parameter
 */
export const selectedFieldVueParam = 'viewId';

/**
 * Path to overview service
 */
export const selectedFieldOverviewPath = `${selectedCampaignFieldsPath}/overview/:${selectedServiceParam}/:${selectedFieldVueParam}`;
export const getServiceOverviewFullRoute = function (
  campaignId: string | number,
  serviceId: ServiceId,
  viewId?: ServiceView
): string {
  let finalView = viewId;
  if(!viewId) {
    log.debug('viewId not defined, defaulting to MAP')
    finalView = ServiceView.MAP;
  }
  return generatePath(selectedFieldOverviewPath, {
    campaignId: campaignId,
    serviceId: serviceId,
    viewId: finalView
  });
};

/**
 * Path for selected field
 */
export const selectedFieldRootPath = `${selectedCampaignFieldsPath}/:${selectedFieldParam}`;
export const selectedFieldWithService = `${selectedFieldRootPath}/:${selectedServiceParam}`;
export const selectedFieldPathByService = `${selectedFieldRootPath}/:${selectedServiceParam}/:${selectedFieldVueParam}`;
export const getSelectedFieldByServiceFullRoute = function (
  campaignId: string,
  fieldId: string,
  serviceId: ServiceId,
  viewId: ServiceView,
): string {
  return generatePath(selectedFieldPathByService, {
    campaignId: campaignId,
    fieldId: fieldId,
    serviceId: serviceId,
    viewId: viewId,
  });
};
