import React from 'react';
import {Box, BoxProps, Card, Typography} from "@mui/material";

interface CustomHelpTooltipProps extends BoxProps {
    title: string;
    info: string;
    action?: string;
    noCard?: boolean;
}

/**
 * Tooltip card used to display legend info or explain disabled feature
 * @param props 
 * @returns 
 */
const CustomHelpTooltip: React.FC<CustomHelpTooltipProps> = (props) => {

    const {title, info, action, noCard, ...boxProps} = props;

    const renderContent = () => {
        return (<Box p={3} maxWidth={300} display="flex" flexDirection="column" alignContent="center" alignItems="center" justifyContent="center" justifyItems="center">
            <Typography variant="body2" style={{fontWeight: "bold"}} color="primary" align="center">{title}</Typography>
            <Box mt={2}>
                <Typography variant="body2" align="center">{info}</Typography>
            </Box>
            {action && <Box mt={2}>
                <Typography variant="body2" style={{fontWeight: "bold"}} color="primary" align="center">{action}</Typography>
            </Box>}
        </Box>);
    }

    return (
        <Box zIndex="tooltip" {...boxProps}>
            {noCard ?
                renderContent() :
                (
                    <Card>
                        {renderContent()}
                    </Card>
                )
            }
        </Box>
    )
};

export default CustomHelpTooltip;