import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as ArrowSoils} from '../../../assets/images/icons/arrowSoils.svg';

const ArrowSoilsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={ArrowSoils}
      viewBox='0 0 95 42'
    />
  );
};

export default ArrowSoilsIcon;
