import {
  CarbonActionsTypes,
  LOAD_CARBON_PERIODS_SUCCESS,
  LOAD_CARBON_THRESHOLDS_SUCCESS,
  ON_SELECT_PERIOD,
  RESET_CARBON_DATA,
  SAVE_SUMMARY_CARBON_INFO,
} from '../actions/CarbonActions';
import {CarbonState} from '../models/CarbonState';
import {Reducer} from 'redux';

const initialState: CarbonState = {
  summaryCarbonInfo: null,
  periods: null,
  selectedPeriodId: null,
  selectedCampaignAnalysis: null,
  thresholds: null
};

const carbonReducers: Reducer<CarbonState, CarbonActionsTypes> = (
  state: CarbonState = initialState,
  action: CarbonActionsTypes,
): CarbonState => {
  switch (action.type) {
    case RESET_CARBON_DATA: 
      return {
        ...state,
        periods: null,
        selectedPeriodId: null,
        selectedCampaignAnalysis: null,
      }
    case SAVE_SUMMARY_CARBON_INFO:
      return {
        ...state,
        summaryCarbonInfo: action.payload,
      };
    case LOAD_CARBON_PERIODS_SUCCESS:
      return {
        ...state,
        periods: action.payload,
      };
    case ON_SELECT_PERIOD: {
      return {
        ...state,
        selectedPeriodId: action.payload.periodId,
        selectedCampaignAnalysis: action.payload.campaignId
      };
    }
    case LOAD_CARBON_THRESHOLDS_SUCCESS: {
      return {
        ...state,
        thresholds: action.payload
      }
    }
    default:
      return state;
  }
};

export default carbonReducers;
