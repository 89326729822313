import {Theme} from '@mui/material';
import log from 'loglevel';
import {gaugeColors} from '../config/CarbonStyleConfigs';
import {CarbonFlowLevel} from '../models/CarbonFlowLevel';
import {CarbonThreshold} from '../models/CarbonThreshold';

/**
 * Define carbon flow level according to carbon_net_flux value
 * @param value
 * @returns
 */
export function getCarbonFlowLevel(
  value: number,
  thresholds: CarbonThreshold[]
): CarbonFlowLevel {
  const carbonFlowNumber = value;

  const thresholdIndex = thresholds.findIndex(thr => thr.thres_min <= carbonFlowNumber && carbonFlowNumber < thr.thres_max)


  if (thresholdIndex < 0) {
    log.debug(`interval for value ${carbonFlowNumber} not found in carbon thresholds`)
    if (carbonFlowNumber < 0) {
     log.debug(`${carbonFlowNumber} minus than 0 => render ${CarbonFlowLevel.LOW}`) 
      return CarbonFlowLevel.LOW;
    } else {
      log.debug(`${carbonFlowNumber} superior to 0 => render ${CarbonFlowLevel.HIGH}`) 
      return CarbonFlowLevel.LOW;
    }
  } else {
    if (thresholds[thresholdIndex] && thresholds[thresholdIndex].level) { 
      return thresholds[thresholdIndex].level as CarbonFlowLevel
    } else {
      log.error(`Carbon level not fill in ${thresholds[thresholdIndex]}`)
      return CarbonFlowLevel.UNDEFINED
    }
  }
}

/**
 * Get carbon level description according to carbon flow level
 * @param carbonFlowLevel 
 * @returns 
 */
export function getCarbonFlowLevelMessage(carbonFlowLevel: CarbonFlowLevel) {
  switch (carbonFlowLevel) {
    case CarbonFlowLevel.LOW:
      return 'carbon.cover_soil.gauge.label_low'
    case CarbonFlowLevel.LOW_MEDIUM:
      return "carbon.cover_soil.gauge.label_medium_low"
    case CarbonFlowLevel.MEDIUM_HIGH:
      return "carbon.cover_soil.gauge.label_medium_high"
    case CarbonFlowLevel.HIGH:
      return "carbon.cover_soil.gauge.label_high"
    case CarbonFlowLevel.UNDEFINED:
      return "carbon.cover_soil.gauge.label_undefined"
    default:
      return "carbon.cover_soil.gauge.label_undefined"
  }
}
/**
 * Get carbon level color according to carbon flow level
 * @param carbonFlowLevel 
 * @param theme 
 * @returns 
 */
export function getCarbonFlowLevelColor(carbonFlowLevel: CarbonFlowLevel, theme: Theme) {
  switch (carbonFlowLevel) {
    case CarbonFlowLevel.LOW:
      return gaugeColors[0]
    case CarbonFlowLevel.LOW_MEDIUM:
      return gaugeColors[1]
    case CarbonFlowLevel.MEDIUM_HIGH:
      return gaugeColors[2]
    case CarbonFlowLevel.HIGH:
      return gaugeColors[3]
    case CarbonFlowLevel.UNDEFINED:
      return theme.palette.grey[400]
    default:
      return theme.palette.grey[400]
  }
}

export function computeCarbonPercent(value: number, thresholds: CarbonThreshold[]): number {
  const number = value;
  const minThres = thresholds[0].thres_min as number
  const maxThres = thresholds[thresholds.length - 1].thres_max as number

  if (number < minThres) {
    return 0
  } else if (number > maxThres) {
    return 1
  } else {
  }
  const minValue = minThres
  const maxValue = maxThres
  const diff = maxValue - minValue;
  return (number-minValue)/diff
  
}

export function getCarbonArcLength(thresholds: CarbonThreshold[]): number[] {
  const minValue = thresholds[0].thres_min as number
  const maxValue = thresholds[thresholds.length - 1].thres_max as number
  const diff = maxValue - minValue;

  const levelLengthList: number[] = []

  thresholds.forEach(thres => {
    const min = thres.thres_min as number
    const max = thres.thres_max as number
    const levelDiff = max - min
    const percent = levelDiff / diff
    levelLengthList.push(percent)
  })
  return levelLengthList
}
