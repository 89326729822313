import {Box, FormControl, InputAdornment, InputLabel, Link, OutlinedInput, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Field from 'modules/fields/models/Field';
import {ProductsState} from 'modules/products/models/ProductsState';
import moment from 'moment';
import * as React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import {CremaTheme} from 'shared/models/AppContextPropsType';
import {AppState} from 'shared/store';
import {QUERY_SUBSCRIPTION_DISPLAY} from '../configs/constant';
import SubscriptionStatusIcon from './icons/SubscriptionStatusIcon';
import SubscriptionLink from 'modules/subscriptions/components/SubscribeLink';
import {useHistory} from 'react-router';
import clsx from 'clsx';
import useActiveSubscription from '../hooks/useActiveSubscription';
import {getProduct} from 'modules/products/services/productService';
import {hasFieldSubscriptionsToService} from '../services/isFieldSubscribe';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    infosCard: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
    },
    infosTextBox: {
      width: '100%',
      padding: theme.spacing(2),
    },
    infosLabel: {
      padding: theme.spacing(3),
    },
    infosTextField: {
      width: '100%',
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    bold: {
      fontWeight: 'bold',
    },
    subtitle: {
      paddingBottom: theme.spacing(1),
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

interface SubscriptionFieldInfoProps {
  selectedFieldElement?: Field;
  isInput?: boolean;
}
export default function SubscriptionFieldInfo({
  selectedFieldElement,
  isInput,
}: SubscriptionFieldInfoProps) {
  const classes = useStyles();
  const {messages} = useIntl();
  const {selectedFieldId, fieldsList, selectedService} = useSelector<
    AppState,
    AppState['fields']
  >(({fields}) => fields);
  const {products} = useSelector<AppState, AppState>((products) => products);

  const history = useHistory();

  const activeSubscription = useActiveSubscription();

  const subscriptionTextFieldRender = (
    selectedFieldElement: Field,
    products: ProductsState,
  ) => {
    if (
      selectedService &&
      activeSubscription &&
      activeSubscription.subscription_id &&
      hasFieldSubscriptionsToService(selectedFieldElement, selectedService)
    ) {
      const product = getProduct(activeSubscription.product_ref, products);
      const productName = product
        ? product.name
        : messages['subscription.subscription'];
      return productName;
    } else {
      return messages['subscription.no_subcriptions_on_field'];
    }
  };

  const handleQuerySubscriptions = (query: string) => {
    history.push({pathname: history.location.pathname, search: query});
  };

  const endAdornement = (selectedFieldElement: Field) => {
    if (
      selectedService &&
      activeSubscription &&
      activeSubscription.subscription_id &&
      hasFieldSubscriptionsToService(selectedFieldElement, selectedService)
    ) {
      const now = moment();
      if (activeSubscription.cancel_at) {
        const endTime = moment.unix(activeSubscription.cancel_at);
        const diff = endTime.diff(now, 'days');
        return (
          <InputAdornment position='end'>
            {diff > 0 ? (
              <Link
                onClick={() =>
                  handleQuerySubscriptions(QUERY_SUBSCRIPTION_DISPLAY)
                }
                className={clsx(
                  classes.primaryColor,
                  classes.bold,
                  classes.pointer,
                )}>
                <IntlMessages id={'subscription.field_info.expire_in'} />
                {` ${diff} `}
                <IntlMessages id={'subscriptions.interval.days'} />
              </Link>
            ) : (
              <Link
                onClick={() =>
                  handleQuerySubscriptions(QUERY_SUBSCRIPTION_DISPLAY)
                }
                className={clsx(
                  classes.primaryColor,
                  classes.bold,
                  classes.pointer,
                )}>
                <IntlMessages id='subscription.field_info.expired_since' />
                {moment.unix(activeSubscription.cancel_at).format('DD/MM/YYYY')}
              </Link>
            )}
          </InputAdornment>
        );
      }
    } else {
      return (
        <InputAdornment position='end'>
          <SubscriptionLink />
        </InputAdornment>
      );
    }
  };

  const startAdornement = (selectedFieldElement: Field) => {
    if (
      activeSubscription &&
      selectedService &&
      hasFieldSubscriptionsToService(selectedFieldElement, selectedService)
    ) {
      return (
        <InputAdornment position='start'>
          <SubscriptionStatusIcon
            fontSize='small'
            status={activeSubscription.status}
          />
        </InputAdornment>
      );
    }
  };
  let selectedField: Field | null = null;
  if (selectedFieldElement) {
    selectedField = selectedFieldElement;
  } else if (fieldsList && fieldsList.length && selectedFieldId) {
    selectedField =
      fieldsList.find((field) => field.field_id === selectedFieldId) || null;
  }
  if (selectedField && products) {
    if (isInput) {
      return (
        <FormControl
          fullWidth
          className={classes.infosTextBox}
          variant='outlined'>
          <InputLabel className={classes.infosLabel} htmlFor='subscription'>
            <IntlMessages id='subscription.subscription' />
          </InputLabel>
          <OutlinedInput
            id='subscription'
            label={<IntlMessages id='subscription.subscription' />}
            className={classes.infosTextField}
            value={
              subscriptionTextFieldRender(selectedField, products)
                ? subscriptionTextFieldRender(selectedField, products)
                : messages['subscriptions.subsctription']
            }
            endAdornment={endAdornement(selectedField)}
            startAdornment={startAdornement(selectedField)}
          />
        </FormControl>
      );
    } else {
      return (
        <Box>
          <Typography
            variant='body1'
            className={clsx(classes.subtitle, classes.bold)}>
            <IntlMessages id='subscription.subscription' />
          </Typography>
          <Box pl={2} pr={2} display='flex' alignItems='center'>
            {startAdornement(selectedField)}
            <Typography style={{flexGrow: 1}}>
              {subscriptionTextFieldRender(selectedField, products)}
            </Typography>
            {endAdornement(selectedField)}
          </Box>
        </Box>
      );
    }
  }
  return null;
}

