import {makeStyles} from '@mui/styles';
import {CremaTheme} from 'shared/models/AppContextPropsType';

/**
 * Minimum height for each selected field tab (irrigation, vegetation, etc.)
 */
export const minFieldTabHeight = "650px";

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    appMain: {
      height: '100%',
      display: 'flex',
    },
    fieldForm: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    fieldFormTextParams: {
      margin: '0.6em 0',
    },
    fieldFormText: {
      width: '100%',
      margin: '0.5em',
    },
    formMapContainer: {
      width: '55%',
    },
    formAddInfos: {
      width: '45%',
    },
    formModifyInfos: {
      width: '100%',
    },
    fieldInfosForm: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(1, 2, 1, 2),
      justifyContent: "flex-start",
      height: "100%"
    },
    validateFieldButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
      margin: '1em',
    },
    dialogPaper: {
      height: '95vh',
      maxWidth: '80vw',
    },
    dialogDualChoice: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    dialogDisplay: {
      height: '80vh',
      margin: '0.5em 0',
    },
    addFieldModalButton: {
      margin: '0.5em',
      width: '100%',
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.background.paper,
    },
    errorPolygon: {
      color: 'red',
      textAlign: 'center',
      margin: '0.5em',
    },
    choiceFormButton: {
      margin: '1em',
    },
  };
});

export default useStyles;
