import {UserPermission} from 'modules/auth/models/UserPermission';
import Field from 'modules/fields/models/Field';
import {isFieldSubscribe} from 'modules/fields/services/FieldsService';
import {ServiceId} from 'shared/models/RouterParam';
import {FREE_TRIAL_KEYWORD} from '../configs/constant';
import PixagriSubscription from '../models/PixagriSubscription';
import {SubscriptionsStatus} from '../models/SubscriptionStatus';

export enum SubscriptionPermissionStatus {
  ACTIVE = 'active',
  NO_PERMISSION = 'no_permission',
  NO_SUBSCRIPTION = 'no_subscription',
}
/**
 * Function use to define if blurredcomponent should be display.
 * there is a double check
 *  => find if field has a subscription for a given service at a given campaign
 *  => check if field has the given permission
 * @param field
 * @param permisionToCheck
 * @param serviceId
 * @param campaignId
 * @returns
 */
export function hasFieldViewPermission(
  field: Field,
  permisionToCheck: UserPermission,
  serviceId: ServiceId,
  campaignId: number,
) {
  const fieldActivePermissions = field.active_permissions;
  const isFieldSubscribeAtCampaign = isFieldSubscribe(
    serviceId,
    campaignId,
    field,
  );
  if (
    fieldActivePermissions &&
    fieldActivePermissions.length &&
    isFieldSubscribeAtCampaign
  ) {
    const permission = fieldActivePermissions.find(
      (perm) => perm === permisionToCheck,
    );
    if (permission) {
      return SubscriptionPermissionStatus.ACTIVE;
    } else {
      return SubscriptionPermissionStatus.NO_PERMISSION;
    }
  } else {
    return SubscriptionPermissionStatus.NO_SUBSCRIPTION;
  }
}

export function getServiceRole(serviceId: ServiceId) {
  return `field.${serviceId}`;
}

export const findFreeTrialSubscription = (
  subscriptionsList: PixagriSubscription[],
  selectedService: ServiceId,
  onlyIfNoActiveOther: boolean,
): PixagriSubscription | undefined => {
  const trialSubscriptions = subscriptionsList.find(
    (subscription) =>
      subscription.price_ref.includes(FREE_TRIAL_KEYWORD) &&
      subscription.roles?.includes(getServiceRole(selectedService)),
  );
  // Check if no other subscription not trial for this service
  if (trialSubscriptions && onlyIfNoActiveOther) {
    //console.log(subscriptionsList)
    const otherSubscriptions = subscriptionsList.find((subscription) => {
      //console.log(`checking subscription ${subscription.sub_id} (${subscription.subscription_id}) with price ${subscription.price_ref} and roles ${subscription.roles}`);
      return (
        !subscription.price_ref.includes(FREE_TRIAL_KEYWORD) &&
        isSubscriptionValid(subscription) &&
        subscription.roles?.includes(getServiceRole(selectedService))
      );
    });
    if (otherSubscriptions) {
      return undefined;
    }
  }
  return trialSubscriptions;
};

export function isSubscriptionValid(
  subscription: PixagriSubscription,
): boolean {
  // Past due subscriptions are still valid
  return (
    subscription.status === SubscriptionsStatus.ACTIVE ||
    subscription.status === SubscriptionsStatus.TRIALING ||
    subscription.status === SubscriptionsStatus.PAST_DUE
  );
}

export function hasFieldSubscriptionsToService(
  field: Field,
  service: ServiceId,
) {
  const fieldActivePermissions = field.active_permissions;
  if (fieldActivePermissions && fieldActivePermissions.length) {
    const serviceSubscription = fieldActivePermissions.find((perm) =>
      perm.includes(getServiceRole(service)),
    );
    if (serviceSubscription) {
      return true;
    }
  }
  return false;
}

export function hasFieldServiceInfoBeforeSubscribe(
  field: Field,
  service: ServiceId,
  currentCampaignId: number,
) {
  switch (service) {
    case ServiceId.IRRIGATION:
      const selectedRotation = field.irrigation.find(
        (rot) => rot.campaign_id === currentCampaignId,
      );
      if (selectedRotation) {
        return true;
      } else {
        return false;
      }
    case ServiceId.NITROGEN:
      const selectedCover = field.nitrogen.find(
        (cover) => cover.campaign_id === currentCampaignId,
      );
      if (selectedCover) {
        return true;
      } else {
        return false;
      }
    case ServiceId.CARBON:
      return true;
    case ServiceId.VEGETATION:
      const selectedVegetation = field.vegetation.find(
        (veg) => veg.campaign_id === currentCampaignId,
      );
      if (selectedVegetation) {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
}
