import {Card, Typography} from '@mui/material';
import {AuthUser} from 'modules/auth/models/AuthUser';
import CarbonMapTooltip from 'modules/carbon/components/CarbonMapTooltip';
import NitrogenMapTooltip from 'modules/nitrogen/components/NitrogenMapTooltip';
import WaterBalanceMapTooltip from 'modules/waterBalance/components/WaterBalanceMapTooltip';
import React from 'react';
import {useSelector} from 'react-redux';
import {ServiceId} from 'shared/models/RouterParam';
import {AppState} from 'shared/store';
import Field from '../models/Field';
import useStyles from './FieldsMap.style';

export interface FieldsMapTooltipProps {
  field: Field;
  campaignId: number;
  position: {
    x: number;
    y: number;
  };
  user: AuthUser | null;
}

/**
 * Tooltip displayed on map switch on service
 * @param props
 * @returns
 */
const FieldsMapTooltip: React.FC<FieldsMapTooltipProps> = (props) => {
  const {field, position, user, campaignId} = props;
  const {selectedService} = useSelector<AppState, AppState['fields']>(
    (state) => state.fields,
  );
  const classes = useStyles();

  const getTooltip = (service: ServiceId) => {
    switch (service) {
      case ServiceId.IRRIGATION:
        return (
          <WaterBalanceMapTooltip
            field={field}
            campaignId={campaignId}
            user={user}
          />
        );
      case ServiceId.VEGETATION:
        return (
          <WaterBalanceMapTooltip
            field={field}
            campaignId={campaignId}
            user={user}
          />
        );
      case ServiceId.CARBON:
        return <CarbonMapTooltip field={field} campaignId={campaignId} />;
      case ServiceId.NITROGEN:
        return <NitrogenMapTooltip field={field} campaignId={campaignId} />;
      default:
        return null;
    }
  };
  if (field && selectedService) {
    return (
      <Card
        className={classes.tooltip}
        style={{left: position.x, top: position.y}}>
        <Typography variant='h6'>{field.field_name}</Typography>
        {getTooltip(selectedService)}
      </Card>
    );
  }
  return null;
};
export default FieldsMapTooltip;
