import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as Info} from '../../../assets/images/icons/infoIcon.svg';

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={Info}
      viewBox='0 0 24 24'
    />
  );
};

export default InfoIcon;
