export interface LaiLegendColorCaseType {
  min: number;
  max: number;
  level: string;
  color: string;
}

const LaiLegendColorCase: LaiLegendColorCaseType[] = [
  {
    min: 0,
    max: 0.33,
    level: '0 - 0.33',
    color: '#a4543e',
  },
  {
    min: 0.33,
    max: 0.67,
    level: '0.33 - 0.67',
    color: '#c4692e',
  },
  {
    min: 0.67,
    max: 1,
    level: '0.67 - 1',
    color: '#e88b20',
  },
  {
    min: 1,
    max: 1.33,
    level: '1 - 1.33',
    color: '#e59719',
  },
  {
    min: 1.33,
    max: 1.67,
    level: '1.33 - 1.67',
    color: '#e8b211',
  },
  {
    min: 1.67,
    max: 2,
    level: '1.67 - 2',
    color: '#f5d513',
  },
  {
    min: 2,
    max: 2.33,
    level: '2 - 2.33',
    color: '#f7d712',
  },
  {
    min: 2.33,
    max: 2.67,
    level: '2.33 - 2.67',
    color: '#dffa0f',
  },
  {
    min: 2.67,
    max: 3,
    level: '2.67 - 3',
    color: '#74e40a',
  },
  {
    min: 3,
    max: 3.33,
    level: '3 - 3.33',
    color: '#60e007',
  },
  {
    min: 3.33,
    max: 3.67,
    level: '3.33 - 3.67',
    color: '#10d104',
  },
  {
    min: 3.67,
    max: 4,
    level: '3.67 - 4',
    color: '#12bd4f',
  },
  {
    min: 4,
    max: 4.33,
    level: '4 - 4.33',
    color: '#1ba96d',
  },
  {
    min: 4.33,
    max: 4.67,
    level: '4.33 - 4.67',
    color: '#1f9f7f',
  },
  {
    min: 4.67,
    max: 5,
    level: '4.67 - 5',
    color: '#1e888a',
  },
  {
    min: 5,
    max: 5.33,
    level: '5 - 5.33',
    color: '#1d7a8a',
  },
  {
    min: 5.33,
    max: 5.67,
    level: '5.33 - 5.67',
    color: '#0f557f',
  },
  {
    min: 5.67,
    max: 6,
    level: '5.67 - 6',
    color: '#0f2949',
  },
];

export default LaiLegendColorCase;
