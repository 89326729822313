import React from 'react';
import {actions} from '../../actions/AuthActions';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from 'shared/store/index';
import * as yup from 'yup';
import IntlMessages from 'shared/components/IntlMessages';
import {Box, Button} from '@mui/material';
import {ModifyUserInfoModel} from '../../models/ModifyUserInfoModel';
import {Form, Formik} from 'formik';
import useStyles from 'shared/components/layout/HeaderButton.style';
import {useIntl} from 'react-intl';
import MyTextField from 'shared/components/MyTextField';
import clsx from 'clsx';
import {getUserInfo} from 'modules/auth/services/getUserInfo';
import {defaultUserInfo} from 'modules/auth/configs/DefaultUserInfo';

interface ModifyUserInfoProps {
  /**
   * Callback when the user submit the user info modifications
   */
  handleModalModifyUser: () => void;

}

/**
 * Modify user info form
 * Does not include the modal
 *
 * @param param0 
 */
const ModifyUserInfo: React.FC<ModifyUserInfoProps> = ({
  handleModalModifyUser,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {messages} = useIntl();

  const {customer, user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  let userInfo: ModifyUserInfoModel = defaultUserInfo
  if (customer) {
    userInfo = getUserInfo(customer)
  }


  const [changeInfo, setChangeInfo] = React.useState<boolean>(true);

  const handleChangeInfo = () => {
    setChangeInfo(false);
  };

  const validationSchema = yup.object({
    lastname: yup
      .string()
      .required(messages['modifyUser.validation.lastname'] as string),
    firstname: yup
      .string()
      .required(messages['modifyUser.validation.firstname'] as string),
    email: yup
      .string()
      .email(messages['modifyUser.validation.emailFormat'] as string)
      .required(messages['modifyUser.validation.email'] as string),
  });
  return (
    <>
      <Box className={classes.userForm}>
        <Formik
          initialValues={userInfo}
          validationSchema={validationSchema}
          onSubmit={(data: ModifyUserInfoModel) => {
            dispatch(actions.modifyUserInfo(data))
            setChangeInfo(true)
          }}>
          <Form className={classes.fullWidth} autoComplete='off'>
            <Box className={classes.textFormContainer}>
              <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                <MyTextField
                  className={classes.fullWidth}
                  disabled={changeInfo}
                  placeholder={userInfo.lastname}
                  label={<IntlMessages id='modifyUser.lastname' />}
                  name='lastname'
                  variant='outlined'
                />
              </Box>
              <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                <MyTextField
                  className={classes.fullWidth}
                  disabled={changeInfo}
                  placeholder={userInfo.firstname}
                  label={<IntlMessages id='modifyUser.firstname' />}
                  name='firstname'
                  variant='outlined'
                />
              </Box>
            </Box>
            <Box className={classes.textFormContainer}>
              <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                <MyTextField
                  className={classes.fullWidth}
                  disabled={changeInfo}
                  placeholder={userInfo.phone}
                  label={<IntlMessages id='modifyUser.phone' />}
                  name='phone'
                  variant='outlined'
                />
              </Box>
              <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                <MyTextField
                  className={classes.fullWidth}
                  disabled={true}
                  placeholder={userInfo.email}
                  label={<IntlMessages id='modifyUser.email' />}
                  name='email'
                  variant='outlined'
                  multiline
                />
              </Box>
            </Box>
            <Box
              flexDirection='column'
              alignItems='center'
              display={changeInfo ? 'none' : 'flex'}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                className={classes.formUserButton}>
                <IntlMessages id='modifyUser.submitButton' />
              </Button>
            </Box>
          </Form>
        </Formik>
        <Box flexDirection='row' display={!changeInfo ? 'none' : 'flex'}>
          <Button
            variant='contained'
            color='primary'
            type='button'
            className={classes.formUserButton}
            onClick={() => handleChangeInfo()}>
            <IntlMessages id='modifyUser.modifyButton' />
          </Button>
          {(user?.email || customer?.email) && <Button
            variant='contained'
            color='secondary'
            type='button'
            className={classes.formUserButton}
            onClick={() => {
              handleModalModifyUser();
              if (user?.email || customer?.email) {
                dispatch(actions.resetPassword(user?.email ? user.email : customer?.email!))
              }
            }}>
            <IntlMessages id='modifyUser.changePasswordButton' />
          </Button>
}
        </Box>
      </Box>
    </>
  );
};

export default ModifyUserInfo;
