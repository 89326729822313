import React from 'react';
import {Box, BoxProps, IconButton} from '@mui/material';
import {makeStyles} from '@mui/styles';
import QuestionIcon from 'shared/components/Icons/QuestionIcon';
import BaseDialog from 'shared/components/BaseDialog';
import {CremaTheme} from 'shared/models/AppContextPropsType';
import {ServiceId} from 'shared/models/RouterParam';
import Informations from './Informations';

const useStyles = makeStyles((theme: CremaTheme) => ({
  dialogInfo: {
    //height: '100%',
    height: "800px",
    minHeight: '700px',
    /*[theme.breakpoints.up('xs')]: {
      width: '430px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '560px',
    },
    [theme.breakpoints.up('md')]: {
      width: '915px',
      minWidth: '915px',
    },*/
  },
}));

interface InformationDialogProps extends BoxProps {
  service?: ServiceId
  /**
   * to manage which part of the information dialog is shown
   */
  tabSelected: string;
  /**
   * to manage the closing of the information dialog
   */
  handleModal: () => void;
  /**
   * state of the information dialog open/close
   */
  open: boolean;
  /**
   * whether to show or not the question mark, depending on where the component is called
   */
  showQuestionMark: boolean;
}

/**
 * Component for showing information dialog
 * Contains an icon shown next to the title's components
 * And the informationDialog
 */
const InformationDialog: React.FC<InformationDialogProps> = ({
  tabSelected,
  handleModal,
  open,
  service,
  showQuestionMark,
  ...boxProps
}) => {
  const classes = useStyles();

  return (
    <Box>
      {showQuestionMark ? (
        <>
          <IconButton onClick={handleModal}>
            <QuestionIcon fontSize='inherit' color='primary' />
          </IconButton>
          <BaseDialog
            fullWidth
            maxWidth='lg'
            open={open}
            titleMessageId='baseDialog.noTitle'
            onClose={handleModal}
            classes={{paper: classes.dialogInfo}}>
            <Informations
              service={service}
              selectedInfo={tabSelected}
              handleModal={handleModal}
              open={open}
            />
          </BaseDialog>
        </>
      ) : (
        <BaseDialog
          fullWidth
          maxWidth='lg'
          open={open}
          titleMessageId='baseDialog.noTitle'
          onClose={handleModal}
          classes={{paper: classes.dialogInfo}}>
          <Informations
            service={service}
            selectedInfo={tabSelected}
            handleModal={handleModal}
            open={open}
          />
        </BaseDialog>
      )}
    </Box>
  );
};
export default InformationDialog;
