import {useTheme} from '@mui/system';
import React, {useCallback, useEffect, useState} from 'react';

function useHeaderTableStyle(backgroundColor?: string) {
    const theme = useTheme()
    const [headerStyle, setHeaderStyle] = useState<React.CSSProperties>({})

    const headerStyleCallBack= useCallback(() => ({
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        // TODO: rule not applied, to fix
        '&:firstChild': {
          borderTopLeftRadius: '4px !important',
        },
        '&:lastChild': {
          borderTopRightRadius: '4px !important',
        },
        borderRight: `1px solid ${theme.palette.background.default}`,
    }), [theme, backgroundColor])

      useEffect(() => {
          setHeaderStyle(headerStyleCallBack)
      }, [headerStyleCallBack])
      
      return headerStyle
      
}

export default useHeaderTableStyle;