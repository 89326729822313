import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import cumulative_image from '../assets/cumulative_image.png'
import {FormattedMessage} from 'react-intl';
import CumulativeArrowTopLeft from './icons/CumulativeArrowTopLeft';
import CumulativeArrowTop from './icons/CumulativeArrowTop';

const CumulativeHelp: React.FC = () => {
  const theme = useTheme()
  return (
    <Box flex={1} width={1} display="flex" flexDirection={"column"}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.weather.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: "center"
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={cumulative_image} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-40}
            left={-180}>
            <Box width={'250px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.weather.selector' values={{
                  b: (str: string) => <Typography variant='caption' fontWeight='bold' display="block">{str}</Typography>
                }} />
              </Typography>
              <CumulativeArrowTopLeft
                sx={{
                  fontSize: '250px',
                  position: 'absolute',
                  top: -50,
                  left: 175,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-70}
            right={-100}>
            <Box width={'350px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.weather.display_button' />
              </Typography>
              <CumulativeArrowTop
                sx={{
                  fontSize: '200px',
                  position: 'absolute',
                  top: 0,
                  left: -140,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Typography
          width='690px'
          variant='caption'
          color={theme.palette.grey[600]}
          paddingY={3}
          textAlign='center'>
          <FormattedMessage id='info.weather.cumulative.explaination' />
        </Typography>
      </Box>
    </Box>
  );
}

export default CumulativeHelp;