import {WeatherStationCredentials} from './BaseWeatherStationSchema';

export interface WeatherStationResponse {
  provider_id: number;
  provider_name: string;
  enabled: boolean;
  provider_type: ConnectedStationType;
}

export enum ConnectedStationType {
  WEENAT = 'weenat',
  SENCROP = 'sencrop',
  MF = 'mf',
}

export interface WeatherStationResponseWithCredentials
  extends WeatherStationResponse {
  credentials: WeatherStationCredentials;
}
