import {appIntl} from 'shared/utils/IntlGlobalProvider';
import * as yup from 'yup';
export const irrigationValidationSchema = () => {
  return {
    sowing_date: yup
      .string()
      .required(
        appIntl().formatMessage({id: 'fields.validation.sowingDate'}),
      ),
    crop_id: yup
      .number()
      .positive()
      .nullable(true)
      .required(
        appIntl().formatMessage({id: 'fields.validation.cropId'}) as string,
      ),
    sys_irrigation_id: yup
      .number()
      .positive()
      .nullable(true)
      .required(
        appIntl().formatMessage({
          id: 'fields.validation.sysIrrigationId',
        }) as string,
      ),
  }
};

