// @ts-ignore
import {Box, Card, CardProps, Theme, Typography, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {CarbonFlowLevel} from '../models/CarbonFlowLevel';
import {
  getCarbonFlowLevelColor,
  getCarbonFlowLevelMessage,
} from '../services/CarbonGaugeService';
// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  levelColor: {
    height: 10,
    width: 30,
    marginRight: theme.spacing(2),
  },
}));

const CarbonLegend: React.FC<CardProps> = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <Card {...props}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyItems='center'>
        <h5>
          <FormattedMessage id='carbon.legend.title' />
        </h5>
        <Box
          pt={2}
          width={1}
          display='flex'
          flexDirection='row'
          alignItems='center'>
                  {Object.values(CarbonFlowLevel).map((levelValue) => {
                      return (
                          <Box
                              flex={1}
                              flexWrap={"nowrap"}
                              display='flex'
                              flexDirection='row'
                              alignItems='center'
                              key={levelValue}>
                              <div
                                  className={styles.levelColor}
                                  style={{background: getCarbonFlowLevelColor(levelValue, theme)}}
                              />
                              <Typography variant='caption'>
                                  <FormattedMessage id={getCarbonFlowLevelMessage(levelValue)} />
                              </Typography>
                          </Box>
                      )
                  })}
        </Box>
      </Box>
    </Card>
  );
};
export default CarbonLegend;
