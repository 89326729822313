import getPixels from 'get-pixels';
import {NdArray} from 'ndarray';
import log from 'shared/services/LogService';
import StatisticIndicatorLegendColorCase from '../models/StatisticIndicatorLegendColorCase';

export const DEFAULT_LEGEND_LEVELS_COUNT = 20;
export const buildIndicatorLegend = async (
  legend_url: string,
  min_value: number,
  max_value: number,
  legend_levels_count?: number,
): Promise<StatisticIndicatorLegendColorCase[]> => {
  return new Promise<StatisticIndicatorLegendColorCase[]>(function (
    resolve,
    reject,
  ) {
    if (legend_url && max_value !== undefined && min_value !== undefined) {
      const legendColorCases: StatisticIndicatorLegendColorCase[] = [];
      getPixels(
        legend_url,
        (err: Error | null, pixels: NdArray<Uint8Array>) => {
          if (err) {
            log.error(`Error reading legend image ${legend_url}`);
            reject(err);
            return;
          }
          log.debug(
            `got pixels for legend ${legend_url}`,
            pixels.shape.slice(),
          );
          const levelCount = Math.min(
            pixels.shape[0],
            legend_levels_count ?? DEFAULT_LEGEND_LEVELS_COUNT,
          );
          const levelStep = Math.floor(pixels.shape[0] / levelCount);
          const initPos = Math.floor(levelStep / 2);
          const scaleStep = (max_value! - min_value!) / levelCount;
          let initVal = min_value!;
          const middleLineIndex = Math.floor(pixels.shape[1] / 2);
          // Loop on first line
          let iLevel = 0;
          for (let i = initPos; i < pixels.shape[0]; i += levelStep) {
            const colorValue = rgbToHex(
              pixels.get(i, middleLineIndex, 0),
              pixels.get(i, middleLineIndex, 1),
              pixels.get(i, middleLineIndex, 2),
            );
            //console.log(colorValue);
            const levelName = `${initVal} - ${initVal + scaleStep}`;
            const legendColorCase: StatisticIndicatorLegendColorCase = {
              color: colorValue,
              min: initVal,
              max: initVal + scaleStep,
              level: levelName,
              max_inclusive: iLevel === levelCount - 1,
            };
            legendColorCases.push(legendColorCase);
            iLevel++;
            initVal += scaleStep;
          }

          resolve(legendColorCases);
        },
      );
    } else {
      reject(`Cannot build legend for this indicator`);
    }
  });
};

export const rgbToHex = (pixelR: number, pixelG: number, pixelB: number) => {
  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return (
    '#' +
    componentToHex(pixelR) +
    componentToHex(pixelG) +
    componentToHex(pixelB)
  ).toUpperCase();
};
