export enum PixagriApplication {
  WEB = 'web',
  APP = 'pixagri-app',
  A3I_API = 'a3i-api',
  API = 'pixagri-data-api',
  REPORT_API = 'report-api',
  REPORT_WORKER = 'report-worker-api',
  PROCESSING_ORCHESTRATOR_API = 'processing-orchestrator-api',
  WAGO_API = 'wago-api',
  CLOUD_FUNCTIONS = 'cloud functions',
  NOTIFICATION_API = 'notification-api',
  /**
   * Titiler is used to read pixels in image & display it on map
   * See https://github.com/developmentseed/titiler
   */
  TITILER = 'terranis-titiler',
}
export default PixagriApplication;
