import {AxiosResponse} from 'axios';
import {DateTime, Interval} from 'luxon';
import Field from 'modules/fields/models/Field';
import {FieldIrrigationService} from 'modules/fields/models/FieldTesting';
import {reloadFieldsSummaryAfterDelay} from 'modules/fields/sagas/FieldsSagas';
import {
  CallEffect,
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {timeoutDuration} from 'shared/configs/AppConst';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../../../shared/actions/Common';
import api, {pixagriApiUrl} from '../../../shared/services/ApiConfig';
import actions, {
  ADD_IRRIGATION,
  ADD_RAIN,
  AddIrrigationAction,
  AddRainAction,
  COMPARE_FIELDS,
  CompareFieldsAction,
  DELETE_IRRIGATION,
  DELETE_RAIN,
  DeleteIrrigationAction,
  DeleteRainAction,
  GET_PDF_WATERBALANCE,
  LOAD_WATER_BALANCE,
  LoadWaterBalanceAction,
  MODIFY_IRRIGATION,
  MODIFY_RAIN,
  ModifyIrrigationAction,
  ModifyRainAction,
} from '../actions/WaterBalanceActions';
import {getWaterBalanceRoutePath} from '../configs/WaterBalanceRoutePath';
import DeleteDataModel from '../models/DeleteDataModel';
import IrrigationDataModel from '../models/IrrigationDataModel';
import ModifyDataModel from '../models/ModifyDataModel';
import NewIrrigationModel from '../models/NewIrrigationModel';
import NewRainModel from '../models/NewRainModel';
import {VegetationCompareFieldDataModel} from '../models/VegetationCompareFieldDataModel';
import VegetationDataModel from '../models/VegetationDataModel';
import WaterBalanceModel, {
  WaterBalanceWithAssets,
} from '../models/WaterBalanceModel';
import {WaterBalancePhenoStage} from '../models/WaterBalancePhenoStage';
import WaterBalanceSortedModel from '../models/WaterBalanceSortedModel';
import WaterBalanceState from '../models/WaterBalanceState';

function getWaterBalanceRequest(
  fieldId: number,
  rotationId: number,
  forceCompute?: boolean,
) {
  // const waterBalanceUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${rotationId}/water_balance`;
  const waterBalanceUrl =
    pixagriApiUrl + getWaterBalanceRoutePath(fieldId, rotationId, forceCompute);
  if (forceCompute) {
    console.log('post waterbalance');

    return api.post(waterBalanceUrl);
  } else {
    console.log('get waterbalance');
    return api.get(waterBalanceUrl);
  }
}

function sortWaterBalanceResult(rawResult: WaterBalanceModel[]) {
  const waterBalance = {} as WaterBalanceSortedModel;

  rawResult.map((x) => {
    if (!waterBalance.hasOwnProperty(x.wb_type)) {
      waterBalance[x.wb_type] = [];
      waterBalance[x.wb_type].push(x as WaterBalanceModel);
      return waterBalance[x.wb_type];
    }
    return waterBalance[x.wb_type].push(x as WaterBalanceModel);
  });
  return waterBalance;
}

function fillCompareFieldDataChart(
  waterBalance: WaterBalanceSortedModel,
  rotation: FieldIrrigationService,
  baseYear: number,
) {
  const fieldDataChartArray: VegetationCompareFieldDataModel[] = [];
  const rotationStartDateYear = DateTime.fromISO(rotation.campaign_start).get(
    'year',
  );
  const yearDiff = baseYear - rotationStartDateYear;

  waterBalance.fcover_verde.forEach((data) => {
    let dataChart: VegetationCompareFieldDataModel = {};

    dataChart.fcover_verde = data.wb_value;
    dataChart.date = data.wb_date;
    dataChart.original_date_epoch = DateTime.fromISO(data.wb_date).toMillis();
    if (dataChart.original_date_epoch) {
      const customDate = DateTime.fromMillis(dataChart.original_date_epoch)
        .plus({
          year: yearDiff,
        })
        .toMillis();
      dataChart.custom_date_epoch = customDate;
    }

    if (waterBalance.GDD) {
      const gddValue = waterBalance.GDD.find(
        (gdd) => gdd.wb_date === data.wb_date,
      )?.wb_value;
      if (gddValue) {
        dataChart.gdd = parseFloat(gddValue);
      }
    } else {
      log.error(`No GDD data for field ${rotation.field_id}`);
    }

    const sowingDate = rotation.sowing_date;
    const intervall = Interval.fromDateTimes(
      DateTime.fromISO(sowingDate),
      DateTime.fromISO(data.wb_date),
    );
    dataChart.day_number = intervall.length('day');

    fieldDataChartArray.push(dataChart);
  });
  if (waterBalance.lai_verde) {
    waterBalance.lai_verde.forEach((data) => {
      const existingPoint = fieldDataChartArray.find(
        (x) => x.date === data.wb_date,
      );
      if (existingPoint) {
        existingPoint.lai_verde = data.wb_value;
      } else {
        let dataChart: VegetationCompareFieldDataModel = {};

        dataChart.fcover_verde = data.wb_value;
        dataChart.date = data.wb_date;
        dataChart.original_date_epoch = DateTime.fromISO(
          data.wb_date,
        ).toMillis();
        if (dataChart.original_date_epoch) {
          const customDate = DateTime.fromMillis(dataChart.original_date_epoch)
            .plus({
              year: yearDiff,
            })
            .toMillis();
          dataChart.custom_date_epoch = customDate;
        }

        if (waterBalance.GDD) {
          const gddValue = waterBalance.GDD.find(
            (gdd) => gdd.wb_date === data.wb_date,
          )?.wb_value;
          if (gddValue) {
            dataChart.gdd = parseFloat(gddValue);
          }
        } else {
          log.error(`No GDD data for field ${rotation.field_id}`);
        }

        const sowingDate = rotation.sowing_date;
        const intervall = Interval.fromDateTimes(
          DateTime.fromISO(sowingDate),
          DateTime.fromISO(data.wb_date),
        );
        dataChart.day_number = intervall.length('day');

        fieldDataChartArray.push(dataChart);
      }
    });
  }
  return fieldDataChartArray.sort((a, b) => {
    return a.original_date_epoch! - b.original_date_epoch!;
  });
}
function* loadFieldsWaterBalance(action: CompareFieldsAction) {
  try {
    yield put(fetchStart('loadFieldsWaterBalance'));

    const {data, selectedFieldId, selectedCampaign} = yield select(
      (state: AppState) => state.fields,
    );

    const rotations = action.payload;
    const callWaterBalanceRequests: CallEffect<any>[] = [];
    const fieldsDataChart: {[key: string]: VegetationCompareFieldDataModel[]} =
      {};
    /**
     * get data for selectedRotation and set a base year reference to compute custom date
     */
    let currentRotation: FieldIrrigationService | null = null;
    let currentYear: number = 2021;
    if (data && selectedFieldId && selectedCampaign) {
      const selectedField: Field = data[selectedCampaign][selectedFieldId];
      if (
        selectedField &&
        selectedField.irrigation &&
        selectedField.irrigation.length
      ) {
        const rotation = selectedField.irrigation.find(
          (rot) => rot.campaign_id === selectedCampaign,
        );
        if (rotation) {
          currentRotation = rotation;
          currentYear = DateTime.fromISO(rotation.campaign_start).get('year');
        }
      }
    }
    if (currentRotation) {
      const res: AxiosResponse = yield call(
        getWaterBalanceRequest,
        selectedFieldId,
        currentRotation.rotation_id,
      );
      const dataSorted = sortWaterBalanceResult(
        res.data as WaterBalanceModel[],
      );
      if (!dataSorted.fcover_verde || !dataSorted.fcover_verde.length) {
        log.error(`No data fcover found for selected field ${selectedFieldId}`);
      } else {
        fieldsDataChart[currentRotation.rotation_id] =
          fillCompareFieldDataChart(dataSorted, currentRotation, currentYear);
      }
    }
    /**
     * Retrieve fields from state and selected rotations
     * stack requests to send
     * if error, log.error and return in order to not throw entire saga, just skip field
     */
    rotations.forEach((rot) => {
      callWaterBalanceRequests.push(
        call(getWaterBalanceRequest, rot.field_id, rot.rotation_id),
      );
    });

    log.debug(
      `launching ${callWaterBalanceRequests.length} waterBalance request`,
    );
    // send requests
    const responses: AxiosResponse<any>[] = yield all(callWaterBalanceRequests);

    responses.forEach((res, index) => {
      // log and skip is response send error status
      if (res.status >= 400) {
        log.error(
          `Request for field ${rotations[index].field_id} res with status ${res.status} : ${res.statusText}`,
        );
        return;
      }

      const rawResult = res.data as WaterBalanceModel[];
      const waterBalance: WaterBalanceSortedModel =
        sortWaterBalanceResult(rawResult);

      const rotation = rotations[index];
      if (!waterBalance.fcover_verde || !waterBalance.fcover_verde.length) {
        // TODO: MAke sure that all field selected have fcover_verde values

        // throw new Error(`No data fcover found for field ${fieldsArray[index].field.field_id}`)
        log.error(
          `No data fcover found for field ${rotations[index].field_id}`,
        );
      } else {
        fieldsDataChart[rotation.rotation_id] = fillCompareFieldDataChart(
          waterBalance,
          rotation,
          currentYear,
        );
      }
    });
    yield put(actions.compareFieldsSuccess(fieldsDataChart));
    yield put(fetchSuccess('loadFieldsWaterBalance'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('loadFieldsWaterBalance', error.message));
  }
}

function* loadWaterBalance(data: LoadWaterBalanceAction) {
  try {
    const fieldId = data.payload.fieldId;
    const rotationId = data.payload.rotationId;
    const forceCompute = data.payload.forceCompute;
    yield put(fetchStart('loadWaterBalance'));
    const {summaryIrrigationInfo} = yield select(
      (state: AppState) => state.waterBalance,
    );
    const {data: fieldsData, selectedCampaign} = yield select(
      (state: AppState) => state.fields,
    );

    const res: AxiosResponse<any> = yield call(
      getWaterBalanceRequest,
      fieldId,
      rotationId,
      forceCompute,
    );
    const rawResult = res.data as WaterBalanceModel[];
    const waterBalance = {} as WaterBalanceSortedModel;
    if (rawResult) {
      rawResult.map((x) => {
        if (!waterBalance.hasOwnProperty(x.wb_type)) {
          waterBalance[x.wb_type] = [];
          waterBalance[x.wb_type].push(x as WaterBalanceModel);
          return waterBalance[x.wb_type];
        }
        return waterBalance[x.wb_type].push(x as WaterBalanceModel);
      });
    }

    const renameKeys = (data: WaterBalanceModel[]) => {
      return data.map((item) => {
        const renamedItem: WaterBalanceWithAssets = {
          ...item,
          img_url: item.fcover_verde_pic ? item.fcover_verde_pic : '',
          bbox: item.fcover_verde_pic_bbox ? item.fcover_verde_pic_bbox : '',
        };
        delete renamedItem.fcover_verde_pic;
        delete renamedItem.fcover_verde_pic_bbox;
        return renamedItem;
      });
    };

    if (waterBalance.fcover_verde) {
      waterBalance.fcover_verde = renameKeys(waterBalance.fcover_verde);
    }

    if (waterBalance.lai_verde) {
      waterBalance.lai_verde = waterBalance.lai_verde.map((item) => {
        const addEpoch = {
          ...item,
          wb_dateEpoch: Date.parse(item.wb_date),
        };
        return addEpoch;
      });
    }

    /*

    !Debug code to handle phenology, currently not render by wb request (10/05/2023)

    if (waterBalance.GDD.length) {
      phenoStages58.forEach((phenoStage) => {
        const value = phenoStage.stage_value;
        const closestGdd = waterBalance.GDD.sort((a, b) => {
          return (
            Math.abs(value - parseFloat(a.wb_value)) -
            Math.abs(value - parseFloat(b.wb_value))
          );
        })[0];

        const gddStageWaterbalance: GddStageWaterBalance = {
          wb_type: 'GDD_stage',
          wb_value: value.toString(),
          wb_date: closestGdd.wb_date,
          crop_id: 58,
          stage_type: phenoStage.stage_type,
        };
        if (
          !waterBalance.GDD_stage ||
          (waterBalance.GDD_stage &&
            !waterBalance.GDD_stage.find(
              (stage) => stage.wb_date === gddStageWaterbalance.wb_date,
            ))
        ) {
          if (!waterBalance.GDD_stage) {
            waterBalance.GDD_stage = [];
          }
          waterBalance.GDD_stage.push(gddStageWaterbalance);
        }
      });
    }

    console.log(waterBalance);*/

    //create array for vegetation chart's data
    const vegetationDataChart = [] as VegetationDataModel[];

    //create array for irrigation datas' charts
    const irrigationDataChart = [] as IrrigationDataModel[];

    // function to fill the vegetation data chart

    function fillArrayChartVegetation(
      key: string,
      dataArray: VegetationDataModel[],
    ) {
      waterBalance[key].filter((value) => {
        const index = dataArray.findIndex((x) => x.wb_date === value.wb_date);
        if (index === -1) {
          dataArray.push({
            wb_date: value.wb_date,
            wb_dateEpoch: new Date(value.wb_date).getTime(),
            [`wb_value_${key}`]: parseFloat(value.wb_value),
          } as VegetationDataModel);
        } else {
          dataArray[index][`wb_value_${key}`] = parseFloat(value.wb_value);
        }
        return dataArray;
      });
    }

    let maxIrrigationChartValue = 0;
    // function to fill the irrigation data chart array
    function fillArrayChartIrrigation(
      key: string,
      dataArray: IrrigationDataModel[],
    ) {
      waterBalance[key].filter((value) => {
        const index = dataArray.findIndex(
          (x) =>
            x.wb_dateEpoch ===
            Date.parse(new Date(value.wb_date).toUTCString()),
        );
        if (index === -1) {
          dataArray.push({
            wb_dateEpoch: Date.parse(new Date(value.wb_date).toUTCString()),
            [`wb_value_${key}`]: parseFloat(value.wb_value),
          } as IrrigationDataModel);
        } else {
          dataArray[index][`wb_value_${key}`] = parseFloat(value.wb_value);
        }

        if (parseFloat(value.wb_value) > maxIrrigationChartValue) {
          maxIrrigationChartValue = parseFloat(value.wb_value);
        }
        return dataArray;
      });
    }

    //fill the vegetation's chart array
    waterBalance.fcover_verde &&
      fillArrayChartVegetation('fcover_verde', vegetationDataChart);
    if (waterBalance.fcover_verde && waterBalance.fcover_verde.length) {
      waterBalance.fcover_verde.forEach((data) => {
        if (data && data.wb_date) {
          data.wb_dateEpoch = DateTime.fromISO(data.wb_date).toMillis();
        }
      });
    }
    waterBalance.GDD && fillArrayChartVegetation('GDD', vegetationDataChart);
    waterBalance.lai_verde &&
      fillArrayChartVegetation('lai_verde', vegetationDataChart);

    //fill the irrigation's chart array
    waterBalance.ETO && fillArrayChartIrrigation('ETO', irrigationDataChart);
    waterBalance.ET && fillArrayChartIrrigation('ET', irrigationDataChart);
    waterBalance.irrigation &&
      fillArrayChartIrrigation('irrigation', irrigationDataChart);
    waterBalance.pluie &&
      fillArrayChartIrrigation('pluie', irrigationDataChart);
    waterBalance.SWC1 && fillArrayChartIrrigation('SWC1', irrigationDataChart);
    waterBalance.SWC2 && fillArrayChartIrrigation('SWC2', irrigationDataChart);
    waterBalance.TAW && fillArrayChartIrrigation('TAW', irrigationDataChart);
    waterBalance.RAW && fillArrayChartIrrigation('RAW', irrigationDataChart);
    waterBalance.DE && fillArrayChartIrrigation('DE', irrigationDataChart);
    waterBalance.GDD_stage &&
      fillArrayChartIrrigation('GDD_stage', irrigationDataChart);

    let compareDataChart = null;
    const selectedField: Field = fieldsData[selectedCampaign][fieldId];
    const selectedRotation = selectedField.irrigation.find(
      (rotation) => rotation.rotation_id === rotationId,
    );
    if (!selectedRotation) {
      log.error(
        `rotation ${rotationId} not found in field ${selectedField.field_id}`,
      );
    } else if (waterBalance.fcover_verde) {
      compareDataChart = {
        [selectedRotation.rotation_id]: fillCompareFieldDataChart(
          waterBalance,
          selectedRotation,
          DateTime.fromISO(selectedRotation.campaign_start).get('year'),
        ),
      };
    }

    // Sort the tables per date DESC
    for (const waterBalanceProp in waterBalance) {
      const waterBalancePropArray = waterBalance[
        waterBalanceProp
      ] as WaterBalanceModel[];
      if (waterBalancePropArray) {
        waterBalancePropArray.sort().reverse();
      }
    }

    let selectedNdviDate: string | null = null;
    let selectedCompareNdviDate: string | null = null;
    if (waterBalance.fcover_verde && waterBalance.fcover_verde.length > 0) {
      selectedNdviDate = waterBalance.fcover_verde[0].wb_date;
      if (waterBalance.fcover_verde.length > 1) {
        selectedCompareNdviDate = waterBalance.fcover_verde[1].wb_date;
      }
    }

    let phenoMainStages: WaterBalancePhenoStage[] = [];
    if (selectedRotation) {
      phenoMainStages.push({
        date: selectedRotation.sowing_date,
        date_epoch: DateTime.fromISO(selectedRotation.sowing_date).toMillis(),
        stage_type: 'stade_s',
        gdd_value: 0,
        isMilestone: true,
        crop_id: selectedRotation.crop_id,
      });
    }
    if (waterBalance && waterBalance.GDD_stages) {
      const rawPhenoMainStages = waterBalance.GDD_stages.filter(
        (data) => data.isMilestone,
      ).map((data) => {
        return {
          date: data.wb_date,
          date_epoch: DateTime.fromISO(data.wb_date).toMillis(),
          stage_type: data.stage_type,
          gdd_value: +data.wb_value,
          isMilestone: data.isMilestone,
          crop_id: data.crop_id,
        } as WaterBalancePhenoStage;
      });
      const sortedRawPhenoMainStages = rawPhenoMainStages.sort(
        (a, b) => a.date_epoch - b.date_epoch,
      );
      phenoMainStages = [...phenoMainStages, ...sortedRawPhenoMainStages];
    }

    const waterBalanceState: WaterBalanceState = {
      summaryIrrigationInfo: summaryIrrigationInfo,
      data: waterBalance,
      selectedNdviDate: selectedNdviDate,
      // Set compare date to selectedNdviDate by default
      selectedCompareNdviDate: selectedCompareNdviDate,
      vegetationDataChart: vegetationDataChart,
      irrigationDataChart: fillCustomDataToIrrigationDataChart(
        irrigationDataChart,
        maxIrrigationChartValue,
      ),
      phenoMainStages: phenoMainStages,
      vegetationCompareFieldDataChart: compareDataChart,
    };

    yield put(actions.loadWaterBalanceSuccessAction(waterBalanceState));
    yield put(fetchSuccess('loadWaterBalance'));
  } catch (error) {
    yield put(fetchError('loadWaterBalance', error.message));
  }
}

function addNewIrrigationRequest(
  dataToSend: NewIrrigationModel,
  fieldId: number,
  fieldRotation: number,
) {
  const irrigationUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/irrigate`;
  return api.post(irrigationUrl, dataToSend);
}

function* addNewIrrigation(action: AddIrrigationAction) {
  try {
    yield put(fetchStart('addNewIrrigation'));
    const data = action.payload;
    const dataToSend: NewIrrigationModel = {
      irrigation_date: data.date as string,
      dose_mm: data.dose_mm,
    };
    const fields: {id: number; rotation: number}[] = data.fields;
    //const fieldRotation: number = data.fieldRotation as number;
    const addIrrigationPromise: Promise<void>[] = [];
    fields.forEach((fld) => {
      const req = call(
        addNewIrrigationRequest,
        dataToSend,
        fld.id,
        fld.rotation,
      );
      addIrrigationPromise.push(req as unknown as Promise<void>);
    });
    yield all(addIrrigationPromise);
    yield put(fetchSuccess('addNewIrrigation'));

    const reloadWaterBalancePromise: Promise<void>[] = [];
    fields.forEach((fld) => {
      const req = call(reloadWaterBalanceAfterDelay, 1, fld.id, fld.rotation);
      reloadWaterBalancePromise.push(req as unknown as Promise<void>);
    });
    yield all(reloadWaterBalancePromise);
    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error) {
    yield put(fetchError('addNewIrrigation', error.message));
  }
}

function deleteIrrigationRequest(selectedIrrigation: DeleteDataModel) {
  const {date, fieldId, fieldRotation} = selectedIrrigation;
  const irrigationUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/irrigate/${date}`;
  return api.delete(irrigationUrl);
}

function* deleteIrrigation(action: DeleteIrrigationAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart('deleteIrrigation'));
    yield call(deleteIrrigationRequest, action.payload);
    yield put(fetchSuccess('deleteIrrigation'));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error) {
    yield put(fetchError('deleteIrrigation', error.message));
  }
}

function modifyIrrigationRequest(modifiedIrrigation: ModifyDataModel) {
  const {date, dose_mm, fieldId, fieldRotation} = modifiedIrrigation;
  const dataToSend = {
    dose_mm,
    irrigation_date: date,
  };
  const irrigationUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/irrigate`;
  return api.put(irrigationUrl, dataToSend);
}

function* modifyIrrigation(action: ModifyIrrigationAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart('modifyIrrigation'));
    yield call(modifyIrrigationRequest, action.payload);
    yield put(fetchSuccess('modifyIrrigation'));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error) {
    yield put(fetchError('modifyIrrigation', error.message));
  }
}

function addNewRainRequest(
  dataToSend: NewRainModel,
  fieldId: number,
  fieldRotation: number,
) {
  const rainUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/rain`;
  return api.post(rainUrl, dataToSend);
}

function* addNewRain(action: AddRainAction) {
  try {
    yield put(fetchStart('addNewRain'));
    const data = action.payload;
    const dataToSend: NewRainModel = {
      rain_date: data.date as string,
      dose_mm: data.dose_mm,
    };

    const fields: {id: number; rotation: number}[] = data.fields;
    const addRainPromise: Promise<void>[] = [];
    fields.forEach((fld) => {
      const req = call(addNewRainRequest, dataToSend, fld.id, fld.rotation);
      addRainPromise.push(req as unknown as Promise<void>);
    });
    yield all(addRainPromise);

    yield put(fetchSuccess('addNewRain'));

    const reloadWaterBalancePromise: Promise<void>[] = [];
    fields.forEach((fld) => {
      const req = call(reloadWaterBalanceAfterDelay, 1, fld.id, fld.rotation);
      reloadWaterBalancePromise.push(req as unknown as Promise<void>);
    });
    yield all(reloadWaterBalancePromise);
    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error) {
    yield put(fetchError('addNewRain', error.message));
  }
}

function deleteRainRequest(selectedRain: DeleteDataModel) {
  const {date, fieldId, fieldRotation} = selectedRain;
  const rainUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/rain/${date}`;
  return api.delete(rainUrl);
}

function* deleteRain(action: DeleteRainAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart('deleteRain'));
    yield call(deleteRainRequest, action.payload);
    yield put(fetchSuccess('deleteRain'));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error) {
    yield put(fetchError('deleteRain', error.message));
  }
}

function modifyRainRequest(modifiedRain: ModifyDataModel) {
  const {date, dose_mm, fieldId, fieldRotation} = modifiedRain;
  const dataToSend = {
    dose_mm,
    rain_date: date,
  };
  const rainUrl = `${pixagriApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/rain`;
  return api.put(rainUrl, dataToSend);
}

function* modifyRain(action: ModifyRainAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart('modifyRain'));
    yield call(modifyRainRequest, action.payload);
    yield put(fetchSuccess('modifyRain'));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
    yield call(reloadFieldsSummaryAfterDelay, 2);
  } catch (error) {
    yield put(fetchError('modifyRain', error.message));
  }
}

function* reloadWaterBalanceAfterDelay(
  delayMultiplier: number,
  fieldId: number,
  rotationId: number,
) {
  // Wait for some times
  yield delay(timeoutDuration * delayMultiplier);

  // Reload waterBalance
  yield put(actions.loadWaterBalance(fieldId, rotationId));
}

function getPdfRequest(selectedCampaign: number) {
  const getpdfUrl = `${pixagriApiUrl}/campaigns/${selectedCampaign}/pdf`;
  return api.post(getpdfUrl);
}
function* getPdfWaterBalanceSaga() {
  try {
    yield put(fetchStart('getPdfWaterBalanceSaga'));
    const {selectedCampaign} = yield select((state: AppState) => state.fields);
    if (selectedCampaign) {
      yield call(getPdfRequest, selectedCampaign);

      yield put(actions.updatePdfWaterBalanceStatus('success'));
      yield put(fetchSuccess('getPdfWaterBalanceSaga'));
    } else {
      throw new Error(
        `Cannot get pdf, invalid campaign => ${selectedCampaign}`,
      );
    }
  } catch (error) {
    yield put(fetchError('getPdfWaterBalanceSaga', error.message));
    yield put(actions.updatePdfWaterBalanceStatus('error'));
  }
}

export function* waterBalanceSagas() {
  yield all([
    takeEvery(LOAD_WATER_BALANCE, loadWaterBalance),
    takeEvery(ADD_IRRIGATION, addNewIrrigation),
    takeEvery(DELETE_IRRIGATION, deleteIrrigation),
    takeEvery(MODIFY_IRRIGATION, modifyIrrigation),
    takeEvery(ADD_RAIN, addNewRain),
    takeEvery(DELETE_RAIN, deleteRain),
    takeEvery(MODIFY_RAIN, modifyRain),
    takeEvery(COMPARE_FIELDS, loadFieldsWaterBalance),
    takeLatest(GET_PDF_WATERBALANCE, getPdfWaterBalanceSaga),
  ]);
}
/**
 * Add to each item of irrigationDataChart range numbers when DE is over TAW or RAW (use to draw area in chart)
 * ex: treshold_raw : [value of RAW, value of DE] as number[]
 * Fill stage divider if GDD_stage exist in waterbalance response
 * Define Prediction area base on current date
 * @param irrigationDataChart
 * @returns
 */
function fillCustomDataToIrrigationDataChart(
  irrigationDataChart: IrrigationDataModel[],
  maxIrrigationChartValue: number,
) {
  const dateNowMillis = DateTime.now().toMillis();

  irrigationDataChart.forEach((data: IrrigationDataModel) => {
    /**
     * Look if is prediction area
     */
    const date =
      typeof data.wb_dateEpoch === 'number'
        ? data.wb_dateEpoch
        : parseFloat(data.wb_dateEpoch);
    if (date >= dateNowMillis) {
      data.prediction = [0, maxIrrigationChartValue];
    }

    /**
     * Add a stage divider if necessary
     */
    if (data.wb_value_GDD_stage) {
      data.stage_divider = maxIrrigationChartValue;
    }

    /**
     * Build warning area if DE > TAW or RAW
     */
    if (
      data.wb_value_DE &&
      data.wb_value_RAW &&
      data.wb_value_DE >= data.wb_value_RAW
    ) {
      data.treshold_raw = [data.wb_value_RAW, data.wb_value_DE] as number[];
    } else {
      // use to extend aera until intersection
      data.treshold_raw = [data.wb_value_DE, data.wb_value_DE] as number[];
    }
    if (
      data.wb_value_DE &&
      data.wb_value_TAW &&
      data.wb_value_DE >= data.wb_value_TAW
    ) {
      data.treshold_taw = [data.wb_value_TAW, data.wb_value_DE] as number[];
    } else {
      // use to extend aera until intersection
      data.treshold_taw = [data.wb_value_DE, data.wb_value_DE] as number[];
    }
  });
  return irrigationDataChart;
}
