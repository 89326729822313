export interface RouterParams {
  campaignId: string;
  fieldId: string;
  serviceId: ServiceId;
  viewId: ServiceView;
}

export enum ServiceView {
  /**
   * Vegetation service view
   */
  DATE_COMPARISON = 'date-comparison',
  FIELD_COMPARISON = 'field-comparison',
  VEGETATION_DEVELOPPMENT = 'vegetation-development',

  /**
   * Carbon service view
   */
  BARE_SOIL = 'bare-soil',
  COVER_SOIL = 'cover-soil',

  /**
   * Nitrogen service view
   */
  NITROGEN_COVER = 'nitrogen-cover',
  RECOMMENDATION = 'recommendation',
  FERTILIZATION = 'fertilization',

  /**
   * Weather service view
   */
  WEATHER_OVERVIEW = 'overview',
  WEATHER_ACCUMULATION = 'accumulation',
  WEATHER_WEEK = 'week',

  /**
   * Irrigation service view
   */
  REVIEW = 'review',
  IRRIGATION = 'analyze',
  WATERBALANCE = 'water-balance',
  EVAPOTRANSPIRATION = 'evapotranspiration',

  /**
   * Common view
   */
  EDIT = 'edit',
  MAP = 'map',
  STATISTICS = 'statistics',
}

export enum ServiceId {
  IRRIGATION = 'irrigation',
  NITROGEN = 'nitrogen',
  CARBON = 'carbon',
  WEATHER = 'weather',
  VEGETATION = 'vegetation',
}
