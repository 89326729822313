import {selectedFieldRootPath} from 'modules/fields/configs/FieldsRoutePaths';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

/**
 * Default vue to redirect if url path doesn't exist
 */
export const DEFAULT_CARBON_VUE: ServiceView = ServiceView.BARE_SOIL;

const carbonServiceRootPath = `${selectedFieldRootPath}/${ServiceId.CARBON}`;

/**
 * Full path to Bare soil vue
 */
export const selectedFieldCarbonNakedSoil = `${carbonServiceRootPath}/${ServiceView.BARE_SOIL}`;

/**
 * Full path to cover soil vue
 */
export const selectedFieldCarbonCoverSoil = `${carbonServiceRootPath}/${ServiceView.COVER_SOIL}`;

/**
 * Full path to carbon edit vue
 */
export const selectedFieldCarbonEdit = `${carbonServiceRootPath}/${ServiceView.EDIT}`;
