import {AuthRequest} from '../models/AuthRequestModel';
import {AuthUser} from '../models/AuthUser';
import {CreateUserModel} from '../models/CreateUserModel';
import Customer from '../models/Customer';
import {ModifyNotificationModel} from '../models/ModifyNotificationModel';
import {ModifyTresholdModel} from '../models/ModifyTresholdModel';
import {ModifyUserInfoModel} from '../models/ModifyUserInfoModel';
import {RoleConfig} from '../models/RoleConfig';
import {TokenState} from '../models/TokenState';
//JWT_AUTH
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export interface AuthenticateUserAction {
  type: typeof AUTHENTICATE_USER;
  payload: AuthRequest;
}

export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export interface UpdateAuthUserAction {
  type: typeof UPDATE_AUTH_USER;
  payload: {
    user: AuthUser | null;
    customer: Customer | null;
  };
}

export const LOAD_USER_INFO = 'LOAD_USER_INFO';
export interface LoadUserInfoAction {
  type: typeof LOAD_USER_INFO;
}

export const LOAD_ROLES_CONFIG = 'LOAD_ROLES_CONFIG';
export interface LoadRolesConfigAction {
  type: typeof LOAD_ROLES_CONFIG;
}

export const LOAD_ROLES_CONFIG_SUCCESS = 'LOAD_ROLES_CONFIG_SUCCESS';
export interface LoadRolesConfigSuccessAction {
  type: typeof LOAD_ROLES_CONFIG_SUCCESS;
  payload: {[key: string]: RoleConfig};
}

export const LOAD_ALL_USERS = 'LOAD_ALL_USERS';
export interface LoadAllUsersAction {
  type: typeof LOAD_ALL_USERS;
}

export const LOAD_ALL_USERS_SUCCESS = 'LOAD_ALL_USERS_SUCCESS';
export interface LoadAllUsersSuccessAction {
  type: typeof LOAD_ALL_USERS_SUCCESS;
  payload: {[key: string]: Customer};
}

export const SET_USER_ROLE = 'SET_USER_ROLE';
export interface SetUserRoleAction {
  type: typeof SET_USER_ROLE;
  payload: {
    user: Customer;
    /**
     * Pixagri role
     */
    role: string;
  };
}

export const SET_USER_ROLE_SUCCESS = 'SET_USER_ROLE_SUCCESS';
export interface SetUserRoleSuccessAction {
  type: typeof SET_USER_ROLE_SUCCESS;
  payload: Customer;
}

export const SIGNOUT_USER = 'SIGNOUT_USER';
export interface SignoutUserAction {
  type: typeof SIGNOUT_USER;
}

export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export interface SignoutUserSuccessAction {
  type: typeof SIGNOUT_USER_SUCCESS;
}

export const SET_TOKEN_SET = 'SET_TOKEN_SET';
export interface SetTokenAction {
  type: typeof SET_TOKEN_SET;
  payload: TokenState | null;
}

export const MODIFY_USER_INFO = 'MODIFY_USER_INFO';
export interface ModifyUserInfoAction {
  type: typeof MODIFY_USER_INFO;
  payload: ModifyUserInfoModel;
}

export const MODIFY_USER_NOTIFICATION = 'MODIFY_USER_NOTIFICATION';
export interface ModifyUserNotificationAction {
  type: typeof MODIFY_USER_NOTIFICATION;
  payload: ModifyNotificationModel;
}

export const MODIFY_USER_TRESHOLD = 'MODIFY_USER_TRESHOLD';
export interface ModifyUserTresholdAction {
  type: typeof MODIFY_USER_TRESHOLD;
  payload: ModifyTresholdModel;
}

export const RESET_PASSWORD = 'RESET_PASSWORD';
export interface ResetPasswordAction {
  type: typeof RESET_PASSWORD;
  payload: string;
}

export const SAVE_PREFERRED_LANGUAGE = 'SAVE_PREFERRED_LANGUAGE';
export interface SavePreferredLanguageAction {
  type: typeof SAVE_PREFERRED_LANGUAGE;
  payload: string;
}

export const CREATE_USER = 'CREATE_USER';
export interface CreateUserAction {
  type: typeof CREATE_USER;
  payload: CreateUserModel;
}

export type AuthActionTypes =
  | AuthenticateUserAction
  | LoadUserInfoAction
  | LoadRolesConfigAction
  | LoadRolesConfigSuccessAction
  | LoadAllUsersAction
  | LoadAllUsersSuccessAction
  | SetUserRoleAction
  | SetUserRoleSuccessAction
  | SetTokenAction
  | UpdateAuthUserAction
  | SignoutUserAction
  | SignoutUserSuccessAction
  | ModifyUserInfoAction
  | ResetPasswordAction
  | SavePreferredLanguageAction
  | CreateUserAction
  | ModifyUserNotificationAction
  | ModifyUserTresholdAction;

//Actions creator
export const actions = {
  authenticateUser: (authRequest: AuthRequest): AuthenticateUserAction => {
    return {
      type: AUTHENTICATE_USER,
      payload: authRequest,
    };
  },

  loadRolesConfig: (): LoadRolesConfigAction => {
    return {
      type: LOAD_ROLES_CONFIG,
    };
  },

  loadRolesConfigSuccess: (rolesConfig: {
    [key: string]: RoleConfig;
  }): LoadRolesConfigSuccessAction => {
    return {
      type: LOAD_ROLES_CONFIG_SUCCESS,
      payload: rolesConfig,
    };
  },

  loadUserInfo: (): LoadUserInfoAction => {
    return {
      type: LOAD_USER_INFO,
    };
  },

  updateAuthUser: (
    authUser: AuthUser,
    customer: Customer | null,
  ): UpdateAuthUserAction => {
    return {
      type: UPDATE_AUTH_USER,
      payload: {
        user: authUser,
        customer,
      },
    };
  },

  loadAllUsers: (): LoadAllUsersAction => {
    return {
      type: LOAD_ALL_USERS,
    };
  },

  loadAllUsersSuccess: (allUsers: {
    [key: string]: Customer;
  }): LoadAllUsersSuccessAction => {
    return {
      type: LOAD_ALL_USERS_SUCCESS,
      payload: allUsers,
    };
  },

  setUserRole: (user: Customer, role: string): SetUserRoleAction => {
    return {
      type: SET_USER_ROLE,
      payload: {
        user,
        role,
      },
    };
  },

  setUserRoleSuccess: (user: Customer): SetUserRoleSuccessAction => {
    return {
      type: SET_USER_ROLE_SUCCESS,
      payload: user,
    };
  },

  setJWTToken: (data: TokenState | null): SetTokenAction => {
    return {
      type: SET_TOKEN_SET,
      payload: data,
    };
  },

  signoutUser: (): SignoutUserAction => {
    return {
      type: SIGNOUT_USER,
    };
  },

  signoutUserSuccess: (): SignoutUserSuccessAction => {
    return {
      type: SIGNOUT_USER_SUCCESS,
    };
  },

  resetPassword: (email: string): ResetPasswordAction => {
    return {
      type: RESET_PASSWORD,
      payload: email,
    };
  },

  modifyUserInfo: (data: ModifyUserInfoModel): ModifyUserInfoAction => {
    return {
      type: MODIFY_USER_INFO,
      payload: data,
    };
  },

  modifyUserTreshold: (data: ModifyTresholdModel): ModifyUserTresholdAction => {
    return {
      type: MODIFY_USER_TRESHOLD,
      payload: data,
    };
  },

  modifyUserNotification: (
    data: ModifyNotificationModel,
  ): ModifyUserNotificationAction => {
    return {
      type: MODIFY_USER_NOTIFICATION,
      payload: data,
    };
  },

  savePreferredLanguage: (locale: string): SavePreferredLanguageAction => {
    return {
      type: SAVE_PREFERRED_LANGUAGE,
      payload: locale,
    };
  },

  createUser: (authUser: CreateUserModel): CreateUserAction => {
    return {
      type: CREATE_USER,
      payload: authUser,
    };
  },
};

export default actions;
