import {put, call, all, takeEvery} from "@redux-saga/core/effects";
import log from "shared/services/LogService";
import {GetDashboardInfoAction, GET_DASHBOARD_INFO} from "../actions/DashBoardActions";
import {getDashboardApiUrl} from "../configs/RouteApi";
import api from '../../../shared/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess} from "shared/actions";
import {DashboardApiResponse} from "../models/DashboardApiResponse";
import {carbonActions} from "modules/carbon/actions/CarbonActions";
import { actions as irrigationActions } from "modules/waterBalance/actions/WaterBalanceActions";
import nitrogenActions from "modules/nitrogen/actions/NitrogenActions";
import vegetationActions from "modules/vegetation/actions/VegetationActions";
import weatherActions from "modules/weather/actions/WeatherActions";


function getDashboardInfoRequest() {
    const dashboardUrl = getDashboardApiUrl()
    return api.get(dashboardUrl)    

}
function* getDashBoardInfo(action: GetDashboardInfoAction) {
    try {
        yield put(fetchStart('loadDashboardInfo'))
        const res = yield call(getDashboardInfoRequest)
        
        /**
         * To handle Summary data, we made choice to split response data and save them in 
         * their own state and to not have a dashboard state
         * Every update on dashboard will require many files modifications but data structure will respected 
         */
        let dashboardData = res.data as DashboardApiResponse;

        // TODO: refacto this code to be more generic by service iteration
        
        if(dashboardData.services.carbon) {
            dashboardData.services.carbon.campaign_id = dashboardData.campaign_id;
            yield put(carbonActions.saveSummaryCarbonInfo(dashboardData.services.carbon))
        }

        if(dashboardData.services.nitrogen) {
            dashboardData.services.nitrogen.campaign_id = dashboardData.campaign_id;
            yield put(nitrogenActions.saveSummaryNitrogenInfo(dashboardData.services.nitrogen))
        }

        if(dashboardData.services.vegetation) {
            dashboardData.services.vegetation.campaign_id = dashboardData.campaign_id;
            yield put(vegetationActions.saveSummaryVegetationInfo(dashboardData.services.vegetation))
        }

        if(dashboardData.services.irrigation) {
            dashboardData.services.irrigation.campaign_id = dashboardData.campaign_id;
            yield put(irrigationActions.saveSummaryIrrigationInfo(dashboardData.services.irrigation))
        }

        if(dashboardData.services.weather) {
            yield put(weatherActions.saveSummaryWeatherInfo(dashboardData.services.weather))
        }


        yield put(fetchSuccess("loadDashboardInfo"));


    } catch(error) {
        log.error('An error occured while fetching dashboard infos: ', error)
        yield put(fetchError('loadDashboardInfo', error.message))
    }
}

export function* dashBoardSaga() {
    yield all([
        takeEvery(GET_DASHBOARD_INFO, getDashBoardInfo)
    ])
}