import React from 'react';
import {InfoDialogChoice} from 'shared/configs/AppEnums';
import Overview from './Overview';
import Welcome from './Welcome';
import CropsSoils from './CropsSoils';
import FieldEdit from './FieldEdit';
import {ServiceView} from 'shared/models/RouterParam';
import BareSoilHelp from '../../carbon/components/BareSoilHelp';
import CoverSoilHelp from '../../carbon/components/CoverSoilHelp';
import VegetationHistoryHelp from '../../vegetation/components/VegetationHistoryHelp';
import VegetationCompareDateHelp from '../../vegetation/components/VegetationCompareDateHelp';
import NitrogenCoverHelp from '../../nitrogen/components/NitrogenCoverHelp';
import VegetationCompareFieldHelp from '../../vegetation/components/VegetationCompareFieldHelp';
import Subscriptions from './Subscriptions';
import CumulativeHelp from '../../weather/components/CumulativeHelp';
import WeekHelp from '../../weather/components/WeekHelp';
import WeatherOverviewHelp from '../../weather/components/WeatherOverviewHelp';

export interface OnBoardingProps {
  /**
   * to manage which part of the information dialog is shown
   */
  selectedInfo: string;

  onActiveStepChange: (i: number) => void

  step: number

}
/**
 * stepper Onboarding info
 * @param props 
 * @returns 
 */
const OnBoarding: React.FC<OnBoardingProps> = (props) => {
  const {selectedInfo, onActiveStepChange, step} = props
  React.useEffect(() => {
    switch (selectedInfo) {
      case InfoDialogChoice.NONE:
        onActiveStepChange(0);
        break;
      case InfoDialogChoice.FILTERS:
        onActiveStepChange(1);
        break;
      case InfoDialogChoice.TABS:
        onActiveStepChange(2);
        break;
      case InfoDialogChoice.SOILS_AND_CROPS:
        onActiveStepChange(3);
        break;
      case InfoDialogChoice.SUBSCRIPTIONS:
        onActiveStepChange(4);
        break
      case ServiceView.NITROGEN_COVER:
        onActiveStepChange(5);
        break;
      case ServiceView.BARE_SOIL:
        onActiveStepChange(6);
        break
      case ServiceView.COVER_SOIL:
        onActiveStepChange(7);
        break
      case ServiceView.VEGETATION_DEVELOPPMENT:
        onActiveStepChange(8);
        break
      case ServiceView.DATE_COMPARISON:
        onActiveStepChange(9);
        break
      case ServiceView.FIELD_COMPARISON:
        onActiveStepChange(10);
        break;
      case ServiceView.WEATHER_ACCUMULATION:
        onActiveStepChange(11);
        break;
      case ServiceView.WEATHER_WEEK:
        onActiveStepChange(12);
        break;
      case ServiceView.WEATHER_OVERVIEW:
        onActiveStepChange(13);
        break;
    }
  }, [selectedInfo, onActiveStepChange]);

  const pageShown = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <Welcome />;
      case 1:
        return <Overview />;
      case 2:
        return <FieldEdit />;
      case 3:
        return <CropsSoils />;
      case 4:
        return <Subscriptions />;
      case 5:
        return <NitrogenCoverHelp />;
      case 6:
        return <BareSoilHelp />;
      case 7:
        return <CoverSoilHelp />;
      case 8:
        return <VegetationHistoryHelp />;
      case 9:
        return <VegetationCompareDateHelp />;
      case 10:
        return <VegetationCompareFieldHelp />
      case 11:
        return <CumulativeHelp />
      case 12:
        return <WeekHelp />
      case 13:
        return <WeatherOverviewHelp />
      default:
        return <Welcome />;
    }
  };

  return pageShown(step)
}

export default OnBoarding;