import {Reducer} from 'redux';
import {LOAD_INVOICES_INFO_SUCCESS, LOAD_SUBSCRIPTIONS_LISTING, LOAD_SUBSCRIPTIONS_LISTING_SUCCESS, SubscriptionsActionTypes} from '../actions/SubscriptionsActions';

import SubscriptionsState from '../models/SubscriptionsState';
const initialState: SubscriptionsState = {
    data: null,
    pixagriData: null,
    selectedSubscription: null,
}

const SubscriptionsReducers: Reducer<SubscriptionsState, SubscriptionsActionTypes> = (state: SubscriptionsState = initialState, action: SubscriptionsActionTypes) => {
    switch (action.type) {
        case LOAD_SUBSCRIPTIONS_LISTING:
            return {
                ...state
            }
        case LOAD_SUBSCRIPTIONS_LISTING_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pixagriData: action.payload.pixagriData,
            }
        case LOAD_INVOICES_INFO_SUCCESS: 
            return {
                ...state,
                selectedSubscription: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default SubscriptionsReducers;