import React, {useEffect, useState} from 'react';
import HeaderButton from 'shared/components/layout/HeaderButton';
import {ServiceId} from 'shared/models/RouterParam';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import CarbonIcon from './icons/CarbonIcon';
import useServiceSelected from 'shared/hooks/useServiceSelected';
import navigationService from 'shared/services/NavigationService';
import {DEFAULT_CARBON_VUE} from '../config/CarbonRoutePaths';
import useCurrentCampaign from 'modules/campaigns/hook/useCurrentCampaign';

interface CarbonHeaderButtonProps {
  alwaysShowTitle?: boolean;
}
const CarbonHeaderButton: React.FC<CarbonHeaderButtonProps> = (props) => {
  const [path, setPath] = useState<string>('');
  const selected = useServiceSelected(ServiceId.CARBON);
  const {selectedFieldId, selectedCampaign} = useSelector<
    AppState,
    AppState['fields']
  >(({fields}) => fields);

  const currentCampaign = useCurrentCampaign();

  useEffect(() => {
    let campaignId: number | null = null

    if (selectedCampaign) {
      campaignId = selectedCampaign
    } else {
      campaignId = currentCampaign
    }
    
    const firstPath = navigationService.getFirstServiceUrl(
      campaignId,
      selectedFieldId,
      ServiceId.CARBON,
      DEFAULT_CARBON_VUE,
    );
    setPath(firstPath);
  }, [selectedFieldId, selectedCampaign, currentCampaign]);

  return (
    <>
      <HeaderButton
        path={path}
        titleId='carbon.headerButton.title'
        icon={<CarbonIcon selected={selected} />}
        alwaysShowTitle={props.alwaysShowTitle}
        selected={selected}
        serviceId={ServiceId.CARBON}
      />
    </>
  );
};

export default CarbonHeaderButton;
