import MaterialTable, {
  MTableBodyRow,
  MTableCell,
  MTableHeader,
} from '@material-table/core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import {AppState} from 'shared/store';
import {
  getFieldListQueryString,
  getInvoiceQueryString,
} from '../configs/constant';
import Subscription from '../models/Subscription';
import * as FirebaseFirestore from '@firebase/firestore-types';
import moment from 'moment';
import {Box, Button, IconButton, Typography, useTheme} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useHistory} from 'react-router';
import {subscriptionActions} from '../actions/SubscriptionsActions';
import useStepperStyles from './StepperStyle';
import {useIntl} from 'react-intl';
import {SubscriptionsStatus} from '../models/SubscriptionStatus';
import {FUNCTION_LOCATION} from 'shared/configs/AppConst';
import firebase from 'firebase/app';
import 'firebase/functions';
import {fetchStart} from 'shared/actions';
import useHeaderTableStyle from 'shared/hooks/useHeaderTableStyle';
import useCellTableStyle from 'shared/hooks/useCellTableStyle';
import {getProduct, duration} from 'modules/products/services/productService';

export default function SubscriptionsTable() {
  const {data} = useSelector<AppState, AppState['subscriptions']>(
    ({subscriptions}) => subscriptions,
  );
  const {products} = useSelector<AppState, AppState>((products) => products);

  const [currentSubscriptions, setCurrentSubscriptions] = useState<
    Subscription[]
  >([]);
  const [pastSubscriptions, setPastSubscriptions] = useState<Subscription[]>(
    [],
  );
  const [subscriptionsToDisplay, setSubscriptionToDisplay] = useState<
    Subscription[]
  >([]);

  const dispatch = useDispatch();

  const classes = useStepperStyles();
  const {messages} = useIntl();
  const theme = useTheme();

  const history = useHistory();

  useEffect(() => {
    if (data) {
      const {currentSubscriptionsList, lastSubscriptionsList} =
        subscriptionList(data);
      setCurrentSubscriptions(currentSubscriptionsList);
      setPastSubscriptions(lastSubscriptionsList);
      setSubscriptionToDisplay(currentSubscriptionsList);
    }
  }, [data]);

  const subscriptionList = (data: {[key: string]: Subscription}) => {
    const currentSubscriptionsList: Subscription[] = [];
    const lastSubscriptionsList: Subscription[] = [];
    Object.values(data).forEach((subscription) => {
      if (subscription.status === SubscriptionsStatus.ACTIVE) {
        currentSubscriptionsList.push(subscription);
      } else if (subscription.status === SubscriptionsStatus.PAST_DUE) {
        lastSubscriptionsList.push(subscription);
      }
    });
    return {currentSubscriptionsList, lastSubscriptionsList};
  };

  const fieldsCount = (data?: string) => {
    if (data) {
      const fieldList = JSON.parse(data);
      return fieldList.length as number;
    } else {
      return 0;
    }
  };

  const displayDate = (date: FirebaseFirestore.Timestamp) => {
    return moment(date.toDate()).format('DD/MM/YYYY');
  };

  const handleStripePortal = async function () {
    dispatch(fetchStart('handleStripePortal'));
    // Call billing portal function
    const functionRef = firebase
      .app()
      .functions(FUNCTION_LOCATION)
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const {data} = await functionRef({returnUrl: window.location.origin});
    window.location.assign(data.url);
  };

  /**
   * With new module compatible Mui V5, styles props have to be called from useCallback to be applied
   * https://material-table-core.com/docs/breaking-changes
   */
  const headerStyle = useHeaderTableStyle(theme.palette.primary.main);
  const cellStyle = useCellTableStyle();
  if (products) {
    return (
      <Box display='flex' flexDirection='column'>
        <Box pb={2} display='flex' justifyContent='strecht'>
          <Button
            className={[
              classes.buttonNavigation,
              subscriptionsToDisplay === currentSubscriptions
                ? classes.selected
                : '',
            ].join(' ')}
            onClick={() => setSubscriptionToDisplay(currentSubscriptions)}>
            <IntlMessages id='subscriptions.table.button.current_subscriptions' />
          </Button>
          <Button
            className={[
              classes.buttonNavigation,
              subscriptionsToDisplay === pastSubscriptions
                ? classes.selected
                : '',
            ].join(' ')}
            onClick={() => setSubscriptionToDisplay(pastSubscriptions)}>
            <IntlMessages id='subscriptions.table.button.past_subscriptions' />
          </Button>
        </Box>
        <MaterialTable
          data={subscriptionsToDisplay as Subscription[]}
          columns={[
            {
              title: <IntlMessages id='subscriptions.table.field' />,
              render: (data) => {
                if (data.metadata && data.metadata.field_ids) {
                  return (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'>
                      <Typography className={classes.bold}>
                        {fieldsCount(data.metadata.field_ids)}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          history.push({
                            pathname: history.location.pathname,
                            search: getFieldListQueryString(
                              data.metadata.field_ids,
                            ),
                          });
                        }}>
                        <VisibilityIcon color='primary' />
                      </IconButton>
                    </Box>
                  );
                } else {
                  return <IntlMessages id='common.na' />;
                }
              },
              align: 'center',
              customSort: (a, b) => {
                return (
                  fieldsCount(a.metadata.field_ids) -
                  fieldsCount(b.metadata.field_ids)
                );
              },
            },
            {
              title: (
                <IntlMessages id='subscriptions.stepper.step_two.area_list.title' />
              ),
              render: (data) => {
                return data.quantity;
              },
              align: 'center',
              customSort: (a, b) => {
                return a.quantity - b.quantity;
              },
            },
            {
              title: <IntlMessages id='subscriptions.pagination' />,
              render: (data) => {
                if (
                  data.metadata.product_ref &&
                  getProduct(data.metadata.product_ref, products)
                ) {
                  return (
                    <Typography className={classes.bold}>
                      {getProduct(data.metadata.product_ref, products)?.name}
                    </Typography>
                  );
                } else {
                  return <IntlMessages id='common.na' />;
                }
              },
              align: 'center',
            },
            {
              title: <IntlMessages id='subscription.table.duration' />,
              render: (data) => {
                if (
                  data.metadata.price_ref &&
                  data.metadata.product_ref &&
                  duration(
                    data.metadata.price_ref,
                    data.metadata.product_ref,
                    products,
                  )
                ) {
                  return (
                    <Typography className={classes.bold}>
                      {duration(
                        data.metadata.price_ref,
                        data.metadata.product_ref,
                        products,
                      )}
                    </Typography>
                  );
                } else {
                  return <IntlMessages id='common.na' />;
                }
              },
              align: 'center',
            },
            {
              title: <IntlMessages id='subscription.table.start_date' />,
              render: (data) => {
                return (
                  <Typography className={classes.bold}>
                    {displayDate(data.current_period_start)}
                  </Typography>
                );
              },
              align: 'center',
              sorting: true,
              defaultSort: 'desc',
              customSort: (a: Subscription, b: Subscription) => {
                return a.current_period_start.seconds - b.current_period_start.seconds;
              },
            },
            {
              title: <IntlMessages id='subscription.table.end_date' />,
              render: (data) => {
                return (
                  <Typography className={classes.bold}>
                    {displayDate(data.current_period_end)}
                  </Typography>
                );
              },
              align: 'center',
              customSort: (a: Subscription, b: Subscription) => {
                return a.current_period_end.seconds - b.current_period_end.seconds;
              },
            },
            {
              title: <IntlMessages id='subscription.table.invoice' />,
              render: (data) => {
                return (
                  <IconButton
                    onClick={() => {
                      dispatch(subscriptionActions.loadInvoicesInfo(data.id));
                      history.push({
                        pathname: history.location.pathname,
                        search: getInvoiceQueryString(data.id),
                      });
                    }}>
                    <DescriptionIcon color='primary' />
                  </IconButton>
                );
              },
              align: 'center',
            },
            {
              title: '',
              render: (data) => {
                return (
                  <Typography
                    onClick={handleStripePortal}
                    className={classes.bold}
                    color='primary'
                    style={{textDecoration: 'underline', cursor: 'pointer'}}>
                    <IntlMessages id='subscription.table.detail' />
                  </Typography>
                );
              },
              align: 'center',
            },
          ]}
          options={{
            search: false,
            actionsColumnIndex: -1,
            toolbar: false,
            sorting: true,
            headerStyle: headerStyle,
            editCellStyle: cellStyle,
          }}
          components={{
            Header: (props) => <MTableHeader {...props} />,
            Row: (props) => (
              <MTableBodyRow
                {...props}
                classes={{
                  root: props.index % 2 ? classes.rowStyle : classes.bold,
                }}
              />
            ),
            Cell: (props) => <MTableCell {...props} />,
          }}
          localization={{
            header: {
              actions: '',
            },
            pagination: {
              labelRowsSelect: messages['subscriptions.pagination'] as string,
              firstTooltip: messages['table.pagination.firstTooltip'] as string,
              lastTooltip: messages['table.pagination.lastTooltip'] as string,
              previousTooltip: messages[
                'table.pagination.previousTooltip'
              ] as string,
              nextTooltip: messages['table.pagination.nextTooltip'] as string,
              labelDisplayedRows: messages[
                'table.pagination.labelDisplayedRows'
              ] as string,
            },
          }}
        />
      </Box>
    );
  }
  return null;
}
