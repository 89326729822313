import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../shared/store';
import actions from '../actions/RotationsActions';
import MaterialTable, {MTableHeader, MTableBodyRow} from '@material-table/core';
import Rotation from '../models/Rotation';
import useStyles from './Rotations.style';
import IntlMessages from 'shared/components/IntlMessages';
import {FormattedMessage, useIntl} from 'react-intl';
import Field from 'modules/fields/models/Field';
import useCheckPermissions from 'modules/auth/hooks/useCheckPermissions';
import {FIELDS_DELETE} from 'modules/auth/models/UserPermission';
import {DELETE_TOOLTIP, EMPTY_DATA_SOURCE_MESSAGE} from 'shared/configs/TableConst';
import useHeaderTableStyle from 'shared/hooks/useHeaderTableStyle';
import useCellTableStyle from 'shared/hooks/useCellTableStyle';
import BaseDialog from 'shared/components/BaseDialog';
import {getCampaignTranslation} from 'modules/campaigns/services/CampaignsTranslation';
import {getCropTranslationKeyFromCode} from 'modules/crops/services/CropsTranslation';

//Loads rotations for a specific field ID and displays them in a

interface RotationsListProps {
  onClose: () => void;
}

const RotationsList: React.FC<RotationsListProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl= useIntl();


    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [rowDataToDelete, setRowDataToDelete] =
      useState<Rotation | null>(null);

  const {selectedCampaign, selectedFieldId, data} = useSelector<
    AppState,
    AppState['fields']
  >(({fields}) => fields);
  const cropsData = useSelector<AppState, AppState['crops']>(
    ({crops}) => crops,
  );
  const campaignsData = useSelector<AppState, AppState['campaigns']>(
    ({campaigns}) => campaigns,
  );


  const rotationsData = useSelector<AppState, AppState['rotations']>(
    ({rotations}) => rotations,
  );
  const rotationsList: Rotation[] = Object.values(rotationsData.data);
  rotationsList.forEach((element) => {
    [...Object.values(cropsData.data)].forEach((value) => {
      if (value.crop_id === element.crop_id)
        element.crop_name = value.crop_name;
    });
    [...Object.values(campaignsData.data)].forEach((value) => {
      if (value.campaign_id === element.campaign_id)
        element.campaign = value.campaign_name;
    });
  });
  function sortRotationsByCampaign(a: Rotation, b: Rotation) {
    if (a.campaign_id < b.campaign_id) return 1;
    if (a.campaign_id > b.campaign_id) return -1;
    return 0;
  }
  rotationsList.sort(sortRotationsByCampaign);

  useEffect(() => {
    if (selectedCampaign && selectedFieldId) {
      dispatch(actions.loadRotation(selectedFieldId));
    }
  }, [selectedCampaign, selectedFieldId, dispatch]);


  const onDeleteRotationClick = () => {
    if (rowDataToDelete) {
    let fieldSelected: Field | null =
      data &&
      data[selectedCampaign as number][selectedFieldId as number];
      dispatch(
        actions.deleteRotation(
          fieldSelected,
          rowDataToDelete.rotation_id || null,
        ),
      );
      setRowDataToDelete(null)
    }
  }

  const allowDelete = useCheckPermissions(FIELDS_DELETE);

  /**
   * With new module compatible Mui V5, styles props have to be called from useCallback to be applied
   * https://material-table-core.com/docs/breaking-changes
   */
  const headerStyle = useHeaderTableStyle();
  const cellStyle = useCellTableStyle();

  return (
    <div className={classes.tableContainer}>
      <MaterialTable
        title={intl.formatMessage({id: 'rotations.history.title'})}
        data={rotationsList as Rotation[]}
        columns={[
          {
            title: intl.formatMessage({
              id: 'nitrogen.cover_history.table.campaign_column.title',
            }),
            field: 'campaign_id',
            align: 'center',
            render: (rowData) => {
              const campaign = campaignsData.data[rowData.campaign_id];
              if (campaign) {
                return getCampaignTranslation(campaign);
              } else {
                return rowData.campaign;
              }
            },
          },
          {
            title: intl.formatMessage({
              id: 'nitrogen.form.label.sowing_date',
            }),
            field: 'sowing_date',
            align: 'center',
            render: (rowData) => {
              return intl.formatDate(rowData.sowing_date);
            },
          },
          {
            title: intl.formatMessage({
              id: 'rotations.history.crop_column_title',
            }),
            field: 'crop_name',
            align: 'center',
            render: (rowData) => {
              const cropCodeTranslationKey = getCropTranslationKeyFromCode(rowData.crop_code);
              return cropCodeTranslationKey ? intl.formatMessage({id: cropCodeTranslationKey, defaultMessage: rowData.crop_name}) : rowData.crop_name;
            },
          },
        ]}
        actions={
          allowDelete
            ? [
                {
                  icon: 'delete',
                  tooltip: intl.formatMessage({id: 'rotations.table.delete'}),
                onClick: (event, rowData) => {
                  const data =
                    rowData as Rotation;
                  setRowDataToDelete(data);
                  setOpenDeleteModal(true);

                  },
                },
              ]
            : undefined
        }
        localization={{
          body: {
            emptyDataSourceMessage: (
              <IntlMessages id={EMPTY_DATA_SOURCE_MESSAGE} />
            ),
            deleteTooltip: <IntlMessages id={DELETE_TOOLTIP} />,
          },
          header: {
            actions: '',
          },
          pagination: {
            labelRowsSelect: intl.formatMessage({id: 'rotations.pagination'}),
            firstTooltip: intl.formatMessage({id: 'table.pagination.firstTooltip'}),
            lastTooltip: intl.formatMessage({id: 'table.pagination.lastTooltip'}),
            previousTooltip: intl.formatMessage({id: 
              'table.pagination.previousTooltip'
            }),
            nextTooltip: intl.formatMessage({id: 'table.pagination.nextTooltip'}),
            labelDisplayedRows: intl.formatMessage({id: 
              'table.pagination.labelDisplayedRows'
            }),
          },
        }}
        options={{
          actionsColumnIndex: -1,
          search: false,
          toolbar: false,
          headerStyle: headerStyle,
          editCellStyle: cellStyle,
        }}
        components={{
          Header: (props) => <MTableHeader {...props} />,
          Row: (props) => (
            <MTableBodyRow
              {...props}
              classes={props.index % 2 ? {root: classes.rowStyle} : {}}
            />
          ),
        }}
      />
      <>
        <BaseDialog
          open={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
            setRowDataToDelete(null);
          }}
          buttonMessageId='fields.delete.button.label'
          titleMessageId='rotation.rotationList.dialog_delete.title'
          handleValidate={onDeleteRotationClick}>
          <FormattedMessage id='rotation.rotationList.dialog_delete.message' />
        </BaseDialog>
      </>
    </div>
  );
};

export default RotationsList;
