import React from 'react';
import useStyles from '../../Informations.style';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';

interface WelcomeProps { }

/**
 * First page of the information dialog
 * Contains an explication on how the application works
 */
const Welcome: React.FC<WelcomeProps> = () => {
  const classes = useStyles();
  return (
    <Box
      height={1}
      width={1}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      className={classes.welcomeContainer}>
      <Typography variant='h3' className={classes.textBold}>
        <IntlMessages id='informations.welcome.title' />
      </Typography>
      <Box className={classes.welcomeContentContainer}>
        <Typography variant='subtitle1'>
          <IntlMessages id='informations.welcome.content1' />
        </Typography>
        <Typography variant='subtitle1'>
          <IntlMessages id='informations.welcome.content2' />
        </Typography>
        <Typography variant='subtitle1'>
          <IntlMessages id='informations.welcome.content3' />
        </Typography>
        <Typography variant='subtitle1'>
          <IntlMessages id='informations.welcome.content4' />
        </Typography>
        <Typography variant='subtitle1'>
          <IntlMessages id='informations.welcome.content5' />
        </Typography>
        <Typography variant='subtitle1' className={classes.contactPixagri}>
          <IntlMessages id='informations.welcome.content6' />
        </Typography>
      </Box>
    </Box>
  );
};

export default Welcome;
