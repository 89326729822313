import {Box, IconButton, List, ListItem, Typography} from '@mui/material';
import React from 'react';
import Field from '../../fields/models/Field';
import DeleteIcon from '@mui/icons-material/Delete';
import useStepperStyles from 'modules/subscriptions/components/StepperStyle';
import IntlMessages from 'shared/components/IntlMessages';
import {computeAreaHa, computeTotalAreaHa} from 'modules/fields/services/FieldsService';
import {AREA_UNIT} from 'modules/products/configs/constant';

interface SummaryFieldSelectionStepProps {
    selectedFields: Field[];
    // TODO: type it
    setSelectedFields?: any;
    editable?: boolean;
};

export default function SummaryFieldSelectionStep({selectedFields, setSelectedFields, editable}: SummaryFieldSelectionStepProps) {

    const classes = useStepperStyles()

    const handleFieldSelected = (field_id: number) => {
        setSelectedFields(selectedFields.filter(field => field.field_id !== field_id))
    }

    const switchDataToDisplay = (type: "fieldName" | "totalFields" | "area" | "totalArea") => {
        switch (type) {
            case "fieldName":
                return selectedFields.map((field) => (
                    <ListItem key={field.field_id} className={classes.listItem}>
                        <Box className={classes.listItemText}>
                            {editable ? (
                                <IconButton onClick={() => handleFieldSelected(field.field_id)} className={classes.deleteIcon}>
                                    <DeleteIcon color={'error'} />
                                </IconButton>) : ""}
                            {field.field_name}
                        </Box>
                    </ListItem>
                ))


            case "totalFields":
                return (
                    <ListItem className={classes.listItem}>
                        <Box className={classes.listItemText}>
                            <Typography className={classes.fieldNumber}>
                                {selectedFields.length}
                                <IntlMessages id='subscriptions.stepper.step_two.field' />
                            </Typography>
                        </Box>
                    </ListItem>
                );
            case "area":
                return selectedFields.map(field => (
                    <ListItem key={field.field_id}>
                        <Box className={classes.listItemText}>
                            <Typography className={classes.primaryColor}>
                                {`${computeAreaHa(field, true)} ${AREA_UNIT}`}
                            </Typography>
                        </Box>
                    </ListItem>
                ));
            case "totalArea":
                return (
                    <ListItem>
                        <Box className={classes.listItemText}>
                            <Typography className={classes.primaryColor}>
                                {`${computeTotalAreaHa(selectedFields).toFixed(0)} ${AREA_UNIT}`}
                            </Typography>
                        </Box>
                    </ListItem>
                )
            default:
                return null
        }
    }

    const list = (title: string, dataToDisplay: "fieldName" | "totalFields" | "area" | "totalArea") => {
        return (
            <List>
                <ListItem className={classes.listItem}>
                    <Box className={classes.listItemText}>
                        <Typography variant='subtitle1' className={classes.titleListItem}>
                            <IntlMessages id={title} />
                        </Typography>
                    </Box>
                </ListItem>
                {switchDataToDisplay(dataToDisplay)}
            </List>
        )
    }


    return (
        <>
            <Box className={classes.listContainer}>
                {selectedFields.length ?
                    (
                        <>
                            <Box className={classes.sideList}>
                                {list("subscriptions.stepper.step_two.field_list.title", "fieldName")}
                                {list("subscriptions.stepper.step_two.sum_field.title", "totalFields")}
                            </Box>
                            <Box className={classes.sideList}>
                                {list("subscriptions.stepper.step_two.area_list.title", "area")}
                                {list("subscriptions.stepper.step_two.sum_area.title", "totalArea")}
                            </Box>
                        </>
                    ) : (
                        <Typography variant='subtitle1' className={classes.fallBack}>
                            <IntlMessages id="subscriptions.stepper.step_two.fall_back.no_field_selected" />
                        </Typography>
                    )}
            </Box>
        </>
    )
}
