/**
 * Model used to store a field filter (e.g. crop filter)
 */
export default interface FieldFilters {
  [key: string]: string[]
}

export enum FieldFilterPropertyKey {
  CROP_NAME = "crop_name",
  FARMER = "farmer"
}