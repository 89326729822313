import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {getCurrentCampaign} from '../services/CampaignsService';

const useCurrentCampaign = () => {
    const {data} = useSelector<AppState, AppState['campaigns']>(
        (state) => state.campaigns,
    );
    const [currentCampaign, setCurrentCampaign] = useState<number>(0)

    useEffect(() => {
        if (data && Object.values(data).length > 0) {
            const currentCampaignId = getCurrentCampaign(Object.values(data));
            setCurrentCampaign(currentCampaignId)
        }
    }, [data]);

    return currentCampaign;

}

export default useCurrentCampaign;