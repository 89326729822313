import {Box} from '@mui/material';
import {
  Polygon as EditPolygon,
  Feature,
  FeatureOf,
  PolygonCoordinates,
  ViewMode,
} from '@nebula.gl/edit-modes';
import {MultiPolygon, Polygon} from 'geojson';
import React, {useEffect, useRef, useState} from 'react';
import ReactMapGL, {MapRef} from 'react-map-gl';
import {DrawPolygonMode, EditingMode, Editor} from 'react-map-gl-draw';
import useDimensions from 'react-use-dimensions';
import InfoIcon from 'shared/components/Icons/InfoIcon';
import IntlMessages from 'shared/components/IntlMessages';
import log from 'shared/services/LogService';
import useAlerterStyles from '../../../shared/components/Alerter.style';
import {mapToken} from '../../../shared/configs/AppConst';
import Field from '../models/Field';
import MapPosition from '../models/MapPosition';
import {FieldFormEditMode} from './AddFieldForm';
import {ZOOM_LEVEL_MARGIN_OFFSET} from './FieldsMap';
import {getEditHandleStyle, getFeatureStyle} from './FormMap.style';

const geoViewport = require('@mapbox/geo-viewport');

interface AddFieldFormMapProps {
  initialMapPosition?: MapPosition;
  readonly?: boolean;
  polygon?: Polygon | MultiPolygon;
  onPolygonChange?: (newPolygon: Polygon) => void;
  editMode: FieldFormEditMode;

  /**
   * Field to Edit, used to zoom
   */
  field?: Field;
}

const editingMode = new EditingMode();
const viewMode = new ViewMode();
const drawPolygonMode = new DrawPolygonMode();

const AddFieldFormMap: React.FC<AddFieldFormMapProps> = ({
  initialMapPosition,
  readonly,
  polygon,
  onPolygonChange,
  editMode,
  field,
}) => {
  const alerterClasses = useAlerterStyles();

  // Initialize viewport state
  const initialViewPort = {
    latitude: 46.23219,
    longitude: 2.20966,
    zoom: 5,
  };
  if (initialMapPosition) {
    initialViewPort.latitude = initialMapPosition.latitude;
    initialViewPort.longitude = initialMapPosition.longitude;
    initialViewPort.zoom = initialMapPosition.zoom;
  }
  const [viewport, setViewport] = useState(initialViewPort);
  const mapRef = useRef<MapRef>(null);
  const [ref, {width, height}] = useDimensions();

  const [editFeatures, setEditFeatures] = React.useState<Feature[]>([]);
  //console.log(`Edit features ${editFeatures.length} vs ${features.length}`);

  const [selectedFeatureIndex, setSelectedFeatureIndex] = React.useState<
    number | null
  >();

  /**
   * Update viewport state
   */
  const handleViewportChange = (newViewport: any) => {
    const {width, height, ...mapPosition} = newViewport;
    setViewport(mapPosition);
  };

  const zoomToBbox = function (
    zoomBbox: number[],
    width: number,
    height: number,
  ) {
    log.debug(`Size: ${width} x ${height}`);
    if (zoomBbox && width && height) {
      const viewport = geoViewport.viewport(zoomBbox, [width, height]);
      log.debug(`Target view port: ${JSON.stringify(viewport)}`);
      if (viewport && viewport.zoom) {
        setViewport({
          latitude: viewport.center[1],
          longitude: viewport.center[0],
          zoom: viewport.zoom - ZOOM_LEVEL_MARGIN_OFFSET,
        });
      }
    }
  };

  useEffect(() => {
    if (field && field.bbox && width && height) {
      zoomToBbox(field.bbox, width, height);
    }
  }, [field, width, height]);

  /**
   *
   */
  useEffect(() => {
    if (polygon) {
      // Create the feature using the given polygon to initialize the editor
      const features: Feature[] = [];
      let featureIndex: number | null = null;
      featureIndex = 0;
      if (polygon.type === 'MultiPolygon') {
        console.log(`Polygon coordinates: ${polygon.coordinates.length}`);
        // Split into several polygons
        polygon.coordinates.forEach((polygonCoordinates, index) => {
          const editPolygon: EditPolygon = {
            type: 'Polygon',
            coordinates: polygonCoordinates as PolygonCoordinates,
          };
          const feature: FeatureOf<EditPolygon> = {
            type: 'Feature',
            id: index,
            geometry: editPolygon,
            properties: {},
          };
          features.push(feature);
        });
      } else {
        const editPolygon: EditPolygon = polygon as EditPolygon;
        const feature: FeatureOf<EditPolygon> = {
          type: 'Feature',
          id: featureIndex,
          geometry: editPolygon,
          properties: {},
        };
        features.push(feature);
      }

      setEditFeatures(features);
      setSelectedFeatureIndex(featureIndex);
    }
  }, [polygon, width, height]);

  /**
   * callback when any feature is updated. Receives an object containing the following parameters
   * @param featuresUpdate
   * data (Feature[]) - the updated list of GeoJSON features.
   * editType (String) - addFeature, addPosition, finishMovePosition
   * editContext (Array) - list of edit objects, depend on editType, each object may contain featureIndexes, editHandleIndexes, screenCoords, mapCoords
   */
  const handleFeaturesUpdate = (featuresUpdate: {
    data: FeatureOf<EditPolygon>[];
    editType: string;
    editContext: any[];
  }) => {
    //console.log("Features update");
    //console.log(featuresUpdate);
    setEditFeatures(featuresUpdate.data);
    if (featuresUpdate.data && featuresUpdate.data.length > 0) {
      const lastFeature = featuresUpdate.data[featuresUpdate.data.length - 1];
      if (onPolygonChange) {
        onPolygonChange(lastFeature.geometry);
      }
    }
  };

  /**
   * Handle a user click on a feature
   */
  const onSelect = (selected: any) => {
    // DO NOTHING TO PREVENT DESELECTING POLYGON
    //setSelectedFeatureIndex(selected.selectedFeatureIndex);
  };

  return (
    // Hack to be able to set a ref to the box
    <Box
      width={1}
      height={1}
      {...({ref: ref} as any)}
      data-test='field-map-add'>
      <ReactMapGL
        width='100%'
        height='100%'
        {...viewport}
        ref={mapRef}
        mapboxApiAccessToken={mapToken}
        mapStyle='mapbox://styles/mapbox/satellite-streets-v11'
        onViewportChange={handleViewportChange}>
        {editMode === FieldFormEditMode.ADD_FIELD_MODAL && (
          <Box position='absolute' width={1} p={3}>
            <Box
              width={1}
              p={2}
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              className={alerterClasses.alerterContainer}>
              <InfoIcon color='primary' fontSize='large' />
              <IntlMessages id='fields.form.mapCreationInfo' />
            </Box>
          </Box>
        )}

        {editMode === FieldFormEditMode.MODIFY_FIELD_PAGE && (
          <Box position='absolute' width={1} p={3}>
            <Box
              width={1}
              p={2}
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              className={alerterClasses.alerterContainer}>
              <InfoIcon color='primary' fontSize='large' />
              {readonly ? (
                <IntlMessages id='fields.form.mapEditReadonlyInfo' />
              ) : (
                <IntlMessages id='fields.form.mapEditInfo' />
              )}
            </Box>
          </Box>
        )}

        <Editor
          mode={readonly ? viewMode : polygon ? editingMode : drawPolygonMode}
          features={editFeatures}
          clickRadius={12}
          selectedFeatureIndex={selectedFeatureIndex}
          onUpdate={handleFeaturesUpdate}
          onSelect={onSelect}
          featureStyle={getFeatureStyle}
          editHandleStyle={getEditHandleStyle}
          editHandleShape={'circle'}
        />
      </ReactMapGL>
    </Box>
  );
};

export default AddFieldFormMap;
