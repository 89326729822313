import React from 'react';
import {ServiceView} from 'shared/models/RouterParam';
import BareSoilHelp from './BareSoilHelp';
import CoverSoilHelp from './CoverSoilHelp';

interface CarbonHelpProps {
  /**
   * to manage which part of the information dialog is shown
   */
  selectedInfo: string;

  onActiveStepChange: (i: number) => void;

  step: number;
}

/**
 * Component to display the totality or different part of the information dialog
 * Contains a button to navigate between the different part of the dialog
 */
const CarbonHelp: React.FC<CarbonHelpProps> = ({
  selectedInfo,
  onActiveStepChange,
  step
}) => {
  React.useEffect(() => {
    switch (selectedInfo) {
      case ServiceView.BARE_SOIL:
        onActiveStepChange(0);
        break;
      case ServiceView.COVER_SOIL:
        onActiveStepChange(1);
        break;
    }
  }, [selectedInfo, onActiveStepChange]);

  const pageShown = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <BareSoilHelp />;
      case 1:
        return <CoverSoilHelp />;
      default: 
        return <BareSoilHelp />;
    }
  };

  return pageShown(step)
};

export default CarbonHelp;
