import {Box, Typography} from '@mui/material';
import {DateTime} from 'luxon';
import Field from 'modules/fields/models/Field';
import {isFieldSubscribe} from 'modules/fields/services/FieldsService';
import {formatDateLocale} from 'modules/vegetation/configs/VegetationChartConfig';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ServiceId} from 'shared/models/RouterParam';
import {getSpecieTranslation} from '../services/NitrogenService';

interface NitrogenMapTooltipProps {
  field: Field;
  campaignId: number;
}
const NitrogenMapTooltip: React.FC<NitrogenMapTooltipProps> = (props) => {
  const {field, campaignId} = props;
  const cover =
    field && field.nitrogen && field.nitrogen.length
      ? field.nitrogen.find((cover) => cover.campaign_id === campaignId)
      : null;
  if (cover && isFieldSubscribe(ServiceId.NITROGEN, campaignId, field)) {
    return (
      <>
        <Box p={1}>
          <Typography fontWeight={'bold'}>
            <FormattedMessage id='nitrogen.map.tooltip.date.title' />
          </Typography>
          <Typography p={1}>
            {formatDateLocale(DateTime.fromISO(cover.date_biomass).toMillis())}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography fontWeight={'bold'}>
            <FormattedMessage id='nitrogen.map.tooltip.n_tot.title' />
          </Typography>
          <Typography p={1}>
            {cover.val_mean_n_tot ? (
              <FormattedMessage
                id='nitrogen.map.tooltip.n_tot.value'
                values={{
                  value: cover.val_mean_n_tot.toFixed(1),
                }}
              />
            ) : (
              <FormattedMessage id='nitrogen.map.tooltip.undefined' />
            )}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography fontWeight={'bold'}>
            <FormattedMessage id='nitrogen.map.tooltip.n_soil.title' />
          </Typography>
          <Typography p={1}>
            {cover.val_mean_n_sol ? (
              <FormattedMessage
                id='nitrogen.map.tooltip.n_soil.value'
                values={{
                  value: cover.val_mean_n_sol.toFixed(1),
                }}
              />
            ) : (
              <FormattedMessage id='nitrogen.map.tooltip.undefined' />
            )}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography fontWeight={'bold'}>
            <FormattedMessage id='nitrogen.map.tooltip.species.title' />
          </Typography>
          <Box display={'flex'} flexWrap={'wrap'}>
            {cover.covercrop_composition.map((spec, index) => (
              <Typography key={index}>
                <FormattedMessage
                  id='nitrogen.map.tooltip.species.item'
                  values={{
                    name: getSpecieTranslation(spec.cc_species_id),
                    ponctuation:
                      index !== cover.covercrop_composition.length - 1
                        ? ','
                        : '',
                  }}
                />
              </Typography>
            ))}
          </Box>
        </Box>
      </>
    );
  }
  return null;
};

export default NitrogenMapTooltip;
