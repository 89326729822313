import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import AppLogo from '../AppLogo';
import {useHistory} from 'react-router-dom';
import {initialUrl} from 'shared/configs/AppConst';

const HeaderLogo = () => {
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      marginTop: 10,
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const onLogoClicked = () => {
    history.push(initialUrl);
  };

  return (
    <Box className={classes.logoRoot} onClick={onLogoClicked}>
      <AppLogo className={classes.logo} />
    </Box>
  );
};

export default HeaderLogo;
