import {selectedFieldRootPath, selectedFieldOverviewPath} from 'modules/fields/configs/FieldsRoutePaths';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

/**
 * Default vue to redirect if url path doesn't exist
 */
export const DEFAULT_IRRIGATION_VUE: ServiceView = ServiceView.REVIEW;

const irrigationOverviewPath = `${selectedFieldOverviewPath}/${ServiceView.IRRIGATION}`;

const irrigationServiceRootPath = `${selectedFieldRootPath}/${ServiceId.IRRIGATION}`;

/**
 * Full path to irrigation vue
 */
export const selectedFieldIrrigationWaterbalance = `${irrigationServiceRootPath}/${ServiceView.WATERBALANCE}`;
export const selectedFieldIrrigationEvapotranspiration = `${irrigationServiceRootPath}/${ServiceView.EVAPOTRANSPIRATION}`;

/**
 * Full path to irrigation vue
 */
export const selectedFieldIrrigationReview = `${irrigationServiceRootPath}/${ServiceView.REVIEW}`;

/**
 * Full path to edit vue
 */
export const selectedFieldIrrigationEdit = `${irrigationServiceRootPath}/${ServiceView.EDIT}`;

/**
 * Full path to map view
 */
export const overviewIrrigationMap = `${irrigationOverviewPath}/${ServiceView.MAP}`;

 /**
 * Full path to statistics view
 */
export const overviewIrrigationStatistics = `${irrigationOverviewPath}/${ServiceView.STATISTICS}`;
