import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';
import {ReactComponent as PastDueSubscription} from '../../assets/past_due_subscription.svg';

const PastDueSubscriptionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} component={PastDueSubscription} viewBox="0 0 83 100" />
  );
};

export default PastDueSubscriptionIcon;
