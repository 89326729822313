import WaterBalanceState from '../models/WaterBalanceState';
import NewDataModel from '../models/NewDataModel';
import DeleteDataModel from '../models/DeleteDataModel';
import ModifyDataModel from '../models/ModifyDataModel';
import {SummaryIrrigationServiceInfo} from '../models/SummaryIrrigationServiceInfo';
import {VegetationCompareFieldDataModel} from '../models/VegetationCompareFieldDataModel';
import {FieldIrrigationService} from 'modules/fields/models/FieldTesting';

export const SAVE_SUMMARY_IRRIGATION_INFO = 'SAVE_SUMMARY_IRRIGATION_INFO';
export interface SaveSummaryIrrigationInfoAction {
  type: typeof SAVE_SUMMARY_IRRIGATION_INFO;
  payload: SummaryIrrigationServiceInfo;
}

export const LOAD_WATER_BALANCE = 'LOAD_WATER_BALANCE';
export interface LoadWaterBalanceAction {
  type: typeof LOAD_WATER_BALANCE;
  payload: {
    fieldId: number;
    rotationId: number;
    forceCompute?: boolean;
  };
}

export const LOAD_WATER_BALANCE_SUCCESS = 'LOAD_WATER_BALANCE_SUCCESS';
export interface LoadWaterBalanceSuccessAction {
  type: typeof LOAD_WATER_BALANCE_SUCCESS;
  payload: WaterBalanceState;
}

export const SET_SELECTED_NDVI_DATE = 'SET_SELECTED_NDVI_DATE';
export interface SetSelectedNdviDateAction {
  type: typeof SET_SELECTED_NDVI_DATE;
  payload: string;
}
export const SET_SELECTED_COMPARE_NDVI_DATE = 'SET_SELECTED_COMPARE_NDVI_DATE';
export interface SetSelectedCompareNdviDateAction {
  type: typeof SET_SELECTED_COMPARE_NDVI_DATE;
  payload: string;
}

export const CLEAR_WATER_BALANCE_DATA = 'CLEAR_WATER_BALANCE_DATA';
export interface ClearWaterBalanceDataAction {
  type: typeof CLEAR_WATER_BALANCE_DATA;
}

export const ADD_IRRIGATION = 'ADD_IRRIGATION';
export interface AddIrrigationAction {
  type: typeof ADD_IRRIGATION;
  payload: NewDataModel;
}

export const DELETE_IRRIGATION = 'DELETE_IRRIGATION';
export interface DeleteIrrigationAction {
  type: typeof DELETE_IRRIGATION;
  payload: DeleteDataModel;
}

export const MODIFY_IRRIGATION = 'MODIFY_IRRIGATION';
export interface ModifyIrrigationAction {
  type: typeof MODIFY_IRRIGATION;
  payload: ModifyDataModel;
}

export const ADD_RAIN = 'ADD_RAIN';
export interface AddRainAction {
  type: typeof ADD_RAIN;
  payload: NewDataModel;
}

export const DELETE_RAIN = 'DELETE_RAIN';
export interface DeleteRainAction {
  type: typeof DELETE_RAIN;
  payload: DeleteDataModel;
}

export const MODIFY_RAIN = 'MODIFY_RAIN';
export interface ModifyRainAction {
  type: typeof MODIFY_RAIN;
  payload: ModifyDataModel;
}

export const COMPARE_FIELDS = 'COMPARE_FIELDS';
export interface CompareFieldsAction {
  type: typeof COMPARE_FIELDS;
  payload: FieldIrrigationService[];
}
export const COMPARE_FIELDS_SUCCESS = 'COMPARE_FIELDS_SUCCESS';
export interface CompareFieldsSuccessAction {
  type: typeof COMPARE_FIELDS_SUCCESS;
  payload: {[key: string]: VegetationCompareFieldDataModel[]};
}

export const RESET_WATERBALANCE_DATA = 'RESET_WATERBALANCE_DATA';
export interface ResetWaterBalanceDataAction {
  type: typeof RESET_WATERBALANCE_DATA;
}

export const GET_PDF_WATERBALANCE = 'GET_PDF_WATERBALANCE';
export interface GetPdfWaterBalanceAction {
  type: typeof GET_PDF_WATERBALANCE;
}

export const UPDATE_PDF_WATERBALANCE_STATUS = 'UPDATE_PDF_WATERBALANCE_STATUS';
export interface UpdatePdfWaterbalanceStatus {
  type: typeof UPDATE_PDF_WATERBALANCE_STATUS;
  payload: WaterBalanceState['sending_pdf'];
}

export type WaterBalanceActionTypes =
  | LoadWaterBalanceAction
  | LoadWaterBalanceSuccessAction
  | SetSelectedNdviDateAction
  | ClearWaterBalanceDataAction
  | AddIrrigationAction
  | DeleteIrrigationAction
  | ModifyIrrigationAction
  | AddRainAction
  | DeleteRainAction
  | ModifyRainAction
  | SaveSummaryIrrigationInfoAction
  | SetSelectedCompareNdviDateAction
  | CompareFieldsAction
  | CompareFieldsSuccessAction
  | ResetWaterBalanceDataAction
  | GetPdfWaterBalanceAction
  | UpdatePdfWaterbalanceStatus;

export const actions = {
  getPdfWaterBalance: (): GetPdfWaterBalanceAction => {
    return {
      type: GET_PDF_WATERBALANCE,
    };
  },
  updatePdfWaterBalanceStatus: (
    status: WaterBalanceState['sending_pdf'],
  ): UpdatePdfWaterbalanceStatus => {
    return {
      type: UPDATE_PDF_WATERBALANCE_STATUS,
      payload: status,
    };
  },

  resetWaterBalanceData: (): ResetWaterBalanceDataAction => {
    return {
      type: RESET_WATERBALANCE_DATA,
    };
  },
  compareFieldsSuccess: (data: {
    [key: string]: VegetationCompareFieldDataModel[];
  }): CompareFieldsSuccessAction => {
    return {
      type: COMPARE_FIELDS_SUCCESS,
      payload: data,
    };
  },

  compareField: (rotations: FieldIrrigationService[]): CompareFieldsAction => {
    return {
      type: COMPARE_FIELDS,
      payload: rotations,
    };
  },

  saveSummaryIrrigationInfo: (
    infos: SummaryIrrigationServiceInfo,
  ): SaveSummaryIrrigationInfoAction => {
    return {
      type: SAVE_SUMMARY_IRRIGATION_INFO,
      payload: infos,
    };
  },

  loadWaterBalance: (
    fieldId: number,
    rotationId: number,
    forceCompute?: boolean,
  ): LoadWaterBalanceAction => {
    return {
      type: LOAD_WATER_BALANCE,
      payload: {fieldId, rotationId, forceCompute},
    };
  },

  loadWaterBalanceSuccessAction: (
    payload: WaterBalanceState,
  ): LoadWaterBalanceSuccessAction => {
    return {
      type: LOAD_WATER_BALANCE_SUCCESS,
      payload: payload,
    };
  },

  setSelectedNdviDate: (date: string): SetSelectedNdviDateAction => {
    return {
      type: SET_SELECTED_NDVI_DATE,
      payload: date,
    };
  },
  setSelectedCompareNdviDate: (
    date: string,
  ): SetSelectedCompareNdviDateAction => {
    return {
      type: SET_SELECTED_COMPARE_NDVI_DATE,
      payload: date,
    };
  },

  clearWaterBalanceData: (): ClearWaterBalanceDataAction => {
    return {
      type: CLEAR_WATER_BALANCE_DATA,
    };
  },

  addIrrigation: (newIrrigation: NewDataModel): AddIrrigationAction => {
    return {
      type: ADD_IRRIGATION,
      payload: newIrrigation,
    };
  },

  deleteIrrigation: (
    selectedIrrigation: DeleteDataModel,
  ): DeleteIrrigationAction => {
    return {
      type: DELETE_IRRIGATION,
      payload: selectedIrrigation,
    };
  },

  modifyIrrigation: (
    modifiedIrrigation: ModifyDataModel,
  ): ModifyIrrigationAction => {
    return {
      type: MODIFY_IRRIGATION,
      payload: modifiedIrrigation,
    };
  },

  addRain: (newRain: NewDataModel): AddRainAction => {
    return {
      type: ADD_RAIN,
      payload: newRain,
    };
  },

  deleteRain: (selectedRain: DeleteDataModel): DeleteRainAction => {
    return {
      type: DELETE_RAIN,
      payload: selectedRain,
    };
  },

  modifyRain: (modifiedRain: ModifyDataModel): ModifyRainAction => {
    return {
      type: MODIFY_RAIN,
      payload: modifiedRain,
    };
  },
};

export default actions;
