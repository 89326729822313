import {FormikProps} from "formik"
import NitrogenEdit from "modules/nitrogen/components/NitrogenEdit";
import IrrigationFieldEditForm from "modules/waterBalance/components/IrrigationFieldEditForm";
import React from "react";
import {ServiceId} from "shared/models/RouterParam"
import {FieldFormEditMode} from "../components/AddFieldForm";
import FieldCreation from "../models/FieldCreation"

/**
 * Get service input require if exists to edit field into a service
 * @param service
 * @param formikProps
 * @returns
 */
export const getServiceFieldForm = (
  service: ServiceId,
  editMode: FieldFormEditMode,
  formikProps: FormikProps<FieldCreation>,
) => {
  switch (service) {
    case ServiceId.IRRIGATION:
      return (
        <IrrigationFieldEditForm
          formikProps={formikProps}
          editMode={editMode}
        />
      );
    case ServiceId.VEGETATION:
      return (
        <IrrigationFieldEditForm
          formikProps={formikProps}
          editMode={editMode}
        />
      );
    case ServiceId.WEATHER:
      return (
        <IrrigationFieldEditForm
          formikProps={formikProps}
          editMode={editMode}
        />
      );
    case ServiceId.NITROGEN:
      return <NitrogenEdit formikProps={formikProps} editMode={editMode} />;
    default:
      return null;
  }
};
