import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import ArrowLeftMiddle from './icons/ArrowLeftMiddle';
import ArrowRightBottom from './icons/ArrowRightBottom';
import ArrowRightTop from './icons/ArrowRightTop';
import ArrowTopLeft from './icons/ArrowTopLeft';
import historyVegetationImage from '../assets/historyVegetationImage.png'
import {FormattedMessage} from 'react-intl';

const VegetationHistoryHelp: React.FC = () => {
  const theme = useTheme()
  return (
    <Box flex={1} width={1} display="flex" flexDirection={"column"}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.vegetation.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: "center"
        }}>
        <Typography
          width='690px'
          variant='caption'
          color={theme.palette.grey[600]}
          paddingY={3}
          textAlign='center'>
          <FormattedMessage id='info.vegetation.explaination' />
        </Typography>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={historyVegetationImage} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-52}
            left={-210}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.vegetation.map' />
              </Typography>
              <ArrowTopLeft
                sx={{
                  fontSize: '250px',
                  position: 'absolute',
                  top: -60,
                  left: 150,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={0}
            right={-200}>
            <Box width={'180px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.vegetation.chart' />
              </Typography>
              <ArrowRightTop
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 60,
                  right: 110,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-50}
            right={-220}>
            <Box width={'210px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.vegetation.images' />
              </Typography>
              <ArrowRightBottom
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: -65,
                  right: 130,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-180}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight='bold'>
                <FormattedMessage id='info.select_field' />
              </Typography>
              <ArrowLeftMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -50,
                  right: -90,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default VegetationHistoryHelp;