import {ExpandLess, ExpandMore, Visibility} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  BoxProps,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ErrorWithCode} from 'shared/models/HttpError';
import imageApiService from 'shared/services/ImageApiService';

interface GetStatisticPixelValueProps {
  image: string | undefined;
  bbox: number[];
  box?: BoxProps;
  size?: 'small';
  position?: number[] | null;
  collapsedTextFields?: boolean;
  instruction?: string;
}

const GetStatisticPixelValueEditor: React.FC<GetStatisticPixelValueProps> = (
  props,
) => {
  const {position, image, bbox, size, collapsedTextFields} = props;
  const intl = useIntl();
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [retrievingPixelValue, setRetrievingPixelValue] =
    useState<boolean>(false);
  const [pixelValue, setPixelValue] = useState<number[] | null>(null);
  const [pixelValueError, setPixelValueError] = useState<ErrorWithCode | null>(
    null,
  );
  const [currentCollapsedTextFields, setCurrentCollapsedTextFields] =
    useState<boolean>(collapsedTextFields ? true : false);
  const theme = useTheme();

  const handleRetrievePixelValue = async (
    lon: string | number,
    lat: string | number,
  ) => {
    setRetrievingPixelValue(true);
    setPixelValueError(null);
    setPixelValue(null);
    if (image) {
      const pixelValueResult = await imageApiService.getImagePixelValue(
        image,
        lon,
        lat,
      );
      if (pixelValueResult.pixelValue?.values) {
        setPixelValue(pixelValueResult.pixelValue?.values);
      }
      if (pixelValueResult.error) {
        setPixelValueError(pixelValueResult.error);
      }
    }
    setRetrievingPixelValue(false);
  };

  useEffect(() => {
    if (position) {
      setLongitude(position[0].toString());
      setLatitude(position[1].toString());
      handleRetrievePixelValue(position[0], position[1]);
    } else {
      setPixelValue(null);
      setPixelValueError(null);
      if (bbox) {
        const centerLongitude = (bbox[0] + bbox[2]) / 2;
        const centerLatitude = (bbox[1] + bbox[3]) / 2;

        if (!latitude) {
          setLatitude(centerLatitude.toFixed(6));
        }
        if (!longitude) {
          setLongitude(centerLongitude.toFixed(6));
        }
      }
    }
  }, [position, bbox]);

  const formatNumber = (num: number): string => num.toFixed(2);

  return (
    <Box {...props.box} display={'flex'} flexDirection={'column'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignContent={'center'}
        alignItems={'center'}>
        <Typography
          variant='subtitle1'
          style={{
            fontWeight: 'bold',
          }}>
          <FormattedMessage id='statistics.get_pixel_value_title' />
        </Typography>
        {collapsedTextFields ? (
          <IconButton
            size='small'
            onClick={() => {
              setCurrentCollapsedTextFields(!currentCollapsedTextFields);
            }}>
            {currentCollapsedTextFields ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        ) : null}
      </Box>
      {currentCollapsedTextFields ? null : (
        <Box
          mt={size === 'small' ? 2 : 4}
          mb={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: size === 'small' ? 3 : 10,
          }}>
          <TextField
            size='small'
            label={intl.formatMessage({
              id: 'statistics.get_pixel_value_longitude',
            })}
            value={longitude}
            onChange={(evt) => {
              if (evt.target.value) {
                setLongitude(evt.target.value);
              }
            }}
          />
          <TextField
            size='small'
            label={intl.formatMessage({
              id: 'statistics.get_pixel_value_latitude',
            })}
            value={latitude}
            onChange={(evt) => {
              if (evt.target.value) {
                setLatitude(evt.target.value);
              }
            }}
          />
          <LoadingButton
            loading={retrievingPixelValue}
            loadingPosition='start'
            startIcon={<Visibility />}
            variant='contained'
            onClick={() => handleRetrievePixelValue(longitude, latitude)}>
            {size === 'small'
              ? ''
              : intl.formatMessage({
                  id: 'statistics.get_pixel_value_button',
                })}
          </LoadingButton>
        </Box>
      )}
      <Box mt={size === 'small' ? 0 : 2}>
        {retrievingPixelValue && <LinearProgress color='primary' />}
        {pixelValueError && !retrievingPixelValue && (
          <Typography color={theme.palette.error.main}>
            {pixelValueError.message}
          </Typography>
        )}
        {pixelValue && !retrievingPixelValue ? (
          <Typography>
            {intl.formatMessage(
              {id: 'statistics.get_pixel_value_result'},
              {
                pixelValue: pixelValue.map(formatNumber).join(', '),
              },
            )}
          </Typography>
        ) : (
          <Typography>
            {intl.formatMessage({
              id: 'statistics.get_pixel_value_instruction',
            })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default GetStatisticPixelValueEditor;
