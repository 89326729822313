import CropCode from '../models/CropCode';
import CropModel, {PhenoStage} from '../models/CropModel';

export const getCropTranslationKey = (crop: CropModel): string | null => {
  return getCropTranslationKeyFromCode(crop.crop_code);
};

export const getCropTranslationKeyFromCode = (
  cropCode?: CropCode,
): string | null => {
  if (cropCode) {
    return `crops.${cropCode.toLowerCase()}.label`;
  } else {
    return null;
  }
};

export const getPhenoStageTranslations = (
  gddStage: PhenoStage,
  crop_id: number,
): {intlValue: string; defaultValue: string} => {
  return {
    intlValue: `crops.${crop_id}.pheno_stage.${gddStage.stage_type}`,
    defaultValue: gddStage.stage_type,
  };
};
