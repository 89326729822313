import {selectedFieldRootPath} from 'modules/fields/configs/FieldsRoutePaths';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

/**
 * Default vue to redirect if url path doesn't exist
 */
export const DEFAULT_WEATHER_VUE: ServiceView = ServiceView.WEATHER_ACCUMULATION;

const weatherServiceRootPath = `${selectedFieldRootPath}/${ServiceId.WEATHER}`;

/**
 * Full path to weather overview vue
 */
export const selectedFieldWeather = `${weatherServiceRootPath}/${ServiceView.WEATHER_OVERVIEW}`;

/**
 * Full path to weather accumulation vue
 */
export const selectedFieldWeatherAccumulation = `${weatherServiceRootPath}/${ServiceView.WEATHER_ACCUMULATION}`;

/**
 * Full path to weather +/- 7 days vue
 */
export const selectedFieldWeatherWeek = `${weatherServiceRootPath}/${ServiceView.WEATHER_WEEK}`;

/**
 * Full path to edit vue
 */
export const selectedFieldWeatherEdit = `${weatherServiceRootPath}/${ServiceView.EDIT}`;
