import {Box, Typography} from '@mui/material';
import React from 'react';
import IntlMessages from 'shared/components/IntlMessages';
import {AREA_UNIT} from '../configs/constant';



interface HelperTextQuantityDisableProps {
    quantity?: number;
    over?: boolean;
}

export default function HelperTextQuantityDisable({quantity, over}: HelperTextQuantityDisableProps) {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' width={'80%'}>
            <Typography variant='subtitle1' style={{fontWeight: 'bold'}} color='error'>
                <IntlMessages id="subscription.price.disable.title" />
            </Typography>
            <Typography style={{textAlign: 'center'}}>
                {over ? <IntlMessages id="subscription.price.disable.over_max_quantity_reason" /> :
                    <IntlMessages id="subscription.price.disable.less_min_quantity_reason" />}
                {`${quantity} ${AREA_UNIT}`}
            </Typography>
        </Box>
    )
}