export const AREA_UNIT = "ha"

export const DAYS_IN_MONTH = 30.5;

export const DAYS_IN_WEEK = 7;

export const DAYS_IN_YEAR = 365;

export const WEEK_IN_MONTH = 4.357;

export const YEAR_IN_MONTH = (1 / 12);

export const PRODUCT_TRIAL_KEYWORD = "trial";

export const STRIPE_METADATA_DESCRIPTION_PREFIX = "description_";

export const STRIPE_METADATA_REF = "ref";

export const STRIPE_METADATA_SERVICE = "service";

export const STRIPE_METADATA_SERVICE_ROLES = "service_roles";

export const STRIPE_METADATA_MAX_QUANTITY = "max_quantity";

export const STRIPE_METADATA_MIN_QUANTITY = "min_quantity";