import {Box, Card, useTheme} from '@mui/material';
import React from 'react';
import {FieldFormEditMode} from './AddFieldForm';
import useStyles from './Fields.style';


interface EditFieldContainerProps {
    editMode: FieldFormEditMode
  type: "map" | "form"
  overflow?: string

}
/**
 * Form and map generic container, apply card or box with right classes according to editMode
 * @editMode
 * @type : "map"|"form" use to apply right className
 * @returns 
 */
const EditFieldContainer: React.FC<EditFieldContainerProps> = (props) => {
    const {editMode, type, overflow} = props
    const classes = useStyles();
    const theme = useTheme()

    return (
      <>
        {editMode === FieldFormEditMode.MODIFY_FIELD_PAGE ? (
          <Card
            sx={{marginY: '10px', padding: theme.spacing(2), overflow: overflow || "unset"}}
            className={
              type === 'map'
                ? classes.formMapContainer
                : `${classes.formModifyInfos}`
            }>
            {props.children}
          </Card>
        ) : (
            <Box
              sx={{overflow: overflow || "unset"}}
            className={
              type === 'map'
                ? classes.formMapContainer
                : ""
            }>
            {props.children}
          </Box>
        )}
      </>
    );
}

export default EditFieldContainer;