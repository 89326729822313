import {isEuMember} from 'is-eu-member';
import VATVerificationResult from 'modules/auth/models/VATVerificationResult';
import {Store} from 'redux';
import {
  getTerranisOauthAuthenticationEndpoint,
  getTerranisSharedApiEndpoint,
} from 'shared/configs/FirebaseConfig';
import {pixagriApiServer} from 'shared/services/ApiConfig';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store/index';
import axiosInstance from '../../../shared/services/ApiConfig';
import authActions from '../actions/AuthActions';
import {AUTH_TOKEN_PARAM} from '../configs/AuthConfig';
import {LOGIN_URL} from '../sagas/AuthSagas';

let requestInterceptorId: number | null = null;
let responseInterceptorId: number | null = null;

export const redirectToOAuthIfNeeded = async (id_token: string) => {
  const auth_token = new URLSearchParams(window.location.search).get(
    AUTH_TOKEN_PARAM,
  );

  if (!auth_token) {
    console.log('No need to redirect');
    return;
  }

  if (!id_token) {
    return;
  }

  const response = await fetch(getTerranisOauthAuthenticationEndpoint(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      auth_token: auth_token,
      id_token: id_token,
      success: 'true',
    }),
  });

  const data = await response.json();

  window.location = data.url;
};

/**
 * Check token validity given an expiration date
 * @param tokenExpiration the token expiration
 */
export const checkTokenValidity = function (
  tokenExpiration: number | null,
): boolean {
  let tokenExpired: boolean = true;
  if (tokenExpiration && !isNaN(tokenExpiration) && tokenExpiration > 0) {
    const now = Math.floor(Date.now() / 1000);
    console.log(
      `Token expiration: ${new Date(tokenExpiration * 1000).toISOString()}`,
    );
    console.log(
      `Token expiration: ${tokenExpiration}, now: ${now}, diff ${
        tokenExpiration - now
      }`,
    );
    if (tokenExpiration - now > 0) {
      tokenExpired = false;
    } else {
      console.log(`Token has expired`);
    }
  }
  return tokenExpired;
};

export const validateTaxNumber = async (
  taxNumber: string,
  country: string,
): Promise<boolean> => {
  log.debug(`Validate ${taxNumber} for country ${country}`);
  if (taxNumber && country) {
    if (isEuMember(country)) {
      if (taxNumber.toUpperCase().startsWith(country.toUpperCase())) {
        const taxValidationUrl = `${getTerranisSharedApiEndpoint()}/users/validate_vat?vat_number=${taxNumber}`;
        log.debug(`Using VAT url ${taxValidationUrl}`);
        const taxValidationResp = await fetch(taxValidationUrl);
        const taxValidationResult =
          (await taxValidationResp.json()) as VATVerificationResult;
        if (
          taxValidationResult &&
          taxValidationResult.data &&
          taxValidationResult.data.valid
        ) {
          return true;
        }
      }
    } else {
      return true;
    }
  }
  return true;
};

/**
 * Network interceptor:
 * Add token authorization header to external requests to API
 * Track network error to logout when 401 is received
 * @param store
 */
export const activateAuthInterceptor = (store: Store<AppState>) => {
  requestInterceptorId = axiosInstance.interceptors.request.use(
    (config) => {
      const state = store.getState();
      if (state.auth && state.auth.token) {
        if (config.url) {
          if (config.url.startsWith('http')) {
            const {origin} = new URL(config.url);
            const allowedOrigins = [pixagriApiServer];
            if (allowedOrigins.includes(origin)) {
              //console.log(`Adding authorization bearer for ${config.url}`)
              config.headers.authorization = `Bearer ${state.auth.token}`;
            } else {
              //console.log(`Do not add authorization bearer for ${origin}`)
            }
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  responseInterceptorId = axiosInstance.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        console.log(`Error status: ${error.response.status}`);
        // Check token validity
        const state = store.getState();
        const tokenExpired = checkTokenValidity(state.auth.tokenExpiration);
        if (!tokenExpired) {
          console.log(`Received 401 but token has not expired`);
          // TODO: send error report
        }
        // Signout anyway except if it's the signin or page
        if (
          !error.request.responseURL ||
          !error.request.responseURL.includes(LOGIN_URL)
        ) {
          store.dispatch(authActions.signoutUser());
        }
      } else {
        console.log(error);
      }

      return Promise.reject(error);
    },
  );
  console.log(
    `Auth interceptor with request id: ${requestInterceptorId} and response id: ${responseInterceptorId}`,
  );
};
