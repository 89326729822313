import {CoverCropExploitation} from './CoverCropExploitation';
import {ResiduManagement} from './ResidueManagement';

export interface NitrogenCoverSat {
  covercrop_id: number;
  field_id: number;
  campaign_id: number;
  sowing_date: string;
  destruction_date: string;
  residue_management: ResiduManagement;
  covercrop_exploitation: CoverCropExploitation;
  target_n_quantity: number;
  biomass: Biomass[];
}

export interface Biomass {
  bbox: string;
  id_biomass: number;
  date_biomass: string;
  date_biomass_epoch?: number;
  id_covercrop: number;
  path_biomass_png: string;
  path_biomass_tif: string;
  path_biomass_zones: null;
  // biomass
  val_mean_biomass: number;
  val_median_biomass: number;
  val_max_biomass: number;
  val_min_biomass: number;
  val_std_biomass: number;
  biomass_area?: number[];

  cloud_cover: number;
  processing_date: string;
  id_merci: number;
  path_n_tot_png: string;
  path_n_tot_tif: string;
  path_n_tot_zones: null;
  path_n_sol_png: string;
  path_n_sol_tif: string;
  path_n_sol_zones: null;
  path_p_png: string;
  path_p_tif: string;
  path_k_png: string;
  path_k_tif: string;
  path_s_png: string;
  path_s_tif: string;
  path_c_png: string;
  path_c_tif: string;
  path_mg_png: string;
  // Azote total’
  val_mean_n_tot: number;
  val_median_n_tot: number;
  val_max_n_tot: number;
  val_min_n_tot: number;
  val_std_n_tot: number;
  n_tot_area?: number[];

  // azote sol
  val_mean_n_sol: number;
  val_median_n_sol: number;
  val_max_n_sol: number;
  val_min_n_sol: number;
  val_std_n_sol: number;
  n_sol_area?: number[];
  // phosphore
  val_mean_p: number;
  val_median_p: number;
  val_max_p: number;
  val_min_p: number;
  val_std_p: number;
  p_area?: number[];
  //Potassium
  val_mean_k: number;
  val_median_k: number;
  val_max_k: number;
  val_min_k: number;
  val_std_k: number;
  k_area?: number[];
  // Souffre
  val_mean_s: number;
  val_median_s: number;
  val_max_s: number;
  val_min_s: number;
  val_std_s: number;
  s_area?: number[];
  //carbon
  val_mean_c: number;
  val_median_c: number;
  val_max_c: number;
  val_min_c: number;
  val_std_c: number;
  c_area?: number[];
  //Magnesium
  path_mg_tif: string;
  val_mean_mg: number;
  val_median_mg: number;
  val_max_mg: number;
  val_min_mg: number;
  val_std_mg: number;
  mg_area?: number[];
  dyn_n: DynNitrogen;
  dyn_n_data: DynNitrogenData[];
}

export interface DynNitrogen {
  dyn_n_30d: number;
  dyn_n_60d: number;
  dyn_n_90d: number;
  dyn_n_120d: number;
  dyn_n_150d: number;
  dyn_n_180d: number;
}

export interface DynNitrogenData {
  dyn_n_value: number;
  day_number: DayPlus;
  dateEpoch: number;
}

export enum DayPlus {
  D30 = 30,
  D60 = 60,
  D90 = 90,
  D120 = 120,
  D150 = 150,
  D180 = 180,
}
