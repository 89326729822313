export const frReportTranslations = {
  'report.modal.title': 'Génération du bilan de campagne',
  'report.modal.error.title': 'Erreur',
  'report.modal.error.text':
    "Une erreur s'est produite durant la génération du rapport",
  'report.modal.selector.text':
    'Sélectionnez la langue dans laquelle le rapport doit être généré',
  'report.modal.error.button': 'Fermer',
  'report.modal.button': 'Télécharger',
};
