import TextField from '@mui/material/TextField';
import {useField} from 'formik';
import React from 'react';

/**
 * Formik textfield wrapper
 * @param props
 */
const MyTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      variant={props.variant ? props.variant : 'standard'}
    />
  );
};
export default MyTextField;
