import {Box, Card, CardProps, Theme, Typography, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import IntlMessages from 'shared/components/IntlMessages';
import {HydroStressLevel} from '../models/HydroStressLevel';
import {
  getHydroStressLevelColor,
  getHydroStressLevelMessageId,
} from '../services/WaterBalanceService';

const useStyles = makeStyles((theme: Theme) => ({
  levelColor: {
    height: 10,
    width: 30,
    marginRight: theme.spacing(2),
  },
}));

interface HydroStressLegendProps extends CardProps {}

/**
 * Component with the NDVI legend gradient and card around it
 * @param props
 */
const HydroStressLegend: React.FC<HydroStressLegendProps> = (props) => {
  const theme = useTheme();
  const styles = useStyles();

  const levelValues = Object.values(HydroStressLevel);
  return (
    <Card {...props}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyItems='center'>
        <h5>
          <IntlMessages id='waterBalance.SWC2Risk.label' />
        </h5>
        <Box
          pt={2}
          width={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          gap={2}>
          {levelValues.map((levelValue) => {
            return (
              <Box
                flex={1}
                display='flex'
                flexDirection='row'
                alignItems='center'
                key={levelValue}>
                <div
                  className={styles.levelColor}
                  style={{
                    background: getHydroStressLevelColor(levelValue, theme),
                  }}
                />
                <Typography variant='caption' noWrap>
                  <IntlMessages id={getHydroStressLevelMessageId(levelValue)} />
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

export default HydroStressLegend;
