import {AddRotation} from '../models/AddRotation';

export const BASE_ROTATION_CREATION: AddRotation = {
  sowing_date: '',
  harvest_date: '',
  crop_id: 0,
  sys_irrigation_id: 0,
};

export const gaugeColors = ['#00FF00', '#FFE700', '#FFA700', '#FF0000'];
