import {Box, Typography} from "@mui/material";
import React from "react";
import {FormattedMessage} from "react-intl";
import coverSoilImage from "../assets/coverSoilImage.png";
import ArrowLeftMiddle from "./icons/ArrowLeftMiddle";
import ArrowRightBottom from "./icons/ArrowRightBottom";
import ArrowRightMiddleBottom from "./icons/ArrowRightMiddleBottom";
import ArrowRightMiddleTop from "./icons/ArrowRightMiddleTop";
import ArrowRightTop from "./icons/ArrowRightTop";
import ArrowTopLeft from "./icons/ArrowTopLeft";
import ArrowTopMiddle from "./icons/ArrowTopMiddle";

const CoverSoilHelp: React.FC = () => {
  return (
    <Box flex={1} width={1} display="flex" flexDirection={"column"}>
      <Typography
        variant='h4'
        fontWeight={'bold'}
        sx={{alignSelf: 'flex-start'}}>
        <FormattedMessage id='info.carbon.title' />
      </Typography>
      <Box
        flex={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Box
          alignSelf={'center'}
          width={'700px'}
          id='image-container'
          sx={{position: 'relative'}}>
          <img src={coverSoilImage} alt='nitrogen' width={'700px'} />
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-55}
            left={-55}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.go_to_edit_page' />
              </Typography>
              <ArrowTopLeft
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -20,
                  left: 140,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-40}
            left={280}>
            <Box width={'200px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.select_campaign' />
              </Typography>
              <ArrowTopMiddle
                sx={{
                  fontSize: '150px',
                  position: 'absolute',
                  top: -20,
                  right: 160,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={-65}
            right={-165}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.go_to_bare_soil' />
              </Typography>
              <ArrowRightTop
                sx={{
                  fontSize: '100px',
                  position: 'absolute',
                  top: 27,
                  right: 85,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={100}
            right={-160}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.cover_soil.gauge' />
              </Typography>
              <ArrowRightMiddleTop
                sx={{
                  fontSize: '80px',
                  position: 'absolute',
                  top: 20,
                  right: 90,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={190}
            right={-200}>
            <Box width={'170px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.cover_soil.data' />
              </Typography>
              <Typography variant='caption' component={'div'}>
                <FormattedMessage id='info.carbon.cover_soil.data.days_number' />
              </Typography>
              <Typography variant='caption' component={'div'}>
                <FormattedMessage id='info.carbon.cover_soil.data.period_number' />
              </Typography>
              <Typography variant='caption' component={'div'}>
                <FormattedMessage id='info.carbon.cover_soil.data.cycle' />
              </Typography>

              <ArrowRightMiddleBottom
                sx={{fontSize: '40px', position: 'absolute', top: 0, left: -45}}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            bottom={-60}
            right={-180}>
            <Box width={'180px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.carbon.cover_soil.timeline' />
              </Typography>
              <ArrowRightBottom
                sx={{
                  fontSize: '70px',
                  position: 'absolute',
                  top: -60,
                  right: 130,
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            position={'absolute'}
            alignItems='center'
            top={'50%'}
            left={-170}>
            <Box width={'150px'} position='relative'>
              <Typography variant='caption' fontWeight={'bold'}>
                <FormattedMessage id='info.select_field' />
              </Typography>
              <ArrowLeftMiddle
                sx={{
                  fontSize: '90px',
                  position: 'absolute',
                  top: -50,
                  right: -20,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoverSoilHelp;
