import {AppState} from 'shared/store';
import {GET_REPORT, GetReportActions, reportActions} from './ReportActions';

import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {GetReportPayload} from '../models/GetReportPayload';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions';
import basePixagriApiService from 'shared/services/BasePixagriApiService';
import FieldsState from 'modules/fields/models/FieldsState';

interface ResponseFormat {
  status: number | null;
  link: string | null;
  error: any;
}

export function* reportSaga() {
  yield all([takeLatest(GET_REPORT, getReportSaga)]);
}

async function getReportRequest(
  payload: GetReportPayload,
  token: string,
): Promise<ResponseFormat> {
  const responseFormat: ResponseFormat = {
    status: null,
    link: null,
    error: null,
  };
  const url = `${basePixagriApiService.getPixagriRootApiUrl()}/report`;

  const header = new Headers();
  header.append('Authorization', `Bearer ${token}`);
  header.append('Content-Type', `application/json`);
  const requestOptions: RequestInit = {
    headers: header,
    redirect: 'follow',
    method: 'POST',
    body: JSON.stringify(payload),
  };
  const req = fetch(url, requestOptions);
  const response = await req;
  responseFormat.status = response.status;
  if (response.status < 300) {
    const data = await response.json();
    const {link} = data;
    responseFormat.link = link;
  } else {
    const error = await response.json();
    responseFormat.error = error;
  }
  return responseFormat;
}
function* getReportSaga(action: GetReportActions) {
  try {
    yield put(fetchStart('getReportSaga'));
    const {lang, email, send_email} = action.payload;
    const {selectedCampaign, selectedFieldId, fieldsList}: FieldsState = yield select((state: AppState) => state.fields);
    const {token} = yield select((state: AppState) => state.auth);

    let farmer_id: number | null = null;

    if(fieldsList && fieldsList.length) {
      const selectedField = fieldsList.find(field => field.field_id === selectedFieldId)

      if(selectedField) {
        farmer_id = selectedField.farmer_id
      }
    }
    if(!selectedCampaign || !farmer_id || !lang ) {
      throw new Error("Missing informations")
    }
    const requestBody: GetReportPayload = {
      lang: lang,
      email: email,
      send_email: send_email,
      campaign_id: selectedCampaign,
      farmer_id: farmer_id,
    };
    const res: ResponseFormat = yield call(
      getReportRequest,
      requestBody,
      token,
    );
    if (res.link) {
      window.open(res.link, '_blank');
    } else {
      yield put(
        reportActions.getReportError(JSON.stringify(res.error) as string),
      );
    }
    yield put(fetchSuccess('getReportSaga'));
  } catch (err) {
    yield put(fetchError('getReportSaga', err as string));
  }
}
