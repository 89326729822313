import {CropsTranslationsType} from './CropsTranslationsType';

export const cropsTranslationsIt: CropsTranslationsType = {
  'crops.58.pheno_stage.stade_1': 'Germinazione',
  'crops.58.pheno_stage.stade_2': '1 foglia',
  'crops.58.pheno_stage.stade_3': '2 foglie',
  'crops.58.pheno_stage.stade_4': '4 foglie',
  'crops.58.pheno_stage.stade_5': '6 foglie',
  'crops.58.pheno_stage.stade_6': '7 foglie',
  'crops.58.pheno_stage.stade_7': '8 foglie',
  'crops.58.pheno_stage.stade_8': '10 foglie',
  'crops.58.pheno_stage.stade_f': 'Fioritura',
  'crops.58.pheno_stage.stade_9': '32% MS',
  'crops.58.pheno_stage.stade_10': 'Maturità',
  'crops.59.pheno_stage.stade_1': 'Germinazione',
  'crops.59.pheno_stage.stade_2': '1 foglia',
  'crops.59.pheno_stage.stade_3': '2 foglie',
  'crops.59.pheno_stage.stade_4': '4 foglie',
  'crops.59.pheno_stage.stade_5': '6 foglie',
  'crops.59.pheno_stage.stade_6': '7 foglie',
  'crops.59.pheno_stage.stade_7': '8 foglie',
  'crops.59.pheno_stage.stade_8': '10 foglie',
  'crops.59.pheno_stage.stade_f': 'Fioritura',
  'crops.59.pheno_stage.stade_9': '32% MS',
  'crops.59.pheno_stage.stade_10': 'Maturità',
  'crops.60.pheno_stage.stade_1': 'Germinazione',
  'crops.60.pheno_stage.stade_2': '1 foglia',
  'crops.60.pheno_stage.stade_3': '2 foglie',
  'crops.60.pheno_stage.stade_4': '4 foglie',
  'crops.60.pheno_stage.stade_5': '6 foglie',
  'crops.60.pheno_stage.stade_6': '7 foglie',
  'crops.60.pheno_stage.stade_7': '8 foglie',
  'crops.60.pheno_stage.stade_8': '10 foglie',
  'crops.60.pheno_stage.stade_f': 'Fioritura',
  'crops.60.pheno_stage.stade_9': '32% MS',
  'crops.60.pheno_stage.stade_10': 'Maturità',
  'crops.102.pheno_stage.stade_1': 'Germinazione',
  'crops.102.pheno_stage.stade_2': '1 foglia',
  'crops.102.pheno_stage.stade_3': '2 foglie',
  'crops.102.pheno_stage.stade_4': '4 foglie',
  'crops.102.pheno_stage.stade_5': '6 foglie',
  'crops.102.pheno_stage.stade_6': '7 foglie',
  'crops.102.pheno_stage.stade_7': '8 foglie',
  'crops.102.pheno_stage.stade_8': '10 foglie',
  'crops.102.pheno_stage.stade_f': 'Fioritura',
  'crops.102.pheno_stage.stade_9': '32% MS',
  'crops.102.pheno_stage.stade_10': 'Maturità',
};
