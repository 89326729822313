import {Button, DialogActions} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router';
import BaseDialog from 'shared/components/BaseDialog';
import IntlMessages from 'shared/components/IntlMessages';
import {QUERY_SUBSCRIPTION_CREATE} from '../configs/constant';
import SubscriptionsTable from './SubscriptionsTable';
import useStepperStyles from './StepperStyle';


export default function SubscriptionList() {

    const history = useHistory()
    const classes = useStepperStyles();

    return (
        <BaseDialog fullWidth maxWidth={'lg'} open={true} onClose={() => history.push({pathname: history.location.pathname})} titleMessageId='subscriptions.title'>
            <SubscriptionsTable />
            <DialogActions className={classes.stepperDialogAction}>
                <Button variant='contained' color='primary' onClick={() => {history.push({pathname: history.location.pathname, search: QUERY_SUBSCRIPTION_CREATE})}}>
                    <IntlMessages id='subscriptions.button.new_subscription' />
                </Button>
            </DialogActions>
        </BaseDialog>
    )
}