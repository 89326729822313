import {Box, Card, CardProps, Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import {NitrogenChartUnit} from '../models/NitrogenChartUnit';
import {backgroundGradient, chromaGradient} from '../services/NitrogenService';

interface NitrogenMapLegendProps extends CardProps {
  unit?: NitrogenChartUnit
}
const NitrogenMapLegend: React.FC<NitrogenMapLegendProps> = (props) => {
  const {legend_thresholds} = useSelector<AppState, AppState['nitrogen']>((state) => state.nitrogen);
  
  const unit = props.unit || NitrogenChartUnit.N_TOT
  if (legend_thresholds && legend_thresholds[unit]) {
    const selectedThresholds = legend_thresholds[unit]
    return (
      <Card {...props}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyItems='center'>
          <h5>
            <FormattedMessage id={`nitrogen.map_legend.label.${unit}`} values={{
              unit: <FormattedMessage id={`nitrogen.tickItem.${unit}`}/>
            }} />
          </h5>

          <Box
            pt={2}
            width={1}
            display='flex'
            flexDirection='row'
            alignItems='center'>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-end'
              alignItems='center'
              width={60}
              pr={1}>
              <Typography variant='body1' fontWeight={"bold"}>
                {selectedThresholds.val_min}
              </Typography>
            </Box>
            <Box
              flex={1}
              height={10}
              sx={{
                background: backgroundGradient(chromaGradient(selectedThresholds.val_min, selectedThresholds.val_max)),
              }}></Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              alignItems='center'
              width={60}
              pl={1}>
              <Typography variant='body1' fontWeight={"bold"}>
                {selectedThresholds.val_max}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  }
  return null
};

export default NitrogenMapLegend;
