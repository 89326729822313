import React from 'react';
import ReactDOM from 'react-dom';
// TODO Clean dependencies
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-table/react-table.css';

import './shared/styles/index.css';
import './assets/vendors/country-flag/sprite-flags-24x24.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
