export class BasePixagriApiService {
  PIXAGRI_ROOT_URL_DEV =
    'https://report-api-s2nygtk3nq-ew.a.run.app/api/v1/agri';

  PIXAGRI_ROOT_URL_PROD =
    'https://report-api-ccgfqp5o5q-ew.a.run.app/api/v1/agri';

  getPixagriRootApiUrl = () => {
    let url = '';
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'prod') {
      url = this.PIXAGRI_ROOT_URL_PROD;
    } else {
      url = this.PIXAGRI_ROOT_URL_DEV;
    }
    return url;
  };
}
const basePixagriApiService = new BasePixagriApiService();
export default basePixagriApiService;
