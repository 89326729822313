import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store';
import {SubscriptionsStatus} from '../models/SubscriptionStatus';
import PixagriSubscription from '../models/PixagriSubscription';

function useActiveSubscription() {

    const {selectedFieldId, selectedCampaign, selectedService, fieldsList} =
      useSelector<AppState, AppState['fields']>((state) => state.fields);
    const [activeSubscription, setActiveSubscription] =
      useState<PixagriSubscription | null>(null);

    useEffect(() => {
      if (
        selectedCampaign &&
        selectedFieldId &&
        selectedService &&
        fieldsList &&
        fieldsList.length
      ) {
        const selectedField = fieldsList.find(
          (field) => field.field_id === selectedFieldId,
        );
        if (!selectedField) {
          log.error(
            `Field with id ${selectedFieldId} not found in campaing ${selectedCampaign}`,
          );
        } else {
          const subscriptions = selectedField.subscriptions[selectedService];
          if (!subscriptions || !subscriptions.length) {
            log.debug(`No subscriptions found for service ${selectedService}`);
          } else {
            const subscriptionActive = subscriptions.find(
              (sub) =>
                (sub.status === SubscriptionsStatus.ACTIVE ||
                  sub.status === SubscriptionsStatus.TRIALING) &&
                sub.campaign_id === selectedCampaign,
            );
            if (subscriptionActive) {
              setActiveSubscription(subscriptionActive);
              return;
            } else {
              log.debug(
                `No subscriptions active found for service ${selectedService}`,
              );
            }
          }
        }
      }
      setActiveSubscription(null);
    }, [selectedService, selectedCampaign, selectedFieldId, fieldsList]);

    return activeSubscription
}

export default useActiveSubscription;