import {makeStyles} from '@mui/styles';
import {Fonts} from 'shared/configs/AppEnums';
import {CremaTheme} from 'shared/models/AppContextPropsType';

const useStepperStyles = makeStyles((theme: CremaTheme) => {
    return {
        stepperDialog: {
            display: 'flex',
            flexDirection: 'column',
        },
        stepperDialogTitle: {
            display: 'flex',
            alignItems: "center",
            marginTop: theme.spacing(3)
        },
        stepperDialogContent: {
            paddingBottom: theme.spacing(5)
        },
        stepperDialogAction: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2, 6, 2, 6)
        },
        stepperButtonsContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
        stepperPrevButton: {
            width: '30%',
            border: `1px solid ${theme.palette.primary.main}`,
            fontWeight: 'bold',
            marginRight: "auto"
        },
        stepperButtonValidation: {
            width: '30%',
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
        step: {
            padding: theme.spacing(0.25)
        },
        stepBar: {
            padding: theme.spacing(2)
        },
        stepBarIcon: {
            fontSize: '15px'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        backButton: {
            paddingLeft: 0
        },
        title: {
            fontFamily: [Fonts.BOLD, 'sans-serif'].join(','),
            paddingRight: theme.spacing(2)
        },
        stepContentTitle: {
            paddingBottom: theme.spacing(5)
        },
        stepTitle: {
            paddingBottom: theme.spacing(2),
            fontWeight: 'bolder'
        },
        stepContent: {
            paddingBottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            height: '50vh',
        },
        stepContentResume: {
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%'
        },
        resumeItem: {
            marginRight: theme.spacing(2),
            display: 'flex'
        },
        resumeItemText: {
            marginRight: theme.spacing(1),
            fontWeight: 'bolder',
        },
        resumeItemNumber: {
            fontWeight: 'bolder',
            color: theme.palette.primary.main
        },
        listItemText: {
            display: 'flex',
            alignItems: 'center',
        },
        listItem: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        titleListItem: {
            fontWeight: 'bolder',
            textDecoration: 'underline'
        },
        primaryColor: {
            fontWeight: 'bolder',
            color: theme.palette.primary.main
        },
        fieldNumber: {
            fontWeight: 'bolder'
        },
        deleteIcon: {
            padding: theme.spacing(0, 1, 0, 1)
        },
        fallBack: {
            margin: '0 auto'
        },
        listContainer: {
            width: '90%',
            height: '100%',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        sideList: {
            display: 'flex',
            flexDirection: 'column'
        },
        errorMessage: {
            position: 'absolute',
            right: theme.spacing(2),
            color: theme.palette.error.main
        },
        bold: {
            fontWeight: 'bold'
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        fitContent: {
            width: 'fit-content',
            fontWeight: 'bold'
        },
        headerStyle: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: theme.palette.common.white,
            fontWeight: 'bold',
            '&:first-child': {
                borderTopLeftRadius: '4px !important',
            },
            '&:last-child': {
                borderTopRightRadius: '4px !important',
            },
            borderRight: `1px solid ${theme.palette.background.default}`,

        },
        rowStyle: {
            backgroundColor: theme.palette.background.default,
            fontWeight: 'bold'
        },
        cellStyle: {
            borderRight: `1px solid ${theme.palette.background.default}`,
            padding: theme.spacing(2)
        },
        buttonNavigation: {
            width: '50%',
            padding: theme.spacing(3),
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            textTransform: "none"
        },
        selected: {
            borderBottom: `3px solid ${theme.palette.primary.main}`
        }
    }
})

export default useStepperStyles