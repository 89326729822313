import {selectedFieldRootPath} from 'modules/fields/configs/FieldsRoutePaths';
import {ServiceId, ServiceView} from 'shared/models/RouterParam';

/**
 * Default vue to redirect if url path doesn't exist
 */
export const DEFAULT_NITROGEN_VUE: ServiceView = ServiceView.NITROGEN_COVER;

const nitrogenServiceRootPath = `${selectedFieldRootPath}/${ServiceId.NITROGEN}`;

/**
 * Full path to nitrogen cover vue
 */
export const selectedFieldNitrogenCover = `${nitrogenServiceRootPath}/${ServiceView.NITROGEN_COVER}`;

/**
 * Full path to fertilization vue
 */
export const selectedFieldNitrogenFertilization = `${nitrogenServiceRootPath}/${ServiceView.FERTILIZATION}`;

/**
 * Full path to recommendation vue
 */
export const selectedFieldNitrogenRecommendation = `${nitrogenServiceRootPath}/${ServiceView.RECOMMENDATION}`;

/**
 * Full path to nitrogen edit vue
 */
export const selectedFieldNitrogenEdit = `${nitrogenServiceRootPath}/${ServiceView.EDIT}`;
