import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {Form, Formik, FormikProps} from 'formik';
import {AuthUser} from 'modules/auth/models/AuthUser';
import Customer from 'modules/auth/models/Customer';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import useStyles from 'shared/components/layout/HeaderButton.style';
import {AppState} from 'shared/store';
import * as yup from 'yup';
import weatherActions from '../actions/WeatherActions';
import {
  MfWeatherStationCredentials,
  SencropWeatherStationCredentials,
  WeatherStationCredentials,
  WeenatWeatherStationCredentials,
} from '../models/BaseWeatherStationSchema';
import {UserWeatherStationPayload} from '../models/UserWeatherStationPayload';
import {
  ConnectedStationType,
  WeatherStationResponse,
} from '../models/WeatherStationResponse';
import MfCredentialsForm from './MfCredentailsForm';
import SencropCredentialsForm from './SencropCredentialsForm';
import WeenatCredentialsForm from './WeenatCredentialsForm';

interface ModifyWeatherStationProps {
  user?: Customer;
}

const ModifyWeatherStation: React.FC<ModifyWeatherStationProps> = (props) => {
  const [disable, setDisable] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const {messages} = useIntl();
  const {weather_providers} = useSelector<AppState, AppState['weather']>(
    ({weather}) => weather,
  );
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  const getCredentials = (e: WeatherStationResponse, user: AuthUser | null) => {
    const stationCredentials = user
      ? user.connected_stations_provider?.find(
          (station) => station.provider_id === e.provider_id,
        )?.credentials
      : null;

    switch (e.provider_type) {
      case ConnectedStationType.SENCROP:
        return {
          id:
            (stationCredentials as SencropWeatherStationCredentials)?.id || '',
          password:
            (stationCredentials as SencropWeatherStationCredentials)
              ?.password || '',
        } as SencropWeatherStationCredentials;
      case ConnectedStationType.WEENAT:
        return {
          email:
            (stationCredentials as WeenatWeatherStationCredentials)?.email ||
            '',
        } as WeenatWeatherStationCredentials;
      case ConnectedStationType.MF:
        return {
          email:
            (stationCredentials as MfWeatherStationCredentials)?.email || '',
        } as MfWeatherStationCredentials;
      default:
        return {
          email: '',
        } as WeenatWeatherStationCredentials;
    }
  };

  const handleChange = (
    event: SelectChangeEvent<ConnectedStationType>,
    formikProps: FormikProps<
      UserWeatherStationPayload<WeatherStationCredentials>
    >,
  ) => {
    const selectedOption = weather_providers?.[
      event.target.value as ConnectedStationType
    ] || {
      provider_id: 0,
      provider_name: '',
      enabled: false,
      provider_type: '' as ConnectedStationType,
    };

    const initialValues: UserWeatherStationPayload<WeatherStationCredentials> =
      {
        uid: props.user ? props.user.id : user ? user.uid : '',
        provider_id: selectedOption.provider_id,
        provider_name: selectedOption.provider_name,
        provider_type: selectedOption.provider_type,
        credentials: getCredentials(selectedOption, user),
      };
    formikProps.resetForm({values: initialValues});
  };
  const isProviderEnable = (
    provider: ConnectedStationType,
    stationData:
      | {[key in ConnectedStationType]?: WeatherStationResponse}
      | null,
  ): boolean => {
    if (stationData && stationData[provider]?.enabled) {
      return true;
    }
    return false;
  };
  const handleSubmit = (
    e: UserWeatherStationPayload<WeatherStationCredentials>,
  ) => {
    if (e.provider_id !== 0) {
      dispatch(weatherActions.modifyUserWeatherStation(e));
      //   dispatch(actions.loadAllUsers());
    }
  };

  const setInitialValues: UserWeatherStationPayload<WeatherStationCredentials> =
    {
      uid: user ? user.uid : '',
      provider_id: user?.connected_stations_provider?.length
        ? user.connected_stations_provider[0].provider_id
        : 0,
      provider_name: user?.connected_stations_provider?.length
        ? user.connected_stations_provider[0].provider_name
        : '',
      provider_type: user?.connected_stations_provider?.length
        ? user.connected_stations_provider[0].provider_type
        : ('' as ConnectedStationType),
      credentials: user?.connected_stations_provider?.length
        ? user.connected_stations_provider[0].credentials
        : ({} as WeatherStationCredentials),
    };

  const setValidationSchema = (
    values: UserWeatherStationPayload<WeatherStationCredentials> | undefined,
  ) => {
    if (!values || !values.provider_type) {
      return yup.object({});
    }
    switch (values.provider_type) {
      case ConnectedStationType.SENCROP: {
        return yup.object({
          id: yup
            .string()
            .required(messages['modifyUser.validation.id'] as string),
          password: yup
            .string()
            .required(messages['modifyUser.validation.password'] as string),
        });
      }
      case ConnectedStationType.WEENAT: {
        return yup.object({
          email: yup
            .string()
            .email(messages['modifyUser.validation.emailFormat'] as string)
            .required(messages['modifyUser.validation.email'] as string),
        });
      }
      default: {
        return yup.object({});
      }
    }
  };

  useEffect(() => {
    if (
      user &&
      user.connected_stations_provider &&
      user.connected_stations_provider.length > 0
    ) {
      setDisable(true);
    }
  }, []);

  const handleDelete = (providerId: number) => {
    if (user) {
      const data = {
        uid: user.uid,
        provider_id: providerId,
      };
      dispatch(weatherActions.deleteUserWeatherStation(data));
    }
  };

  return (
    <Box
      sx={{display: 'flex', flexDirection: 'column', gap: '20px', pt: '20px'}}>
      <Typography variant='body1' color='default'>
        <IntlMessages id='weather.weather_provider_linking' />
      </Typography>
      <Formik
        initialValues={setInitialValues}
        validationSchema={(
          values: UserWeatherStationPayload<WeatherStationCredentials>,
        ) => setValidationSchema(values)}
        onSubmit={handleSubmit}>
        {(
          props: FormikProps<
            UserWeatherStationPayload<WeatherStationCredentials>
          >,
        ) => (
          <Form style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id='selected-provider-label'>
                <IntlMessages id='weather.weather_provider' />
              </InputLabel>
              <Select
                disabled={disable}
                labelId='selected-provider-label'
                id='selected-provider-select'
                value={props.values.provider_type}
                label='Weather provider'
                onChange={(e) => handleChange(e, props)}>
                {weather_providers &&
                  Object.entries(weather_providers).map(([key, value]) => (
                    <MenuItem
                      key={value && value.provider_id}
                      value={value && value.provider_type}>
                      {value && value.provider_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {isProviderEnable(
              props.values.provider_type,
              weather_providers,
            ) && (
              <>
                {props.values.provider_type ===
                  ConnectedStationType.SENCROP && (
                  <SencropCredentialsForm
                    formikProps={
                      props as FormikProps<
                        UserWeatherStationPayload<SencropWeatherStationCredentials>
                      >
                    }
                    disable={disable}
                  />
                )}
                {props.values.provider_type === ConnectedStationType.WEENAT && (
                  <WeenatCredentialsForm
                    formikProps={
                      props as FormikProps<
                        UserWeatherStationPayload<WeenatWeatherStationCredentials>
                      >
                    }
                    disable={disable}
                  />
                )}
                {props.values.provider_type === ConnectedStationType.MF && (
                  <MfCredentialsForm
                    formikProps={
                      props as FormikProps<
                        UserWeatherStationPayload<MfWeatherStationCredentials>
                      >
                    }
                    disable={disable}
                  />
                )}
              </>
            )}
            {!isProviderEnable(
              props.values.provider_type,
              weather_providers,
            ) && (
              <>
                {props.values.provider_id !== 0 && (
                  <Typography
                    variant='body1'
                    color='red'
                    fontWeight={'bold'}
                    alignSelf={'center'}
                    justifySelf={'center'}
                    paddingTop={'12px'}
                    paddingBottom={'12px'}>
                    <IntlMessages id='weather.weather_provider_disabled' />
                  </Typography>
                )}
                {props.values.provider_id === 0 && (
                  <Typography
                    variant='body1'
                    color='default'
                    fontStyle={'italic'}
                    alignSelf={'center'}
                    justifySelf={'center'}
                    paddingTop={'12px'}
                    paddingBottom={'12px'}>
                    <IntlMessages id='weather.weather_provider_no_select' />
                  </Typography>
                )}
              </>
            )}
            <Box
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              display='flex'>
              {!disable && (
                <Button
                  disabled={
                    props.isSubmitting ||
                    !isProviderEnable(
                      props.values.provider_type,
                      weather_providers,
                    )
                  }
                  variant='contained'
                  color='primary'
                  type='submit'
                  className={classes.formUserButton}>
                  <IntlMessages id='modifyUser.submitButton' />
                </Button>
              )}
              {disable && (
                <Button
                  variant='contained'
                  color='primary'
                  type='button'
                  className={classes.formUserButton}
                  onClick={() => setDisable(false)}>
                  <IntlMessages id='weather.weather_provider.modifyButton' />
                </Button>
              )}
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant='body1' color='default'>
                <IntlMessages id='weather.weather_provider.linked_provider_list' />
              </Typography>
              {user?.connected_stations_provider &&
                user?.connected_stations_provider.length > 0 &&
                user.connected_stations_provider.map((provider) => {
                  return (
                    <Box
                      key={provider.provider_id}
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{alignItems: 'center'}}>
                      <Typography
                        variant='body1'
                        color='default'
                        fontStyle={'italic'}
                        sx={{height: 'fit-content'}}>
                        {provider.provider_name}
                      </Typography>
                      <DeleteIcon
                        sx={{margin: '10px', cursor: 'pointer'}}
                        color='error'
                        onClick={() => handleDelete(provider.provider_id)}
                      />
                    </Box>
                  );
                })}
              {!user?.connected_stations_provider ||
                (user?.connected_stations_provider.length === 0 && (
                  <Typography
                    variant='body1'
                    color='default'
                    fontStyle={'italic'}
                    fontWeight={'bold'}
                    sx={{height: 'fit-content', margin: '10px'}}>
                    <IntlMessages id='weather.weather_provider.no_linked' />
                  </Typography>
                ))}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ModifyWeatherStation;
