import {DateTime} from 'luxon';
import {BASE_COVER_CREATION} from 'modules/nitrogen/configs/NitrogenConfigs';
import {nitrogenValidationSchema} from 'modules/nitrogen/configs/NitrogenFieldEdit';
import {FieldNitrogenService} from 'modules/nitrogen/models/FieldNitrogenService';
import {getRotation} from 'modules/rotations/services/RotationService';
import {
  hasFieldSubscriptionsToService,
  isSubscriptionValid,
} from 'modules/subscriptions/services/isFieldSubscribe';
import {irrigationValidationSchema} from 'modules/waterBalance/configs/IrrigationFieldEdit';
import {BASE_ROTATION_CREATION} from 'modules/waterBalance/configs/RotationConfig';
import {ServiceId} from 'shared/models/RouterParam';
import log from 'shared/services/LogService';
import * as yup from 'yup';
import {ObjectSchema} from 'yup';
import {baseValidationSchema} from '../configs/FieldEdit';
import Field from '../models/Field';
import FieldCreation from '../models/FieldCreation';
import {FieldIrrigationService} from '../models/FieldTesting';
import FieldsState from '../models/FieldsState';

const SQUARE_METER_TO_HA = 0.0001;

export const replaceFieldId = function (
  url: string,
  fieldId: number | string,
): string {
  return url.replace(
    /(.*\/fields)\/?(\d*|overview)?(\/.*)?/,
    `$1/${fieldId}$3`,
  );
};
export const replaceServiceId = function (
  url: string,
  serviceId: ServiceId,
): string {
  return url.replace(/(irrigation|carbon|weather|vegetation)/, serviceId);
};
export const deleteEndUrl = function (url: string): string {
  return url.replace(/(.*\/overview)?(\/.*)?(\/.*)/, `$1$2`);
};
export const getViewId = function (url: string): string {
  return url.replace(/(.*\/)(.*)/, `$2`);
};
export const removeDuplicates = function (
  noDuplicates: FieldsState['campaigns'],
) {
  return (noDuplicates = noDuplicates?.filter(
    (campaign, index: any, self: any[]) =>
      self.findIndex((t) => t.campaign_id === campaign.campaign_id) === index,
  ) as FieldsState['campaigns']);
};

/**
 * Compute the area in Ha
 * (Floor to int)
 * @param selectedFields
 * @returns
 */
export const computeAreaHa = (field: Field, floor: boolean): number => {
  if (field.area) {
    let areaHa = field.area * SQUARE_METER_TO_HA;
    if (floor) {
      areaHa = Math.floor(areaHa);
    }
    if (areaHa === 0) {
      log.debug(
        `areaHa forced to 1 beacause floor areaHA return 0 with areaHa of ${
          field.area * SQUARE_METER_TO_HA
        }`,
      );
      return 1;
    } else {
      return areaHa;
    }
  } else {
    log.error(`Field ${field.field_id} does not have a filled area`);
  }
  return 0;
};

/**
 * Compute the total area in Ha
 * (Floor to int)
 * @param selectedFields
 * @returns
 */
export const computeTotalAreaHa = (selectedFields: Field[]): number => {
  let count: number = 0;
  selectedFields.forEach((field) => {
    count += computeAreaHa(field, false);
  });
  count = Math.floor(count);
  if (count === 0 && selectedFields.length) {
    count = 1;
  }
  return count;
};

export const getFieldEditValidationSchema = (
  service: ServiceId,
): ObjectSchema => {
  switch (service) {
    case ServiceId.IRRIGATION:
      return yup.object().shape({
        ...baseValidationSchema(),
        rotation: yup.object().shape({
          ...irrigationValidationSchema(),
        }),
      });
    case ServiceId.VEGETATION:
      return yup.object().shape({
        ...baseValidationSchema(),
        rotation: yup.object().shape({
          ...irrigationValidationSchema(),
        }),
      });
    case ServiceId.WEATHER:
      return yup.object().shape({
        ...baseValidationSchema(),
        rotation: yup.object().shape({
          ...irrigationValidationSchema(),
        }),
      });
    case ServiceId.NITROGEN:
      return yup.object().shape({
        ...baseValidationSchema(),
        cover: yup.object().shape({
          ...nitrogenValidationSchema(),
        }),
      });
    default:
      return yup.object().shape(baseValidationSchema());
  }
};

export function getFieldCreationMap(
  field: Field,
  campaignId: number | null,
  addRotationOrCover?: boolean,
) {
  let currentRotation: FieldIrrigationService | null = null;
  if (campaignId && field.irrigation && field.irrigation.length) {
    const rotations = field.irrigation.filter(
      (rot) => rot.campaign_id === campaignId,
    );
    if (rotations && rotations.length) {
      if (rotations.length > 1) {
        rotations.sort(
          (a, b) =>
            DateTime.fromISO(b.sowing_date).toMillis() -
            DateTime.fromISO(a.sowing_date).toMillis(),
        );
      }
      currentRotation = rotations[0];
    }
  }
  let currentCover: FieldNitrogenService | null = null;
  if (campaignId && field.nitrogen && field.nitrogen.length) {
    currentCover =
      field.nitrogen.find((cover) => cover.campaign_id === campaignId) || null;
  }

  const fieldEdit: FieldCreation = {
    max_irrigation_dose: field.max_irrigation_dose ?? '',
    irrigation_cycle_duration: field.irrigation_cycle_duration ?? '',
    field_thresholds: {
      ...field.field_thresholds,
    },
    station_id:
      field.connected_stations.length > 0
        ? field.connected_stations[0].station_id
        : null,
    field_id: field.field_id,
    field_name: field.field_name,
    comments: field.comments,
    soil_id: field.soil_id,
    geometry: field.geometry,
    group: 'group',
    rotation:
      currentRotation && !addRotationOrCover
        ? {
            rotation_id: currentRotation.rotation_id,
            sowing_date: currentRotation.sowing_date,
            harvest_date: currentRotation.harvest_date,
            crop_id: currentRotation.crop_id,
            sys_irrigation_id: currentRotation.sys_irrigation_id,
          }
        : BASE_ROTATION_CREATION,
    cover:
      currentCover && !addRotationOrCover
        ? {
            covercrop_id: currentCover.covercrop_id,
            sowing_date: currentCover.sowing_date,
            harvest_date: currentCover.harvest_date,
            destruction_date: currentCover.destruction_date,
            residue_management: currentCover.residue_management,
            covercrop_exploitation: currentCover.covercrop_exploitation,
            target_n_quantity: currentCover.target_n_quantity,
            covercrop_composition: currentCover.covercrop_composition,
          }
        : BASE_COVER_CREATION,
  };
  return fieldEdit;
}

export function getFieldName(
  fieldId: number | string,
  fieldsData: {[key: string]: {[key: string]: Field}},
) {
  const fieldsListByCampaign = Object.values(fieldsData);
  const firstCampaignFound = fieldsListByCampaign.find((camp) => camp[fieldId]);
  if (firstCampaignFound) {
    return firstCampaignFound[fieldId].field_name;
  } else {
    log.error(`Field ${fieldId} not found in any campaign`);
  }
  return fieldId.toString();
}

export function getFieldNameFromRotationId(
  rotationId: number | string,
  fieldsData: {[key: string]: {[key: string]: Field}},
  dateCampaign?: boolean,
) {
  const rotation = getRotation(rotationId, fieldsData);
  if (rotation) {
    const fieldId = rotation.field_id;
    const fieldName = getFieldName(fieldId, fieldsData);
    if (!dateCampaign) {
      return `${fieldName}`;
    } else {
      const startYear = DateTime.fromISO(rotation.campaign_start).get('year');
      const endYear = DateTime.fromISO(rotation.campaign_end).get('year');
      return `${fieldName} (${startYear} - ${endYear})`;
    }
  }
  return rotationId;
}

/**
 * Check if field has a subscription for a service in the campaign
 *
 * @param service
 * @param campaignId
 * @param field
 * @returns
 */
export const isFieldSubscribe = (
  service: ServiceId,
  campaignId: number,
  field: Field,
) => {
  switch (service) {
    case ServiceId.IRRIGATION:
      if (
        field.subscriptions.irrigation &&
        field.subscriptions.irrigation.length
      ) {
        const subscription = field.subscriptions.irrigation.find(
          (subs) =>
            subs.campaign_id === campaignId && isSubscriptionValid(subs),
        );
        if (subscription && hasFieldSubscriptionsToService(field, service)) {
          return true;
        }
      }
      return false;
    case ServiceId.VEGETATION:
      if (
        field.subscriptions.vegetation &&
        field.subscriptions.vegetation.length
      ) {
        const subscription = field.subscriptions.vegetation.find(
          (subs) => subs.campaign_id === campaignId,
        );
        if (subscription && hasFieldSubscriptionsToService(field, service)) {
          return true;
        }
      }
      return false;
    case ServiceId.CARBON:
      if (field.subscriptions.carbon && field.subscriptions.carbon.length) {
        const subscription = field.subscriptions.carbon.find(
          (subs) =>
            subs.campaign_id === campaignId && isSubscriptionValid(subs),
        );
        if (subscription) {
          return true;
        }
      }
      return false;
    case ServiceId.NITROGEN:
      if (field.subscriptions.nitrogen && field.subscriptions.nitrogen.length) {
        const subscription = field.subscriptions.nitrogen.find(
          (subs) =>
            subs.campaign_id === campaignId && isSubscriptionValid(subs),
        );
        if (subscription) {
          return true;
        }
      }
      return false;
    case ServiceId.WEATHER:
      if (field.subscriptions.weather && field.subscriptions.weather.length) {
        const subscription = field.subscriptions.weather.find(
          (subs) =>
            subs.campaign_id === campaignId && isSubscriptionValid(subs),
        );
        if (subscription) {
          return true;
        }
      }
      return false;
    default:
      return false;
  }
};

export const isFieldComplete = (
  service: ServiceId,
  campaignId: number,
  field: Field,
) => {
  switch (service) {
    case ServiceId.IRRIGATION:
      if (field.irrigation && field.irrigation.length) {
        const selectedRotation = field.irrigation.find(
          (rot) => rot.campaign_id === campaignId,
        );
        if (selectedRotation) {
          return true;
        }
      }
      return false;
    case ServiceId.VEGETATION:
      if (field.vegetation && field.vegetation.length) {
        const selectedVegetation = field.vegetation.find(
          (veg) => veg.campaign_id === campaignId,
        );
        if (selectedVegetation) {
          return true;
        }
      }
      return false;
    case ServiceId.CARBON:
      return true;
    case ServiceId.NITROGEN:
      if (field.nitrogen && field.nitrogen.length) {
        const selectedCover = field.nitrogen.find(
          (cover) => cover.campaign_id === campaignId,
        );
        if (selectedCover) {
          return true;
        }
      }
      return false;
    case ServiceId.WEATHER:
      if (field.irrigation && field.irrigation.length) {
        const selectedRotation = field.irrigation.find(
          (rot) => rot.campaign_id === campaignId,
        );
        if (selectedRotation) {
          return true;
        }
      }
      return false;
    default:
      return false;
  }
};
