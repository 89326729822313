import {ReportTranslationsType} from './ReportTranslationsType';

export const esReportTranslations: ReportTranslationsType = {
  'report.modal.title': 'Generación del Informe de Campaña',
  'report.modal.error.title': 'Error',
  'report.modal.error.text':
    'Se ha producido un error durante la generación del informe',
  'report.modal.selector.text':
    'Seleccione el idioma en el que se debe generar el informe',
  'report.modal.error.button': 'Cerrar',
  'report.modal.button': 'Descargar',
};
