import {AxiosResponse} from 'axios';
import log from 'loglevel';
import {DateTime} from 'luxon';
import {reloadFieldsSummaryAfterDelay} from 'modules/fields/sagas/FieldsSagas';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions';
import api, {pixagriApiUrl} from 'shared/services/ApiConfig';
import nitrogenActions, {
  ADD_COVER,
  AddCoverAction,
  DELETE_COVER_CROP,
  DeleteCovercropAction,
  GET_COVERSAT_DATA,
  GET_COVER_CROP,
  GET_NITROGEN_THRESHOLDS,
  GET_SPECIES,
  GetCoverCropAction,
  GetCoversatDataAction,
  GetNitrogenThresholdsAction,
  UPDATE_COVER,
  UpdateCoverAction,
} from '../actions/NitrogenActions';
import {AddCover} from '../models/AddCover';
import {NitrogenCoverCropGet} from '../models/NitrogenCoverCropGet';
import {
  DynNitrogen,
  DynNitrogenData,
  NitrogenCoverSat,
} from '../models/NitrogenCoverSat';
import {NitrogenSpecie} from '../models/NitrogenSpecies';
import {NitrogenThresholds} from '../models/NitrogenThresholds';
import {getDynNitrogenDayNumber} from '../services/NitrogenService';

function getCoversatData(fieldId: number, covercrop_id: number) {
  const coversatUrl = `${pixagriApiUrl}/fields/${fieldId}/covercrops/${covercrop_id}/coversat`;
  return api.get(coversatUrl);
}

function getSpecies() {
  const speciesUrl = `${pixagriApiUrl}/cc_species`;
  return api.get(speciesUrl);
}

function* getNitrogenSpecies() {
  try {
    yield put(fetchStart(`getNitrogenSpecies`));
    const res: AxiosResponse = yield call(getSpecies);
    const data = res.data as NitrogenSpecie[];
    let species: {[key: string]: NitrogenSpecie} = {};
    data.forEach((spec) => {
      species[spec.cc_species_id] = spec;
    });
    yield put(nitrogenActions.getSpeciesSuccess(species));
    yield put(fetchSuccess(`getNitrogenSpecies`));
  } catch (error) {
    log.error(`${error}`);
    yield put(fetchError(`getNitrogenSpecies`, error as string));
  }
}

function fillAreaData(meanValue: number, stdValue: number) {
  let minValue = meanValue - stdValue;
  if (minValue < 0) {
    minValue = 0;
  }
  const maxValue = meanValue + stdValue;

  return [minValue, maxValue];
}

function* getNitrogenCoversat(action: GetCoversatDataAction) {
  try {
    yield put(fetchStart('getNitrogenCoversat'));
    const {field_id, cc_id} = action.payload;

    const res: AxiosResponse = yield call(getCoversatData, field_id, cc_id);
    const data = res.data as NitrogenCoverSat[];

    if (data && data[0]) {
      data[0].biomass?.forEach((bio) => {
        bio.date_biomass_epoch = DateTime.fromISO(bio.date_biomass).toMillis();
        bio.biomass_area = fillAreaData(
          bio.val_mean_biomass,
          bio.val_std_biomass,
        );
        bio.n_tot_area = fillAreaData(bio.val_mean_n_tot, bio.val_std_n_tot);
        bio.n_sol_area = fillAreaData(bio.val_mean_n_sol, bio.val_std_n_sol);
        bio.p_area = fillAreaData(bio.val_mean_p, bio.val_std_p);
        bio.k_area = fillAreaData(bio.val_mean_k, bio.val_std_k);
        bio.s_area = fillAreaData(bio.val_mean_s, bio.val_std_s);
        bio.mg_area = fillAreaData(bio.val_mean_mg, bio.val_std_mg);
        bio.c_area = fillAreaData(bio.val_mean_c, bio.val_std_c);
        bio.path_biomass_tif = bio.path_biomass_png.slice(0, -3) + 'tif';
        bio.path_c_tif = bio.path_c_png.slice(0, -3) + 'tif';
        bio.path_k_tif = bio.path_k_png.slice(0, -3) + 'tif';
        bio.path_mg_tif = bio.path_mg_png.slice(0, -3) + 'tif';
        bio.path_n_sol_tif = bio.path_n_sol_png.slice(0, -3) + 'tif';
        bio.path_n_tot_tif = bio.path_n_tot_png.slice(0, -3) + 'tif';
        bio.path_p_tif = bio.path_p_png.slice(0, -3) + 'tif';
        bio.path_s_tif = bio.path_s_png.slice(0, -3) + 'tif';

        let dynNitrogenData: DynNitrogenData[] = [];
        Object.keys(bio.dyn_n).forEach((key, index) => {
          dynNitrogenData.push({
            dyn_n_value: bio.dyn_n[key as keyof DynNitrogen],
            day_number: getDynNitrogenDayNumber(key as keyof DynNitrogen),
            dateEpoch: DateTime.fromMillis(bio.date_biomass_epoch!)
              .plus({
                days: getDynNitrogenDayNumber(key as keyof DynNitrogen),
              })
              .toMillis(),
          });
        });
        bio.dyn_n_data = dynNitrogenData;
      });
      yield put(nitrogenActions.getCoverSatDataSuccess(data[0]));
    }
    yield put(fetchSuccess('getNitrogenCoversat'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getNitrogenCoversat', error as string));
  }
}

function postCoverUrl(fieldId: number, cover: AddCover) {
  const postCoverUrl = `${pixagriApiUrl}/fields/${fieldId}/covercrops`;
  return api.post(postCoverUrl, cover);
}

function* postCover(action: AddCoverAction) {
  try {
    yield put(fetchStart('AddCover'));
    const {field_id, cover} = action.payload;

    const res: AxiosResponse = yield call(postCoverUrl, field_id, cover);
    if (res.status < 400) {
      log.info(`Cover for field ${field_id} posted with status ${res.status}`);
    } else {
      throw Error(`Cover for field ${field_id} fail with status ${res.status}`);
    }
    yield call(reloadFieldsSummaryAfterDelay, 3);

    yield put(fetchSuccess('AddCover'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('AddCover', error.message));
  }
}

function getCovercropUrl(fieldId: number, ccId: number) {
  const url = `${pixagriApiUrl}/fields/${fieldId}/covercrops/${ccId}`;
  return api.get(url);
}
function* getCovercrop(action: GetCoverCropAction) {
  try {
    yield put(fetchStart('getCovercrop'));

    const {field_id, cc_id} = action.payload;

    const res: AxiosResponse = yield call(getCovercropUrl, field_id, cc_id);

    const covercrop = res.data[0] as NitrogenCoverCropGet;

    yield put(nitrogenActions.getCovercropSuccess(covercrop));

    yield put(fetchSuccess('getCovercrop'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getCovercrop', error.message));
  }
}

function updateCoverUrl(fieldId: number, coverId: number, cover: AddCover) {
  const url = `${pixagriApiUrl}/fields/${fieldId}/covercrops/${coverId}`;
  return api.put(url, cover);
}

function* updateCover(action: UpdateCoverAction) {
  try {
    yield put(fetchStart('updateCover'));

    const {field_id, cover} = action.payload;
    const {covercrop_id, ...newValues} = cover;

    if (covercrop_id) {
      const res: AxiosResponse = yield call(
        updateCoverUrl,
        field_id,
        covercrop_id,
        newValues,
      );
      if (res.status < 300) {
        log.info(`Cover ${cover.covercrop_id} updated`);
      } else {
        throw Error(`Fail to update ${covercrop_id} with status ${res.status}`);
      }
    } else {
      log.info(`Covercrop_id not found cover crop posted`);
      const res: AxiosResponse = yield call(postCoverUrl, field_id, newValues);
      // TODO status >=200 && < 300
      if (res.status < 400) {
        log.info(`Cover posted`);
      } else {
        throw Error(`Fail to post cover with status ${res.status}`);
      }
    }

    yield put(fetchSuccess('updateCover'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('updateCover', error.message));
  }
}

function getNitrogenThresholdsRequest() {
  const url = `${pixagriApiUrl}/coversat_indicators_minmax`;
  return api.get(url);
}
function* getNitrogenThresholds(action: GetNitrogenThresholdsAction) {
  try {
    yield put(fetchStart('getNitrogenThresholds'));
    const res: AxiosResponse = yield call(getNitrogenThresholdsRequest);
    const thresholds = res.data as NitrogenThresholds[];
    const legendThresholds: {[key: string]: NitrogenThresholds} = {};

    thresholds.forEach((thr) => {
      legendThresholds[thr.indicator] = thr;
    });
    yield put(nitrogenActions.getNitrogenThresholdsSuccess(legendThresholds));
    yield put(fetchSuccess('getNitrogenThresholds'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getNitrogenThresholds', error.messaga as string));
  }
}
function deleteNitrogenCovercropRequest(fieldId: number, ccId: number) {
  const url = `${pixagriApiUrl}/fields/${fieldId}/covercrops/${ccId}`;
  return api.delete(url);
}
function* deleteNitrogenCoverCrop(action: DeleteCovercropAction) {
  try {
    yield put(fetchStart('deleteNitrogenCoverCrop'));
    const {fieldId, ccId} = action.payload;
    yield call(deleteNitrogenCovercropRequest, fieldId, ccId);
    yield call(reloadFieldsSummaryAfterDelay);
    yield put(fetchSuccess('deleteNitrogenCoverCrop'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('deleteNitrogenCoverCrop', error.message));
  }
}

export function* nitrogenSaga() {
  yield all([
    takeEvery(GET_SPECIES, getNitrogenSpecies),
    takeEvery(GET_COVERSAT_DATA, getNitrogenCoversat),
    takeEvery(ADD_COVER, postCover),
    takeEvery(GET_COVER_CROP, getCovercrop),
    takeEvery(UPDATE_COVER, updateCover),
    takeEvery(GET_NITROGEN_THRESHOLDS, getNitrogenThresholds),
    takeEvery(DELETE_COVER_CROP, deleteNitrogenCoverCrop),
  ]);
}
