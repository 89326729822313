import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {useIntl} from 'react-intl';
import AdapterLuxon from "@mui/lab/AdapterLuxon"
import { DateTime } from 'luxon';



interface LocalizationProviderProps {
    children: JSX.Element
}
const LocalizationProviderComponent: React.FC<LocalizationProviderProps> = (props) => {

    const { locale } = useIntl();


    return (
        <LocalizationProvider
        dateAdapter={AdapterLuxon}
        libInstance={DateTime}
        locale={locale}
        >
            {props.children}
        </LocalizationProvider>
    )
}

export default LocalizationProviderComponent