import {AxiosResponse} from 'axios';
import log from 'loglevel';
import {DateTime} from 'luxon';
import actions from 'modules/auth/actions/AuthActions';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {fetchError, fetchStart, fetchSuccess} from 'shared/actions';
import api, {pixagriApiUrl} from 'shared/services/ApiConfig';
import weatherActions, {
  DELETE_USER_WEATHER_STATION,
  DeleteUserWeatherStationAction,
  GET_CONNECTED_STATIONS,
  GET_WEATHER_CLIMATE_DATA,
  GET_WEATHER_CUMULATIVE_DATA,
  GET_WEATHER_HISTOGRAM_DATA,
  GET_WEATHER_PROVIDER_DATA,
  GetWeatherClimateDataAction,
  GetWeatherCumulativeDataAction,
  GetWeatherHistogramDataAction,
  MODIFY_USER_WEATHER_STATION,
  ModifyUserWeatherStationAction,
} from '../actions/WeatherActions';
import {WeatherStationCredentials} from '../models/BaseWeatherStationSchema';
import {Climate, ClimateDataChart, ClimateResponse} from '../models/Climate';
import {DeleteProviderData} from '../models/DeleteProviderData';
import {
  Histogram,
  HistogramDataChart,
  HistogramResponse,
} from '../models/Histrogram';
import {UserWeatherSationSagasPayload} from '../models/UserWeatherStationPayload';
import {WeatherStation, WeatherStationData} from '../models/WeaterStationData';
import {
  CumulativeDataChart,
  WeatherCumulative,
  WeatherCumulativeResponse,
} from '../models/WeatherCumulative';
import {
  ConnectedStationType,
  WeatherStationResponse,
} from '../models/WeatherStationResponse';

function getCumulativeDataRequest(fieldId: number) {
  const url = `${pixagriApiUrl}/fields/${fieldId}/weather/cumulatives`;
  return api.get(url);
}
function* getCumulativeData(action: GetWeatherCumulativeDataAction) {
  try {
    yield put(fetchStart('getCumulativeData'));
    const fieldId = action.payload;
    const res: AxiosResponse = yield call(getCumulativeDataRequest, fieldId);
    let data = res.data as WeatherCumulativeResponse;

    const weatherCumulativeState: {[key: string]: CumulativeDataChart[]} = {};
    const currentYear = DateTime.now().year;
    if (data.data) {
      data.data.forEach((dt: WeatherCumulative) => {
        const {cumulative_data, ...otherprops} = dt;
        const {rain, Tmean, ETO, P_ETO} = cumulative_data;
        const newData: CumulativeDataChart = {
          ...otherprops,
          ETO: ETO,
          rain: rain,
          Tmean: Tmean,
          P_ETO: P_ETO,
        };
        const campaignId = dt.campaign_id;
        const dataDateTime = DateTime.fromISO(dt.date);
        newData.date_epoch = dataDateTime.toMillis();
        if (!weatherCumulativeState[campaignId]) {
          weatherCumulativeState[campaignId] = [];
        }
        weatherCumulativeState[campaignId].push(newData);
      });

      Object.values(weatherCumulativeState).forEach((dataArray) => {
        // sort array by date to be sure that index 0 is the first date
        dataArray.sort((a, b) => a.date_epoch - b.date_epoch);
        // compute on year diff to apply to all custom date
        const firstDataYear = DateTime.fromMillis(dataArray[0].date_epoch).year;
        const yearDiff = currentYear - firstDataYear;
        dataArray.forEach((data) => {
          data.date_custom = DateTime.fromMillis(data.date_epoch)
            .set({year: currentYear + yearDiff})
            .toMillis();
        });
      });

      yield put(
        weatherActions.getCumulativeDataSuccess(weatherCumulativeState),
      );
    } else {
      log.info(`No data for field ${fieldId}`);
    }
    yield put(fetchSuccess('getCumulativeData'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getCumulativeData', error as string));
  }
}
function getHistogramDataRequest(fieldId: number) {
  const url = `${pixagriApiUrl}/fields/${fieldId}/weather`;
  return api.get(url);
}
function* getHistogramData(action: GetWeatherHistogramDataAction) {
  try {
    yield put(fetchStart('getHistogramData'));
    const fieldId = action.payload;
    //@ts-ignore
    const res: AxiosResponse = yield call(getHistogramDataRequest, fieldId);
    const data = res.data as HistogramResponse;

    const weatherHistogramState: {[key: string]: HistogramDataChart} = {};
    if (data.data) {
      data.data.forEach((dt: Histogram) => {
        dt.date_epoch = DateTime.fromISO(dt.date).toMillis();
        // TODO: why toFixed & parseFloat here!!
        dt.ETO = dt.ETO ? parseFloat(dt.ETO.toFixed(2)) : null;
        dt.rain = dt.rain ? parseFloat(dt.rain.toFixed(0)) : null;
        dt.Tmean = dt.Tmean ? parseFloat(dt.Tmean.toFixed(1)) : null;
        const newData: HistogramDataChart = {
          date: dt.date,
          date_epoch: dt.date_epoch,
          rain: dt.rain,
          Tmean: dt.Tmean,
          ETO: dt.ETO,
          P_ETO: dt.P_ETO,
        };
        weatherHistogramState[newData.date] = newData;
      });
      yield put(weatherActions.getHistogramDataSuccess(weatherHistogramState));
    } else {
      log.info(`No Data for field ${fieldId}`);
    }
    yield put(fetchSuccess('getHistogramData'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getHistogramData', error as string));
  }
}
function getClimateDataRequest(fieldId: number) {
  const url = `${pixagriApiUrl}/fields/${fieldId}/weather/historical`;
  return api.get(url);
}
function* getClimateData(action: GetWeatherClimateDataAction) {
  try {
    yield put(fetchStart('getClimateData'));
    const fieldId = action.payload;
    const res: AxiosResponse = yield call(getClimateDataRequest, fieldId);
    const data = res.data as ClimateResponse;

    const weatherClimateState: Climate[] = [];
    if (data.data && data.data.length) {
      data.data.forEach((dt: Climate) => {
        dt.date_epoch = DateTime.fromISO(dt.date).toMillis();
        if (dt.ETO) {
          dt.ETO.area = [dt.ETO.q1, dt.ETO.q3];
        }
        if (dt.rain) {
          dt.rain.area = [dt.rain.q1, dt.rain.q3];
        }
        if (dt.Tmean) {
          dt.Tmean.area = [dt.Tmean.q1, dt.Tmean.q3];
        }
        if (dt.P_ETO) {
          dt.P_ETO.area = [dt.P_ETO.q1, dt.P_ETO.q3];
        }
        weatherClimateState.push(dt);
      });
      weatherClimateState.sort((a, b) => a.date_epoch - b.date_epoch);

      const currentYear = DateTime.now().year;
      const firstDataYear = DateTime.fromMillis(
        weatherClimateState[0].date_epoch,
      ).year;
      const yearDiff = currentYear - firstDataYear;

      weatherClimateState.forEach((data) => {
        data.date_custom = DateTime.fromMillis(data.date_epoch)
          .set({year: currentYear + yearDiff})
          .toMillis();
      });
      const weatherClimateDataChart = weatherClimateState.map((data) => {
        const {ETO, rain, Tmean, P_ETO, ...otherprops} = data;
        const newData: ClimateDataChart = {
          ...otherprops,
          P_ETO_area: P_ETO.area,
          P_ETO_med: P_ETO.med,
          ETO_area: ETO.area,
          ETO_med: ETO.med,
          rain_area: rain.area,
          rain_med: rain.med,
          Tmean_area: Tmean.area,
          Tmean_med: Tmean.med,
        };
        return newData;
      });
      yield put(weatherActions.getClimateDataSuccess(weatherClimateDataChart));
    } else {
      log.info(`No data for field ${fieldId}`);
    }
    yield put(fetchSuccess('getClimateData'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getClimateData', error as string));
  }
}

function getWeatherProviderDataRequest() {
  const url = `${pixagriApiUrl}/connected_stations/providers`;
  return api.get(url);
}

function* getWeatherStationData() {
  try {
    yield put(fetchStart('getWeatherStationData'));
    const res: AxiosResponse = yield call(getWeatherProviderDataRequest);
    // const res = dataStation;
    const data = res.data as WeatherStationResponse[];
    const dataObject = {} as {
      [key in ConnectedStationType]?: WeatherStationResponse;
    };

    data.forEach((data) => {
      dataObject[data.provider_type] = {
        provider_id: data.provider_id,
        provider_name: data.provider_name,
        enabled: data.enabled,
        provider_type: data.provider_type,
      };
    });
    yield put(weatherActions.getWeatherProviderDataSuccess(dataObject));
    yield put(fetchSuccess('getWeatherStationData'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getWeatherStationData', error as string));
  }
}

function modifyUserWeatherStationRequest(
  data: UserWeatherSationSagasPayload<WeatherStationCredentials>,
) {
  const url = `${pixagriApiUrl}/connected_stations/credentials`;
  return api.post(url, data);
}

function* modifyUserWeatherStation(action: ModifyUserWeatherStationAction) {
  try {
    yield put(fetchStart('modifyUserWeatherStation'));
    const data = {
      uid: action.payload.uid,
      provider_id: action.payload.provider_id,
      credentials: action.payload.credentials,
    };
    yield call(modifyUserWeatherStationRequest, data);
    yield put(actions.loadUserInfo());
    yield put(fetchSuccess('modifyUserWeatherStation'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('modifyUserWeatherStation', error as string));
  }
}

function deleteUserWeatherStationRequest(data: DeleteProviderData) {
  const url = `${pixagriApiUrl}/users/${data.uid}/connected_stations/credentials/providers/${data.provider_id}`;
  return api.delete(url);
}

function* deleteUserWeatherStation(action: DeleteUserWeatherStationAction) {
  try {
    yield put(fetchStart('deleteUserWeatherStation'));
    const data = {
      uid: action.payload.uid,
      provider_id: action.payload.provider_id,
    };
    yield call(deleteUserWeatherStationRequest, data);
    yield put(actions.loadUserInfo());
    yield put(fetchSuccess('deleteUserWeatherStation'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('deleteUserWeatherStation', error as string));
  }
}

function getConnectedStationsRequest() {
  const url = `${pixagriApiUrl}/connected_stations`;
  return api.get(url);
}

function* getConnectedStations() {
  try {
    yield put(fetchStart('getConnectedStations'));
    const res: AxiosResponse = yield call(getConnectedStationsRequest);
    const data = res.data.data as WeatherStation[];
    const resProvider: AxiosResponse = yield call(
      getWeatherProviderDataRequest,
    );
    const dataProvider = resProvider.data as WeatherStationResponse[];
    const stationData: WeatherStationData = {};
    if (data.length > 0) {
      data.forEach((station) => {
        const provider = dataProvider.find(
          (provider) => provider.provider_id === station.provider_id,
        );
        if (!stationData[provider!.provider_type]) {
          stationData[provider!.provider_type] = [station];
        } else {
          stationData[provider!.provider_type]!.push(station);
        }
      });
    }

    yield put(weatherActions.getConnectedStationSuccess(stationData));
    yield put(fetchSuccess('getConnectedStations'));
  } catch (error) {
    log.error(error);
    yield put(fetchError('getConnectedStations', error as string));
  }
}

export function* weatherSagas() {
  yield all([
    takeEvery(GET_WEATHER_HISTOGRAM_DATA, getHistogramData),
    takeEvery(GET_WEATHER_CLIMATE_DATA, getClimateData),
    takeEvery(GET_WEATHER_CUMULATIVE_DATA, getCumulativeData),
    takeEvery(GET_WEATHER_PROVIDER_DATA, getWeatherStationData),
    takeEvery(MODIFY_USER_WEATHER_STATION, modifyUserWeatherStation),
    takeEvery(DELETE_USER_WEATHER_STATION, deleteUserWeatherStation),
    takeEvery(GET_CONNECTED_STATIONS, getConnectedStations),
  ]);
}
